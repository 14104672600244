<template lang="pug">
#content.adv_center
  .form_wrap
    .tit_area
      h1.tit 광고주 {{isUpdate ? isAdmin ? '조회' : '수정' : '등록'}}
    .form
      table
        caption
          span 광고주관리 - 광고주등록
        colgroup
          col(style='width:173px')
          col
        tbody
          tr
            th(scope='row')
              label.lbl(for='input-name')
                | 법인명 (광고주명)
                em.required
                  span.blind 필수
            td
              .inp_area(:class="{has_error: !validationName}")
                .inp_bx(style='width:300px')
                  // [D] input에 입력된 내용 있으면 .txt_lbl 숨김 처리
                  span.txt_lbl(v-if="advertiserRegistration.advertiserName === ''") 예: (주)엔에이치엔페이코
                  input#input-name(type='text', maxlength="30", :value="advertiserRegistration.advertiserName",
                    @input="inputAdvertiserName($event)",
                    :disabled="advertiserRegistration.advertiserCode",
                    :style="{backgroundColor: advertiserRegistration.advertiserCode ? '#eee' : ''}")
                span.txt_explain(v-if="!validationName") 광고주명은 필수값입니다.
          tr
            th(scope='row')
              label.lbl(for='input-manager-name') 광고주 실무자명
            td
              .inp_area
                .inp_bx(style='width:200px')
                  // [D] input에 입력된 내용 있으면 .txt_lbl 숨김 처리
                  span.txt_lbl(v-if="advertiserRegistration.managerName === ''") 예: 홍길동
                  input#input-manager-name(type='text', maxlength="10",
                    :value="advertiserRegistration.managerName",
                    @input="inputManagerName($event)",
                    :disabled="isView",
                    :style="{backgroundColor: isView ? '#eee' : ''}",)
          tr
            th(scope='row')
              span.lbl 실무자 연락처
            td
              .inp_area(:class="{has_error: !validationManagerEmail || !validationManagerEmailFormat}", style="display: inline-block")
                span.txt_tit_inp E-mail
                  em.required
                    span.blind 필수
                .inp_bx(style='width:280px')
                  // [D] input에 입력된 내용 있으면 .txt_lbl 숨김 처리
                  span.txt_lbl(v-if="advertiserRegistration.managerEmail === ''") 예: payco@payco.com
                  input(type='text', maxlength="50", :value="advertiserRegistration.managerEmail", @input="inputManagerEmail($event)",
                    :disabled="isView", :style="{backgroundColor: isView ? '#eee' : ''}",)
                span.txt_explain(v-if="!validationManagerEmail") 실무자 E-mail은 필수값입니다.
                span.txt_explain(v-else-if="!validationManagerEmailFormat") 유효한 메일 주소를 입력해주세요.
                span.txt_explain * 광고주 승인여부 및 캠페인 운영 관련 알림 메일은 위 메일 주소로 발송 됩니다.
              .inp_area(style="display: inline-block; margin-left: 30px;")
                span.txt_tit_inp 대표번호
                .inp_bx(style='width:260px')
                  // [D] input에 입력된 내용 있으면 .txt_lbl 숨김 처리
                  span.txt_lbl(v-if="advertiserRegistration.managerPhoneNumber === ''") 예: 000-0000-0000
                  input(type='text', maxlength="15", :value="advertiserRegistration.managerPhoneNumber", @input="inputManagerPhone($event)",
                    :disabled="isView", :style="{backgroundColor: isView ? '#eee' : ''}",)
                span.txt_explain(v-if="!validationManagerEmail") &nbsp
                span.txt_explain &nbsp
          tr
            th(scope='row')
              span.lbl 사업자 등록번호
                em.required
                  span.blind 필수
            td
              .inp_area(:class="{has_error: !validationBusinessRegistrationNumber}")
                .inp_bx(style='width:100px')
                  input(type='text', maxlength="3", :value="advertiserRegistration.businessRegistrationNumber[0]", @input="inputBusinessNumber($event, 0)",
                    :disabled="isView", :style="{backgroundColor: isView ? '#eee' : ''}",)
                span.separate_bar -
                .inp_bx(style='width:90px')
                  input(type='text', maxlength="2", :value="advertiserRegistration.businessRegistrationNumber[1]", @input="inputBusinessNumber($event, 1)",
                    :disabled="isView", :style="{backgroundColor: isView ? '#eee' : ''}",)
                span.separate_bar -
                .inp_bx(style='width:160px')
                  input(type='text', maxlength="5", :value="advertiserRegistration.businessRegistrationNumber[2]", @input="inputBusinessNumber($event, 2)",
                    :disabled="isView", :style="{backgroundColor: isView ? '#eee' : ''}",)
                span.txt_explain(v-if="!validationBusinessRegistrationNumber") 사업자 등록번호는 필수값입니다.
          tr
            th(scope='row')
              label.lbl 사업자 등록증
                em.required
                  span.blind 필수
            td
              .inp_area(:class="{has_error: !validationBusinessRegistrationImage}")
                .addfile_wrap
                  span.inp_file
                    span.txt_file(style="cursor: pointer",
                      @click='selectFile("businessRegistration", advertiserRegistration.businessRegistrationImage.url)') 파일업로드
                  file-uploader(v-if="advertiserRegistration.businessRegistrationImage.file"
                    :api="fileUploader('businessRegistration')",
                    :file="advertiserRegistration.businessRegistrationImage.file",
                    :fileName="advertiserRegistration.businessRegistrationImage.file.name",
                    :fileUrl="advertiserRegistration.businessRegistrationImage.url",
                    @onUploaded="setUploadedBusinessRegistrationImageUrl",
                    @onClickRemove="removeBusinessRegistrationImage")
                span.txt_explain(v-if="!validationBusinessRegistrationImage") 사업자 등록증은 필수값입니다.
          tr
            th.align_top(scope='row')
              label.lbl 통장사본
            td
              .inp_area(:class="{has_error: !validBankBookImage}")
                .addfile_wrap
                  span.inp_file
                    span.txt_file(style="cursor: pointer",
                      @click='selectFile("bankBook", advertiserRegistration.bankBookImage.file.name)') 파일업로드
                  .bx_add_file(v-if='advertiserRegistration.bankBookImage.file.name !== null')
                    ul.lst_einfo
                      li
                        p.file_name
                          a.txt_link(@click='downloadBankbookImage') {{ advertiserRegistration.bankBookImage.file.name }}
                        button.btn_del_file(@click='removeBankBookImage')
                          span.blind 첨부파일 삭제
                span.txt_explain * 법인명이 표기된 통장 사본을 첨부해주세요.
                span.txt_explain * 실제 업로드한 파일명이 아닌 랜덤한 값으로 파일명이 업로드 됩니다.
                span.txt_explain * 용량 1MB이하, PNG, JPG 형식만 허용됩니다.
    advertiser-agree(
      :personalInformationMandatoryAgreeView='personalInformationMandatoryAgreeView'
      :personalInformationMandatoryAgree.sync='advertiserRegistration.personalInformationMandatoryAgree'
      :personalInformationOptionalAgreeView='personalInformationOptionalAgreeView'
      :personalInformationOptionalAgree.sync='advertiserRegistration.personalInformationOptionalAgree'
      :creditInformationOptionalAgreeView='creditInformationOptionalAgreeView'
      :creditInformationOptionalAgree.sync='advertiserRegistration.creditInformationOptionalAgree'
      :personalInformationOptionalAgreeDisable='!personalInformationOptionalAgreeAble'
      :personalInformationOptionalAgreeChangeable.sync='personalInformationOptionalAgreeChangeable'
    )
    .btn_act_area.form_v2
      button.btn.btn_gray3(type='button', @click="goAccount") {{isUpdate ? '이전' : '취소'}}
      button.btn.btn_bl(
        v-if="!(isAdmin && isUpdate)"
        type='button'
        :class="{disable: !isValidInput}" :disabled="!isValidInput"
        @click="registerModifyAdvertiser"
      ) {{isUpdate ? '수정' : '등록'}}
      // [D] 버튼이 disable 시  "disable" 클래스 및 "disabled" 속성을 추가
    confirm(v-show='needAgreeView' :confirm='needAgreeConfirm' @yes='needAgreeView = false')
</template>

<script>
import $ from '@/util'
import FileUploader from '../common/FileUploader'
import fileManager from '../common/file-manager'
import advertiserApi from '@/api/advertiser'
import store from '@/store'
import { mapGetters } from 'vuex'
import AdvertiserAgree from './fragments/AdvertiserAgree'
import Confirm from '../shop/fragments/Confirm'
import { PERSONAL_INFORMATION_MANDATORY_CONFIRM, PERSONAL_INFORMATION_OPTIONAL_CONFIRM } from './data/advertiser-personal-information-collect-use-agree'

const FILE_DEFAULT = {
  data: null,
  mimeType: null,
  name: null,
  size: 0
}

export default {
  data: function () {
    return {
      advertiserRegistration: {
        advertiserCode: null,
        advertiserName: '',
        businessRegistrationNumber: ['', '', ''],
        businessRegistrationImage: {
          file: null,
          url: ''
        },
        bankBookImage: {
          file: FILE_DEFAULT,
          isUpdated: false
        },
        managerName: '',
        managerEmail: '',
        managerPhoneNumber: '',
        personalInformationMandatoryAgree: false,
        personalInformationOptionalAgree: false,
        creditInformationOptionalAgree: false
      },
      needAgreeView: false,
      personalInformationOptionalAgreeView: true,
      creditInformationOptionalAgreeView: true,
      personalInformationOptionalAgreeChangeable: true
    }
  },
  mounted () {
    if (this.isUpdate) {
      this.setAdvertiserRegistration(this.$route.params.advertiserCode)
    }
    this.$nextTick().then(() => window.scrollTo(0, 0))
  },
  methods: {
    inputAdvertiserName ({ target }) {
      this.advertiserRegistration.advertiserName = target.value
    },
    inputManagerName ({ target }) {
      this.advertiserRegistration.managerName = target.value
    },
    inputManagerEmail ({ target }) {
      this.advertiserRegistration.managerEmail = target.value
    },
    inputManagerPhone ({ target }) {
      target.value = target.value.replace(/\D/g, '')
      this.advertiserRegistration.managerPhoneNumber = target.value
    },
    inputBusinessNumber ({ target }, index) {
      this.$set(this.advertiserRegistration.businessRegistrationNumber, index, target.value.replace(/\D/g, ''))
    },
    selectFile (type, filename) {
      if (this.isView) {
        return
      }
      if (filename) {
        this.showError('첨부 파일 수정 시 기존 파일 삭제 후 업로드 해 주세요.')
        return
      }
      const callback =
        type === 'businessRegistration'
          ? file => { this.advertiserRegistration.businessRegistrationImage.file = file }
          : file => {
            this.advertiserRegistration.bankBookImage.file = file
            if (this.isUpdate) {
              this.advertiserRegistration.bankBookImage.isUpdated = true
            }
          }

      fileManager.openFile('image/*').then(callback)
    },
    fileUploader (type) {
      return function (file, progress, success) {
        advertiserApi.uploadImageForAdvertiser(
          type,
          file,
          progress,
          success
        )
      }
    },
    removeBusinessRegistrationImage () {
      if (this.isView) {
        return
      }
      this.$set(this.advertiserRegistration, 'businessRegistrationImage', {
        file: null,
        url: ''
      })
    },
    removeBankBookImage () {
      if (this.isView) {
        return
      }
      this.$set(this.advertiserRegistration.bankBookImage, 'file', FILE_DEFAULT)
      if (this.isUpdate) {
        this.advertiserRegistration.bankBookImage.isUpdated = true
      }
    },
    setUploadedBusinessRegistrationImageUrl (url) {
      if (!url || !url.logoUrl) {
        this.showError('사업자 등록증 이미지 저장에 실패했습니다.')
        return
      }

      const savedImgUrl = url.logoUrl
      if ($.isObject(savedImgUrl) && savedImgUrl.storedPath) {
        this.advertiserRegistration.businessRegistrationImage.url = savedImgUrl.storedPath
      } else if ($.isString(savedImgUrl) && savedImgUrl.length > 0) {
        this.advertiserRegistration.businessRegistrationImage.url = savedImgUrl
      } else {
        this.showError('사업자 등록증 이미지 저장에 실패했습니다.')
      }
    },
    goAccount () {
      this.$router.push('/account')
    },
    registerModifyAdvertiser () {
      if (this.isInvalidPersonalInformationMandatoryAgree || this.isInvalidPersonalInformationOptionalAgree) {
        this.needAgreeView = true
        return
      }
      const params = {
        advertiserName: this.advertiserRegistration.advertiserName,
        businessRegistrationNumber: this.advertiserRegistration.businessRegistrationNumber.join('-'),
        businessRegistrationImageUrl: this.advertiserRegistration.businessRegistrationImage.url,
        bankBookImage: this.advertiserRegistration.bankBookImage.file.data,
        isBankBookImageUpdated: this.advertiserRegistration.bankBookImage.isUpdated,
        managerName: this.advertiserRegistration.managerName,
        managerEmail: this.advertiserRegistration.managerEmail.trim(),
        managerPhoneNumber: this.advertiserRegistration.managerPhoneNumber,
        personalInformationOptionalAgree: this.advertiserRegistration.personalInformationOptionalAgree,
        creditInformationOptionalAgree: this.advertiserRegistration.creditInformationOptionalAgree
      }

      if (this.advertiserRegistration.advertiserCode) {
        params.advertiserCode = this.advertiserRegistration.advertiserCode
      }

      advertiserApi.registerAdvertiser(
        params,
        e => { this.advertiserRegistration.bankBookImage.progress = e.loaded / e.total * 100 },
        body => {
          if (body.isSuccessful) {
            this.isUpdate
              ? this.showError('광고주 정보가 수정되었습니다.\n담당자가 승인 후 최종 완료 됩니다.')
              : this.showError('광고주 등록신청을 하셨습니다.\n담당자가 승인 후 최종 완료 됩니다.')
            this.goAccount()
          } else {
            this.showError('광고주 등록신청을 실패했습니다.')
          }
        },
        () => { this.showError('광고주 등록신청을 실패했습니다.') }
      )
    },
    setAdvertiserRegistration (advertiserCode) {
      advertiserApi.getAdvertiserRegistration(
        advertiserCode,
        body => {
          const info = body.advertiserRegistration
          this.advertiserRegistration = {
            advertiserCode: info.advertiserCode,
            advertiserName: info.advertiserName,
            businessRegistrationNumber: this.formatBusinessNumber(info.businessRegistrationNumber),
            businessRegistrationImage: {
              file: info.businessRegistrationImageUrl ? { name: info.businessRegistrationImageUrl.split('/').pop() } : null,
              url: info.businessRegistrationImageUrl
            },
            bankBookImage: {
              file: { name: info.bankBookImageName, mimeType: 'image/png' },
              isUpdated: false
            },
            managerName: info.managerName,
            managerEmail: info.managerEmail,
            managerPhoneNumber: info.managerPhoneNumber,
            personalInformationMandatoryAgree: true,
            personalInformationOptionalAgree: info.personalInformationOptionalAgree,
            creditInformationOptionalAgree: info.creditInformationOptionalAgree
          }
          this.personalInformationOptionalAgreeView = !this.isView && this.advertiserRegistration.personalInformationOptionalAgree === false
          this.creditInformationOptionalAgreeView = !this.isView && this.advertiserRegistration.creditInformationOptionalAgree === false
          this.personalInformationOptionalAgreeChangeable = this.advertiserRegistration.personalInformationOptionalAgree === false
        }
      )
    },
    showError (message) {
      store.commit('setError', {
        id: Date.now(),
        message: message,
        hadshown: false
      })
    },
    formatBusinessNumber (number) {
      const newNumber = number.replace(/\D/g, '')
      const businessNumber = ['', '', '']
      const length = newNumber.length
      for (let i = 0; i < length && i < 3; i += 1) {
        businessNumber[0] += newNumber[i]
      }
      for (let i = 3; i < length && i < 5; i += 1) {
        businessNumber[1] += newNumber[i]
      }
      for (let i = 5; i < length; i += 1) {
        businessNumber[2] += newNumber[i]
      }
      return businessNumber
    },
    downloadBankbookImage () {
      if (this.isAdmin) {
        return
      }
      const advertiserCode = this.advertiserRegistration.advertiserCode
      const bankBookImageName = this.advertiserRegistration.bankBookImage.file.name
      advertiserApi.getAdvertiserBankBookImage(
        advertiserCode,
        blob => {
          if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, bankBookImageName)
          } else {
            if (blob.size === 0) return
            const a = document.createElement('a')
            a.href = URL.createObjectURL(blob)
            a.setAttribute('download', bankBookImageName)
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          }
        }
      )
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    validationName () {
      return this.advertiserRegistration.advertiserName !== ''
    },
    validationBusinessRegistrationNumber () {
      return this.advertiserRegistration.businessRegistrationNumber.every(
        (currentValue) => currentValue !== ''
      )
    },
    validationBusinessRegistrationImage () {
      return this.advertiserRegistration.businessRegistrationImage.url !== ''
    },
    validationManagerEmail () {
      return this.advertiserRegistration.managerEmail !== ''
    },
    validationManagerEmailFormat () {
      if (this.advertiserRegistration.managerEmail === '') return true
      return this.advertiserRegistration.managerEmail.includes('@')
    },
    isUpdate () {
      return this.$route.params.advertiserCode !== undefined
    },
    isView () {
      return this.isUpdate && this.isAdmin
    },
    validBankBookImage () {
      const isBankBookFile = this.advertiserRegistration.bankBookImage.file.data !== null
      const isPngOrJpg = ['image/png', 'image/jpeg', 'image/jpg'].includes(this.advertiserRegistration.bankBookImage.file.mimeType)
      const isMoreThenMegaByte = this.advertiserRegistration.bankBookImage.file.size > 1024 * 1024

      if (isBankBookFile) {
        if (!isPngOrJpg) return false
        if (isMoreThenMegaByte) return false
      }
      return true
    },
    isValidInput () {
      return this.validationName &&
        this.validationManagerEmail &&
        this.validationManagerEmailFormat &&
        this.validationBusinessRegistrationNumber &&
        this.validationBusinessRegistrationImage &&
        this.validBankBookImage
    },
    isInvalidPersonalInformationMandatoryAgree () {
      return this.personalInformationMandatoryAgreeView && !this.advertiserRegistration.personalInformationMandatoryAgree
    },
    isInvalidPersonalInformationOptionalAgree () {
      return this.personalInformationOptionalAgreeAble && !this.advertiserRegistration.personalInformationOptionalAgree
    },
    personalInformationMandatoryAgreeView () {
      return !this.isUpdate
    },
    personalInformationOptionalAgreeAble () {
      return (this.advertiserRegistration.managerName && this.advertiserRegistration.managerName.trim()) ||
        (this.advertiserRegistration.managerPhoneNumber && this.advertiserRegistration.managerPhoneNumber.trim()) ||
        (this.advertiserRegistration.bankBookImage.file.name && this.advertiserRegistration.bankBookImage.file.name.trim())
    },
    needAgreeConfirm () {
      return this.isInvalidPersonalInformationMandatoryAgree ? PERSONAL_INFORMATION_MANDATORY_CONFIRM : PERSONAL_INFORMATION_OPTIONAL_CONFIRM
    }
  },
  components: { Confirm, AdvertiserAgree, FileUploader }
}
</script>
