function isEnglish (char) {
  return /[a-zA-Z]/.test(char)
}

function isKorean (char) {
  return /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(char)
}

function isNumber (char) {
  return /[0-9]/.test(char)
}

function isSpecial (char) {
  return !isEnglish(char) && !isKorean(char) && !isNumber(char)
}

function areEitherLanguages (firstLang, secondLang, c1, c2) {
  return (
    (firstLang(c1) && secondLang(c2)) ||
    (firstLang(c2) && secondLang(c1))
  )
}

export { isEnglish, isKorean, isNumber, isSpecial, areEitherLanguages }
