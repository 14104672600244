import { USER_ROLE } from '@/types/user-role-types'

const MENUS = [
  {
    name: '계정관리',
    path: '/account',
    isActive: true,
    visibleAuthorities: [USER_ROLE.NE_MGR, USER_ROLE.NORMAL_MGR, USER_ROLE.PAYMENT_MGR, USER_ROLE.NORMAL, USER_ROLE.AROUND_ME_MGR, USER_ROLE.CAMPAIGN_NE_MGR],
    invisibleAuthorities: [],
    activeEnv: ['ALPHA', 'BETA', 'REAL']
  },
  {
    name: 'CLO관리',
    path: '/clo-merchant',
    isActive: false,
    visibleAuthorities: [USER_ROLE.NE_MGR],
    invisibleAuthorities: [],
    activeEnv: ['ALPHA', 'BETA', 'REAL']
  },
  {
    name: 'CLO캠페인',
    path: '/clo-campaign',
    isActive: false,
    visibleAuthorities: [USER_ROLE.NE_MGR, USER_ROLE.PAYMENT_MGR],
    invisibleAuthorities: [],
    activeEnv: ['ALPHA', 'BETA', 'REAL']
  },
  {
    name: 'CLO리포트',
    path: '/clo/report',
    isActive: false,
    visibleAuthorities: [USER_ROLE.NE_MGR, USER_ROLE.NORMAL_MGR, USER_ROLE.PAYMENT_MGR],
    invisibleAuthorities: [USER_ROLE.AROUND_ME_MGR],
    activeEnv: ['ALPHA', 'BETA', 'REAL']
  },
  {
    name: 'PAYCO캠페인',
    path: '/campaign',
    isActive: false,
    visibleAuthorities: [USER_ROLE.NE_MGR, USER_ROLE.AROUND_ME_MGR, USER_ROLE.CAMPAIGN_NE_MGR],
    invisibleAuthorities: [],
    activeEnv: ['ALPHA', 'BETA', 'REAL']
  },
  {
    name: 'PAYCO리포트',
    path: '/payco-report',
    isActive: false,
    visibleAuthorities: [USER_ROLE.NE_MGR, USER_ROLE.NORMAL_MGR, USER_ROLE.PUBLISHER_MGR],
    invisibleAuthorities: [],
    activeEnv: ['ALPHA', 'BETA', 'REAL']
  },
  {
    name: '어드민관리',
    path: '/admin-management',
    isActive: false,
    visibleAuthorities: [USER_ROLE.NE_MGR],
    invisibleAuthorities: [],
    activeEnv: ['ALPHA', 'BETA', 'REAL']
  },
  {
    name: '결제관리',
    path: '/payment',
    isActive: false,
    visibleAuthorities: [USER_ROLE.NE_MGR, USER_ROLE.NORMAL_MGR],
    invisibleAuthorities: [],
    activeEnv: ['ALPHA', 'BETA', 'REAL']
  },
  {
    name: '데이터추출',
    path: '/extraction',
    isActive: false,
    visibleAuthorities: [USER_ROLE.NE_MGR],
    invisibleAuthorities: [],
    activeEnv: ['BETA']
  }
]

export { MENUS }
