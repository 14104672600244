import _ from '@/partial'

export default {
  state: {
    errors: [],
    toasts: [],
    confirms: []
  },
  getters: {
    errors: state => state.errors,
    toast: state => state.toasts[0],
    confirm: state => state.confirms[0]
  },
  mutations: {
    setError (state, error) {
      state.errors.push(error)
    },
    setHasShown (state, errId) {
      _.go(
        state.errors,
        _.findIndex(e => e.id === errId),
        i => _.set(state.errors[i], 'hadshown', true)
      )
    },
    removeError (state, errId) {
      _.go(
        state.errors,
        _.reject(e => e.id === errId),
        _(_.set, state, 'errors')
      )
    },
    pushToast (state, toast) {
      state.toasts.push(toast)
    },
    shiftToast (state) {
      state.toasts.shift()
    },
    pushConfirm (state, confirm) {
      state.confirms.push(confirm)
    },
    shiftConfirm (state) {
      state.confirms.shift()
    }
  }
}
