<template lang="pug">
.datepicker_tui
  .datepicker_wrap
    .tui-datepicker-input.tui-datetime-input.tui-has-focus
      input#startpicker-input(type='text', aria-label='Date')
      span.tui-ico-date
      #startpicker-container(style='margin-left: -1px;')
    span.datepicker_bar ~
    .tui-datepicker-input.tui-datetime-input.tui-has-focus
      input#endpicker-input(type='text', aria-label='Date')
      span.tui-ico-date
      #endpicker-container(style='margin-left: -1px;')
</template>

<script>
const DatePicker = require('tui-date-picker')

export default {
  name: 'DateRangePicker',
  props: ['maxRange'],
  data: function () {
    return {
      rangepicker: null
    }
  },
  mounted: function () {
    const today = new Date()
    this.rangepicker = DatePicker.createRangePicker({
      startpicker: {
        input: '#startpicker-input',
        container: '#startpicker-container'
      },
      endpicker: {
        input: '#endpicker-input',
        container: '#endpicker-container'
      },
      type: 'date',
      format: 'yyyy-MM-dd',
      selectableRanges: [
        [today, new Date(today.getFullYear() + 1, today.getMonth(), today.getDate())]
      ]
    })
    this.rangepicker.on('change:start', function () {
      this.syncRangesToEndpicker()
      this.$emit('change:start', this.rangepicker.getStartDate())
    }.bind(this))
    this.rangepicker.on('change:end', function () {
      this.$emit('change:end', this.rangepicker.getEndDate())
    }.bind(this))
  },
  methods: {
    syncRangesToEndpicker: function () {
      if (!this.maxRange) {
        return
      }
      const startPicker = this.rangepicker.getStartpicker()
      const endPicker = this.rangepicker.getEndpicker()
      const startDate = startPicker.getDate()
      const originalEndDate = endPicker.getDate()
      if (startDate) {
        const overlappedRange = startPicker.findOverlappedRange(
          new Date(startDate),
          new Date(startDate)
        )
        const maximum = new Date(
          startDate.getFullYear() + this.maxRange.years,
          startDate.getMonth() + this.maxRange.months,
          startDate.getDate() + this.maxRange.days
        )
        const startTime = startDate.getTime()
        const endTime = Math.min(maximum.getTime(), overlappedRange[1].getTime())
        if (originalEndDate < startTime) {
          endPicker.setDate(startTime)
        } else if (originalEndDate > endTime) {
          endPicker.setDate(endTime)
        }
        endPicker.setRanges([[startTime, endTime]])
      } else {
        endPicker.setNull()
      }
    }
  },
  watch: {
    startDate: function (newDate) {
      this.rangepicker.setStartDate(newDate)
    },
    endDate: function (newDate) {
      this.rangepicker.setEndDate(newDate)
    }
  }
}
</script>
