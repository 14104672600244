<template lang="pug">
.wrap
  h1.blind 비밀번호 변경안내
  <!-- container -->
  .dimmed(style="display:none;")
  #container
    .content.security_guide_wrap
      .layer_content
        .guide_area
          strong.thumb
            img(src="/static/img/password_change_guide.png" alt="비밀번호를 변경해주세요.")
          p.guide_txt.password_guid_font 마지막으로 비밀번호를 변경하신지 <br> 90일 이상 지났습니다.
          p.guide_txt.point_guide_txt.password_guid_font 안전한 서비스 이용을 위해  <br> 비밀번호를 변경해주세요.
        .btn_bx
          button.btn#password_change(type="button" @click="passwordChange") 비밀번호 변경
  <!-- //container -->
</template>
<script>

import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'PasswordChangeGuide',
  props: {
    nextUrl: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapMutations(['setIsNeedToChangePassword']),
    passwordChange () {
      document.location.href = location.protocol + '//' + process.env.LOGIN_HOST + '/campaign/updateRecentDisplayCampaign.nhn?changeYN=Y&nextUrl=' + encodeURIComponent(process.env.MAIN_URI + '/#' + this.nextUrl)
    }
  },
  computed: {
    ...mapGetters(['isAdmin'])
  },
  mounted () {
    this.setIsNeedToChangePassword(true)
  },
  beforeRouteLeave (to, from, next) {
    if (this.isAdmin) {
      next(false)
    } else {
      this.setIsNeedToChangePassword(false)
      next()
    }
  }
}
</script>

<style scoped>
html,
body,
body.m {
  background-color: transparent;
}

.password_guid_font {
  font-family: 'NotoSans', 'Apple SD Gothic Neo', '돋움', dotum, sans-serif;
}

.content {
  max-width: 330px;
  padding: 0 0 35px;
  margin: 0 auto
}

/*비밀번호 변경 안내 팝업*/
.layer_content {
  position: relative;
  padding: 60px 45px 35px
}

.layer_content .guide_area::before {
  position: absolute;
  top: 47%;
  right: 0;
  width: 247px;
  height: 168px;
  background-image: url(/public/static/img/password_change_guide2.png);
  background-size: 100% 100%;
  content: '';
}

.layer_content .guide_area .thumb {
  display: block;
  padding-bottom: 20px;
  width: 172px;
  height: 65px
}

.layer_content .guide_area .thumb img {
  width: 100%
}

.layer_content .guide_area .guide_txt {
  font-size: 14px;
  line-height: 1.36;
  letter-spacing: -.1px;
  color: #111
}

.guide_area .guide_txt.point_guide_txt {
  padding-top: 5px;
  color: #fa2828
}

.layer_content .btn_bx {
  margin-top: 205px;
  text-align: center
}

.layer_content .btn_bx .btn {
  width: 100%;
  height: 50px;
  background: #fa2828;
  font-size: 15px;
  line-height: 50px;
  color: #fff;
}

.layer_content .btn_bx .btn_next_change {
  display: inline-block;
  margin-top: 16px;
  font-size: 14px;
  letter-spacing: -.1px;
  color: #111;
  text-decoration: underline
}

/*비밀번호  - 보안가이드 페이지로 이동*/
.security_guide_wrap {
  min-width: 420px;
  padding: 0
}

.security_guide_wrap .ly_content {
  position: relative
}

.security_guide_wrap .btn_area {
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: 100%;
  padding: 0 45px;
  box-sizing: border-box;
  cursor: pointer
}

.security_guide_wrap .btn_area .btn_point {
  background: transparent;
  font-size: 0
}

.security_guide_wrap .tit_area {
  padding: 0;
}

.security_guide_wrap .lk_dn {
  display: none
}</style>
