<template lang="pug">
.ly_pop.ly_pop_wrap.ly_pop1
  .ly_pop_layer
    .ly_pop_cont
      h1.pop_tit 가맹점연결
      .member_info
        //
          [D]
          스크롤바 라이브러리 사용
          div.lst_merchant scrollbar-inner 클래스 추가 시 라이브러리 동작 함.
        .lst_merchant.scrollbar-inner
          ul
            li(v-for="item in this.merchantLinkList") {{item.merchantName}}
              font(color="#CCC") &nbsp&nbsp&nbsp({{ item.merchantCode }})
      .ly_btn
        button.btn.btn_bl.btn_pop_md(type='button', @click="closeModal") 확인
    button.btn_close(type='button', @click="closeModal")
      span.blind 닫기
</template>

<script>
import jQuery from 'jquery'
// eslint-disable-next-line no-unused-vars
import scrollbar from 'jquery.scrollbar'

export default {
  props: ['merchantLinkList'],
  mounted () {
    jQuery('.scrollbar-inner').scrollbar()
  },
  methods: {
    closeModal: function () {
      this.$emit('showModal', false)
    }
  }
}
</script>
