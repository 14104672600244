<template lang="pug">
#content.adv_center
  .tit_area
    h1.tit 계정관리
  .clo_tab
    .tab_head
      ul
        li(:class="{active : currentTabName === tab.name}" v-for="tab in visibleTabs")
          button(type='button', @click='$router.push(tab.location.main)' v-html='tab.name')
    router-view(@popUpShopList="popUpShopList")

  // AdvertiserList.vue 에서 사용, .clo_tab에 css style 영향을 받지 않기 위해 바깥으로 뺌.
  shop-list(
    v-if="popUp.showModal.shop",
    :advertiserCode="popUp.shop.advertiserCode",
    :advertiserName="popUp.shop.advertiserName",
    :isSearchMode="false"
    @showModal="setShopModal")
</template>

<script>
import { mapGetters } from 'vuex'
import _ from '@/partial'
import ShopList from '../shop/ShopList'
import { USER_ROLE } from '@/types/user-role-types'

export default {
  components: { ShopList },
  name: 'AccountManagement',
  data () {
    return {
      tabs: [
        {
          name: '광고주 관리',
          location: {
            main: '/account/advertiser',
            subPages: ['/account/advertiser/registerModify', '/account/advertiser/connect']
          },
          visibleAuthorities: [USER_ROLE.NE_MGR, USER_ROLE.NORMAL_MGR, USER_ROLE.NORMAL]
        },
        {
          name: '결제사 관리',
          location: {
            main: '/account/clo-payment',
            subPages: ['/account/clo-payment/registerModify']
          },
          visibleAuthorities: [USER_ROLE.NE_MGR, USER_ROLE.PAYMENT_MGR]
        }
      ],
      popUp: {
        shop: {
          advertiserCode: '',
          advertiserName: ''
        },
        showModal: {
          shop: false
        }
      }
    }
  },
  methods: {
    popUpShopList (shop) {
      this.popUp.shop = shop
      this.popUp.showModal.shop = true
    },
    setShopModal (showModal) {
      this.popUp.showModal.shop = showModal
    }
  },
  computed: {
    ...mapGetters(['myRole']),
    visibleTabs () {
      return _.filter(this.tabs, tab =>
        _.intersection(tab.visibleAuthorities, this.myRole).length > 0
      )
    },
    currentTab () {
      return this.visibleTabs.find(tab =>
        this.$route.path.includes(tab.location.main) ||
        tab.location.subPages.find(s => this.$route.path.startsWith(s))
      )
    },
    currentTabName () {
      return this.currentTab ? this.currentTab.name : ''
    }
  },
  watch: {
    currentTab: {
      handler (val) {
        if (!val && this.visibleTabs.length > 0) {
          this.$router.push(this.visibleTabs[0].location.main)
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped></style>
