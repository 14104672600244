<template lang="pug">
  .ly_pop_wrap.ly_pop7.store_list_pop
    .ly_pop_layer.scrollbar-inner(ref='scrollbar')
      .ly_pop_cont.adv_center
        h1.pop_tit 매장 리스트
        p.pop_des(v-if="isSearchMode") 등록 완료된 매장 리스트입니다. 등록 요청 후 승인되지 않은 매장은 노출되지 않습니다.
        form(onSubmit='return false')
          fieldset
            .query_form
              dl.q_row
                dt.blind 매장 검색
                dd
                  .form.form_row
                    .bx_item
                      dl
                        dt.blind 검색
                        dd
                          .inp_area
                            label.txt_label(for='search-shop-name') 매장명
                            .inp_bx
                              span.txt_lbl(v-if="table.searchKeyword === ''") 예: 아론 요거트
                              input#search-shop-name(
                                type='text',
                                :value="table.searchKeyword",
                                :class="{on_typing: el.inputSearchKeyword.isOn}",
                                @input="toggleSearchInputClass($event)",
                                @focusout="toggleSearchInputClass($event)"
                              )
                    .confirm_area
                      button.btn.btn_red(@click="searchShopList(1, table.page.size)") 조회
                    .btn_area.tr
                      a.btn_w.btn_export.excel(v-if="!isSearchMode", @click="excel") 엑셀 다운로드
        .tbl_wrap
          .page_head
            template(v-if="isSearchMode")
              template(v-if="isAdmin")
                .tit_area_shop
                  h2.stit {{displayTotalCount}}
                .btn_group
                  .btn_txt_check
                    input.blind(type='checkbox', id='check_all_store', @click="setCheckedAll($event)")
                    label(for='check_all_store') 전체 매장 선택
              template(v-else)
                .btn_group
                  .tit_area
                    h2.stit.nodata_stit {{displayTotalCount}}
            template(v-else)
              .tit_area(style="float: none;")
                h2.stit {{displayTotalCount}}
              .btn_group
                button.btn_item(type='button', v-if="isAdmin", @click='approveShopList') 승인
                button.btn_item(type='button', @click='deleteShopList') 삭제
                .btn_txt_check
                  input.blind(type='checkbox', id='check_all_store', @click="setCheckedAll($event)", :checked="isCheckedAll")
                  label(for='check_all_store') 전체 매장 선택
            .btn_area
              .sel_cont.bx_item
                .sel(style='width: 155px' :class="{on: el.dropdownPageSize.isOn}" @click="changePageSize($event)")
                  a {{table.page.size}}개씩 보기
                    span.ic_arrow_lg
                  .dropdown_layer
                    .dropdown_wrap.scrollbar-inner
                      ul.select_lst
                        template(v-for="i in [30, 50, 100, 300, 500]")
                          li
                            a(@click="changePageSize($event, i)") {{i}}개씩 보기
              template(v-if="isSearchMode")
                a.btn.btn_red.apply_btn(@click='returnSelectedShop') 적용
              template(v-else)
                a.btn_bl.btn_add_more(@click='popUpShopForm') 신규 등록
              shop-form-popup(
                v-if="popUp.showModal.shopForm",
                :advertiserCode="advertiserCode",
                :advertiserName="advertiserName",
                @showModal="setShopFormModal",
                @onRegistered="searchShopList(1, table.page.size)")
          .tbl
            .tbl_content
              table.form
                template(v-if="table.totalCount !== 0")
                  colgroup
                    template(v-if="!isSearchMode")
                      col(style='width:22px')
                      col(style='width:130px')
                      col(style='width:220px')
                      col(style='width:71px')
                      col(style='width:235px; display: table-column;', v-if="isAdmin")
                      col(style='width:64px')
                      col(style='width:80px')
                    template(v-else)
                      col(style='width:22px')
                      col(style='width:110px')
                      col(style='width:200px')
                      col(style='width:100px')
                      col(style='width:64px', v-if="isAdmin")
                      col(style='width:80px')
                  thead
                    tr
                      th(scope='col')
                        span.inp_checkbox2
                          input.inp_chk(type='checkbox',
                            id='shop-checkbox-all',
                            @click="setCheckedAllCurrentPage($event)",
                            :checked="isCheckedAllCurrentPage")
                          label.check_control_label(for='shop-checkbox-all')
                            span.ico_chk
                              span.blind 전체선택
                      th.sort.txt.align_left(scope='col')
                        button(type='button', @click="sortShopList('SHOP_NAME')") 매장명(브랜드)
                      th.sort.txt.align_left(scope='col')
                        button(type='button', @click="sortShopList('ADDRESS')") 주소
                      th.txt.align_center(scope='col') 매장ID
                      th.txt.align_left(scope='col', v-if="isAdmin") 위.경도
                      th.txt.align_center(scope='col', v-if="!isSearchMode") 상태
                      th.sort(scope='col')
                        button(type='button', @click="sortShopList('REG_YMDT')") 등록일
                  tbody
                    tr(v-for="(item,index) in table.shopList")
                      // [D] 체크박스
                      td
                        span.inp_checkbox2
                          input.inp_chk(type='checkbox'
                            :id="'shop-checkbox-' + index",
                            :value='item.shopId',
                            v-model="table.checkedShopIdList",
                            @change="checkTargetCount()")
                          label.check_control_label(:for="'shop-checkbox-' + index")
                            span.ico_chk
                            span.blind 선택
                      // [D] --- (START) 매장명, 주소, 매장ID, 위.경도, 상태 ---
                      template(v-if="isSearchMode")
                        td.txt.align_left {{item.shopName}}
                        td.txt.align_left {{item.address}}
                        td {{item.shopId}}
                        td.txt.align_left.latlong(v-if="isAdmin", style="display: table-cell;")
                          span 북위 {{item.latitude}} / 동경 {{item.longitude}}
                      template(v-else)
                        td
                          .inp_area
                            .inp_bx(style="width: 100%;")
                              input(type='text', :id="'shopName-' + item.shopId", :value='item.shopName',
                                @input="onInputTarget($event.target.value, index, 'shopName')")
                        td
                          .inp_area
                            .inp_bx(style="width: 100%;")
                              input(type='text', :id="'address-' + item.shopId", :value='item.address',
                                @input="onInputTarget($event.target.value, index, 'address')")
                        td(:id="item.shopId") {{item.shopId}}
                        td.txt.align_left.latlong(v-if="isAdmin", style="display: table-cell;")
                          span 북위
                          .inp_area(style="display: inline-block;")
                            .inp_bx(style="width: 78px;")
                              input(type='text', :id="'latitude-' + item.shopId", :value='item.latitude',
                                @input="onInputTarget($event.target.value, index, 'latitude')")
                          span / 동경
                          .inp_area(style="display: inline-block;")
                            .inp_bx(style="width: 78px;")
                              input(type='text', :id="'longitude-' + item.shopId", :value='item.longitude',
                                @input="onInputTarget($event.target.value, index, 'longitude')")
                        td {{item.shopStatus}}
                      // [D] --- (END) 매장명, 주소, 매장ID, 위.경도, 상태 ---
                      td {{formatDate(new Date(item.regYmdt))}}
                template(v-else)
                  tbody
                    tr
                      td
                        .nodata_area
                          p.txt 등록한 매장이 없습니다. 신규등록 버튼을 클릭하여 매장을 등록하세요.
            .tbl_bottom_area
              .notice_area
                ul
                  li 매장 정보 수정이 필요한 경우 매장리스트에서 수정이 가능합니다.
                  li 정보 수정 후 운영자의 최종 승인을 거쳐 수정 내용이 반영됩니다.
                  li(v-if='isAdmin') 위,경도는 십진수 (도)(DD)방식으로 표기하고 소수점 자리까지 입력해주세요. (예: 37.401234, 127.104273)
              .confirm_area(v-if="!isSearchMode")
                button.btn.btn_red(type='button', @click='updateShopList')
                  p(v-if="isAdmin") 저장
                  p(v-else) 수정
            paging(:currentPage="this.table.page.now",
              :pageSize="this.table.page.size"
              :totalCount="this.table.totalCount"
              @onClickPage='searchShopList')
      button.btn_close(type='button', @click="closeModal")
        span.blind 닫기
      confirm(v-if="popUp.showModal.confirm", :confirm="confirm" @yes="confirm.yesCallback", @no="confirm.noCallback")
</template>

<script>
import $ from '@/util.js'
import _ from '@/partial'
import jQuery from 'jquery'
import { mapGetters } from 'vuex'
import shopApi from '@/api/shop.js'
import Paging from '../common/fragments/Paging'
import ShopFormPopup from './fragments/ShopForm'
import Confirm from './fragments/Confirm'

import { ShopForm } from '@/components/shop/shop-form'

const SHOP_LIST_DEFAULT_SIZE = 30

export default {
  props: ['advertiserCode', 'advertiserName', 'isSearchMode', 'checkedShopIdList', 'isMultipleShopAvailable'],
  components: {
    Paging,
    ShopFormPopup,
    Confirm
  },
  data: function () {
    return {
      table: {
        searchKeyword: '',
        shopList: [],
        totalCount: 0,
        sort: {
          column: '',
          direction: 'DESC'
        },
        page: {
          now: 1,
          size: 30
        },
        checkedShopIdList: [],
        changedShopList: {}
      },
      el: {
        dropdownPageSize: {
          isOn: false
        },
        inputSearchKeyword: {
          isOn: false
        }
      },
      popUp: {
        showModal: {
          shopForm: false,
          confirm: false
        },
        shopForm: {
          advertiserCode: ''
        }
      },
      validation: null,
      isCheckedAll: false
    }
  },
  mounted () {
    jQuery('.scrollbar-inner').scrollbar()
    this.validation = new ShopForm()
    this.table.checkedShopIdList = this.checkedShopIdList || []
    this.searchShopList(1, SHOP_LIST_DEFAULT_SIZE)

    if ($.getUserAgent() === 'firefox') {
      this.$refs.scrollbar.style.overflow = 'auto'
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    displayTotalCount () {
      return '총 ' + this.table.totalCount + '건'
    },
    isCheckedAllCurrentPage () {
      var isCheckedAllCurrentPage = this.table.shopList.every(shop => this.table.checkedShopIdList.includes(shop.shopId))
      if (!isCheckedAllCurrentPage && this.isAdmin) {
        document.getElementById('check_all_store').checked = false
      }
      return isCheckedAllCurrentPage
    }
  },
  methods: {
    closeModal: function () {
      this.$emit('showModal', false)
    },
    searchShopList: function (requestPageNumber, requestPageSize, isReloaded = false) {
      shopApi.getShopList(
        {
          advertiserCode: this.advertiserCode,
          shopName: this.table.searchKeyword,
          isSearchMode: this.isSearchMode,
          sort: this.table.sort,
          page: {
            now: requestPageNumber - 1,
            size: requestPageSize
          }
        },
        body => {
          this.table.shopList = body.result.shopList
          this.table.totalCount = body.result.totalCount
          this.table.page.now = requestPageNumber
          this.table.page.size = requestPageSize

          if (isReloaded) {
            this.table.checkedShopIdList = []
          }
          this.table.changedShopList = {}
          document.getElementById('search-shop-name').focus()
        }
      )
    },
    excel: function () {
      shopApi.getShopListAsExcel(
        {
          advertiserCode: this.advertiserCode,
          shopName: this.table.searchKeyword,
          isAdmin: this.isAdmin,
          isSearchMode: this.isSearchMode
        },
        blob => $.OpenBlobToExcel(blob, 'shop-list'))
    },
    deleteShopList: function () {
      if (this.table.checkedShopIdList.length === 0) return
      this.commonConfirm('',
        '매장을 삭제하시겠습니까?',
        '확인',
        '취소',
        () => {
          shopApi.deleteShopList(
            {
              shopIdList: this.table.checkedShopIdList,
              advertiserCode: this.advertiserCode
            },
            body => {
              this.isCheckedAll = false
              const yesCallback = () => {
                this.searchShopList(1, this.table.page.size, true)
              }
              if (body.result && !body.result.removable) {
                this.commonConfirm('',
                  '집행 중인 캠페인이 있는 경우,<br>매장 정보를 삭제할 수 없습니다.<br>집행 중인 캠페인을 종료시키고<br>매장 정보를 삭제하세요.',
                  '확인', null, yesCallback)
              } else {
                this.commonConfirm('', '매장을 삭제하고 있습니다. \n매장 개수가 많을 경우, \n모든 매장이 삭제될 때까지 다소 시간이 소요될 수 있습니다.\n', '확인', null, yesCallback)
              }
            },
            header => {
              var text = '작업에 실패하였습니다.<br>관리자에게 문의해주세요.'
              this.commonConfirm('', text, '확인')
            }
          )
        }
      )
    },
    getInvalidColumn: function (row) {
      var columns = ['shopName', 'address']
      if (this.isAdmin) {
        columns = columns.concat(['latitude', 'longitude'])
      }
      for (var i = 0; i < columns.length; i++) {
        var column = columns[i]
        var suffix = column.substring(0, 1).toUpperCase() + column.substring(1, column.length)
        this.validation['valid' + suffix](row[column])
        if (!this.validation.validation[column].isValid) {
          return column
        }
      }
    },
    getCheckColumnsPromise: function (rows) {
      return new Promise((resolve) => {
        for (var i = 0; i < rows.length; i++) {
          var invalidColumn = this.getInvalidColumn(rows[i])
          if (invalidColumn) {
            this.commonConfirm('', this.validation.validation[invalidColumn].message, '확인', null,
              () => {
                document.getElementById(invalidColumn + '-' + rows[i].shopId).focus()
              })
            resolve(true)
            return
          }
        }
        resolve(false)
      })
    },
    convertToShopModel: function (rows) {
      return rows.map(row => {
        return {
          shopId: row.shopId,
          shopName: row.shopName,
          address: row.address,
          latitude: row.latitude,
          longitude: row.longitude,
          shopStatus: row.shopStatusCode
        }
      })
    },
    updateShopList: function () {
      var rows = Object.values(this.table.changedShopList)
      if (rows.length === 0) return

      this.getCheckColumnsPromise(rows).then((isInvalidRows) => {
        if (isInvalidRows) return

        const action = this.isAdmin ? '저장' : '수정'
        this.commonConfirm('', `${action}하시겠습니까?`, '확인', '취소',
          () => {
            shopApi.updateShopList(
              {
                advertiserCode: this.advertiserCode,
                advertiserName: this.advertiserName,
                isAdmin: this.isAdmin,
                shopModelList: this.convertToShopModel(rows)
              },
              body => {
                if (body.result && !body.result.removable) {
                  this.commonConfirm('', '집행 중인 캠페인이 있는 경우,<br>매장 정보를 수정할 수 없습니다.<br>집행 중인 캠페인을 종료시키고<br>매장 정보를 수정하세요.', '확인')
                } else {
                  this.commonConfirm('', `${action}되었습니다.`, '확인', null, () => {
                    this.searchShopList(1, this.table.page.size, true)
                  })
                }
              },
              header => {
                var text = '작업에 실패하였습니다.<br>관리자에게 문의해주세요.'
                this.commonConfirm('', text, '확인')
              }
            )
          }
        )
      })
    },
    approveShopList: function () {
      const total = this.table.checkedShopIdList.length
      if (total === 0) return

      // checked + updated
      var checkedUpdatedRows = _.filter(Object.values(this.table.changedShopList),
        shop => this.table.checkedShopIdList.includes(shop.shopId))

      // checked + !updated
      var onlyCheckedRows = _.filter(this.table.shopList,
        shop => this.table.checkedShopIdList.includes(shop.shopId))

      this.getCheckColumnsPromise(_.union(checkedUpdatedRows, onlyCheckedRows)).then((isInvalidRows) => {
        if (isInvalidRows) return

        this.commonConfirm('',
          '승인하시겠습니까?',
          '확인',
          '취소',
          () => {
            shopApi.approveShopList(
              {
                advertiserCode: this.advertiserCode,
                advertiserName: this.advertiserName,
                shopIdList: this.table.checkedShopIdList,
                shopModelList: this.convertToShopModel(checkedUpdatedRows)
              },
              body => {
                if (body.result && body.result.msg) {
                  this.commonConfirm('', body.result.msg, '확인')
                } else {
                  this.commonConfirm('', '매장을 승인 처리하고 있습니다. \n매장 개수가 많을 경우, \n모든 매장이 승인될 때까지 다소 시간이 소요될 수 있습니다.\n',
                    '확인', null, () => this.searchShopList(1, this.table.page.size, true))
                }
              },
              header => {
                var text = '작업에 실패하였습니다.<br>관리자에게 문의해주세요.'
                this.commonConfirm('', text, '확인')
              }
            )
          }
        )
      })
    },
    returnSelectedShop: function () {
      shopApi.getApprovedShopList(this.table.checkedShopIdList,
        body => {
          if (body && body.result) {
            if (body.result.length === 0) {
              this.commonConfirm('', '대상을 선택해주세요.', '확인')
              return
            }
            this.table.checkedShopIdList = body.result.map(shop => shop.shopId)
            if (!this.isAvailableShopCount()) {
              this.confirmNotApplicable()
              return
            }
            this.commonConfirm('', '선택한 매장을 적용하시겠습니까?', '적용', '취소', () => {
              this.$emit('selectShopList', body.result)
              this.$emit('showModal', false)
            })
          }
        })
    },
    onInputTarget: function (val, idx, target) {
      this.table.shopList[idx][target] = val
      this.table.changedShopList[idx] = this.table.shopList[idx]
    },
    isAvailableShopCount: function () {
      if (this.isSearchMode) {
        if (this.isAdmin) {
          return true
        } else {
          return this.table.checkedShopIdList.length <= 1 || this.isMultipleShopAvailable
        }
      }
      return true
    },
    confirmNotApplicable: function () {
      this.commonConfirm('', '1개 매장만 적용 가능합니다.', '확인')
    },
    checkTargetCount: function () {
      if (!this.isAvailableShopCount()) this.confirmNotApplicable()
    },
    sortShopList: function (column) {
      this.table.sort.direction = $.getReverseSortDirection(this.table.sort.direction)
      this.table.sort.column = column
      this.searchShopList(this.table.page.now, SHOP_LIST_DEFAULT_SIZE)
    },
    changePageSize: function (e, pageSize) {
      this.el.dropdownPageSize.isOn = !this.el.dropdownPageSize.isOn
      if (pageSize) {
        this.searchShopList(1, pageSize)
      }
    },
    setCheckedAll: function (e) {
      if ((this.isCheckedAll = e.target.checked)) {
        const success = body => {
          this.table.checkedShopIdList = body.result
        }
        if (this.isSearchMode) {
          shopApi.getApprovedShopAll(this.advertiserCode, success)
        } else {
          shopApi.getShopListByAdvertiserCode(this.advertiserCode, success)
        }
      } else {
        this.table.checkedShopIdList = []
      }
    },
    setCheckedAllCurrentPage: function (e) {
      var arr = []
      if (e.target.checked) {
        arr = _.union(this.table.checkedShopIdList, this.table.shopList.map(shop => shop.shopId))
      } else {
        arr = _.difference(this.table.checkedShopIdList, this.table.shopList.map(shop => shop.shopId))
        this.isCheckedAll = false
      }
      this.table.checkedShopIdList = arr
      this.checkTargetCount()
    },
    formatDate: function (date) {
      return $.formatDate(date)
    },
    toggleSearchInputClass: function (e) {
      this.table.searchKeyword = e.target.value
      this.el.inputSearchKeyword.isOn = (e.target === 'input')
    },
    setShopFormModal (showModal) {
      this.popUp.showModal.shopForm = showModal
    },
    popUpShopForm () {
      this.popUp.showModal.shopForm = true
    },
    commonConfirm: function (subTitle, text, yes, no, yesCallback, noCallback) {
      this.popUp.showModal.confirm = true
      this.confirm = {
        subTitle: subTitle,
        text: text,
        yes: yes,
        yesCallback: () => {
          this.popUp.showModal.confirm = false
          if (yesCallback) yesCallback()
        },
        noCallback: () => {
          this.popUp.showModal.confirm = false
          if (noCallback) noCallback()
        }
      }
      if (no) this.confirm.no = no
    }
  }
}
</script>
