<template lang="pug">
#toast.ly_pop.ly_pop_wrap(:class="{ ly_pop_success: this.type === 'success', ly_pop_alert: this.type === 'alert', show: this.isShow, hide: this.isHide }")
  .ly_pop_layer
    .ly_pop_cont
      .ly_pop_main
        strong(v-html="message")
</template>

<script>
export default {
  name: 'ToastAlert',
  props: {
    type: {
      type: String,
      default: 'success'
    },
    message: {
      type: String,
      default: ''
    },
    duration: {
      default: 850
    }
  },
  data () {
    return {
      isShow: false,
      isHide: false
    }
  },
  methods: {
    show () {
      this.$set(this, 'isShow', true)
      setTimeout(() => {
        this.$set(this, 'isShow', false)
        this.$set(this, 'isHide', true)
        setTimeout(() => {
          this.$emit('expired')
        }, 450)
      }, this.duration)
    }
  }
}
</script>

<style>
#toast.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}

#toast.hide {
  visibility: visible;
  -webkit-animation: fadeout 0.5s;
  animation: fadeout 0.5s;
}

@-webkit-keyframes fadein {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadein {
  from {opacity: 0;}
  to {opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {opacity: 1;}
  to {opacity: 0;}
}

@keyframes fadeout {
  from {opacity: 1;}
  to {opacity: 0;}
}
</style>
