import _ from '@/partial'
const filterListBySearchValue = {
  methods: {
    filterListBySearchValue (list, searchValue, isCaseSensitive = true, ...properties) {
      if (searchValue === '') {
        return list
      } else if (!list || !Array.isArray(list)) {
        return list
      } else if (!properties) {
        return list
      }

      if (!isCaseSensitive) {
        searchValue = searchValue.toLowerCase()
      }
      const resultList = []
      _.each(list, function (item) {
        for (const index in properties) {
          const property = properties[index]
          const target = isCaseSensitive ? item[property] : item[property].toLowerCase()
          if (target.indexOf(searchValue) > -1) {
            resultList.push(item)
            break
          }
        }
      })
      return resultList
    }
  }
}
export default filterListBySearchValue
