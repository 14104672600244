<template lang="pug">
.sub.sub_menu3
  ul
    li(v-for="menu in visibleMenus", :class='{on: menu.isActive}')
      a(@click='routeTo(menu)') {{ menu.name }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { MENUS } from './components/subGnb/data/sub-gnb-data'
import _ from '@/partial'
import { USER_ROLE } from '@/types/user-role-types'

const PHASE = process.env.ENV_NAME ? process.env.ENV_NAME.toUpperCase() : null

export default {
  name: 'SubGnb',
  created () {
    this.fetchMyRole()
  },
  mounted () {
    this.syncActive()
  },
  data () {
    return {
      menus: MENUS
    }
  },
  methods: {
    ...mapActions(['fetchMyRole']),
    routeTo (menu) {
      this.$router.push(menu.path)
    },
    syncActive () {
      this.menus.forEach(menu => {
        if (this.$route.path.startsWith(menu.path)) {
          menu.isActive = true
        } else {
          menu.isActive = false
        }
      })
      if (this.$route.path === '/') {
        this.menus[0].isActive = true
      }
    },
    isVisible (menu) {
      const visiblePhase = menu.activeEnv.filter(e => e === PHASE)
      const visibleAuthority = _.intersection(menu.visibleAuthorities, this.myRole)
      const invisibleAuthority = _.intersection(menu.invisibleAuthorities, this.myRole)

      if (visiblePhase.length === 0 || visibleAuthority.length === 0 || invisibleAuthority.length > 0) {
        return false
      }

      if (visibleAuthority.length === 1 && visibleAuthority[0] === USER_ROLE.NORMAL_MGR) {
        return this.hasApprovedAdvertiser || menu.path === '/account'
      }

      return true
    }
  },
  watch: {
    '$route.path' () {
      this.syncActive()
    }
  },
  computed: {
    ...mapGetters(['myRole', 'isAdmin', 'hasApprovedAdvertiser']),
    visibleMenus () {
      return _.filter(this.menus, menu => this.isVisible(menu))
    }
  }
}
</script>
