<template lang="pug">
.ly_pop.ly_pop_wrap.ly_pop1
  .ly_pop_layer(v-click-outside='close')
    .ly_pop_cont
      h1.pop_tit {{notice.title}}
      .agree_cont
        .inner_box
          p {{notice.topAdditionalInformation}}
          dl
            template(v-for='item in notice.items')
              dt(:class='item.emphasis ? "pop_txt_v3" : ""') {{item.name}}
              dd(:class='item.emphasis ? "pop_txt_v3" : ""') {{item.description}}
          p
            template(v-for="(item, index) in notice.bottomAdditionalInformations")
              br(v-if='index != 0')
              | {{ item }}
      .ly_btn
        button.btn.btn_bl.btn_pop_md(type='button' @click='close') 확인
    button.btn_close(type='button' @click='close')
      span.blind 닫기
</template>

<script>
import $ from '@/util.js'

export default {
  name: 'NoticePopUp',
  props: ['notice'],
  methods: {
    close () {
      this.$emit('close')
    }
  },
  directives: {
    'click-outside': $.clickOutside()
  }
}
</script>

<style scoped>

</style>
