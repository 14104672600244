import req from './http-request'
import _ from '@/partial'

var URI = {
  GET_CAMPAIGN: 'campaign/',
  GET_CAMPAIGN_TYPES: campaignId => `campaign/${campaignId}/types`,
  GET_POINT_HOME_BASIC_CAMPIGN_BASIC: campaignId => `pointHome/basic/campaigns/${campaignId}/basic`,
  GET_POINT_HOME_BAISC_CAMPAIGN_TARGET: campaignId => `pointHome/basic/campaigns/${campaignId}/target`,
  GET_POINT_HOME_BAISC_CAMPAIGN_BANNER: campaignId => `pointHome/basic/campaigns/${campaignId}/banner`,
  GET_POINT_HOME_BAISC_CAMPAIGN_COUPON: campaignId => `pointHome/basic/campaigns/${campaignId}/coupon`,
  GET_ESSENTIAL_CAMPAIGNS: 'campaign/essential',
  GET_CAMPAIGNS_INCLUDED_ADVERTISERCODES: 'campaign/advertiserCodes',
  GET_MERCHANT_NAME: 'campaign/merchant-name',
  GET_ALL_COMMON_CODE: 'campaign/common/code',
  GET_TARGET_CODE: 'campaign/common/code/target',
  GET_AD_TYPE_CODE: 'campaign/common/code/ad',
  GET_MERCHANT_EP_TYPE_CODE: 'campaign/common/code/merchant/ep',
  GET_CAMPAIGN_CATEGORIES: 'campaign/common/code/campaignCategories',
  GET_CUSTOM_CAMPAIGN_CATEGORIES: 'campaign/common/code/campaign-custom-category',
  GET_TARGET_PUBLISHERS: (campaignId) => `campaign/target/publishers/${campaignId}`,
  CHECK_CAMPAIGN_PAGE_NAME: 'campaign/valid/event-page-name/',
  CREATE_DEFAULT_CAMPAIGN: 'campaign/create',
  UPDATE_DEFAULT_CAMPAIGN: 'campaign/default',
  UPLOAD_BANNER_IMGS: (type) => `campaign/create/upload/banner/${type}`,
  GET_DISPLAYAD_CREATIVES: (campaignId) => `campaign/displayad-creatives/${campaignId}`,
  UPLOAD_DISPLAYAD_CREATIVES: 'campaign/create/upload/displayad-creatives',
  CREATE_DISPLAYAD_CREATIVES: 'campaign/create/displayad-retarget',
  UPDATE_DISPLAYAD_CREATIVES: 'campaign/update/displayad-retarget',
  CAMPAIGN_BANNER: 'campaign/banner',
  CAMPAIGN_RESERVATION_BANNER: 'campaign/banner/reservation',
  GET_CAMPAIGN_RESERVATION_BANNER: campaignId => `campaign/banner/reservation?campaignId=${campaignId}`,
  DELETE_CAMPAIGN_RESERVATION_BANNER: reservationSeq => `campaign/banner/reservation/${reservationSeq}`,
  GET_CAMPAIGN_LIST: 'campaign/management/campaign-list',
  GET_TOP_POSITION_CAMPAIGN_LIST: (publishCode, campaignStatusList) => `campaign/management/top-position-campaign-list?publishCode=${publishCode}&campaignStatusList=${campaignStatusList}`,
  GET_CAMPAIGN_LIST_EXCEL: 'campaign/management/campaign-list/download',
  UPDATE_CAMPAIGN_STATUS: 'campaign/management/change-campaign-status',
  COPY_CAMPAIGN: (campaignId) => `campaign/${campaignId}/copy`,
  COPY_POINTHOME_BASIC_CAMPAIGN: (campaignId) => `pointHome/basic/campaigns/${campaignId}/copy`,
  GET_CAMPAIGN_NAME_LIST: 'campaign/management/campaign-name-list',
  UPLOAD_EVENT_IMG: (type) => `campaign/create/upload/event/${type}`,
  CREATE_EVENT_PAGE: 'campaign/create/event-page',
  CREATE_EVENT_PAGE_CUSTOM: 'campaign/create/event-page-custom',
  UPLOAD_TARGET_FILES: 'campaign/target/files',
  UPLOAD_TARGET_IDNOS: 'campaign/target/idnos',
  UPLOAD_LEAFLET_IMAGES: 'campaign/leaflets',
  CREATE_CAMPAIGN_TARGET: 'campaign/target',
  CREATE_CAMPAIGN_TARGET_PUBLISHERS: 'campaign/target/publishers',
  UPLOAD_COUPON_SERIAL: 'campaign/coupon/serial/file',
  DELETE_COUPON_SERIAL: 'campaign/coupon/serial',
  GET_COUPONS: 'campaign/coupons',
  REGISTER_COUPONS: 'campaign/register/coupons',
  GET_CAMPAIGN_BANNER: (campaignId) => `campaign/banner/${campaignId}`,
  UPDATE_COUPONS: 'campaign/update/coupons',
  DELETE_COUPON: 'campaign/delete/coupon',
  GET_TARGET: 'campaign/target/',
  GET_TARGET_FILES: (campaignId) => `campaign/target/${campaignId}/files`,
  DELETE_TARGET_FILES: (campaignId) => `campaign/target/${campaignId}`,
  COUPON_LIST_EVENT_PAGE_PREVIEW: 'campaign/coupon/event-page-preview',
  CHECK_EVENT_PAGE: 'campaign/event-page/existence',
  GET_EVENT_PAGE: 'campaign/event-page',
  GET_EVENT_PAGE_CUSTOM: 'campaign/event-page-custom',
  UPDATE_EVENT_PAGE: 'campaign/update/event-page',
  COUPON_DOWNLOAD_PAGE: 'campaign/coupon-download-page',
  UPDATE_EVENT_PAGE_CUSTOM: 'campaign/update/event-page-custom',
  REJECT_CAMPAIGN: 'campaign/reject',
  GET_COUPON_INFO: (campaignId, couponIds) => `campaign/coupon/info?campaignId=${campaignId}&couponIds=${couponIds}`,
  GET_COUPON: couponId => `pointHome/basic/campaigns/coupons/${couponId}`,
  GET_AVAILABLE_MERCHANTS: (campaignId) => `campaign/coupon/available-merchants?campaignId=${campaignId}`,
  GET_AVAILABLE_MERCHANT: (merchantCode) => `campaign/coupon/available-merchant?merchantCode=${merchantCode}`,
  GET_CAMPAIGN_SETTING_FLOW: (campaignGroupType, campaignType) => `campaign/settingFlow/${campaignGroupType}/${campaignType}`,
  GET_CAMPAIGN_TYPE_SETTING: 'campaign/type-setting',
  GET_CAMPAIGN_SHOP_TYPE: 'campaign/common/code/campaign-shop',
  GET_CAMPAIGN_PAGE_TYPE: 'campaign/common/code/event-page',
  SET_CAMPAIGN_SHOP: 'campaign/shop',
  CREATE_CUSTOM_COUPONS: 'campaign/custom-coupons',
  UPDATE_CUSTOM_COUPONS: 'campaign/custom-coupons',
  GET_SHOPPING_REWARD_CAMPAIGN: 'campaign/shopping-reward/',
  UPDATE_SHOPPIMG_REWARD_CAMPAIGN: 'campaign/shopping-reward',
  DELETE_CAMPAIGNS: (campaignIds) => `campaign?campaignIds=${campaignIds.join(',')}`,
  GET_PROMOTION_LIST: 'campaign/promotions',
  GET_CAMPAIGN_RESERVATION_EVENT_PAGE: (campaignId) => `campaign/event-page/reservation?campaignId=${campaignId}`,
  CREATE_CAMPAIGN_RESERVATION_EVENT_PAGE: 'campaign/event-page/reservation',
  UPDATE_CAMPAIGN_RESERVATION_EVENT_PAGE: 'campaign/event-page/reservation',
  DELETE_CAMPAIGN_RESERVATION_EVENT_PAGE: (campaignId, reservationSeq) => `campaign/event-page/reservation/${reservationSeq}?campaignId=${campaignId}`,
  VALID_CAMPAIGN_RESERVATION_EVENT_PAGE: 'campaign/event-page/reservation/valid',
  CAMPAIGN_PARTYPLUS: campaignId => `campaign/${campaignId}/partyplus`,
  POINT_HOME_BAISC_CAMPAIGN_BASIC: 'pointHome/basic/campaigns/basic',
  POINT_HOME_BAISC_CAMPAIGN_TARGET: 'pointHome/basic/campaigns/target',
  POINT_HOME_BAISC_CAMPAIGN_BANNER: 'pointHome/basic/campaigns/banner',
  POINT_HOME_BAISC_CAMPAIGN_COUPON: 'pointHome/basic/campaigns/coupon'
}

export default {
  getCampaign (campaignId, success, fail) {
    req.get(URI.GET_CAMPAIGN + campaignId, success, fail)
  },
  getCampaignTypes (campaignId, success, fail) {
    req.get(URI.GET_CAMPAIGN_TYPES(campaignId), success, fail)
  },
  getPointHomeBasicCampaignBasic (campaignId, success, fail) {
    req.get(URI.GET_POINT_HOME_BASIC_CAMPIGN_BASIC(campaignId), success, fail)
  },
  getEssentialCampaigns (param, success) {
    req.get(URI.GET_ESSENTIAL_CAMPAIGNS + '?' + req.makeGetSuffixByObject(param), success)
  },
  getCampaignsIncludedInAdvertiserCodes (advertiserCodes, adType, success) {
    req.post(`${URI.GET_CAMPAIGNS_INCLUDED_ADVERTISERCODES}`, {
      advertiserCodes: advertiserCodes,
      adType: adType
    }, success)
  },
  getMerchantName (campaignId, success) {
    req.get(`${URI.GET_MERCHANT_NAME}/${campaignId}`, success)
  },
  getAllCommonCodes (success) {
    req.get(URI.GET_ALL_COMMON_CODE, success)
  },
  getTargetCodes (success) {
    req.get(URI.GET_TARGET_CODE, success)
  },
  getAdCodes (success) {
    req.get(URI.GET_AD_TYPE_CODE, success)
  },
  getMerchantEpCodes (success) {
    req.get(URI.GET_MERCHANT_EP_TYPE_CODE, success)
  },
  getCampaignCategories (success) {
    req.get(URI.GET_CAMPAIGN_CATEGORIES, success)
  },
  getCustomCampaignCategories (success) {
    req.get(URI.GET_CUSTOM_CAMPAIGN_CATEGORIES, success)
  },
  getTargetPublishers (campaignId, success) {
    req.get(URI.GET_TARGET_PUBLISHERS(campaignId), success)
  },
  checkCampaignPageName (campaignPageName, success) {
    req.get(URI.CHECK_CAMPAIGN_PAGE_NAME + campaignPageName, success)
  },
  createDefaultCampaign (campaign, success) {
    req.post(URI.CREATE_DEFAULT_CAMPAIGN, campaign, success)
  },
  updateDefaultCampaign (campaign, success) {
    req.put(URI.UPDATE_DEFAULT_CAMPAIGN, campaign, success)
  },
  uploadImageForBanner (type, advId, campaignId, file, progress, success, fail) {
    req.upload(URI.UPLOAD_BANNER_IMGS(type.toUpperCase()),
      {
        advId: advId,
        campaignId: campaignId,
        file: file
      },
      progress,
      success,
      fail
    )
  },
  getDisplayadCreatives (campaignId, success, fail) {
    req.get(URI.GET_DISPLAYAD_CREATIVES(campaignId), success, fail)
  },
  uploadImageForDisplayadCreatives (advId, campaignId, files, progress, success, fail) {
    req.upload(URI.UPLOAD_DISPLAYAD_CREATIVES,
      _.union(
        [['advId', advId], ['campaignId', campaignId]],
        _.map(files, f => ['file', f])
      ),
      progress,
      success,
      fail
    )
  },
  setDisplayadCreatives (body, success, fail) {
    req.post(URI.CREATE_DISPLAYAD_CREATIVES, body, success, fail)
  },
  updateDisplayadCreatives (body, success, fail) {
    req.put(URI.UPDATE_DISPLAYAD_CREATIVES, body, success, fail)
  },
  uploadImageForEventPage (type, advId, campaignId, file, progress, success, fail) {
    req.upload(URI.UPLOAD_EVENT_IMG(type.toUpperCase()),
      {
        advid: advId,
        campaignId: campaignId,
        file: file
      },
      progress,
      success,
      fail)
  },
  setBanner (banner, success) {
    req.post(URI.CAMPAIGN_BANNER, banner, success)
  },
  updateBanner (banner, success) {
    req.put(URI.CAMPAIGN_BANNER, banner, success)
  },
  getCampaignList (search, success) {
    req.post(URI.GET_CAMPAIGN_LIST, search, success)
  },
  getTopPositionCampaignList (publishCode, campaignStatusList, success) {
    req.get(URI.GET_TOP_POSITION_CAMPAIGN_LIST(publishCode, campaignStatusList), success)
  },
  downloadCampaignListExcel (search, success) {
    req.postBlob(URI.GET_CAMPAIGN_LIST_EXCEL, search, success)
  },
  updateCampaignStatus (campaignCodeListAndTargetStatus, success, fail) {
    req.post(URI.UPDATE_CAMPAIGN_STATUS, campaignCodeListAndTargetStatus, success, fail)
  },
  copyCampaign (campaignId, success) {
    req.post(URI.COPY_CAMPAIGN(campaignId), null, success)
  },
  copyPointHomeBasicCampaign (campaignId, success) {
    req.post(URI.COPY_POINTHOME_BASIC_CAMPAIGN(campaignId), null, success)
  },
  approveCampaign (campaignId, success, fail) {
    this.updateCampaignStatus({
      campaignCodeList: [campaignId],
      campaignStatus: 'ACTIVE',
      campaignApprovalStatus: 'APPROVED'
    }, success, fail)
  },
  waitApprovalCampaign (campaignId, success) {
    this.updateCampaignStatus({
      campaignCodeList: [campaignId],
      campaignStatus: 'WAIT_APPROVAL',
      campaignApprovalStatus: 'WAIT_APPROVAL'
    }, success)
  },
  getCampaignNameList (search, success) {
    req.post(URI.GET_CAMPAIGN_NAME_LIST, search, success)
  },
  setEventPage (eventPage, success) {
    req.post(URI.CREATE_EVENT_PAGE, eventPage, success)
  },
  uploadTargetFiles (advId, campaignId, targetFileReqType, files, progress, success, fail) {
    req.upload(URI.UPLOAD_TARGET_FILES,
      _.union(
        [['advId', advId], ['campaignId', campaignId], ['targetFileReqType', targetFileReqType]],
        _.map(files, f => ['file', f])
      ),
      progress,
      success,
      fail
    )
  },
  uploadTargetIdnos (advId, campaignId, targetFileReqType, idnos, success, fail) {
    req.post(URI.UPLOAD_TARGET_IDNOS,
      {
        advId,
        campaignId,
        targetFileReqType,
        idnos
      },
      success,
      fail
    )
  },
  uploadLeafletImages (advId, campaignId, files, progress, success, fail) {
    req.upload(URI.UPLOAD_LEAFLET_IMAGES,
      _.union(
        [['advId', advId], ['campaignId', campaignId]],
        _.map(files, f => ['file', f])
      ),
      progress,
      success,
      fail
    )
  },
  createCampaignTarget (target, success) {
    req.post(URI.CREATE_CAMPAIGN_TARGET, target, success)
  },
  updateCampaignTarget (target, success) {
    req.put(URI.CREATE_CAMPAIGN_TARGET, target, success)
  },
  createCampaignTargetPublishers (targetPublishers, success) {
    req.post(URI.CREATE_CAMPAIGN_TARGET_PUBLISHERS, targetPublishers, success)
  },
  updateCampaignTargetPublishers (targetPublishers, success) {
    req.put(URI.CREATE_CAMPAIGN_TARGET_PUBLISHERS, targetPublishers, success)
  },
  deleteCampaignTarget (campaignId, success, fail) {
    req.delete(URI.DELETE_TARGET_FILES(campaignId), success, fail)
  },
  getCampaignTarget (campaignId, success, error) {
    req.get(URI.GET_TARGET + campaignId, success, error)
  },
  getPointHomeBasicCampaignTarget (campaignId, success, error) {
    req.get(URI.GET_POINT_HOME_BAISC_CAMPAIGN_TARGET(campaignId), success, error)
  },
  getCampaignTargetFiles (campaignId, success) {
    req.get(URI.GET_TARGET_FILES(campaignId), success)
  },
  uploadCouponSerial (advId, campaignId, file, progress, success) {
    req.upload(URI.UPLOAD_COUPON_SERIAL,
      {
        advId: advId,
        campaignId: campaignId,
        file: file
      },
      progress,
      success)
  },
  deleteCouponSerial (advId, campaignId, serialFileName, success) {
    req.delete(`${URI.DELETE_COUPON_SERIAL}/${advId}/${campaignId}/${serialFileName}`, success)
  },
  registerCoupons (coupons, campaignId, success) {
    req.post(`${URI.REGISTER_COUPONS}/${campaignId}`, coupons, success)
  },
  getCouponListForEventPagePreview (campaignId, success) {
    const uri = URI.COUPON_LIST_EVENT_PAGE_PREVIEW + req.makeGetSuffix(
      {
        name: 'campaignId',
        value: campaignId
      })
    req.get(uri, success)
  },
  getPointHomeBasicCampaignBanner (campaignId, success, error) {
    req.get(URI.GET_POINT_HOME_BAISC_CAMPAIGN_BANNER(campaignId), success, error)
  },
  getCampaignBanner (campaignId, success) {
    req.get(URI.GET_CAMPAIGN_BANNER(campaignId), success)
  },
  createCampaignBannerReservation (reservation, success) {
    req.post(URI.CAMPAIGN_RESERVATION_BANNER, reservation, success)
  },
  getCampaignBannerReservations (campaignId, success) {
    req.get(URI.GET_CAMPAIGN_RESERVATION_BANNER(campaignId), success)
  },
  updateCampaignBannerReservation (reservation, success) {
    req.put(URI.CAMPAIGN_RESERVATION_BANNER, reservation, success)
  },
  deleteCampaignBannerReservation (reservationSeq, success) {
    req.delete(URI.DELETE_CAMPAIGN_RESERVATION_BANNER(reservationSeq), success)
  },
  getCoupons (campaignId, success) {
    req.get(`${URI.GET_COUPONS}/${campaignId}`, success)
  },
  getPointHomeBasicCampaignCoupon (campaignId, success, fail) {
    req.get(URI.GET_POINT_HOME_BAISC_CAMPAIGN_COUPON(campaignId), success, fail)
  },
  getCouponsByAdvertiserCode (advertiserCode, success) {
    req.get(`${URI.GET_COUPONS}/by-advertiser/${advertiserCode}`, success)
  },
  getCouponsIncludeAdvertiserCodes (advertiserCodes, success) {
    req.post(`${URI.GET_COUPONS}/advertiserCodes`, advertiserCodes, success)
  },
  updateCoupons (couponUpdateReqModel, success) {
    req.put(URI.UPDATE_COUPONS, couponUpdateReqModel, success)
  },
  deleteCoupon (campaignId, couponId, campaignStatus, couponRemoveStatus, success) {
    req.delete(`${URI.DELETE_COUPON}/${campaignId}/${couponId}/${campaignStatus}/${couponRemoveStatus}`, success)
  },
  isEventPageExist (campaignId, success) {
    req.get(`${URI.CHECK_EVENT_PAGE}/${campaignId}`, success)
  },
  getOriginEventPage (campaignId, campaignType, success) {
    const uri = URI.GET_EVENT_PAGE + req.makeGetSuffix(
      {
        name: 'campaignId',
        value: campaignId
      },
      {
        name: 'campaignType',
        value: campaignType
      }
    )
    req.get(uri, success)
  },
  updateEventPage (eventPage, success) {
    req.put(URI.UPDATE_EVENT_PAGE, eventPage, success)
  },
  getCouponDownloadPage (eventPage, success) {
    req.post(URI.COUPON_DOWNLOAD_PAGE, eventPage, success)
  },
  rejectCampaign (reject, success) {
    req.post(URI.REJECT_CAMPAIGN, reject, success)
  },
  getCustomCoupons (campaignId, couponIds, success) {
    if (couponIds.size === 0) success()
    req.get(URI.GET_COUPON_INFO(campaignId, couponIds), success)
  },
  getCoupon (couponId, success, fail) {
    req.get(URI.GET_COUPON(couponId), success, fail)
  },
  getAvailableMerchants (cmpId, success) {
    req.get(URI.GET_AVAILABLE_MERCHANTS(cmpId), success)
  },
  getAvailableMerchant (merchantCode, success) {
    req.get(URI.GET_AVAILABLE_MERCHANT(merchantCode), success)
  },
  getCampaignSettingFlow ({
    campaignGroupType,
    campaignType
  }, success) {
    req.get(URI.GET_CAMPAIGN_SETTING_FLOW(campaignGroupType, campaignType), success)
  },
  getCampaignTypeSetting (success) {
    req.get(URI.GET_CAMPAIGN_TYPE_SETTING, success)
  },
  getCampaignShopType (success) {
    req.get(URI.GET_CAMPAIGN_SHOP_TYPE, success)
  },
  getCampaignPageType (success) {
    req.get(URI.GET_CAMPAIGN_PAGE_TYPE, success)
  },
  setCampaignShop (campaignShop, success) {
    req.post(URI.SET_CAMPAIGN_SHOP, campaignShop, success)
  },
  createCustomCoupons (campaignIdAndCustomCoupons, success) {
    req.post(URI.CREATE_CUSTOM_COUPONS, campaignIdAndCustomCoupons, success)
  },
  updateCustomCoupons (campaignIdAndCustomCoupons, success) {
    req.put(URI.UPDATE_CUSTOM_COUPONS, campaignIdAndCustomCoupons, success)
  },
  updateCampaignShop (campaignShop, success) {
    req.put(URI.SET_CAMPAIGN_SHOP, campaignShop, success)
  },
  getShoppingRewardCampaign (campaignId, success, fail) {
    req.get(URI.GET_SHOPPING_REWARD_CAMPAIGN + campaignId, success, fail)
  },
  updateShoppingRewardCampaign (campaign, success) {
    req.put(URI.UPDATE_SHOPPIMG_REWARD_CAMPAIGN, campaign, success)
  },
  deleteCampaigns (campaignIds, success) {
    req.delete(URI.DELETE_CAMPAIGNS(campaignIds), success)
  },
  getPromotionList (advertiserCode, success) {
    req.get(`${URI.GET_PROMOTION_LIST}?advertiserCode=${advertiserCode}`, success)
  },
  getCampaignEventPageReservations (campaignId, success) {
    req.get(URI.GET_CAMPAIGN_RESERVATION_EVENT_PAGE(campaignId), success)
  },
  createCampaignEventPageReservations (eventPageReservation, success, fail) {
    req.post(URI.CREATE_CAMPAIGN_RESERVATION_EVENT_PAGE, eventPageReservation, success, fail)
  },
  updateCampaignEventPageReservations (eventPageReservation, success, fail) {
    req.put(URI.UPDATE_CAMPAIGN_RESERVATION_EVENT_PAGE, eventPageReservation, success, fail)
  },
  deleteCampaignEventPageReservations (eventPageReservation, success, fail) {
    req.delete(URI.DELETE_CAMPAIGN_RESERVATION_EVENT_PAGE(eventPageReservation.campaignId, eventPageReservation.reservationSeq), success, fail)
  },
  validCampaignEventPageReservations (eventPageReservation, success, fail) {
    req.post(URI.VALID_CAMPAIGN_RESERVATION_EVENT_PAGE, eventPageReservation, success, fail)
  },
  getCampaignPartyplus (campaignId, success) {
    req.get(URI.CAMPAIGN_PARTYPLUS(campaignId), success)
  },
  createPointHomeBasicCampaignBaisc (basic, success, fail) {
    req.post(URI.POINT_HOME_BAISC_CAMPAIGN_BASIC, basic, success, fail)
  },
  createPointHomeBasicCampaignTarget (target, success, fail) {
    req.post(URI.POINT_HOME_BAISC_CAMPAIGN_TARGET, target, success, fail)
  },
  createPointHomeBasicCampaignBanner (banner, success, fail) {
    req.post(URI.POINT_HOME_BAISC_CAMPAIGN_BANNER, banner, success, fail)
  },
  createPointHomeBasicCampaignCoupon (coupon, success, fail) {
    req.post(URI.POINT_HOME_BAISC_CAMPAIGN_COUPON, coupon, success, fail)
  },
  updatePointHomeBasicCampaignBaisc (basic, success, fail) {
    req.put(URI.POINT_HOME_BAISC_CAMPAIGN_BASIC, basic, success, fail)
  },
  updatePointHomeBasicCampaignTarget (target, success, fail) {
    req.put(URI.POINT_HOME_BAISC_CAMPAIGN_TARGET, target, success, fail)
  },
  updatePointHomeBasicCampaignBanner (banner, success, fail) {
    req.put(URI.POINT_HOME_BAISC_CAMPAIGN_BANNER, banner, success, fail)
  },
  updatePointHomeBasicCampaignCoupon (coupon, success, fail) {
    req.put(URI.POINT_HOME_BAISC_CAMPAIGN_COUPON, coupon, success, fail)
  }
}
