import _ from '@/partial'
const setAdvertiserMixin = {
  methods: {
    setAdvertiserMixin (advertiserList, advertiserCode) {
      _.go(
        advertiserList,
        _.find(adv => adv.advId === advertiserCode),
        _.tap(_(this.setAdvertiser)),
        (adv) => {
          adv.svcCode === 'ADVERTISER' && window.sessionStorage.setItem('advertiser', adv.advId)
        }
      )
    }
  }
}

export default setAdvertiserMixin
