import req from '@/api/http-request'

const CLO_PAYMENT_URI = {
  GET_LIST: 'clo/account/payment/list',
  GET_SIMPLE_LIST: 'clo/account/payment/simple-list',
  GET_PAYMENT: 'clo/account/payment',
  CREATE_PAYMENT: 'clo/account/payment',
  UPDATE_PAYMENT: 'clo/account/payment'
}

export default {
  getCloPaymentList (search, success) {
    const uri = CLO_PAYMENT_URI.GET_LIST + req.makeGetSuffix(
      { name: 'page', value: search.page.now },
      { name: 'size', value: search.page.size },
      { name: 'column', value: search.sort.column },
      { name: 'direction', value: search.sort.direction })
    req.get(uri, success)
  },
  getSimpleCloPaymentList (success) {
    const uri = CLO_PAYMENT_URI.GET_SIMPLE_LIST
    req.get(uri, success)
  },
  getCloPayment (cloPaymentCode, success) {
    const uri = CLO_PAYMENT_URI.GET_PAYMENT + req.makeGetSuffix(
      { name: 'cloPaymentCode', value: cloPaymentCode })
    req.get(uri, success)
  },
  createCloPayment (cloPayment, success) {
    req.post(CLO_PAYMENT_URI.CREATE_PAYMENT, cloPayment, success)
  },
  updateCloPayment (cloPayment, success) {
    req.put(CLO_PAYMENT_URI.UPDATE_PAYMENT, cloPayment, success)
  }
}
