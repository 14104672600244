<template lang="pug">
footer.p_developer_footer2(:style='{display: footerDisplay}')
  .footer__inner
    .footer__info
      ul.footer__info--list
        li(v-for='f in finfo' :class="{font_bold: f.isStrong}")
          a(@click='openPage(f.name, f.link, f.isNewWindow, WH_TYPES[0])') {{f.name}}
      .footer__family(:class='{open: isOpenedfamilyDropBox}' @click='isOpenedfamilyDropBox = !isOpenedfamilyDropBox')
        a.footer__site
          span 패밀리 사이트
          em.arrow_down
            svg(xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none")
              path(fill-rule="evenodd" clip-rule="evenodd" d="M2.44594 3.55408L1.83984 4.16017L6.00003 8.32036L10.1602 4.16017L9.55413 3.55408L6.00003 7.10817L2.44594 3.55408Z" fill="#111111" fill-opacity="0.6")
        ul.footer__list
          li(v-for='f in family' :class='{top_border: f.topBorder}')
            a(@click='openPage(f.name, f.link, f.isNewWindow, WH_TYPES[0])') {{ f.name }}
    .footer__corpinfo
      div
        span.black.font_medium 엔에이치엔페이코(주)
        span 대표자: 정연훈
      div
        span 경기도 성남시 분당구 대왕판교로645번길 16, 플레이뮤지엄
      .wrap
        span 사업자 등록번호: 563-81-00723
        span
          a.gray(@click='openPage(corpInfo.name, corpInfo.link, corpInfo.isNewWindow, WH_TYPES[1])') {{ corpInfo.name }}
        span 통신판매업신고번호: 제2017-성남분당-0458호
        span 호스팅서비스 사업자: 엔에이치엔페이코(주)
      .col
        span 대표전화 1544-6891
        span 이메일&nbsp;
          a.gray(href="mailto:mailto:dl_membership_partner@nhnpayco.com") 멤버십센터 (dl_membership_partner@nhnpayco.com)
        span
          a.gray.clo_center(href="mailto:help_biz_payco@nhnpayco.com") CLO센터 (help_biz_payco@nhnpayco.com)
    p.footer__copyrights &copy;
      a.link_copy(@click="openPage('NHN PAYCO Corp.', 'https://company.payco.com/', false)") NHN PAYCO Corp.
      |  All Right Reserved.
</template>

<script>
import { mapGetters } from 'vuex'

const MARKETING_CENTER_URL = 'https://' + process.env.GNB_HOST
const LOGIN_HOST = 'https://' + process.env.LOGIN_HOST

export default {
  name: 'PageFooter',
  data () {
    return {
      footerDisplay: 'none',
      WH_TYPES: [{ w: 730, h: 780 }, { w: 712, h: 760 }],
      finfo: [
        { name: '회사소개', link: 'https://company.payco.com/', isNewWindow: false },
        { name: '회원약관', link: `${LOGIN_HOST}/termAgree.nhn`, isNewWindow: true },
        { name: '이용약관', link: `${MARKETING_CENTER_URL}/popup/termAgree.nhn`, isNewWindow: true },
        { name: '전자금융거래 이용약관', link: 'https://www.payco.com/financeAgree.nhn', isNewWindow: true },
        { name: '통신과금서비스이용약관', link: 'https://www.payco.com/billingAgree.nhn', isNewWindow: true },
        { name: '개인정보처리방침', link: 'https://id.payco.com/privacyPolicy.nhn', isNewWindow: true, isStrong: true }
      ],
      family: [
        { name: 'PAYCO 닷컴', link: 'https://www.payco.com/', isNewWindow: false },
        { name: 'PAYCO 개발자센터', link: 'https://devcenter.payco.com/', isNewWindow: false },
        { name: 'PAYCO 로그인 개발자센터', link: 'https://developers.payco.com/', isNewWindow: false },
        { name: 'PAYCO 파트너센터', link: 'https://partner.payco.com/', isNewWindow: false },
        { name: 'PAYCO 기업복지', link: 'https://bizplus.payco.com/', isNewWindow: false },
        { name: 'PAYCO 상품권', link: 'https://gift.payco.com/', isNewWindow: false },
        { name: 'PAYCO 오더클라우드', link: 'https://order.payco.kr/main', isNewWindow: false },
        { name: 'PAYCO 인증센터', link: 'https://cert.payco.com/', isNewWindow: false },
        { name: 'PAYCO 멤버십 클라우드', link: 'https://membership.payco.com/', isNewWindow: false },
        { name: 'NHN Cloud', link: 'https://www.toast.com/kr', isNewWindow: false, topBorder: true },
        { name: 'INSIDE NHN', link: 'https://inside.nhn.com/br/index.nhn', isNewWindow: false }
      ],
      corpInfo: { name: '사업자 정보확인', link: 'https://www.ftc.go.kr/info/bizinfo/communicationViewPopup.jsp?wrkr_no=5638100723', isNewWindow: true },
      isOpenedfamilyDropBox: false
    }
  },
  methods: {
    openPage (name, link, isNewWindow, wh) {
      if (isNewWindow) {
        const x = (screen.width - wh.w) / 2
        const y = (screen.height - wh.h) / 2
        const sScroll = screen.width >= 800 && screen.height >= 600 ? 'yes' : 'no'

        let sOption = 'toolbar=no, channelmode=no, location=no, directories=no, menubar=no'
        sOption += `, scrollbars=${sScroll}, resizable=no, status=no, left=${x}, top=${y}, width=${wh.w}, height=${wh.h}`

        const win = window.open(link, name, sOption)

        try { win.focus() } catch (e) {}
      } else {
        window.open(link, '_blank')
      }
    }
  },
  computed: {
    ...mapGetters(['myRole'])
  },
  created () {
    if (this.myRole.length > 0) this.footerDisplay = 'block'
  },
  watch: {
    myRole: function (n, o) {
      if (o.length === 0 && n.length > 0) {
        this.footerDisplay = 'block'
      }
    }
  }
}
</script>
