import $ from '@/util'
import {
  checkAllValidation,
  setValidation,
  validText,
  validTextWithNoRegex,
  byteCountChecker,
  nullChecker
} from '@/components/common/validation-checker'

class ShopForm {
  constructor () {
    this.validation = {}
    this.validation.shopName = {
      bytes: 0,
      isValid: false,
      message: '매장명은 필수 입력값입니다.'
    }
    this.validation.address = {
      bytes: 0,
      isValid: false,
      message: '주소는 필수 입력값입니다.'
    }
    this.validation.latitude = {
      bytes: 0,
      isValid: false,
      message: '북위는 필수 입력값입니다.'
    }
    this.validation.longitude = {
      bytes: 0,
      isValid: false,
      message: '동경은 필수 입력값입니다.'
    }
  }

  validShopName (shopName) {
    shopName = shopName.trim()
    this.validation.shopName.bytes = $.getUTF8Length(shopName)
    validTextWithNoRegex(
      100,
      '매장명은 필수 입력값입니다.',
      setValidation(this.validation.shopName)
    )(shopName)
  }

  validAddress (address) {
    address = address.trim()

    this.validation.address = {
      bytes: $.getUTF8Length(address),
      isValid: true,
      message: ''
    }

    var valids = [
      nullChecker('주소는 필수 입력값입니다.')(address),
      byteCountChecker(200)(address)
    ]

    var isNotValid = valids.find(v => !v[0])

    if (isNotValid) {
      setValidation(this.validation.address)(isNotValid[0], isNotValid[1])
    }
  }

  validLatitude (latitude) {
    latitude = latitude.trim()
    var regex = /^[1-9]{1}[0-9]{1}[\\.][0-9]{1,6}$/
    validText(
      regex,
      11,
      '북위는 필수 입력값입니다.',
      '유효한 북위 값을 입력해주세요.',
      setValidation(this.validation.latitude)
    )(latitude)
  }

  validLongitude (longitude) {
    longitude = longitude.trim()
    var regex = /^[1-9]{1}[0-9]{2}[\\.][0-9]{1,6}$/
    validText(
      regex,
      12,
      '동경은 필수 입력값입니다.',
      '유효한 동경 값을 입력해주세요.',
      setValidation(this.validation.longitude)
    )(longitude)
  }

  validAll () {
    return checkAllValidation(this.validation)
  }
}

export { ShopForm }
