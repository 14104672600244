import { areEitherLanguages, isEnglish, isKorean, isNumber, isSpecial } from './char-util'
import _ from '@/partial'

function decimalAdjust (type, value, exp) {
  // If the exp is undefined or zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value)
  }
  value = +value
  exp = +exp
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN
  }
  // Shift
  value = value.toString().split('e')
  value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)))
  // Shift back
  value = value.toString().split('e')
  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp))
}

export default {
  match (value) {
    return {
      flag: false,
      result: null,
      case (cond, task) {
        if (!this.flag) {
          this.result = _.go(value,
            v => _.isFunction(cond) ? cond(v) : cond,
            _.tap(b => {
              this.flag = b
            }),
            c => c ? (_.isFunction(task) ? _.identity(task(value)) : _.identity(task)) : null
          )
        }
        return this
      },
      default (task) {
        return !this.flag
          ? (_.isFunction(task) ? task(value) : _.identity(task))
          : _.identity(this.result)
      }
    }
  },
  getUTF8LengthOfCharCode (code) {
    let [len, skipNext] = [0, false]
    if (code <= 0x7f) {
      len = 1
    } else if (code <= 0x7ff) {
      len = 2
    } else if (code >= 0x3131 && code <= 0x318e) {
      len = 2 // 단자음, 단모음 (ㄱ-ㅎㅏ-ㅣ)
    } else if (code >= 0xac00 && code <= 0xd7a3) {
      len = 2 // 한글은 2바이트로 계산 (가-힣)
    } else if (code >= 0xd800 && code <= 0xdfff) {
      len = 4
      skipNext = true
    } else if (code < 0xffff) {
      len = 3
    } else {
      len = 4
    }
    return [len, skipNext]
  },
  getUTF8Length (s) {
    var len = 0
    if (!s) return len
    for (var i = 0; i < s.length; i++) {
      const [codeLen, skipNext] = this.getUTF8LengthOfCharCode(s.charCodeAt(i))
      len += codeLen
      if (skipNext) i++
    }
    return len
  },
  formatNumber (num, length) {
    const n = num.toString()
    return new Array(length - n.length + 1).join('0') + n
  },
  addDateDays (date, days) {
    const result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
  },
  formatDate (date, splitter) {
    var sp = splitter || '-'
    return date.getFullYear() + sp +
      this.formatNumber(date.getMonth() + 1, 2) + sp +
      this.formatNumber(date.getDate(), 2)
  },
  formatDateTime (date, splitter = '-') {
    return this.formatDate(date, splitter) + ' ' +
      this.formatNumber(date.getHours(), 2) + ':' +
      this.formatNumber(date.getMinutes(), 2)
  },
  formatDateRange (startDate, endDate) {
    return startDate.getFullYear() + '. ' +
      Number(startDate.getMonth() + 1) + '. ' +
      startDate.getDate() + '. ~ ' +
      endDate.getFullYear() + '. ' +
      Number(endDate.getMonth() + 1) + '. ' +
      endDate.getDate() + '.'
  },
  formatDateYYMMDD (date) {
    const fullYear = date.getFullYear().toString()
    return fullYear.substring(fullYear.length - 2) +
      this.formatNumber(date.getMonth() + 1, 2) +
      this.formatNumber(date.getDate(), 2)
  },
  formatDateYYYYMM (date) {
    const fullYear = date.getFullYear().toString()
    return fullYear + this.formatNumber(date.getMonth() + 1, 2)
  },
  numberWithCommas (x) {
    if (x !== 0 && !x) return ''
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  autoAddCommas (e) {
    const regex = /^[0-9,]+$/
    if (!regex.test(e.target.value)) {
      e.target.value = this.numberWithCommas(e.target.value.replace(/\D/g, ''))
      return
    }
    e.target.value = this.numberWithCommas(e.target.value.replace(/,/gi, ''))
  },
  commaStringToNumber (string) {
    if (string === '') return -1
    return parseInt(string.toString().replace(/,/gi, ''), 10)
  },
  clickOutside (exceptionId = '#') {
    return {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          // here I check that click was outside the el and his childrens
          if (!(el === event.target || el.contains(event.target)) && event.target.id !== exceptionId) {
            // and if it did, call method provided in attribute value
            if (binding.arg !== undefined) {
              vnode.context[binding.expression](binding.arg)
            } else {
              vnode.context[binding.expression](event)
            }
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
    }
  },
  getUserAgent () {
    return this.match(navigator.userAgent)
      .case(ua => (ua.indexOf('Opera') || ua.indexOf('OPR')) !== -1, _.identity('opera'))
      .case(ua => ua.indexOf('Chrome') !== -1, _.identity('chrome'))
      .case(ua => ua.indexOf('Safari') !== -1, _.identity('safari'))
      .case(ua => ua.indexOf('Firefox') !== -1, _.identity('firefox'))
      .case(ua => ua.indexOf('MSIE') !== -1 || !!document.documentMode, _.identity('ie'))
      .default(_.identity('unknown'))
  },
  getReverseSortDirection (direction) {
    return direction === 'ASC' ? 'DESC' : 'ASC'
  },
  onClipboardEvent (fnCopy, fnPaste) {
    return function (e) {
      e = e || window.event
      var key = e.which || e.keyCode
      var ctrl = (navigator.userAgent.includes('Mac OS X') ? e.metaKey : e.ctrlKey) || key === 17
      if (key === 86 && ctrl) {
        fnPaste && fnPaste()
      } else if (key === 67 && ctrl) {
        fnCopy && fnCopy()
      }
    }
  },
  OpenBlobToExcel (blob, fileName, extension = 'xlsx') {
    var xlsxName = `${fileName}.${extension}`
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, xlsxName)
    } else {
      const a = document.createElement('a')
      a.href = URL.createObjectURL(blob)
      a.setAttribute('download', xlsxName)
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  },
  stringCompareSpecialNumberAlphabetKorean (a, b) {
    const left = a.toLowerCase().replace(/\s/g, '')
    const right = b.toLowerCase().replace(/\s/g, '')
    const minLength = Math.min(left.length, right.length)
    let leftChar, rightChar
    for (let i = 0; i < minLength; i += 1) {
      leftChar = left.charAt(i)
      rightChar = right.charAt(i)

      if (leftChar !== rightChar) {
        if (
          // 영어/한글, 한글/숫자, 영어/숫자, 한글/특수문자 조합의 경우 바로 ascii 비교가 가능
          areEitherLanguages(isEnglish, isKorean, leftChar, rightChar) ||
          areEitherLanguages(isKorean, isNumber, leftChar, rightChar) ||
          areEitherLanguages(isEnglish, isNumber, leftChar, rightChar) ||
          areEitherLanguages(isKorean, isSpecial, leftChar, rightChar)
        ) {
          return leftChar > rightChar ? 1 : -1
        } else if (
          // 영어/특수문자, 숫자/특수문자의 경우, ascii 비교가 원하는 대로 나오지 않아, 따로 처리
          areEitherLanguages(isEnglish, isSpecial, leftChar, rightChar) ||
          areEitherLanguages(isNumber, isSpecial, leftChar, rightChar)
        ) {
          return (isEnglish(leftChar) || isNumber(leftChar)) ? 1 : -1
        } else {
          return leftChar > rightChar ? 1 : -1
        }
      }
    }
    return left.length > right.length ? 1 : -1
  },
  substrUTF8bytes (s, lengthInBytes) {
    let len = 0
    let result = ''
    if (!s) return result
    for (let i = 0; i < s.length && len <= lengthInBytes; i++) {
      const [codeLen, skipNext] = this.getUTF8LengthOfCharCode(s.charCodeAt(i))
      len += codeLen
      if (skipNext) i++
      if (len <= lengthInBytes) result += s[i]
    }
    return result
  },
  round10: (value, exp) => decimalAdjust('round', value, exp), // Decimal round
  floor10: (value, exp) => decimalAdjust('floor', value, exp), // Decimal floor
  ceil10: (value, exp) => decimalAdjust('ceil', value, exp), // Decimal ceil
  deleteHtmlTag (s, tags) {
    let regex
    if (tags) {
      tags = tags.join('|')
      regex = new RegExp(`<(\\/(${tags})|(${tags}))\\s([^>]+)>`, 'gi')
      const regexNoProperty = new RegExp(`<(\\/(${tags})|(${tags}))>`, 'gi')
      return s.replace(regex, '').replace(regexNoProperty, '')
    } else {
      regex = /(<([^>]+)>)/gi
      return s.replace(regex, '')
    }
  },
  deleteLFCode  (str) {
    const unicodeLF = 10
    const last = str.charAt(str.length - 1)
    return last.charCodeAt(0) === unicodeLF ? str.slice(0, -1) : str
  },
  formatDisplayCodeWithOutAllValue (value) {
    return value === 'ALL' ? '' : '(' + value + ')'
  },
  parseFileNameFromPath (path) {
    return path.substring(path.lastIndexOf('/') + 1)
  },
  strip (htmlText) {
    return new DOMParser().parseFromString(htmlText, 'text/html')
  },
  isNumericOnly (str) {
    const regex = /^\d+$/
    return regex.test(str)
  },
  adjustRate (value, fixed = 2) {
    if (value === '.') return 0
    const percentRegex = /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{2}|\.)?$)/
    const underPointSencond = /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{2}|\.)?$)/
    if (!percentRegex.test(value)) {
      value = underPointSencond.test(value) ? value : value.replace(/[^0-9.]/g, '')
      if (value.split('.').length > 2) {
        value = value.substring(0, value.indexOf('.', value.indexOf('.') + 1))
      }
      const num = value.split('.')
      if (num[1] && num[1].length > fixed) {
        value = num[0] + '.' + num[1].substring(0, fixed)
      }
    }
    return value
  },
  removeNoneNumber (value) {
    if (typeof value === 'number') {
      return value
    }
    return value.replace(/\D/g, '')
  },
  adjustNumber (value, max = 10000000) {
    let num = this.removeNoneNumber(value)
    if (num === '') {
      return ''
    }
    num = Math.min(Number(num), max)
    return this.numberWithCommas(num)
  },
  clone (obj) {
    if (!obj) return null
    return JSON.parse(JSON.stringify(obj))
  },
  getKeyByValue (object, value) {
    return Object.keys(object).find(key => object[key] === value)
  },
  initDocumentHtml (window, title, head, body) {
    // 커스텀 팝업 등 창에 html을 새로 작성할 때
    // innerHTML 등으로 초기화하면 크롬에서 css 로딩이 안되는 이슈가 있어
    // document.write를 이용해 초기화
    window.document.open()
    window.document.write(`
        <head>
            <title>${title || ''}</title>
            ${head || ''}
        </head>
        <body>
            ${body || ''}
        </body>
    `)
    window.document.close()
  },
  today () {
    const t = new Date()
    return {
      year: t.getFullYear(),
      month: t.getMonth(),
      date: t.getDate(),
      hour: t.getHours(),
      minute: t.getMinutes(),
      second: t.getSeconds()
    }
  },
  range (start, stop, step) {
    return _.range(start, stop, step)
  },
  trim (str) {
    if (str === '') return ''
    if (!str) return null
    return str.trim()
  },
  trimStart (str) {
    if (typeof str === 'number') {
      return str
    }
    if (str === '') return ''
    if (!str) return null
    const trimmed = str.replace(/^\s+/, '')
    if (trimmed === '') return str
    return trimmed
  },
  toFixedFloat (value, digit) {
    return parseFloat(value.toFixed(digit))
  },
  dateToString (date) {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`
  },
  toSimpleYmdtObj (datetime) {
    const t = new Date(datetime)
    return {
      y: t.getFullYear(),
      m: t.getMonth(),
      d: t.getDate(),
      h: t.getHours(),
      min: t.getMinutes(),
      s: t.getSeconds()
    }
  },
  isEmpty (obj) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false
      }
    }
    return true
  },
  isEmptyObject (value) {
    if (value == null) {
      return false
    }

    if (typeof value !== 'object') {
      return false
    }

    const proto = Object.getPrototypeOf(value)
    if (proto !== null && proto !== Object.prototype) {
      return false
    }

    return this.isEmpty(value)
  },
  hasText (string) {
    return string === null || string === undefined || string === ''
  },
  isObject (value) {
    return value !== null && typeof value === 'object'
  },
  isString (value) {
    return typeof value === 'string' || value instanceof String
  }
}
