import campaignApi from '@/api/campaign'
import advertiserApi from '@/api/advertiser'
import clickRewardApi from '@/api/clickReward'
import { getComputedTypes } from '../../components/campaign/create/data/types-common'
import { CAMPAIGN_GROUP_TYPE, CAMPAIGN_TYPE } from '@/types/campaign-types'
import Vue from 'vue'

import _ from '@/partial'

const STEP = {
  LIST: 'list',
  DEFAULT: 'default'
}

export default {
  state: {
    commonCode: {},
    campaign: null,
    step: { prev: STEP.LIST, next: STEP.LIST, mode: 'create', number: 1 }
  },
  getters: {
    campaign: state => state.campaign,
    campaignGroupType: state => getComputedTypes(CAMPAIGN_GROUP_TYPE, state.campaign, 'campaignGroupType'),
    campaignType: state => getComputedTypes(CAMPAIGN_TYPE, state.campaign, 'campaignType'),
    commonCode: state => state.commonCode,
    prevUri: state => state.step.prev === STEP.LIST
      ? '/campaign'
      : state.step.prev === STEP.DEFAULT && state.step.mode === 'create'
        ? `/campaign/create/${STEP.DEFAULT}?campaignId=${state.campaign.campaignId}`
        : `/campaign/${state.step.mode}/${state.step.prev}/${state.campaign.campaignId}`,
    nextUri: state => state.step.next === STEP.LIST
      ? '/campaign'
      : `/campaign/${state.step.mode}/${state.step.next}/${state.campaign.campaignId}`,
    prevUriWith: state => campaignId => state.step.prev === STEP.LIST
      ? '/campaign'
      : state.step.prev === STEP.DEFAULT && state.step.mode === 'create'
        ? `/campaign/create/${STEP.DEFAULT}?campaignId=${campaignId}`
        : `/campaign/${state.step.mode}/${state.step.prev}/${campaignId}`,
    nextUriWith: state => campaignId => state.step.next === STEP.LIST
      ? '/campaign'
      : `/campaign/${state.step.mode}/${state.step.next}/${campaignId}`,
    stepNumber: state => state.step.number,
    isLastStep: state => state.step.maxNumber === state.step.number
  },
  actions: {
    fetchCampaign ({ commit }, campaignId) {
      return new Promise((resolve, reject) => {
        campaignApi.getCampaign(campaignId, body => {
          commit('setCampaign', body.campaign)
          resolve()
        }, header => reject(header.resultMessage))
      })
    },
    fetchCampaignTarget ({ commit }, campaignId) {
      return new Promise((resolve, reject) => {
        campaignApi.getCampaignTarget(campaignId, body => {
          commit('setCampaignTarget', body.campaignTarget)
          resolve()
        },
        header => header.resultCode === 400
          ? commit('setCampaignTarget', null)
          : reject(header.resultMessage)
        )
      })
    },
    fetchCampaignBanner ({ commit }, campaignId) {
      return new Promise((resolve, reject) => {
        campaignApi.getCampaignBanner(campaignId, body => {
          commit('setCampaignBanner', body.campaignBanner)
          resolve()
        }, header => reject(header.resultMessage))
      })
    },
    fetchMerchant ({ commit }, merchantCode) {
      return new Promise((resolve, reject) => {
        advertiserApi.getMerchantByCode(merchantCode, body => {
          commit('setMerchant', body.merchant)
          resolve()
        })
      })
    },
    fetchCommonCodes ({ state, commit }) {
      return new Promise((resolve, reject) => {
        _.isEmpty(state.commonCode)
          ? campaignApi.getAllCommonCodes(body => {
            commit('setCommonCode', body.commonCode)
            resolve()
          })
          : resolve()
      })
    },
    fetchCoupons ({ commit }, campaignId) {
      return new Promise((resolve, reject) => {
        campaignApi.getCoupons(campaignId, body => {
          commit('setCoupons', body.coupons)
          resolve()
        })
      })
    },
    fetchShoppingRewardCampaign ({ commit }, campaignId) {
      return new Promise((resolve, reject) => {
        campaignApi.getShoppingRewardCampaign(campaignId, body => {
          commit('setCampaign', body.campaign)
          resolve()
        }, header => reject(header.resultMessage))
      })
    },
    fetchCampaignClickReward ({ commit }, campaignId) {
      return new Promise((resolve, reject) => {
        clickRewardApi.getClickReward(campaignId, body => {
          commit('setClickReward', body.campaignClickReward)
          resolve()
        }, header => reject(header.resultMessage))
      })
    },
    fetchCampaignReservedBanners ({ state, commit }, campaignId, callback) {
      return new Promise((resolve, reject) => {
        if (state.campaign && state.campaign.banner && state.campaign.banner.reservedBanners) {
          resolve()
        } else {
          campaignApi.getCampaignBannerReservations(campaignId, res => {
            commit('setCampaignReservedBanners', res.reservedBanners)
            resolve()
          }, header => reject(header.resultMessage))
        }
      })
    },
    fetchCampaignReservedEventPages ({ state, commit }, campaignId) {
      return new Promise((resolve, reject) => {
        campaignApi.getCampaignEventPageReservations(campaignId, res => {
          commit('setCampaignReservedEventPages', res.reservedEventPages)
          resolve()
        }, header => reject(header.resultMessage))
      })
    }
  },
  mutations: {
    setCampaign (state, campaign) {
      state.campaign = campaign
    },
    setCampaignShop (state, campaignShop) {
      _.set(state.campaign, 'campaignShop', campaignShop)
    },
    setMerchant (state, merchant) {
      if (state.campaign) {
        _.set(state.campaign, 'merchant', merchant)
      }
    },
    setCommonCode (state, commonCode) {
      state.commonCode = commonCode
    },
    setCoupons (state, coupons) {
      if (state.campaign) {
        _.set(state.campaign, 'coupons', coupons)
      }
      state.coupons = coupons
    },
    setCampaignBanner (state, banner) {
      if (state.campaign) {
        _.set(state.campaign, 'banner', banner)
      }
    },
    setCampaignReservedBanners (state, reservedBanners) {
      if (state.campaign && state.campaign.banner) {
        _.set(state.campaign.banner, 'reservedBanners', reservedBanners)
      }
    },
    addCampaignReservedBanner (state, reservedBanner) {
      if (state.campaign && state.campaign.banner && state.campaign.banner.reservedBanners) {
        state.campaign.banner.reservedBanners.push(reservedBanner)
        state.campaign.banner.reservedBanners.sort((a, b) => a.reservationYmdt - b.reservationYmdt)
      }
    },
    setCampaignReservedBanner (state, reservedBanner) {
      if (state.campaign && state.campaign.banner && state.campaign.banner.reservedBanners) {
        const index = state.campaign.banner.reservedBanners.findIndex(b => b.reservationSeq === reservedBanner.reservationSeq)
        if (index > -1) {
          state.campaign.banner.reservedBanners[index] = reservedBanner
          state.campaign.banner.reservedBanners.sort((a, b) => a.reservationYmdt - b.reservationYmdt)
        }
      }
    },
    removeCampaignReservedBanner (state, reservationSeq) {
      if (state.campaign && state.campaign.banner && state.campaign.banner.reservedBanners) {
        const index = state.campaign.banner.reservedBanners.findIndex(b => b.reservationSeq === reservationSeq)
        if (index > -1) {
          state.campaign.banner.reservedBanners.splice(index, 1)
        }
      }
    },
    setCampaignTarget (state, target) {
      state.campaign && _.set(state.campaign, 'target', target)
    },
    setClickReward (state, clickReward) {
      if (state.campaign) {
        Vue.set(state.campaign, 'clickReward', clickReward)
      }
    },
    setStep ({ step }, { prev, next, mode, number, maxNumber }) {
      _.set(step, 'prev', prev)
      _.set(step, 'next', next)
      _.set(step, 'mode', mode)
      _.set(step, 'number', number)
      _.set(step, 'maxNumber', maxNumber)
    },
    setDeactiveStartDateTimeByIndex (state, data) {
      state.campaign.campaignDeactivePeriods[data.index].deactiveStartDateTime = data.date
    },
    setDeactiveEndDateTimeByIndex (state, data) {
      state.campaign.campaignDeactivePeriods[data.index].deactiveEndDateTime = data.date
    },
    deleteStateCampaignDeactivePeriod (state, index) {
      state.campaign.campaignDeactivePeriods.splice(index, 1)
    },
    addStateCampaignDeactivePeriod (state, nowDate) {
      state.campaign.campaignDeactivePeriods.push({
        deactiveStartDateTime: nowDate + 24 * 60 * 60 * 1000 - (new Date(nowDate).getHours() * 60 * 60 * 1000),
        deactiveEndDateTime: nowDate + 48 * 60 * 60 * 1000 - (new Date(nowDate).getHours() * 60 * 60 * 1000) - (60 * 1000) + (59 * 1000)
      })
    },
    clearStateCampaignDeactivePeriod (state) {
      state.campaign.campaignDeactivePeriods = []
    },
    setCampaignReservedEventPages (state, reservedEventPages) {
      if (state.campaign) {
        Vue.set(state.campaign, 'reservedEventPages', reservedEventPages)
      }
    },
    setCampaignEventPages (state, eventPages) {
      if (state.campaign) {
        Vue.set(state.campaign, 'eventPages', eventPages)
      }
    }
  }
}
