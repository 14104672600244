import req from './http-request'

const ACCOUNT_URI = {
  GET_MY_ROLE: 'account/my/roles',
  GET_MASKED_USER_ID: 'account/masked-user-id',
  GET_NEED_TO_CHANGE_PASSWORD: 'user/need-to-change-password',
  POST_TWO_FACTOR_AUTHENTICATION: 'user/two-factor-authentication-code',
  DELETE_TWO_FACTOR_AUTHENTICATION: (code) => `user/two-factor-authentication-code?code=${code}`,
  GET_NEED_TWO_FACTOR_AUTHENTICATION: 'user/need-two-factor-authentication',
  POST_SESSION: 'user/session',
  GET_IS_VALID_SESSION: 'user/is-valid-session'
}

export default {
  getMyRole (success) {
    req.get(ACCOUNT_URI.GET_MY_ROLE, success)
  },
  getMaskedUserId (success) {
    req.get(ACCOUNT_URI.GET_MASKED_USER_ID, success)
  },
  getNeedToChangePassword (success) {
    req.get(ACCOUNT_URI.GET_NEED_TO_CHANGE_PASSWORD, success)
  },
  postTwoFactorAuthenticationCode (success, fail) {
    req.post(ACCOUNT_URI.POST_TWO_FACTOR_AUTHENTICATION, null, success, fail)
  },
  deleteTwoFactorAuthenticationCode (code, success) {
    req.delete(ACCOUNT_URI.DELETE_TWO_FACTOR_AUTHENTICATION(code), success)
  },
  getNeedTwoFactorAuthentication (success) {
    req.get(ACCOUNT_URI.GET_NEED_TWO_FACTOR_AUTHENTICATION, success)
  },
  postSession (success) {
    req.post(ACCOUNT_URI.POST_SESSION, null, success)
  },
  getIsValidSession (success) {
    req.get(ACCOUNT_URI.GET_IS_VALID_SESSION, success)
  }
}
