import OptimalTargetCouponApi from '@/api/campaign/optimal-target-coupon-custom'

export default {
  state: {
    campaign: {
      basic: null,
      target: null,
      banner: null,
      coupon: null,
      eventPage: null
    }
  },
  getters: {
    optimalTargetCouponCampaign: state => state.campaign,
    optimalTargetCouponCampaignBasic: state => state.campaign.basic,
    optimalTargetCouponCampaignTarget: state => state.campaign.target,
    optimalTargetCouponCampaignBanner: state => state.campaign.banner,
    optimalTargetCouponCampaignCoupon: state => state.campaign.coupon,
    optimalTargetCouponCampaignEventPage: state => state.campaign.eventPage
  },
  actions: {
    fetchOptimalTargetCouponCampaignBasic ({
      state,
      commit
    }, campaignId) {
      return new Promise((resolve, reject) => {
        OptimalTargetCouponApi.getOptimalTargetCouponCustomCampaignBasic(campaignId, body => {
          commit('setOptimalTargetCouponCampaignBasic', body.campaign || null)
          resolve(body.campaign)
        }, header => reject(header.resultMessage))
      })
    },
    fetchOptimalTargetCouponCampaignTarget ({
      state,
      commit
    }, campaignId) {
      return new Promise((resolve, reject) => {
        OptimalTargetCouponApi.getOptimalTargetCouponCustomCampaignTarget(campaignId, body => {
          commit('setOptimalTargetCouponCampaignTarget', body.campaignTarget || null)
          resolve(body.campaignTarget)
        },
        header => header.resultCode === 400
          ? commit('setOptimalTargetCouponCampaignTarget', null)
          : reject(header.resultMessage)
        )
      })
    },
    fetchOptimalTargetCouponCampaignBanner ({
      state,
      commit
    }, campaignId) {
      return new Promise((resolve, reject) => {
        OptimalTargetCouponApi.getOptimalTargetCouponCustomCampaignBanner(campaignId, body => {
          commit('setOptimalTargetCouponCampaignBanner', body.campaignBanner || null)
          resolve(body.campaignBanner)
        }, header => reject(header.resultMessage))
      })
    },
    fetchOptimalTargetCouponCampaignCoupon ({
      state,
      commit
    }, campaignId) {
      return new Promise((resolve, reject) => {
        OptimalTargetCouponApi.getOptimalTargetCouponCustomCampaignCoupon(campaignId, body => {
          commit('setOptimalTargetCouponCampaignCoupon', body.result || null)
          resolve(body.result)
        })
      })
    },
    fetchOptimalTargetCouponCustomEventPage ({
      state,
      commit
    }, campaignId) {
      return new Promise((resolve, reject) => {
        OptimalTargetCouponApi.getOptimalTargetCouponCustomEventPage(campaignId, body => {
          commit('setOptimalTargetCouponCampaignEventPage', body.eventPage || null)
          resolve(body.eventPage)
        })
      })
    }
  },
  mutations: {
    setOptimalTargetCouponCampaignBasic (state, basic) {
      state.campaign.basic = basic
    },
    setOptimalTargetCouponCampaignTarget (state, target) {
      state.campaign.target = target
    },
    setOptimalTargetCouponCampaignBanner (state, banner) {
      state.campaign.banner = banner
    },
    setOptimalTargetCouponCampaignCoupon (state, coupon) {
      state.campaign.coupon = coupon
    },
    setOptimalTargetCouponCampaignEventPage (state, eventPage) {
      state.campaign.eventPage = eventPage
    }
  }
}
