<template lang="pug">
.info_log_area
  ul.lst_log.scrollbar-inner
    slot
      li.item(v-for="file in files")
        span.timer -
        span.name_file {{file.name}}
      li.item(v-for="file in uploaded")
        span.timer (등록: {{registYmdt(file.regYmdt)}})
        span.name_file {{file.originalFileName}}
        span.name_file(v-if="!!file.lineCount", style="color: #fa2828") ({{ numberWithCommas(file.lineCount) }}개 업로드)
</template>
<script>
import _ from '@/partial'
import $ from '@/util'
import jQuery from 'jquery'
import { mapMutations } from 'vuex'
// eslint-disable-next-line no-unused-vars
import scrollbar from 'jquery.scrollbar'

export default {
  props: ['api', 'uploaded', 'files'],
  data () {
    return {
      progress: 0
    }
  },
  created () {
    this.uploadFiles()
  },
  mounted () {
    jQuery('.scrollbar-inner').scrollbar()
  },
  methods: {
    ...mapMutations({
      setCommonLoadingOn: 'setCommonLoadingOn',
      setCommonLoadingOff: 'setCommonLoadingOff'
    }),
    registYmdt (d) {
      return $.formatDate(new Date(d))
    },
    uploadFiles () {
      if (this.files && this.files.length > 0) {
        this.setCommonLoadingOn()
        this.api(
          _.map(this.files, f => f.data),
          e => { this.progress = e.loaded / e.total * 100 },
          body => {
            this.$emit('onUploaded', body)
            this.setCommonLoadingOff()
          },
          header => {
            this.$emit('onUploadFailed', header)
            this.setCommonLoadingOff()
          }
        )
      } else {
        this.progress = 100
      }
    },
    numberWithCommas (n) {
      return $.numberWithCommas(n)
    }
  },
  watch: {
    files () {
      this.uploadFiles()
    }
  }
}
</script>
