<template lang="pug">
#content.adv_center.ad_camp_cate(:class="isAdmin ? 'admin_account' : ''")
  .tit_area
    h1.tit 캠페인 유형 선택
    p.sub_tit 원하시는 캠페인 유형을 선택해 주세요.
  form.form
    ul.option_lst
      li(v-for="(group, groupIndex) in display.campaignGroupTypes", v-if="!group.onlyAdmin || isAdmin" :class="{is_selected: group.value === campaignGroupType}")
        fieldset
          .query_form
            .inp_area
              span.inp_radio.has_tit
                input.inp_rdo(:id="`group-${groupIndex}`" type="radio" name="rdo_category" :value="group.value" v-model="campaignGroupType" @click="display.selectedType = `${group.value} ${group.campaignTypes[0].value}`")
                label.rdo_control_label(:for="`group-${groupIndex}`")
                  span.ico_rdo
                  span.lb_cate
                    strong.tit {{group.name}}
                    span.gloss(v-if='group.value === CAMPAIGN_GROUP_TYPE.POINT_HOME') (포인트사업부 전용)
                    span.gloss(v-else-if="group.onlyAdmin") (어드민 전용)
                    span.desc
                      template(v-for="(item, index) in group.description") {{item}}
                        br(v-if="index !== group.description.length - 1")
              ul.lst_rdo(v-if="group.campaignTypes")
                li.rdo(v-for="(type, itemIndex) in group.campaignTypes" v-if="showCampaignType(type)")
                  span.inp_radio(:class="{disabled: !type.enable}")
                    input.inp_rdo(:disabled="!type.enable" :id="`type-${groupIndex}-${itemIndex}`" type="radio" name="rdo" :value="`${group.value} ${type.value}`" v-model="display.selectedType")
                    label.rdo_control_label(:for="`type-${groupIndex}-${itemIndex}`")
                      span.ico_rdo
                      | {{type.name}}
                      span.gloss(v-if="type.subDescription") {{type.subDescription}}
                      span.gloss(v-if="type.onlyAdmin && !group.onlyAdmin") (어드민 전용)
                    .tooltip(v-if="type.description")
                      .ico_tooltip
                        button(:disabled='true')
                          span.blind 캡션 열기
                        .box_tooltip(:tabindex="0")
                          h3 {{type.name}}
                          p {{type.description}}
  .btn_act_area
    button.btn.btn_w(type="button" @click="goCampaign") 취소
    button.btn.btn_red(type="button" :class="{disable: !campaignGroupType || !campaignType}" :disabled="!campaignGroupType || !campaignType" @click="createCampaign") 시작
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import _ from '@/partial'
import campaignApi from '@/api/campaign'
import { CAMPAIGN_GROUP_TYPE, CAMPAIGN_TYPE } from '@/types/campaign-types'

export default {
  name: 'CampaignTypeSetting',
  data () {
    return {
      campaignGroupType: '',
      campaignType: '',
      display: {
        campaignGroupTypes: {},
        selectedType: ''
      },
      CAMPAIGN_GROUP_TYPE: CAMPAIGN_GROUP_TYPE,
      CAMPAIGN_TYPE: CAMPAIGN_TYPE
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'commonCode', 'myRole', 'hasAroundMeRole'])
  },
  methods: {
    ...mapActions(['fetchCommonCodes']),
    ...mapMutations(['setCampaign']),
    initCampaignTypeSetting () {
      var initField = (field, code) => {
        _.go(
          _.val(this.commonCode, code),
          _.find(c => c.default),
          _.val('value'),
          _(_.set, this, field)
        )
      }
      initField('campaignGroupType', 'campaignGroupTypes')
      initField('campaignType', 'campaignTypes')
      this.display.selectedType = this.hasAroundMeRole ? `${CAMPAIGN_GROUP_TYPE.AROUND_ME} ${CAMPAIGN_TYPE.OUTER}` : `${this.campaignGroupType} ${this.campaignType}`
    },
    goCampaign () {
      this.$router.push('/campaign')
    },
    createCampaign () {
      this.setCampaign(null)
      this.$router.push({
        path: '/campaign/create/default',
        query: {
          campaignGroupType: this.campaignGroupType,
          campaignType: this.campaignType
        }
      })
    },
    showCampaignType (type) {
      return Object.entries(type.visibilityByRole)
        .some(([role, visible]) => this.myRole.includes(role) && visible)
    }
  },
  created () {
    campaignApi.getCampaignTypeSetting(body => { this.display.campaignGroupTypes = body.campaignTypeSetting })
    this.fetchCommonCodes()
      .then(this.initCampaignTypeSetting)
  },
  watch: {
    'display.selectedType' () {
      [this.campaignGroupType, this.campaignType] = this.display.selectedType.split(' ')
    }
  }
}
</script>

<style scoped></style>
