import PublisherApi from '@/api/admin/publisher'
import _ from '@/partial'

export default {
  state: {
    publisherList: []
  },
  getters: {
    publisherListForManager: state => state.publisherList
  },
  actions: {
    fetchPublisherList ({ commit }) {
      return PublisherApi.getPublishersForManager((body) => {
        _.go(
          body.publishers,
          (list) => { commit('setPublisherList', list) }
        )
      })
    }
  },
  mutations: {
    setPublisherList (state, publisherList) {
      state.publisherList = publisherList
    }
  }
}
