import AdvertiserApi from '@/api/advertiser'
import CampaignApi from '@/api/campaign'

/* 어디서든 필요한 정보들을 조회 */
export default {
  state: {
    advertiserMerchants: null,
    campaignCategories: null,
    loading: false
  },
  getters: {
    commonLoading: state => state.loading,
    commonAdvertiserMerchants: state => state.advertiserMerchants,
    commonCampaignCategories: state => state.campaignCategories
  },
  actions: {
    fetchAdvertiserMerchants ({ state, commit }, { advertiserCode }) {
      return new Promise((resolve, reject) => {
        if (advertiserCode === 'ALL') {
          commit('setCommonAdvertiserMerchants', null)
          resolve()
          return
        }
        AdvertiserApi.getMerchantLinkList(advertiserCode, body => {
          commit('setCommonAdvertiserMerchants', body.listOfMerchantLink)
          resolve()
        }, header => reject(header.resultMessage))
      })
    },
    fetchCampaignCategories ({ state, commit }, force = false) {
      return new Promise((resolve, reject) => {
        if (!force && state.campaignCategories !== null) {
          resolve()
          return
        }
        CampaignApi.getCampaignCategories(body => {
          commit('setCommonCampaignCategories', body.categories)
          resolve()
        }, header => reject(header.resultMessage))
      })
    }
  },
  mutations: {
    setCommonLoadingOn (state) {
      state.loading = true
    },
    setCommonLoadingOff (state) {
      state.loading = false
    },
    setCommonAdvertiserMerchants (state, merchants) {
      state.advertiserMerchants = merchants
    },
    setCommonCampaignCategories (state, categories) {
      state.campaignCategories = categories
    }
  }
}
