<template lang="pug">
.adv_center(:class='{ly_pop_tbl: reportStore.type === GRID_TYPE.CAMPAIGN_MANAGEMENT}')
  grid(
    ref='fullScreenGrid'
    :type='reportStore.type'
    :params='reportStore.designatedCampaignStatus.params'
    :frame='reportStore.designatedCampaignStatus.frame'
    :totalCount='reportStore.designatedCampaignStatus.totalCount'
    :data='reportStore.designatedCampaignStatus.data'
    :summary='reportStore.designatedCampaignStatus.summary'
    :isAdmin='reportStore.isAdmin'
    :isFullScroll='true'
    :headType='reportStore.headType'
    :head='reportStore.head'
    :isSpecificAdvertiser='reportStore.isSpecificAdvertiser'
    :isFullScreenMode='true'
    @moveTo='moveTo'
    @sort='sort'
    @download='downloadCampaignReport'
    @closeFullScreen='close'
    @openLinkedRevenueDetail='openLinkedRevenueDetail'
  )
  linked-revenue-detail(
    v-if='linkedRevenueDetail.isOpened'
    :campaign='linkedRevenueDetail.campaign'
    :isDownloading='linkedRevenueDetail.isDownloading'
    :isAllPublisher='isAllPublisher'
    :isAllByCouponId='isAllByCouponId'
    @downloadExcel='downloadLinkedRevenueDetail'
    @onCloseLinkedRevenueDetail='linkedRevenueDetail.isOpened = false'
  )
</template>

<script>
import grid from '../report/Grid'
import reportApi from '@/api/report'
import campaignApi from '@/api/campaign'
import { mapGetters, mapMutations } from 'vuex'
import LinkedRevenueDetail from '../report/fragments/LinkedRevenueDetail'
import store from '@/store'

const DOMAIN = `${location.protocol}//${location.hostname}${process.env.BACKEND_PORT !== null ? `:${process.env.BACKEND_PORT}` : ''}${process.env.BACKEND_URI}`

export default {
  props: ['type', 'isAdmin', 'headType', 'head', 'isSpecificAdvertiser', 'designatedCampaignStatus', 'publisherCodes', 'isAllByCouponId', 'params'],
  components: {
    grid,
    LinkedRevenueDetail
  },
  created () {
    if (this.type) {
      this.setFullScreenType(this.type)
      const params = {
        type: this.type,
        isAdmin: this.isAdmin,
        headType: this.headType,
        head: this.head,
        isSpecificAdvertiser: this.isSpecificAdvertiser,
        designatedCampaignStatus: this.designatedCampaignStatus
      }
      this.reportStore = params
      localStorage.setItem('fullScreenReport', JSON.stringify(params))
    } else {
      this.reportStore = JSON.parse(localStorage.getItem('fullScreenReport'))
    }
    window.addEventListener('beforeunload', this.leave)
  },
  mounted () {
    this.switchedOn = false
    // 새로고침하는 경우에 헤더/푸터가 남아있는 이슈 해결을 위해 nextTick 사용
    this.$nextTick(() => this.switchOnHeaderAndFooter())
    this.$refs.fullScreenGrid.pagination.page = this.reportStore.type === this.GRID_TYPE.CAMPAIGN_MANAGEMENT ? this.reportStore.designatedCampaignStatus.params.page.now : this.reportStore.designatedCampaignStatus.params.page
  },
  beforeDestroy () {
    this.switchedOn = true
    this.switchOnHeaderAndFooter()
  },
  data: function () {
    return {
      GRID_TYPE: {
        CAMPAIGN_STATUS: {
          ALL_ADVERTISER: 'campaignStatusAllAdvertiser',
          ALL_ADVERTISER_ADMIN: 'campaignStatusAllAdvertiserAdmin',
          SPECIFIC_ADVERTISER: 'campaignStatusSpecificAdvertiser',
          SPECIFIC_ADVERTISER_ADMIN: 'campaignStatusSpecificAdvertiserAdmin',
          PUBLISHER: 'campaignStatusForPublisher'
        },
        CAMPAIGN_MANAGEMENT: 'campaignManagement'
      },
      reportStore: {},
      switchedOn: null,
      linkedRevenueDetail: { campaign: {}, isOpened: false, isDownloading: false }
    }
  },
  methods: {
    ...mapMutations(['setReportFullScreen', 'setFullScreenType']),
    switchOnHeaderAndFooter () {
      const header = document.getElementById('header')
      const footer = document.getElementById('footer')
      const wrap = document.getElementById('wrap')
      header.style.display = this.switchedOn ? 'block' : 'none'
      footer.style.display = this.switchedOn ? 'block' : 'none'
      wrap.style.paddingTop = this.switchedOn ? '' : 0
      if (this.type !== this.GRID_TYPE.CAMPAIGN_MANAGEMENT) {
        wrap.style.marginTop = this.switchedOn ? 0 : '-40px'
      }
    },
    close () {
      this.$router.go(-1)
    },
    moveTo (param) {
      if (this.type === this.GRID_TYPE.CAMPAIGN_MANAGEMENT) {
        this.reportStore.designatedCampaignStatus.params.page.now = param.page
        campaignApi.getCampaignList(this.reportStore.designatedCampaignStatus.params, body => { this.reportStore.designatedCampaignStatus.data = body.campaignList.campaignList })
        return
      }
      const target = this.designatedCampaignStatus
      const api = reportApi.getCampaignReport
      target.params.page = param.page
      api(body => { target.data = this.manufacture(body.data, param.type) }, target.params)
    },
    sort (param) {
      if (this.type === this.GRID_TYPE.CAMPAIGN_MANAGEMENT) {
        this.reportStore.designatedCampaignStatus.params.sort.column = param.orderBy
        this.reportStore.designatedCampaignStatus.params.sort.direction = this.reportStore.designatedCampaignStatus.params.sort.direction === 'ASC' ? 'DESC' : 'ASC'
        campaignApi.getCampaignList(this.reportStore.designatedCampaignStatus.params, body => { this.reportStore.designatedCampaignStatus.data = body.campaignList.campaignList })
        return
      }
      const target = this.designatedCampaignStatus
      const api = reportApi.getCampaignReport
      target.params.orderBy = param.orderBy
      target.params.sortType = target.params.sortType === 'ASC' ? 'DESC' : 'ASC'
      api(body => { target.data = this.manufacture(body.data, param.type) }, target.params)
    },
    manufacture (data, type) {
      const needManuFaction = this.isSpecificAdvertiser &&
        (type === this.GRID_TYPE.CAMPAIGN_STATUS.SPECIFIC_ADVERTISER || type === this.GRID_TYPE.CAMPAIGN_STATUS.SPECIFIC_ADVERTISER_ADMIN)
      if (!needManuFaction) return data
      return this.manufactureCampaignStatus(data)
    },
    fetchCampaignStatus () {
      reportApi.getCampaignTotalCount(body => { this.designatedCampaignStatus.totalCount = body.totalCount }, this.designatedCampaignStatus.params)
      reportApi.getCampaignReport(body => { this.designatedCampaignStatus.data = this.isSpecificAdvertiser ? this.manufactureCampaignStatus(body.data) : body.data }, this.designatedCampaignStatus.params)
      if (!this.isSpecificAdvertiser) reportApi.getCampaignReportSummary(body => { this.designatedCampaignStatus.summary = body.summary }, this.designatedCampaignStatus.params)
    },
    downloadExcelForCampaignManagement () {
      const params = this.clone(this.reportStore.designatedCampaignStatus.params)
      params.page.now = params.page.size = 0
      campaignApi.downloadCampaignListExcel(
        params,
        blob => {
          if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, 'campaign-list.xlsx')
          } else {
            const a = document.createElement('a')
            a.href = URL.createObjectURL(blob)
            a.setAttribute('download', 'campaign-list.xlsx')
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          }
        })
    },
    downloadCampaignReport (type) {
      if (this.type === this.GRID_TYPE.CAMPAIGN_MANAGEMENT) {
        this.downloadExcelForCampaignManagement()
        return
      }
      const params = this.clone(this.designatedCampaignStatus.params)
      params.excelMode = true
      this.downloadFromUri(`report/campaign/download/${this.getCampaignStatusPathVariableType(type)}`, 'POST', params, 'campaign-status.xlsx')
    },
    clone (obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    getCampaignStatusPathVariableType (type) {
      switch (type) {
        case this.GRID_TYPE.CAMPAIGN_STATUS.ALL_ADVERTISER: return 'allAdvertiser'
        case this.GRID_TYPE.CAMPAIGN_STATUS.ALL_ADVERTISER_ADMIN: return 'allAdvertiserAdmin'
        case this.GRID_TYPE.CAMPAIGN_STATUS.SPECIFIC_ADVERTISER: return 'specificAdvertiser'
        case this.GRID_TYPE.CAMPAIGN_STATUS.SPECIFIC_ADVERTISER_ADMIN: return 'specificAdvertiserAdmin'
        case this.GRID_TYPE.CAMPAIGN_STATUS.PUBLISHER: return 'publisher'
      }
    },
    downloadFromUri (uri, method, params, name) {
      reportApi.downloadExcel(
        `${DOMAIN}${uri}`,
        method,
        params,
        blob => {
          if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, name)
          } else {
            const a = document.createElement('a')
            a.href = URL.createObjectURL(blob)
            a.setAttribute('download', name)
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          }
        })
    },
    manufactureCampaignStatus (data) {
      const newData = data.flatMap(d => {
        const rows = [
          {
            advertiserCode: d.advertiserCode,
            campaignId: d.campaignId,
            campaignName: d.campaignName,
            startDate: d.startDate,
            endDate: d.endDate,
            status: d.status,
            bannerImpression: d.bannerImpression,
            bannerImpressionUnique: d.bannerImpressionUnique,
            bannerClick: d.bannerClick,
            bannerClickUnique: d.bannerClickUnique,
            pushAlert: d.pushAlert,
            click: d.click,
            bannerCtr: d.bannerCtr,
            budget: d.budget,
            cost: d.cost,
            searchImpression: d.searchImpression,
            searchClick: d.searchClick,
            searchCtr: d.searchCtr,
            couponName: '',
            couponId: '',
            couponStatus: '',
            couponDownloadCount: d.coupons.map(c => c.couponDownloadCount).reduce((a, b) => a + b, 0),
            couponUsingCount: d.coupons.map(c => c.couponUsingCount).reduce((a, b) => a + b, 0),
            couponDownloadRatePerClick: d.click !== 0 ? d.coupons.map(c => c.couponDownloadCount).reduce((a, b) => a + b, 0) / d.click * 100 : 0.0,
            couponUsingRatePerClick: d.click !== 0 ? d.coupons.map(c => c.couponUsingCount).reduce((a, b) => a + b, 0) / d.click * 100 : 0.0,
            couponUsingCost: d.coupons.map(c => c.couponUsingCost).reduce((a, b) => a + b, 0),
            linkedRevenue: d.coupons.map(c => c.linkedRevenue).reduce((a, b) => a + b, 0),
            roas: d.cost !== 0 ? d.coupons.map(c => c.linkedRevenue).reduce((a, b) => a + b, 0) / d.cost * 100 : 0.0,
            rewardPointCount: d.rewardPointCount,
            rewardPointCountUnique: d.rewardPointCountUnique,
            rewardPoint: d.rewardPoint,
            paybackFee: d.paybackFee
          }
        ]
        rows.push(d.coupons.map(c => {
          return {
            advertiserCode: '',
            campaignName: '',
            startDate: '',
            endDate: '',
            status: '',
            bannerImpression: '',
            bannerImpressionUnique: '',
            bannerClick: '',
            bannerClickUnique: '',
            pushAlert: '',
            click: '',
            bannerCtr: '',
            budget: '',
            cost: '',
            searchImpression: '',
            searchClick: '',
            searchCtr: '',
            couponName: c.couponName,
            couponId: c.couponId,
            couponStatus: c.couponStatus,
            couponDownloadCount: c.couponDownloadCount,
            couponUsingCount: c.couponUsingCount,
            couponUsingCost: c.couponUsingCost,
            couponDownloadRatePerClick: c.couponDownloadRatePerClick,
            couponUsingRatePerClick: c.couponUsingRatePerClick,
            linkedRevenue: c.linkedRevenue,
            roas: c.roas,
            rewardPointCount: '',
            rewardPointCountUnique: '',
            paybackFee: ''
          }
        }))
        return rows.flatMap(r => r)
      })
      return newData
    },
    openLinkedRevenueDetail (campaign) {
      if (campaign.coupons.length === 0) {
        return this.showError('캠페인에 쿠폰이 없습니다.')
      }
      this.linkedRevenueDetail = {
        campaign: {
          id: campaign.id,
          name: campaign.name,
          startDate: this.designatedCampaignStatus.params.startDate,
          endDate: this.designatedCampaignStatus.params.endDate,
          coupons: campaign.coupons,
          publisherCodes: this.publisherCodes,
          adType: this.designatedCampaignStatus.params.adType,
          bannerTypes: this.designatedCampaignStatus.params.bannerTypes
        },
        isOpened: true,
        isDownloading: false
      }
    },
    downloadLinkedRevenueDetail () {
      if (this.linkedRevenueDetail.campaign.coupons.length === 0) {
        return this.showError('캠페인에 쿠폰이 없습니다.')
      }
      this.linkedRevenueDetail.isDownloading = true
      this.downloadFromUri(
        'report/linked-revenue-detail/download',
        'POST',
        {
          campaignIds: [this.linkedRevenueDetail.campaign.id],
          startDate: this.linkedRevenueDetail.campaign.startDate,
          endDate: this.linkedRevenueDetail.campaign.endDate,
          publisherCodes: this.linkedRevenueDetail.campaign.publisherCodes,
          coupons: this.isAllByCouponId ? this.linkedRevenueDetail.campaign.coupons.map(c => { return { couponId: c.couponId } }) : [],
          excelMode: true,
          isSpecificAdvertiser: this.isSpecificAdvertiser,
          isAllByCouponId: this.isAllByCouponId,
          adType: this.linkedRevenueDetail.campaign.adType,
          bannerTypes: this.linkedRevenueDetail.campaign.bannerTypes,
          page: 1
        },
        'linked-revenue-detail.xlsx',
        () => { this.linkedRevenueDetail.isDownloading = false }
      )
    },
    showError (message) {
      store.commit('setError', {
        id: Date.now(),
        message: message,
        hadshown: false
      })
    },
    leave () {
      localStorage.setItem('fullScreenReport', null)
    }
  },
  computed: {
    ...mapGetters(['reportFullScreen', 'reportTarget']),
    isAllPublisher () {
      return this.publisherCodes.includes('PUB_ALL')
    }
  },
  watch: {
    type () {
      this.setFullScreenType(this.type)
    }
  }
}
</script>
