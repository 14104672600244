<template lang="pug">
.ly_pop.ly_pop_wrap.ly_pop1
  .ly_pop_layer
    .ly_pop_cont
      h1.pop_tit 초대메일 발송하기
      p.pop_des CLO센터 초대 메일을 발송하세요.
      .member_info
        .inp_area
          label.txt_label(for='inp_email') 이메일
          .inp_bx(style='width:260px;border:0;padding:0')
            // [D] input에 입력된 내용 있으면 .txt_lbl 숨김 처리
            span.txt_lbl(v-if="invite.invitationEmail === ''") 예: payco@nhn.com
            input#inp_email(type='text', name="inp_email" v-model.trim="invite.invitationEmail")
      .ly_btn
        button.btn.btn_gray3(type='button', @click="closeModal") 취소
        button.btn.btn_bl(type='button', @click="inviteManager") 확인
    button.btn_close(type='button', @click="closeModal")
      span.blind 닫기
    confirm(v-if="showConfirm", @yes="accept", :confirm="confirm")
</template>

<script>
import advertiserApi from '@/api/advertiser.js'
import Confirm from '../../campaign/fragments/Confirm'
import store from '@/store'

export default {
  components: { Confirm },
  props: ['advertiserCode'],
  data: function () {
    return {
      invite: {
        advertiserCode: this.advertiserCode,
        invitationEmail: ''
      },
      isSuccessful: true,
      showConfirm: false,
      confirm: {
        subTitle: '',
        text: '초대 메일이 발송되었습니다',
        no: '',
        yes: '확인'
      }
    }
  },
  methods: {
    closeModal () {
      this.$emit('showModal', false)
    },
    accept () {
      this.showConfirm = false
      this.closeModal()
    },
    inviteManager () {
      advertiserApi.inviteManager(
        this.invite,
        function (body) {
          if (body.result.isSuccessful) {
            this.confirm.text = '초대 메일이 발송되었습니다'
            this.showConfirm = true
          } else {
            this.showError(body.result.message)
          }
        }.bind(this))
    },
    showError (message) {
      store.commit('setError', {
        id: Date.now(),
        message: message,
        hadshown: false
      })
    }
  }
}
</script>

<style scoped></style>
