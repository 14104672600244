<template lang="pug">
#content.adv_center
  .tit_area.desc
    h1.tit CLO가맹점 {{ isCreateMode()? '등록' : '수정' }}
  .form_wrap
    .form.bdt3
      table
        caption
          span CLO가맹점 등록
        colgroup
          col(style='width: 173px')
        tbody
          tr
            th.align_top(scope='row')
              label.lbl(for='input1') 가맹점
                em.required
                  span.blind 필수
            td
              // [D] 오류 메시지 inp_area 에 has_error 클래스 추가. txt_explain 태그 show
              .inp_area(:class='{"has_error" : !isValidCloMerchantName}')
                .inp_bx(style='width: 300px')
                  span.txt_lbl(v-if='cloMerchant.cloMerchantName === ""') 가맹점명을 입력하세요.
                  input(type='text', :value='cloMerchant.cloMerchantName', @input='inputCloMerchantName')
                span.txt_count {{`${cloMerchantNameLength}/${MAX_LENGTH_CLO_MERCHANT_NAME}`}}
                span.txt_explain(v-if='cloMerchant.cloMerchantName === ""') 가맹점명은 필수 입력값입니다.
          tr
            th.align_top(scope='row')
              label.lbl 사업자등록번호
                em.required
                  span.blind 필수
            td
              .inp_area(:class='{"has_error" : !isValidBusinessRegistrationNumber}')
                .inp_bx(style='width:80px')
                  input(type='text', maxlength='3', :value='cloMerchant.businessRegistrationNumber[0]', @input='inputBusinessRegistrationNumber($event, 0)')
                span.separate_bar -
                .inp_bx(style='width:75px')
                  input(type='text', maxlength='2', :value='cloMerchant.businessRegistrationNumber[1]', @input='inputBusinessRegistrationNumber($event, 1)')
                span.separate_bar -
                .inp_bx(style='width:100px')
                  input(type='text', maxlength='5', :value='cloMerchant.businessRegistrationNumber[2]', @input='inputBusinessRegistrationNumber($event, 2)')
                span.txt_explain(v-if="!isValidBusinessRegistrationNumber") 사업자등록번호는 필수 입력값입니다.
          tr
            th.align_top(scope='row')
              label.lbl(for='input2') 가맹점 URL
                em.required
                  span.blind 필수
            td
              // [D] 오류 메시지 inp_area 에 has_error 클래스 추가. txt_explain 태그 show
              .inp_area(:class='{"has_error" : !isValidCloMerchantUrl}')
                .inp_bx(style='width: 600px')
                  span.txt_lbl(v-if='cloMerchant.cloMerchantUrl === ""') https://m.payco.com
                  input(type='text', :value='cloMerchant.cloMerchantUrl', @input='cloMerchant.cloMerchantUrl = $event.target.value')
                span.txt_explain(v-if='cloMerchant.cloMerchantUrl === ""') 가맹점 URL은 필수값입니다.
                span.txt_explain(v-if='cloMerchant.cloMerchantUrl !== "" && !isValidCloMerchantUrlFormat') URL형식이 잘못되었습니다.
          tr
            th.align_top(scope='row')
              label.lbl(for='input3') 가맹점 로고
                em.required
                  span.blind 필수
            td
              .inp_area(:class='{"has_error" : !isLogoRegistered}')
                .addfile_wrap
                  span.inp_file
                    span.txt_file 파일업로드
                    input(type='button', @click='selectLogoFiles')
                span.txt_explain.txt_guide.beside
                  | 용량 3MB이하, JPG, PNG, AI, PSD 형식의 파일만 허용되며, 최대 {{MAX_LOGO_FILE_LENGTH}}개까지 업로드 가능합니다.
                  br
                  | 업로드한 파일의 파일명이 결제사에게 노출됩니다.
                span.txt_explain(v-show='!isLogoRegistered') 가맹점 로고는 필수 입력값입니다.
              .info_log_area.clo_branch_area(v-show='isLogoRegistered')
                ul.lst_log.scrollbar-inner
                  li.item(v-for='(item, index) in cloMerchant.logoList')
                    a.name_file(:href='item.url') {{ item.fileName }}
                    button.btn_del_file(type='button', @click='removeLogoList(index)')
                      span.blind 첨부파일 삭제
          tr
            th.align_top(scope='row')
              label.lbl(for='input3') 카테고리
                em.required
                  span.blind 필수
            td
              .inp_area(:class='{"has_error" : !isValidCategory}')
                // [D] 한 라인에 아이템 6개 제한 시 ".range_medium" 클라스 추가
                ul.lst_chk.range_medium
                  template(v-for='(item, index) in metaCategoryList')
                    li.chk
                      span.inp_checkbox2
                        input.inp_chk(type='checkbox',
                          :id='`category-check-${index}`'
                          :value='item.seq'
                          :disabled='cloMerchant.categorySeqList.length >= 2 && cloMerchant.categorySeqList.indexOf(item.seq) == -1'
                          v-model='cloMerchant.categorySeqList')
                        label.check_control_label(:for='`category-check-${index}`')
                          span.ico_chk
                          | {{ item.name }}
                span.txt_explain.txt_guide 카테고리는 최대 2개까지 설정 할 수 있습니다.
                span.txt_explain(v-if='cloMerchant.categorySeqList.length == 0') 카테고리는 필수 입력값입니다.
            tr
              th.align_top(scope='row')
                label.lbl(for='input3') 주요고객
              td
                .inp_area
                  .addfile_wrap
                    span.inp_file
                      span.txt_file(
                        @click='selectCsvFile',
                        style='cursor: pointer'
                      ) 파일업로드
                    .bx_add_file
                      ul.lst_einfo.sort_ib.align_center
                        li
                          p.file_name
                            a.txt_link.ul.txt_gray(href='/static/csv/clo_customer_sample.csv') 파일양식다운로드
                        li(v-if='isCsvRegistered')
                          p.file_name
                            a.txt_link(style='text-overflow: ellipsis; overflow: hidden; max-width: 300px') {{ cloMerchant.customerFileName }}
                          button.btn_del_file(type='button', @click='removeCsvFile')
                            span.blind 첨부파일 삭제
                  .span.txt_explain.txt_guide 차트를 구성할 성별/연령별 분포 CSV UTF-8 파일을 업로드해주세요.
            tr
              th.align_top(scope='row')
                label.lbl(for='input3') 유의사항
              td
                .inp_area
                  .lst_new_camp
                    .item(v-for='(item, index) in inputCautionList')
                      .inp_bx(style='width: 600px')
                        span.txt_lbl(v-if='inputCautionList[index].trim() === ""') 직접 입력
                        input.custom_caution_input(type='text', :value='item', @input="inputCaution(index, $event.target.value)")
                      template(v-if='index == inputCautionList.length - 1 && inputCautionList.length < MAX_COUNT_CAUTION_LIST')
                        button.btn_new_dir(type='button', @click='addCautionList(index)')
                          span.blind 새 텍스트 영역
                      template(v-else)
                        button.btn_close_dir(type='button', @click='removeInputCation(index)')
                          span.blind 텍스트 영역 제거
            tr
              th.align_top_v7(scope='row')
                label.lbl PG사
                  em.required
                    span.blind 필수
              td
                .inp_area(:class='{"has_error" : !isValidPgCompany}')
                  ul.lst_chk.range_medium2
                    template(v-for='(item, index) in metaPgCompanyList')
                      li.chk
                        span.inp_checkbox2
                          input.inp_chk(type='checkbox',
                            :id='`pg-check-${index}`'
                            :value='item.code'
                            :disabled='cloMerchant.usedPgCompanyCodeList.length > 0 && cloMerchant.usedPgCompanyCodeList.indexOf(item.code) != -1'
                            v-model='cloMerchant.pgCompanyCodeList')
                          label.check_control_label(:for='`pg-check-${index}`')
                            span.ico_chk
                            | {{ item.name }}
                  span.txt_explain(v-if='!isValidPgCompany') PG사는 필수 입력값입니다.
            tr
              th.align_top(scope='row')
                label.lbl(for='input3') 광고주 연결
              td
                .inp_area
                  ul.lst_select
                    li.item(v-for='item in linkedAdvList')
                      p {{ item.advertiserName }}
                    li.item(v-if='isSearchMerchantType && linkedAdvList.length == 0')
                      p -
                  template(v-if='!isSearchMerchantType')
                    advertiser-select-box(
                      ref='advertiserSelectBox',
                      @selectAdvertiser='setSelectedAdvertiser',
                      @initSelectedAdvList='initSelectedAdvList')
                    button.btn_add2.btn_bl_gray3(type='button', @click='addAdvertiserList') 추가
                ul.lst_select
                  li.item(v-for='item in selectedAdvList')
                    button.btn_remove(type='button', @click='removeAdvertiser(item.advId)')
                      span.sp Remove
                    p {{ item.advName }}
    .btn_act_area.form_v2
      button.btn.btn_gray3(type='button', @click='goCloMerchantList()') 취소
      button.btn.btn_bl(type='button',
        :class='{disable: !isAllValid || isProcessing}', :disabled="!isAllValid || isProcessing"
        @click='preCreateModifyCloMerchant') {{ isCreateMode()? '등록' : '저장' }}
      single-text-notice-popup(
        v-show="errorList.error01.view"
        :text="errorList.error01.text"
        @yes="errorList.error01.view = false")
</template>

<script>
import store from '@/store'
import $ from '@/util'
import _ from '@/partial'
import cloMerchantApi from '@/api/clo/clo-merchant.js'
import advertiserApi from '@/api/advertiser.js'
import AdvertiserSelectBox from './fragments/AdvertiserSelectBox'
import SingleTextNoticePopup from '@/components/clo/common/SingleTextNoticePopup'
import { CLO_MERCHANT_TYPE } from '@/types/clo-merchant-types.js'
import FileManager from '../../common/file-manager'
import MultiFileUploader from '@/components/common/MultiFileUploader'

export default {
  components: {
    AdvertiserSelectBox,
    SingleTextNoticePopup,
    MultiFileUploader
  },
  name: 'CloMerchantRegisterModify',
  data: function () {
    return {
      MAX_COUNT_CAUTION_LIST: 10,
      MAX_LENGTH_CLO_MERCHANT_NAME: 100,
      MAX_LOGO_FILE_LENGTH: 10,
      CLO_MERCHANT_TYPE: CLO_MERCHANT_TYPE,
      metaCategoryList: [],
      metaPgCompanyList: [],
      cloMerchant: {
        cloMerchantType: '',
        cloMerchantCode: '',
        cloMerchantName: '',
        customerFileName: '',
        customerFileNameOrigin: '',
        cloMerchantUrl: '',
        caution: [''],
        categorySeqList: [],
        pgCompanyCodeList: [],
        usedPgCompanyCodeList: [],
        logoList: [],
        businessRegistrationNumber: ['', '', '']
      },
      csvFile: null,
      logoImg: { file: null, url: '' },
      linkedAdvList: [],
      selectedAdvList: [],
      selectedAdvId: '',
      selectedAdvName: '',
      inputCautionList: [''],
      errorList: {
        error01: {
          view: false,
          text: '이미 추가한 광고주입니다.'
        }
      },
      param: {
        cloMerchantType: '',
        cloMerchantCode: '',
        cloMerchantSeq: ''
      },
      isProcessing: false
    }
  },
  created () {
    if (this.isCreateMode()) this.initCreateMode()
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.fetchCategories()
      this.fetchPgCompanies()
      new Promise(resolve => {
        if (this.isCreateMode()) {
          this.fetchSearchMerchantInfo()
          resolve(this.param.cloMerchantCode)
        } else {
          this.fetchCloMerchant(resolve)
        }
      }).then((cloMerchantCode) => {
        this.isSearchMerchantType && this.fetchLinkedAdvertiserList(cloMerchantCode)
      })
    },
    fetchCloMerchant (resolve) {
      cloMerchantApi.getCloMerchant(this.$route.params.cloMerchantSeq, body => {
        this.cloMerchant.cloMerchantSeq = body.result.cloMerchantSeq
        this.cloMerchant.cloMerchantType = body.result.cloMerchantType
        this.cloMerchant.cloMerchantCode = body.result.cloMerchantCode
        this.cloMerchant.cloMerchantName = body.result.cloMerchantName
        this.cloMerchant.cloMerchantUrl = body.result.cloMerchantUrl
        this.cloMerchant.customerFileName = this.cloMerchant.customerFileNameOrigin = body.result.customerFileName
        this.cloMerchant.categorySeqList = body.result.cloMerchantCategories.map(cs => cs.seq)
        this.cloMerchant.pgCompanyCodeList = _.union(body.result.pgCompanyList.map(i => i.code), body.result.usedPgCompanyCodeList)
        this.cloMerchant.usedPgCompanyCodeList = body.result.usedPgCompanyCodeList
        this.inputCautionList = body.result.caution
        if (this.inputCautionList.length > 1 && this.inputCautionList.length < this.MAX_COUNT_CAUTION_LIST) this.inputCautionList.push('')
        this.setAdvertiserCodeList(body.result.advertiserCodeList)
        this.setBusinessRegistrationNumber(body.result.businessRegistrationNumber)
        body.result.logoList.forEach(i => this.addLogoList(i.fileUrl, i.fileName))
        resolve(body.result.cloMerchantCode)
      })
    },
    fetchSearchMerchantInfo () {
      if (!this.isSearchMerchantType) return
      cloMerchantApi.getSearchMerchantInfo(this.param.cloMerchantCode, ({ result }) => {
        this.$set(this.cloMerchant, 'cloMerchantUrl', result.merchantUrl || '')
        if (result.logoImgFileUrl !== '') this.addLogoList(result.logoImgFileUrl, result.logoImgFileName)
        this.setBusinessRegistrationNumber(result.businessRegistrationNumber)
      })
    },
    fetchCategories () {
      cloMerchantApi.getCloMerchantCategories(body => {
        this.metaCategoryList = body.result
      })
    },
    fetchLinkedAdvertiserList (cloMerchantCode) {
      advertiserApi.getLinkedAdvertiserList(cloMerchantCode, body => {
        this.linkedAdvList = body.advertisers
      })
    },
    fetchPgCompanies () {
      cloMerchantApi.getCloPgCompanies(body => {
        this.metaPgCompanyList = body.result
      })
    },
    preCreateModifyCloMerchant () {
      const cautionList = _.go(this.$el.querySelectorAll('.custom_caution_input'),
        els => _.map(els, i => i.value),
        values => _.filter(values, i => i.trim() !== ''))
      const isDeletedCsvFile = (this.isUpdateMode() && this.cloMerchant.customerFileNameOrigin !== '' && this.cloMerchant.customerFileName === '')
      const data = []
      data.push(['cloMerchantType', this.cloMerchant.cloMerchantType])
      data.push(['cloMerchantCode', this.cloMerchant.cloMerchantCode])
      data.push(['cloMerchantName', this.cloMerchant.cloMerchantName])
      data.push(['cloMerchantUrl', this.cloMerchant.cloMerchantUrl])
      data.push(['caution', cautionList.join('\n')])
      data.push(['advertiserCodeList', _.map(this.selectedAdvList, i => i.advId)])
      data.push(['categorySeqList', this.cloMerchant.categorySeqList])
      data.push(['pgCompanyCodeList', this.cloMerchant.pgCompanyCodeList])
      data.push(['businessRegistrationNumber', this.cloMerchant.businessRegistrationNumber.join('-')])
      data.push(['csvFile', this.csvFile && this.csvFile.data])
      data.push(['isDeletedCsvFile', isDeletedCsvFile])
      _.map(this.cloMerchant.logoList, i => data.push(['logoList', `${i.url}|${i.fileName}`]))
      this.isCreateMode() ? this.createCloMerchant(data) : this.modifyCloMerchant(data)
    },
    createCloMerchant (data) {
      if (this.isProcessing) {
        return
      }
      this.isProcessing = true
      cloMerchantApi.registerCloMerchant(data, body => {
        this.isProcessing = false
        if (body.code === 500) {
          this.showError(body.msg)
          return
        }
        this.showError('CLO가맹점이 등록되었습니다.')
        this.goCloMerchantList()
      }, body => {
        this.isProcessing = false
        this.showError(body.resultMessage)
      })
    },
    modifyCloMerchant (data) {
      if (this.isProcessing) {
        return
      }
      this.isProcessing = true
      cloMerchantApi.modifyCloMerchant(data, this.cloMerchant.cloMerchantSeq, body => {
        this.isProcessing = false
        if (body.code === 500) {
          this.showError(body.msg)
          return
        }
        this.showError('CLO가맹점이 수정되었습니다.')
        this.goCloMerchantList()
      }, body => {
        this.isProcessing = false
        this.showError(body.resultMessage)
      })
    },
    selectLogoFiles () {
      if (this.cloMerchant.logoList.length >= this.MAX_LOGO_FILE_LENGTH) {
        this.showError('파일 삭제 후, 다시 업로드해주세요.')
        return
      }
      FileManager.openMultipleFiles('.png,.jpg,.psd,.ai').then(files => {
        files = files.slice(0, this.MAX_LOGO_FILE_LENGTH - this.cloMerchant.logoList.length)
        cloMerchantApi.uploadLogoFile(files.map(i => ['files', i.data]), ({ result }) => {
          result.forEach(i => this.addLogoList(i.url, i.fileName))
        }, body => {
          this.showError(body.resultMessage)
        })
      })
    },
    selectCsvFile () {
      if (this.isCsvRegistered) {
        this.showError('파일 삭제 후, 다시 업로드해주세요.')
        return
      }
      FileManager.openFile().then(file => {
        this.csvFile = file
        this.cloMerchant.customerFileName = file.name
      })
    },
    addLogoList (url, fileName) {
      this.cloMerchant.logoList.push({ url: url, fileName: fileName })
    },
    setAdvertiserCodeList (advertiserCodeList) {
      this.$refs.advertiserSelectBox.getAdvertiserListByIdList(advertiserCodeList, 'initSelectedAdvList')
    },
    setSelectedAdvertiser (obj) {
      this.selectedAdvId = obj.advId
      this.selectedAdvName = obj.advName
    },
    setBusinessRegistrationNumber (str) {
      if (!str) return
      const arr = str.split('-')
      this.$set(this.cloMerchant.businessRegistrationNumber, 0, arr[0])
      this.$set(this.cloMerchant.businessRegistrationNumber, 1, arr[1])
      this.$set(this.cloMerchant.businessRegistrationNumber, 2, arr[2])
    },
    initSelectedAdvList (advList) {
      _.each(advList, adv => this.selectedAdvList.push({
        advId: adv.advId,
        advName: adv.advName
      }))
    },
    initCreateMode () {
      this.cloMerchant.cloMerchantType = this.param.cloMerchantType = this.$route.query.cloMerchantType || ''
      this.cloMerchant.cloMerchantCode = this.param.cloMerchantCode = this.$route.query.cloMerchantCode || ''
      this.cloMerchant.cloMerchantName = this.param.cloMerchantName = decodeURI(this.$route.query.cloMerchantName || '')
    },
    addAdvertiserList () {
      if (this.selectedAdvId === '') return

      if (this.selectedAdvList.find(adv => adv.advId === this.selectedAdvId)) {
        this.errorList.error01.view = true
        return
      }
      this.selectedAdvList.push({
        advId: this.selectedAdvId,
        advName: this.selectedAdvName
      })
      this.$refs.advertiserSelectBox.clearSelectBox()
    },
    addCautionList (index) {
      if (this.inputCautionList[index].trim() === '') return
      if (this.inputCautionList.length >= this.MAX_COUNT_CAUTION_LIST) return
      this.inputCautionList = _.go(
        this.$el.querySelectorAll('.custom_caution_input'),
        els => _.map(els, i => i.value))
      this.inputCautionList.push('')
    },
    inputCaution (idx, inputValue) {
      this.$set(this.inputCautionList, idx, inputValue)
    },
    inputCloMerchantName ({ target }) {
      if ($.getUTF8Length(target.value) <= this.MAX_LENGTH_CLO_MERCHANT_NAME) {
        this.cloMerchant.cloMerchantName = target.value
      } else {
        target.value = this.cloMerchant.cloMerchantName
      }
    },
    inputBusinessRegistrationNumber ({ target }, index) {
      this.$set(this.cloMerchant.businessRegistrationNumber, index, target.value.replace(/\D/g, ''))
    },
    removeLogoList (index) {
      _.removeByIndex(this.cloMerchant.logoList, index)
    },
    removeCsvFile () {
      this.csvFile = null
      this.cloMerchant.customerFileName = ''
    },
    removeInputCation (index) {
      _.removeByIndex(this.inputCautionList, index)
    },
    removeAdvertiser (advId) {
      this.selectedAdvList = this.selectedAdvList.filter(adv => adv.advId !== advId)
    },
    isCreateMode () {
      return this.$route.params.mode === 'create'
    },
    isUpdateMode () {
      return !this.isCreateMode()
    },
    showError (message) {
      store.commit('setError', {
        id: Date.now(),
        message: message,
        hadshown: false
      })
    },
    goCloMerchantList () {
      this.$router.push('/clo-merchant')
    }
  },
  computed: {
    cloMerchantType () {
      return this.param.cloMerchantType || this.cloMerchant.cloMerchantType
    },
    isSearchMerchantType () {
      const type = this.isCreateMode() ? this.param.cloMerchantType : this.cloMerchant.cloMerchantType
      return this.CLO_MERCHANT_TYPE.PAYCO === type
    },
    isLogoRegistered () {
      return this.cloMerchant.logoList.length > 0
    },
    isCsvRegistered () {
      return this.csvFile != null || this.cloMerchant.customerFileName !== ''
    },
    cloMerchantNameLength () {
      return $.getUTF8Length(this.cloMerchant.cloMerchantName)
    },
    isValidCloMerchantName () {
      return this.cloMerchant.cloMerchantName.trim() !== '' && this.cloMerchantNameLength <= this.MAX_LENGTH_CLO_MERCHANT_NAME
    },
    isValidCloMerchantUrl () {
      return this.cloMerchant.cloMerchantUrl.trim() !== '' && this.isValidCloMerchantUrlFormat
    },
    isValidCloMerchantUrlFormat () {
      // eslint-disable-next-line no-useless-escape
      const regex = /^(https|http):\/\/[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?(\.[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])*(\.[a-zA-Z]{2,}){1,2}([:\/?][^ㄱ-ㅎㅏ-ㅣ가-힣\s]*)?$|^(market):\/\//
      return regex.test(this.cloMerchant.cloMerchantUrl)
    },
    isValidCategory () {
      return this.cloMerchant.categorySeqList.length > 0 && this.cloMerchant.categorySeqList.length <= 2
    },
    isValidPgCompany () {
      return this.cloMerchant.pgCompanyCodeList.length > 0
    },
    isValidBusinessRegistrationNumber () {
      return this.cloMerchant.businessRegistrationNumber.every(
        (currentValue) => currentValue && currentValue !== ''
      )
    },
    isAllValid () {
      return this.isValidCloMerchantName && this.isValidCloMerchantUrl &&
        this.isValidCategory && this.isLogoRegistered && this.isValidPgCompany && this.isValidBusinessRegistrationNumber
    }
  }
}
</script>

<style scoped></style>
