import cloPaymentApi from '@/api/clo/clo-payment'
import _ from '@/partial'

export default {
  state: {
    cloPaymentList: [],
    isCloPaymentRegistered: false
  },
  getters: {
    cloPaymentList: state => state.cloPaymentList,
    cloPaymentPartnerList: state => _.filter(state.cloPaymentList, it => it.cloPaymentType === 'PAYMENT_PARTNER'),
    isCloPaymentRegistered: state => state.isCloPaymentRegistered
  },
  actions: {
    fetchCloPaymentList ({ commit }) {
      return new Promise(resolve => {
        cloPaymentApi.getSimpleCloPaymentList((body) => {
          commit('setCloPaymentList', body.cloPaymentList)
          commit('setIsCloPaymentRegistered')
          resolve()
        })
      })
    }
  },
  mutations: {
    setCloPaymentList (state, cloPaymentList) {
      state.cloPaymentList = cloPaymentList
    },
    setIsCloPaymentRegistered (state) {
      state.isCloPaymentRegistered = state.cloPaymentList.length > 0
    }
  }
}
