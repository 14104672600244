<template lang="pug">
#content.adv_center
  .form_wrap
    .tit_area
      h1.tit 광고주 인증키 등록
    .form.single_inp
      table
        caption
          span 광고주관리 - 광고주인증키등록
        colgroup
          col(style='width:173px')
          col
        tbody
          tr
            th(scope='row')
              label.lbl(for='input-link-key') 광고주 인증키
                em.required
                  span.blind 필수
            td
              .inp_area
                .inp_bx(style='width:460px')
                  input#input-link-key(type='text', v-model.trim="link.advertiserLinkKey")
    .btn_act_area.form_v2
      button.btn.btn_gray3(type='button', @click="goAccount") 취소
      button.btn.btn_bl(type='button', @click="authManager") 등록
      // [D] 버튼이 disable 시  "disable" 클래스 및 "disabled" 속성을 추가
    confirm(v-if="showConfirm" :confirm="confirm" @yes="acceptAction")
</template>

<script>
import advertiserApi from '@/api/advertiser'
import Confirm from '../campaign/fragments/Confirm'
import { mapActions } from 'vuex'
export default {
  components: { Confirm },
  data: function () {
    return {
      link: {
        advertiserLinkKey: ''
      },
      showConfirm: false,
      confirm: {
        subTitle: '',
        text: '',
        yes: '확인',
        no: ''
      },
      isSuccessful: false
    }
  },
  methods: {
    ...mapActions(['fetchAdvertiserList']),
    goAccount () {
      this.$router.push('/account')
    },
    authManager () {
      if (!this.validationAdvertiserLinkKey) {
        this.confirm = {
          subTitle: '',
          text: '인증 키는 필수값 입니다.\n입력해주세요.',
          yes: '확인',
          no: ''
        }
        this.showConfirm = true
        return
      }
      advertiserApi.authManager(
        this.link,
        async body => {
          this.isSuccessful = body.result.isSuccessful
          this.confirm = {
            subTitle: '',
            text: body.result.message,
            yes: '확인',
            no: ''
          }
          this.showConfirm = true
          if (this.isSuccessful) {
            await this.fetchAdvertiserList()
          }
        }
      )
    },
    acceptAction () {
      this.showConfirm = false
      if (this.isSuccessful) {
        this.goAccount()
      }
    }
  },
  computed: {
    validationAdvertiserLinkKey: function () {
      return this.link.advertiserLinkKey !== ''
    }
  }
}
</script>
