<template lang="pug">
.ly_pop.ly_pop_wrap.ly_pop4
  .ly_pop_layer
    .ly_pop_cont
      .ly_pop_main
        h1.blind 알림
        h2.pop_sub_tit.pop_txt_v2.fw_normal(v-if="text", v-html="text")
      .ly_btn
        button.btn.btn_bl_gray3.btn_pop_lg(type='button', @click="$emit('no')") 취소
        button.btn.btn_bl.btn_pop_lg(type='button', @click="$emit('yes')") 확인
</template>

<script>
export default {
  name: 'SingleTextNoticeConfirm',
  props: ['text']
}
</script>
