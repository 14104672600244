import _ from '@/partial'

function getComputedTypes (enumType, targetObject, targetProperty) {
  return _.mapObject(enumType, (val, key) => {
    return {
      value: val,
      isSelected: targetObject && targetObject[targetProperty] === val
    }
  })
}

export { getComputedTypes }
