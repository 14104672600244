import $ from '../../util'
import _ from '@/partial'

const fileManager = {
  openMultipleFiles: function (accept) {
    return new Promise(function (resolve) {
      var input = createInput()
      input.accept = accept
      input.multiple = true
      input.addEventListener('change', function () {
        var filesArray = Array.prototype.slice.call(input.files)
        var filesObjects = filesArray.map(function (file) {
          return createFile(file)
        })
        resolve(filesObjects)
      })
      input.click()
    })
  },
  openMultipleImages: function (accept = 'image/*') {
    return new Promise(function (resolve) {
      var input = createInput()
      input.accept = accept
      input.multiple = true
      input.addEventListener('change', function () {
        const filesArray = Array.prototype.slice.call(input.files)
        const images = filesArray.map(function (file) {
          return createImageSource(file)
        })

        Promise.all(images).then(files => {
          resolve(files)
        })
      })
      input.click()
    })
  },
  openFile: function (accept) {
    return new Promise(function (resolve) {
      var input = createInput()
      input.accept = accept
      input.addEventListener('change', function () {
        resolve(createFile(input.files[0]))
      })
      input.click()
    })
  },
  validMaxSize: function (files, maxSize, success) {
    _.go(files,
      _.filter(f => f.size <= maxSize),
      fs => $.match(fs)
        .case(fs => fs.length === 0, () => {
          // eslint-disable-next-line no-console
          console.log('"업로드 파일은 ' + (maxSize / 1024 / 1024) + 'MB를 초과할 수 없습니다."')
        })
        .case(fs => fs.length < files.length, fs => {
          // eslint-disable-next-line no-console
          console.log('30MB를 초과하는 파일은 제외하였습니다.')
          fs.forEach(success)
        })
        .defaultCase(fs => fs.forEach(success))
    )
  }
}

function createInput () {
  var input = document.createElement('input')

  input.style.width = '1px'
  input.style.height = '1px'
  input.style.position = 'absolute'
  input.style.left = '-1px'
  input.style.top = '-1px'
  input.style.display = 'none'
  input.type = 'file'
  input.callback = null

  document.body.appendChild(input)

  return input
}

function createFile (file) {
  return {
    name: file.name,
    size: file.size,
    mimeType: file.type,
    data: file
  }
}

function createImageSource (file) {
  return new Promise(resolve => {
    const image = new Image()
    image.addEventListener('load', function () {
      const fileObject = {
        width: this.width,
        height: this.height,
        name: file.name,
        size: file.size,
        mimeType: file.type,
        data: file
      }
      resolve(fileObject)
    })
    image.src = URL.createObjectURL(file)
  })
}

export default fileManager
