<template lang="pug">
#header.sub_on(:style='{display: gnbDisplay}')
  .hwrap
    a.logo(:href='marketingCenterUrl')
      span.bg_logo 페이코
      | 마케팅센터
    .gnb
      .loginfo
        gnb-advertisers(v-if='showGnbAdvertisers' @onAdvertiserChange='onAdvertiserChange')
        a.email(:href='account.email.link') {{ account.email.name }}
        a.txt_bar(:href='account.logout.link') {{ account.logout.name }}
    ul.nav
      template(v-for='m in gnbMenu')
        li(:class='{has_middot: m.hasMiddot, on: m.isActive}')
          a(:href='m.link') {{ m.name }}
          .sub_area(v-if='m.hasSubMenu')
            sub-gnb
</template>

<script>
import { mapGetters } from 'vuex'
import GnbAdvertisers from './GnbAdvertisers'
import SubGnb from './SubGnb'
import AccountApi from '@/api/account'

const scheme = 'https'
const MARKETING_CENTER_URL = `${scheme}://${process.env.MARKETING_CENTER_HOST}`
const MY_INFO_URL = `${MARKETING_CENTER_URL}/myInfo/index.nhn`
const LOGOUT_URL = `${scheme}://${process.env.LOGIN_HOST}/logout.nhn?serviceProviderCode=MARKETING&nextUrl=${encodeURIComponent(MARKETING_CENTER_URL)}`
const MEMBERSHIP_URL = `${MARKETING_CENTER_URL}/membership/manage/main/info.nhn`
const STATISTICS_URL = `${MARKETING_CENTER_URL}/membership/result/reg.nhn`
const MARKETING_URL = `${MARKETING_CENTER_URL}/membership/marketing/event/eventList.nhn`
const NOTICE_URL = `${MARKETING_CENTER_URL}/cs/noticeList.nhn`

export default {
  name: 'PageGnb',
  components: { GnbAdvertisers, SubGnb },
  data () {
    return {
      gnbDisplay: 'none',
      marketingCenterUrl: MARKETING_CENTER_URL,
      account: {
        email: { name: 'unkownuser@payco.com', link: MY_INFO_URL },
        logout: { name: '로그아웃', link: LOGOUT_URL }
      },
      gnbMenu: [
        { name: '멤버십', link: MEMBERSHIP_URL, hasMiddot: true, hasSubMenu: false },
        { name: '통계', link: STATISTICS_URL, hasMiddot: true, hasSubMenu: false },
        { name: '마케팅', link: MARKETING_URL, hasMiddot: true, hasSubMenu: false },
        { name: 'CLO센터', link: '/#', hasMiddot: false, hasSubMenu: true, isActive: true },
        { name: '공지사항', link: NOTICE_URL, hasMiddot: false, hasSubMenu: false }
      ]
    }
  },
  created () {
    AccountApi.getMaskedUserId(body => {
      this.account.email.name = body.maskedUserId
    })
    if (this.myRole.length > 0) this.gnbDisplay = 'block'
  },
  methods: {
    onAdvertiserChange (advertiser) {
      this.$emit('onAdvertiserChange', advertiser)
    }
  },
  computed: {
    ...mapGetters(['myRole', 'hasPublisherRoleOnly', 'hasPaymentRoleOnly']),
    showGnbAdvertisers () {
      // 결제사, 매체사 권한만 가진 운영자는 보여주지 않는다.
      return !this.hasPaymentRoleOnly && !this.hasPublisherRoleOnly
    }
  },
  watch: {
    myRole: function (n, o) {
      if (o.length === 0 && n.length > 0) {
        this.gnbDisplay = 'block'
      }
    }
  }
}

</script>
