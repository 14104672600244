<template lang="pug">
.ly_pop.ly_pop_wrap.ly_pop4
  .ly_pop_layer
    .ly_pop_cont
      .ly_pop_main
        h1.blind 알림
        h2.pop_sub_tit(v-if="confirm.subTitle", v-html="subTitle")
        p.pop_txt_v2(v-html="text")
      .ly_btn
        button.btn.btn_bl_gray3.btn_pop_lg(type='button', ref="noButton", @click="$emit('no')", v-if="confirm.no") {{confirm.no}}
        button.btn.btn_bl.btn_pop_lg(type='button', ref="yesButton", @click="$emit('yes')", v-if="confirm.yes") {{confirm.yes}}
</template>

<script>
export default {
  name: 'CommonConfirm',
  props: ['confirm'],
  computed: {
    subTitle () {
      return this.confirm.subTitle.replace(/\n/g, '<br>')
    },
    text () {
      return this.confirm.text.includes('\n')
        ? this.confirm.text.split('\n').join('<br>')
        : this.confirm.text
    }
  }
}
</script>
