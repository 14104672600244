const CLO_CAMPAIGN_STATUS_TYPE = {
  WAIT_APPROVAL: {
    code: 'WAIT_APPROVAL',
    name: '심사중',
    class: 'checking'
  },
  WAITING: {
    code: 'WAITING',
    name: '대기',
    class: 'save_storage'
  },
  ACTIVE: {
    code: 'ACTIVE',
    name: '작동',
    class: 'work'
  },
  INACTIVE: {
    code: 'INACTIVE',
    name: '종료',
    class: 'end'
  },
  DELETE: {
    code: 'DELETE',
    name: '삭제',
    class: ''
  }
}

const CLO_CAMPAIGN_PAGE_TYPE = {
  NEW: {
    code: 'new',
    name: '신규'
  },
  PROGRESSING: {
    code: 'progressing',
    name: '진행'
  }
}

export { CLO_CAMPAIGN_STATUS_TYPE, CLO_CAMPAIGN_PAGE_TYPE }
