<template lang="pug">
.ly_pop.ly_pop_wrap.ly_pop17
  .ly_pop_layer
    .ly_pop_cont
      h1.pop_tit {{cloMerchantName}} 주요고객 분포
      .column_chart_box
        .legend
          span(v-for='item in chart.legend' :class='item.css') {{ item.title }}
        .chart_area
          ul.x_data
            li(v-for='item in chart.x_axis') {{ item }}
          ul.bar_data
            li(v-for='(item, index) in chart.values.male')
              span.bar1(:style='{width: item + "%"}')
              span.bar2(:style='{width: chart.values.female[index] + "%"}')
    button.btn_close(type='button', @click='closeModal')
      span.blind 닫기
</template>

<script>
import _ from '@/partial'
import cloMerchantApi from '@/api/clo/clo-merchant.js'

export default {
  props: ['cloMerchantSeq', 'cloMerchantName'],
  data: function () {
    return {
      chart: {
        x_axis: ['10대 이하', '20대', '30대', '40대', '50대', '60대 이상'],
        legend: [
          { title: '남성', css: 'legend_blue' },
          { title: '여성', css: 'legend_pink' }
        ],
        values: {
          male: [],
          female: []
        }
      }
    }
  },
  mounted () {
    this.fetchChartValues()
  },
  methods: {
    fetchChartValues () {
      cloMerchantApi.getChartValues(this.cloMerchantSeq, body => {
        const values = _.union(body.result.male, body.result.female)
        if (_.filter(values, i => i !== 0).length === 0) return
        const max = _.max(values)
        this.chart.values.male = _.map(body.result.male, i => Math.round(i / max * 100))
        this.chart.values.female = _.map(body.result.female, i => Math.round(i / max * 100))
      })
    },
    closeModal: function () {
      this.$emit('showModal', false)
    }
  }
}
</script>

<style scoped></style>
