import req from '@/api/http-request'

const CLO_CAMPAIGN_URI = {
  GET_CLO_PROMOTION_BUDGETS: (param) => `clo/promotion-budgets?${req.getQueryString(param)}`,
  GET_CLO_PROMOTION_BUDGET: (cloPromotionBudgetSeq) => `clo/promotion-budget/${cloPromotionBudgetSeq}`,
  GET_CLO_NEW_CAMPAIGNS: (param) => `clo/campaign/newCampaign?${req.getQueryString(param)}`,
  GET_CLO_NEW_CAMPAIGNS_EXCEL: (param) => `clo/campaign/newCampaign/download?${req.getQueryString(param)}`,
  POST_CLO_BUDGET: 'clo/promotion-budget',
  PUT_CLO_BUDGET: 'clo/promotion-budget',
  GET_PROMOTION_BUDGETS_BY_MERCHANT_SEQ: (param) => `clo/simple-promotion-budgets?${req.getQueryString(param)}`,
  CREATE_CLO_CAMPAIGN: 'clo/campaign',
  UPDATE_CLO_CAMPAIGN: ({ cloCampaignId }) => `clo/campaign/${cloCampaignId}`,
  UPDATE_CLO_CAMPAIGN_STATUS: 'clo/campaign/change-campaign-status',
  UPDATE_CLO_CAMPAIGN_BENEFIT_IDS: 'clo/campaign/benefit',
  ISSUE_CLO_CAMPAIGN_REFER_KEY: 'clo/campaign/campaign-refer-key',
  GET_CLO_CAMPAIGN: (cloCampaignId) => `clo/campaign/${cloCampaignId}`,
  GET_CLO_CAMPAIGNS_FOR_REPORT: (param) => `clo/campaign?reqCloPaymentCodes=${param.cloPaymentCodes}&reqAdvertiserCodes=${param.advertiserCodes}`,
  DOWNLOAD_CLO_OFFICIAL_DOCUMENT: 'clo/campaign/download/official-document'
}

export default {
  getCloPromotionBudgets ({ merchantSeq, promotionBudgetName, advertiserCode, size, page, column, direction }, success) {
    const uri = CLO_CAMPAIGN_URI.GET_CLO_PROMOTION_BUDGETS({ merchantSeq, promotionBudgetName, advertiserCode, size, page, column, direction })
    req.get(uri, success)
  },
  getCloPromotionBudget (cloPromotionBudgetSeq, success) {
    const uri = CLO_CAMPAIGN_URI.GET_CLO_PROMOTION_BUDGET(cloPromotionBudgetSeq)
    req.get(uri, success)
  },
  postCloPromotionBudget (registration, success) {
    const uri = CLO_CAMPAIGN_URI.POST_CLO_BUDGET
    req.post(uri, registration, success)
  },
  putCloPromotionBudget (registration, success) {
    const uri = CLO_CAMPAIGN_URI.PUT_CLO_BUDGET
    req.put(uri, registration, success)
  },
  getCloNewCampaigns ({ pageType, paymentCode, advertiserCode, merchantSeq, merchantCategoryList, campaignStatusList, page, size, column, direction }, success) {
    const uri = CLO_CAMPAIGN_URI.GET_CLO_NEW_CAMPAIGNS({ pageType, paymentCode, advertiserCode, merchantSeq, merchantCategoryList, campaignStatusList, page, size, column, direction })
    req.get(uri, success)
  },
  downloadCloNewCampaignsExcel ({ pageType, paymentCode, advertiserCode, merchantSeq, merchantCategoryList, campaignStatusList, page, size, column, direction }, success) {
    const uri = CLO_CAMPAIGN_URI.GET_CLO_NEW_CAMPAIGNS_EXCEL({ pageType, paymentCode, advertiserCode, merchantSeq, merchantCategoryList, campaignStatusList, page, size, column, direction })
    req.postBlob(uri, {}, success)
  },
  getPromotionBudgetsByMerchantSeq (param, success, fail = null) {
    req.get(CLO_CAMPAIGN_URI.GET_PROMOTION_BUDGETS_BY_MERCHANT_SEQ(param), success, fail)
  },
  getCloPromotion (cloPromotionId, success) {
    const uri = CLO_CAMPAIGN_URI.GET_CLO_PROMOTION(cloPromotionId)
    req.get(uri, success)
  },
  createCloCampaign (cloCampaign, success) {
    req.post(CLO_CAMPAIGN_URI.CREATE_CLO_CAMPAIGN, cloCampaign, success)
  },
  updateCloCampaign (cloCampaign, success) {
    req.put(CLO_CAMPAIGN_URI.UPDATE_CLO_CAMPAIGN(cloCampaign), cloCampaign, success)
  },
  updateCloCampaignStatus (cloCampaignIdList, cloCampaignStatus, success) {
    const requestBody = {
      cloCampaignIdList: cloCampaignIdList,
      cloCampaignStatus: cloCampaignStatus
    }
    req.put(CLO_CAMPAIGN_URI.UPDATE_CLO_CAMPAIGN_STATUS, requestBody, success)
  },
  updateCloCampaignBenefitIds (cloCampaignId, paymentBenefitIds, success) {
    const requestBody = { cloCampaignId, paymentBenefitIds }
    req.put(CLO_CAMPAIGN_URI.UPDATE_CLO_CAMPAIGN_BENEFIT_IDS, requestBody, success)
  },
  issueCloCampaignReferKey (cloCampaignId, success) {
    req.post(CLO_CAMPAIGN_URI.ISSUE_CLO_CAMPAIGN_REFER_KEY, { cloCampaignId: cloCampaignId }, success)
  },
  getCloCampaign (cloCampaignId, success) {
    req.get(CLO_CAMPAIGN_URI.GET_CLO_CAMPAIGN(cloCampaignId), success)
  },
  getCloCampaignsForReport (param, success) {
    req.get(CLO_CAMPAIGN_URI.GET_CLO_CAMPAIGNS_FOR_REPORT(param), success)
  },
  downloadOfficialDocument (param, success, fail) {
    req.postBlob(CLO_CAMPAIGN_URI.DOWNLOAD_CLO_OFFICIAL_DOCUMENT, param, success, fail)
  }
}
