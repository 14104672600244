<template lang="pug">
.bx_item
  dl
    dt.sel_tit 캠페인명
    dd.sel_cont
      // [D] 목록 레이어 노출시 .sel에 .on 클래스 추가
      .sel(:style="isAllAdvertiser ? 'width:300px' : 'width:410px'",
        :class="{'on' : queryForm.isSelectCampaign && (hasSelectedAdvertiser || !isAllAdvertiser)}",
        @click="toggleCampaignName($event)")
        // [D] dropdown의 항목이 선택 시 a 태그에 "selected"클래스를 추가
        a.selected
          | {{queryForm.selectedCampaign}}
          span.ic_arrow_lg
        ul
          li.sch
            .bx_sch
              .inp_bx
                // [D] input에 입력된 내용 있으면 .txt_lbl 숨김 처리
                span.txt_lbl(v-if="queryForm.campaignName === ''") 캠페인 검색
                input(type='text', title='검색어를 입력', :value="queryForm.campaignName", @input="inputCampaignName($event)")
              button.btn_sch(type='button')
                span.ic_sch_sm 검색
          li
            //
              [D]
              스크롤바 라이브러리 사용
              div.dropdown_wrap scrollbar-inner 클래스 추가 시 라이브러리 동작 함.
            .dropdown_wrap.scrollbar-inner
              ul.sch_lst
                li
                  a(@click="setCampaignQuery('전체')") 전체
                template(v-for="item in campaignNameList")
                  li(v-if="item.includes(queryForm.campaignName)")
                    a(@click="setCampaignQuery(item)") {{item}}
</template>

<script>
export default {
  name: 'CampaignBoxItem',
  props: ['advertiser', 'campaignNameList', 'selectedAdvertiser'],
  data () {
    return {
      queryForm: {
        isSelectCampaign: false,
        selectedCampaign: '전체',
        campaignName: ''
      }
    }
  },
  methods: {
    inputCampaignName ({ target }) {
      this.queryForm.campaignName = target.value
    },
    close () {
      this.queryForm.isSelectCampaign = false
    },
    toggleCampaignName (e) {
      if (e.target.tagName === 'A') {
        this.queryForm.isSelectCampaign = !this.queryForm.isSelectCampaign
      }
    },
    setCampaignQuery (campaignName) {
      this.queryForm.selectedCampaign = campaignName
      this.queryForm.campaignName = ''
      this.$emit('select', campaignName)
    }
  },
  computed: {
    isAllAdvertiser () {
      return this.advertiser ? this.advertiser.advId === 'ALL' : false
    },
    hasSelectedAdvertiser () {
      return this.selectedAdvertiser !== '전체'
    }
  }
}
</script>

<style scoped></style>
