import req from './http-request'

const CLICK_REWARD_URI = {
  getReward: (campaignId) => `campaign/clickReward/${campaignId}`,
  setReward: 'campaign/clickReward',
  updateReward: 'campaign/clickReward',
  getAvailableLinkCampaign: (campaignId, advId) => `campaign/clickReward/available-link-campaign/${campaignId}?advId=${advId}`
}

export default {
  getClickReward (campaignId, success) {
    req.get(CLICK_REWARD_URI.getReward(campaignId), success)
  },
  setReward (clickReward, success) {
    req.post(CLICK_REWARD_URI.setReward, clickReward, success)
  },
  updateReward (clickReward, success) {
    req.put(CLICK_REWARD_URI.updateReward, clickReward, success)
  },
  getAvailableLinkCampaign (campaignId, advId, success) {
    req.get(CLICK_REWARD_URI.getAvailableLinkCampaign(campaignId, advId), success)
  }
}
