<template lang="pug">
a.btn_w.btn_export(
  @click="downloadCampaignGuide",
  style="text-align:center;padding-left:0px;"
) 캠페인 가이드

</template>

<script>
import userApi from '@/api/account'

export default {
  name: 'CampaignGuide',
  props: {
    hasCampaign: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isReadCampaignGuide: false
    }
  },
  methods: {
    checkReadCampaignGuide () {
      if (!this.hasCampaign && !this.isReadCampaignGuide) {
        this.$emit('showConfirm', '캠페인 가이드를 먼저 확인 후 캠페인 생성을 진행 해 주세요.')
        return false
      }
      return true
    },
    downloadCampaignGuide () {
      userApi.getIsValidSession(() => {
        this.isReadCampaignGuide = true
        const a = document.createElement('a')
        a.href = '/static/pdf/PAYCO CLO센터 이용가이드_v1.4.pdf'
        a.setAttribute('download', 'PAYCO CLO센터 이용가이드_v1.4.pdf')
        a.setAttribute('target', '_blank')
        a.setAttribute('rel', 'noreferrer noopener')
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
    }
  }
}
</script>

<style scoped>

</style>
