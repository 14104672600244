<template lang="pug">
.step_campaign(:class="flow.css", v-if="!this.campaign || this.campaign.campaignGroupType != 'MALL_REWARD'")
  ul
    li.step(v-for="step in flow.steps" :class="step.css")
      span.step_inner(style="cursor: pointer" @click="go(step.value)")
        span.step_num {{step.stepNumber}}
      p(@click="go(step.value)") {{step.name}}
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import campaignApi from '@/api/campaign'

export default {
  name: 'StepCampaign',
  data () {
    return {
      flow: {}
    }
  },
  computed: {
    ...mapGetters(['campaign'])
  },
  methods: {
    ...mapActions(['fetchCampaign']),
    ...mapMutations(['setStep']),
    go (step) {
      this.$route.params.mode === 'update' && this.$router.push(`/campaign/update/${step}/${this.$route.params.campaignId}`)
    },
    getCampaignSettingFlow () {
      const [groupType, type] = this.getCampaignGroupTypeAndCampaignType()
      groupType && type && campaignApi.getCampaignSettingFlow({
        campaignGroupType: groupType,
        campaignType: type
      }, body => {
        this.flow = body.campaignSettingFlow
        this.flow.css = this.flow.steps.length <= 5
          ? ['', '', '', 'tri_level', 'four_level', ''][this.flow.steps.length]
          : ''
        this.flow.steps.forEach((step, index, steps) => {
          steps[index].stepNumber = index + 1
        })
        this.setSteps()
        this.setStepsCss()
      })
    },
    getCampaignGroupTypeAndCampaignType () {
      const query = this.$route.query
      return this.$route.fullPath === '/campaign/create/default'
        ? ['CUSTOMIZED_COUPON', 'BASIC']
        : [query.campaignGroupType || (this.campaign && this.campaign.campaignGroupType), query.campaignType || (this.campaign && this.campaign.campaignType)]
    },
    setSteps () {
      const steps = this.flow.steps
      const currentIndex = steps.findIndex(step => step.value === this.$route.params.step)
      const [prevIndex, nextIndex] = [currentIndex - 1, currentIndex + 1]
      const list = 'list'
      this.setStep({
        prev: prevIndex < 0 ? list : steps[prevIndex].value,
        next: nextIndex >= steps.length ? list : steps[nextIndex].value,
        mode: this.$route.params.mode,
        number: currentIndex + 1,
        maxNumber: steps.length
      })
    },
    setStepsCss () {
      const steps = this.flow.steps
      const currentIndex = steps.findIndex(step => step.value === this.$route.params.step)
      steps.forEach((step, index) => {
        if (index < currentIndex) {
          step.css = 'complete'
        } else if (index === currentIndex) {
          step.css = 'on'
        } else {
          step.css = this.$route.params.mode === 'update' ? 'complete' : ''
        }
      })
      this.$forceUpdate()
    }
  },
  async created () {
    const campaignId = this.$route.params.campaignId || this.$route.query.campaignId
    if (campaignId && this.$route.params.step !== 'shopping-reward') {
      await this.fetchCampaign(campaignId)
    }
    this.getCampaignSettingFlow()
  },
  watch: {
    'campaign.campaignGroupType' () {
      this.getCampaignSettingFlow()
    },
    'campaign.campaignType' () {
      this.getCampaignSettingFlow()
    },
    '$route.params.step' () {
      this.setSteps()
      this.setStepsCss()
    }
  }
}
</script>

<style scoped></style>
