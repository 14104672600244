import req from '../http-request'

const PUBLISHER_URI = {
  POST_PUBLISHER: 'admin-management/publisher',
  PUT_PUBLISHER: (publisherCode) => `admin-management/publishers/${publisherCode}`,
  GET_PUBLISHERS: (param) => `admin-management/publishers?${req.getQueryString(param)}`,
  GET_PUBLISHER: (publisherCode) => `admin-management/publishers/${publisherCode}`,
  GET_EXTERNAL_PUBLISHER_SETTING: (campaignId, publishTypes, firstOrderPayco) => `admin-management/external-publisher-setting/${campaignId}?publishTypes=${publishTypes}&firstOrderPayco=${firstOrderPayco}`,
  GET_LINKED_HOUSE_PUBLISHERS: (campaignId) => `admin-management/linked-house-publishers/${campaignId}`,
  GET_LINKED_CAMPAIGNS: (publisherCode) => `admin-management/linked-campaigns/${publisherCode}`,
  SIMPLE_PUBLISHERS: (firstOrderPayco) => `admin-management/simple-publishers?firstOrderPayco=${firstOrderPayco}`,
  GET_PUBLISHERS_FOR_MANAGER: 'admin-management/manager/publishers',
  PUT_EXTERNAL_PUBLISHER_SETTING: (campaignId) => `admin-management/external-publisher-setting/${campaignId}`,
  GET_CARD_LINKED_PUBLISHERS: (publisherCode) => `admin-management/card/linkedPublishers/${publisherCode}`,
  GET_LINKED_CARDS: (campaignId, type) => `admin-management/card-list/${campaignId}?type=${type}`
}

export default {
  createPublisher (publisher, success, fail) {
    req.post(PUBLISHER_URI.POST_PUBLISHER, publisher, success, fail)
  },
  updatePublisher (publisherCode, publisher, success, fail) {
    req.put(PUBLISHER_URI.PUT_PUBLISHER(publisherCode), publisher, success, fail)
  },
  getPublishers ({ page, size, column, direction, pubTypeCode, useYn, exceptPubCodes }, success) {
    req.get(PUBLISHER_URI.GET_PUBLISHERS({ page, size, column, direction, pubTypeCode, useYn, exceptPubCodes }), success)
  },
  getPublisher (publisherCode, success) {
    req.get(PUBLISHER_URI.GET_PUBLISHER(publisherCode), success)
  },
  getExternalPublisherSetting (campaignId, publishTypes, firstOrderPayco, success) {
    req.get(PUBLISHER_URI.GET_EXTERNAL_PUBLISHER_SETTING(campaignId, publishTypes, firstOrderPayco), success)
  },
  getLinkedHousePublishers (campaignId, success) {
    req.get(PUBLISHER_URI.GET_LINKED_HOUSE_PUBLISHERS(campaignId), success)
  },
  getLinkedCampaigns (publisherCode, success) {
    req.get(PUBLISHER_URI.GET_LINKED_CAMPAIGNS(publisherCode), success)
  },
  getSimplePublishers (firstOrderPayco, success) {
    req.get(PUBLISHER_URI.SIMPLE_PUBLISHERS(firstOrderPayco), success)
  },
  getPublishersForManager (success) {
    req.get(PUBLISHER_URI.GET_PUBLISHERS_FOR_MANAGER, success)
  },
  updateExternalPublisherSetting (campaignId, linkedPublishers, success, fail) {
    req.put(PUBLISHER_URI.PUT_EXTERNAL_PUBLISHER_SETTING(campaignId), linkedPublishers, success, fail)
  },
  getLinkedCards (campaignId, type, success) {
    req.get(PUBLISHER_URI.GET_LINKED_CARDS(campaignId, type), success)
  },
  getCardLinkedPublishers (publisherCode, success) {
    req.get(PUBLISHER_URI.GET_CARD_LINKED_PUBLISHERS(publisherCode), success)
  }
}
