<template lang="pug">
</template>

<script>
export default {
  name: 'CommonClose',
  created () {
    window.close()
  }
}
</script>

<style scoped>

</style>
