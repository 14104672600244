<template lang="pug">
.tab_cont
  .form_wrap
    .tit_area
      h1.tit 결제사 {{isUpdate ? '수정' : '등록'}}
    .form.bdt3
      table
        caption
          span 결제사관리 - 결제사등록
        colgroup
          col(style='width:173px')
          col
        tbody
          tr
            th.align_top(scope='row')
              label.lbl(for='input-clo-payment-name') 결제사명
                em.required
                  span.blind 필수
            td
              .inp_area(:class="{has_error: !validationPaymentName}")
                .inp_bx(style='width:300px')
                  input#input-clo-payment-name(type='text',
                    :value="cloPaymentRegistration.cloPaymentName",
                    @input="inputCloPaymentName",
                    :disabled="isView"
                  )
                span.txt_count {{`${cloPaymentNameByteLength}/${CLO_PAYMENT_NAME_MAX_LENGTH}`}}
                span.txt_explain(v-if="!validationPaymentName") 결제사명은 필수값입니다.
          tr(v-if="isAdmin")
            th.align_top(scope='row')
              label.lbl(for='input-budget-use-rate') 예산한도율
                em.required
                  span.blind 필수
            td
              .inp_area(:class="{has_error: validationBudgetUseRateMessage !== ''}")
                .inp_bx(style='width:100px')
                  input#input-budget-use-rate(type='text',
                    :value="cloPaymentRegistration.budgetUseRate",
                    @input="inputBudgetUseRate",
                    :disabled="isView"
                  )
                span.txt_unit %
                span.txt_explain.txt_guide.beside *어드민 전용 노출
                span.txt_explain.txt_guide 캠페인 예산한도율 설정 시, 디폴트로 입력됩니다.
                span.txt_explain(v-if="validationBudgetUseRateMessage !== ''") {{validationBudgetUseRateMessage}}
          tr
            th.align_top(scope='row')
              label.lbl(for='input-api-url') API URL
            td
              .inp_area
                .inp_bx(style='width:600px')
                  input#input-api-url(type='text',
                    :maxlength="API_URL_MAX_LENGTH"
                    :value="cloPaymentRegistration.apiUrl",
                    @input="inputApiUrl",
                    :disabled="isView"
                  )
                span.txt_explain.txt_guide 결제사 캠페인의 상태 변경 시, 입력된 API URL로 상태를 전달합니다.
    .btn_act_area.form_v2
      button.btn.btn_gray3(type='button', @click="goCloPaymentList") 취소
      button.btn.btn_bl(
        v-if="!(isView)"
        type='button'
        :class="{disable: !isValidInput}" :disabled="!isValidInput"
        @click="registerModifyCloPayment"
      ) {{isUpdate ? '수정' : '등록'}}
</template>

<script>
import cloPaymentApi from '@/api/clo/clo-payment.js'
import store from '@/store'
import $ from '@/util.js'
import { mapGetters } from 'vuex'

export default {
  data: function () {
    return {
      cloPaymentRegistration: {
        cloPaymentCode: null,
        cloPaymentName: '',
        budgetUseRate: 100,
        apiUrl: ''
      },
      CLO_PAYMENT_NAME_MAX_LENGTH: 30,
      API_URL_MAX_LENGTH: 250
    }
  },
  mounted () {
    if (this.isUpdate) {
      this.setCloPaymentModification(this.$route.params.cloPaymentCode)
    }
    this.$nextTick().then(() => window.scrollTo(0, 0))
  },
  methods: {
    inputCloPaymentName ({ target }) {
      if ($.getUTF8Length(target.value) > this.CLO_PAYMENT_NAME_MAX_LENGTH) {
        target.value = this.cloPaymentRegistration.cloPaymentName
      } else {
        this.cloPaymentRegistration.cloPaymentName = target.value
      }
    },
    inputBudgetUseRate ({ target }) {
      const prevText = this.cloPaymentRegistration.budgetUseRate
      const newText = target.value
      const budgetUseRate = parseFloat(newText)
      if (newText !== '' && !(budgetUseRate >= 0 && budgetUseRate <= 100)) {
        target.value = prevText
      }
      this.cloPaymentRegistration.budgetUseRate = target.value
    },
    inputApiUrl ({ target }) {
      target.value = target.value.trim()
      this.cloPaymentRegistration.apiUrl = target.value
    },
    goCloPaymentList () {
      this.$router.push('/account/clo-payment')
    },
    registerModifyCloPayment () {
      const params = {
        cloPaymentName: this.cloPaymentRegistration.cloPaymentName,
        budgetUseRate: $.toFixedFloat(this.cloPaymentRegistration.budgetUseRate / 100, 4),
        lastCampaignStatusApiUrl: this.cloPaymentRegistration.apiUrl
      }

      if (this.isUpdate) {
        params.cloPaymentCode = this.cloPaymentRegistration.cloPaymentCode
        cloPaymentApi.updateCloPayment(
          params,
          body => {
            if (body.isSuccessful) {
              this.showError('결제사가 수정 되었습니다.')
              this.goCloPaymentList()
            } else {
              this.showError('결제사 수정에 실패했습니다. 다시 시도해주세요.')
            }
          },
          () => {
            this.showError('결제사 수정에 실패했습니다. 다시 시도해주세요.')
          }
        )
      } else {
        cloPaymentApi.createCloPayment(
          params,
          body => {
            if (body.isSuccessful) {
              this.showError('결제사가 생성 되었습니다.')
              this.goCloPaymentList()
            } else {
              this.showError('결제사 생성에 실패했습니다. 다시 시도해주세요.')
            }
          },
          () => {
            this.showError('결제사 생성에 실패했습니다. 다시 시도해주세요.')
          }
        )
      }
    },
    setCloPaymentModification (paymentCode) {
      cloPaymentApi.getCloPayment(
        paymentCode,
        body => {
          const info = body.cloPayment
          this.cloPaymentRegistration = {
            cloPaymentCode: info.cloPaymentCode,
            cloPaymentName: info.cloPaymentName,
            budgetUseRate: (info.budgetUseRate || info.budgetUseRate === 0.0)
              ? (info.budgetUseRate * 100).toFixed(2)
              : null,
            apiUrl: info.lastCampaignStatusApiUrl || ''
          }
        }
      )
    },
    showError (message) {
      store.commit('setError', {
        id: Date.now(),
        message: message,
        hadshown: false
      })
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    validationPaymentName () {
      return this.cloPaymentRegistration.cloPaymentName !== ''
    },
    validationBudgetUseRateMessage () {
      if (this.cloPaymentRegistration.budgetUseRate === '') {
        return '예산한도율은 필수값입니다.'
      } else if (!/(^[1-9]\d*|^0)(\.\d{1,2})?$/.test(this.cloPaymentRegistration.budgetUseRate)) {
        return '숫자+소수점 둘째자리까지 입력할 수 있습니다.'
      } else {
        return ''
      }
    },
    isUpdate () {
      return this.$route.params.cloPaymentCode !== undefined
    },
    isValidInput () {
      return this.validationPaymentName &&
        this.validationBudgetUseRateMessage === ''
    },
    isView () {
      return this.isUpdate && !this.isAdmin
    },
    cloPaymentNameByteLength () {
      return $.getUTF8Length(this.cloPaymentRegistration.cloPaymentName)
    }
  }
}
</script>
