export const USER_ROLE = {
  NE_MGR: 'NE_MGR',
  NORMAL_MGR: 'NORMAL_MGR',
  PAYMENT_MGR: 'PAYMENT_MGR',
  NORMAL: 'NORMAL',
  AROUND_ME_MGR: 'AROUND_ME_MGR',
  PUBLISHER_MGR: 'PUBLISHER_MGR',
  CAMPAIGN_NE_MGR: 'CAMPAIGN_NE_MGR'
}

export const getRedirectPath = function (role) {
  switch (role) {
    case USER_ROLE.PUBLISHER_MGR:
      return '/payco-report'
    case USER_ROLE.NE_MGR:
    case USER_ROLE.NORMAL_MGR:
    case USER_ROLE.PAYMENT_MGR:
    case USER_ROLE.NORMAL:
    case USER_ROLE.AROUND_ME_MGR:
    case USER_ROLE.CAMPAIGN_NE_MGR:
      return '/account'
  }
  return '/account'
}
