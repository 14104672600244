<template lang="pug">
div
  .ly_pop_wrap.ly_pop7.store_list_pop
    .ly_pop_layer.scrollbar-inner(ref='scrollbar')
      .ly_pop_cont.adv_center
        h1.pop_tit {{ cloMerchantName }} 가맹점번호
        form(onSubmit="return false")
          fieldset
            .query_form.bg_white
              dl.q_row
                dt.blind PG사명 또는 결제사명 검색
                dd
                  .form.form_row
                    .bx_item
                      dl
                        dt.blind 검색
                        dd
                          .inp_area
                            .inp_bx
                              // [D] input에 입력된 내용 있으면 .txt_lbl 숨김 처리
                              span.txt_lbl(v-if="search.keyword === ''") PG사명 또는 결제사명을 입력해주세요.
                              input#storelst1_inp1(type='text',
                                :value="search.keyword",
                                :class='{"on_typing" : isOnTypingKeyword}',
                                style='width:100%',
                                @input='toggleKeywordInputClass($event)',
                                @focusout='toggleKeywordInputClass($event)')
                              // [D] input에 focus와 입력값이 존재할때 "on_typing" 클래스 추가
                    .confirm_area
                      button.btn.btn_red(type='submit', @click='searchDataList(1)') 조회
                    .btn_area.tr
                      a.btn_bl.btn_add_more(@click='popup.showModal.form = true') 신규 등록
        .tbl_wrap
          .page_head
            .btn_group
              button.btn_item(type='button', @click='editNumbers()') 수정
              button.btn_item(type='button' @click='preDeleteNumbers()') 삭제
            .btn_area
              a.btn_w.btn_export.excel(@click='excel()') 엑셀 다운로드
          .tbl
            .tbl_content
              table.form
                colgroup
                  col(style='width:37px')
                  col(style='width:200px')
                  col(style='width:200px')
                  col
                  col(style='width:100px')
                thead
                  tr
                    th(scope='col')
                      span.inp_checkbox2
                        input#numbers_check_all.inp_chk(type='checkbox',
                          @click='checkAll($event)',
                          :checked='isCheckedAll')
                        label.check_control_label(for='numbers_check_all')
                          span.ico_chk
                            span.blind 전체 선택
                    th.txt.align_center(scope='col') PG사명
                    th.txt.align_center(scope='col') 결제사명
                    th.txt.align_center(scope='col') 가맹점번호
                    th.sort(scope='col')
                      button(type='button', @click="sortDataList()") 등록일
                tbody(v-if="search.totalCount > 0")
                  tr(v-for='(item, index) in search.dataList')
                    td
                      span.inp_checkbox2
                        input.inp_chk.custom_checkbox(type='checkbox',
                          :id='`numbers-check-${index}`'
                          :value='index',
                          v-model="checkedIndexList")
                        label.check_control_label(:for='`numbers-check-${index}`')
                          span.ico_chk
                          span.blind 선택
                    td {{ item.pgCompanyName }}
                    td {{ item.cloPaymentName }}
                    template(v-if='editedIndexList.includes(index)')
                      td.txt.align_center
                        .inp_area(:class='{has_error: item.pgMerchantNumber.trim() == ""}')
                          .inp_bx(style='width: 50%')
                            input(type='text' :value='item.pgMerchantNumber', @input='changeInputNumber(index, $event)')
                    template(v-else)
                      td {{ item.pgMerchantNumber }}
                    td {{ item.regYmdtStr }}
                tbody(v-if="search.totalCount == 0")
                  tr
                    td(colspan=5)
                      .nodata_area
                        p.txt {{ tableMessage }}
              .tbl_bottom_area
                .notice_area
                  ul
                    li 정보 수정 후, 저장 버튼을 눌러야 수정 내용이 반영됩니다.
                .confirm_area
                  button.btn.btn_red(type='submit', @click='preSaveNumbers()') 저장
              paging(:currentPage="this.search.page.now",
                :pageSize="this.search.page.size"
                :totalCount="this.search.totalCount"
                @onClickPage='searchDataList')
      button.btn_close(type='button', @click='closeModal')
        span.blind 닫기
  clo-merchant-number-form(
    v-if='popup.showModal.form'
    :cloMerchantSeq='cloMerchantSeq',
    :cloMerchantName='cloMerchantName',
    @showModal='setFormModal')
  single-text-notice-confirm(
    v-show="popup.showModal.confirm"
    :text="popup.text"
    @yes="popup.yesFunc"
    @no="popup.noFunc")
  single-text-notice-popup(
    v-show="popup.showModal.notice"
    :text="popup.text"
    @yes="popup.yesFunc")
</template>

<script>
import $ from '@/util.js'
import _ from '@/partial'
import Paging from '@/components/common/fragments/Paging'
import CloMerchantNumberForm from './CloMerchantNumberForm'
import cloMerchantApi from '@/api/clo/clo-merchant.js'
import SingleTextNoticePopup from '@/components/clo/common/SingleTextNoticePopup'
import SingleTextNoticeConfirm from '@/components/clo/common/SingleTextNoticeConfirm'
import { mapMutations } from 'vuex'
import jQuery from 'jquery'

export default {
  props: ['cloMerchantSeq', 'cloMerchantName'],
  name: 'CloMerchantNumberList',
  components: { Paging, CloMerchantNumberForm, SingleTextNoticePopup, SingleTextNoticeConfirm },
  data: function () {
    return {
      MAX_LENGTH_CLO_MERCHANT_NUMBER: 30,
      search: {
        dataList: [],
        keyword: '',
        totalCount: 0,
        page: {
          now: 1,
          size: 30
        },
        sort: {
          column: 'REG_YMDT',
          direction: 'DESC'
        }
      },
      hasCloMerchantNumber: false,
      popup: {
        showModal: {
          form: false,
          notice: false,
          confirm: false
        },
        yesFunc: () => { },
        noFunc: () => { },
        text: ''
      },
      isOnTypingKeyword: false,
      checkedIndexList: [],
      editedIndexList: []
    }
  },
  mounted () {
    jQuery('.scrollbar-inner').scrollbar()
    this.searchDataList(1)
  },
  methods: {
    ...mapMutations(['pushToast']),
    searchDataList (requestPageNumber) {
      cloMerchantApi.getCloMerchantNumberPaging({
        cloMerchantSeq: this.cloMerchantSeq,
        keyword: this.search.keyword,
        sort: this.search.sort,
        page: {
          now: requestPageNumber - 1,
          size: this.search.page.size
        }
      }, ({ result }) => {
        this.search.totalCount = result.totalCount
        this.search.dataList = result.list.map(i => _.extend(i, { originPgMerchantNumber: i.pgMerchantNumber }))
        this.search.page.now = requestPageNumber
        this.hasCloMerchantNumber = result.hasCloMerchantNumber
        this.checkedIndexList = []
        this.editedIndexList = []
      })
    },
    sortDataList () {
      this.search.sort.direction = $.getReverseSortDirection(this.search.sort.direction)
      this.searchDataList(this.search.page.now)
    },
    preDeleteNumbers: function () {
      if (this.checkedIndexList.length === 0) return
      this.popup.showModal.confirm = true
      this.popup.text = '삭제하시겠습니까?'
      this.popup.yesFunc = () => this.deleteNumbers()
      this.popup.noFunc = () => { this.popup.showModal.confirm = false }
    },
    deleteNumbers: function () {
      cloMerchantApi.deleteCloMerchantNumber(this.checkedIndexList.map(i => this.search.dataList[i].cloMerchantNumberSeq), () => {
        this.popup.showModal.confirm = false
        this.popup.showModal.notice = true
        this.popup.text = '삭제되었습니다.'
        this.popup.yesFunc = () => {
          this.checkedIndexList = []
          this.popup.showModal.notice = false
          this.searchDataList(1)
        }
      })
    },
    preSaveNumbers: function () {
      if (this.checkedIndexList.length === 0) return
      if (this.search.dataList.filter(i => i.pgMerchantNumber.trim() === '').length !== 0) {
        this.popup.showModal.notice = true
        this.popup.text = '가맹점번호는 필수 입력값입니다.'
        this.popup.yesFunc = () => { this.popup.showModal.notice = false }
        return
      }
      const filteredDataList = this.editedIndexList.map(i => this.search.dataList[i])
      if (filteredDataList.length !== 0) {
        this.popup.showModal.confirm = true
        this.popup.text = '저장하시겠습니까?'
        this.popup.yesFunc = () => this.saveNumbers(filteredDataList)
        this.popup.noFunc = () => { this.popup.showModal.confirm = false }
      }
    },
    saveNumbers: function (filteredDataList) {
      cloMerchantApi.modifyCloMerchantNumber(filteredDataList, body => {
        if (body.code === 500) {
          this.popupNotice(body.msg)
          return
        }
        this.editedIndexList = []
        this.popupNotice('저장되었습니다.', () => {
          this.checkedIndexList = []
          this.searchDataList(this.search.page.now)
        })
      }, body => {
        this.popupNotice(body.resultMessage)
      })
    },
    popupNotice: function (text, additional) {
      this.popup.showModal.confirm = false
      this.popup.showModal.notice = true
      this.popup.text = text.replace('\n', '<br>')
      this.popup.yesFunc = () => {
        this.popup.showModal.notice = false
        additional && additional()
      }
    },
    excel: function () {
      cloMerchantApi.getCloMerchantNumberExcel(
        {
          cloMerchantSeq: this.cloMerchantSeq,
          keyword: this.search.keyword,
          sort: this.search.sort
        },
        blob => $.OpenBlobToExcel(blob, `${this.cloMerchantName}_가맹점번호`))
    },
    changeInputNumber: function (idx, { target }) {
      if ($.getUTF8Length(target.value) <= this.MAX_LENGTH_CLO_MERCHANT_NUMBER) {
        this.$set(this.search.dataList[idx], 'pgMerchantNumber', target.value)
      } else {
        target.value = this.search.dataList[idx].pgMerchantNumber
      }
    },
    editNumbers: function () {
      this.editedIndexList = this.checkedIndexList
    },
    setFormModal: function (flag, refresh) {
      this.popup.showModal.form = flag
      refresh && this.searchDataList(1)
    },
    toggleKeywordInputClass: function ({ target }) {
      this.search.keyword = target.value
      this.isOnTypingKeyword = (target === 'input')
    },
    checkAll: function (e) {
      if (e.target.checked) {
        this.checkedIndexList = [...Array(this.search.dataList.length)].map((v, i) => i)
      } else { this.checkedIndexList = [] }
    },
    closeModal: function () {
      this.$emit('showModal', false)
    }
  },
  computed: {
    isCheckedAll () {
      return this.search.dataList.length !== 0 && this.checkedIndexList.length === this.search.dataList.length
    },
    tableMessage () {
      if (this.hasCloMerchantNumber) return '조회 결과가 없습니다.'
      else return '등록한 가맹점번호가 없습니다. 신규등록 버튼을 클릭하여 가맹점번호를 등록하세요.'
    }
  }
}
</script>

<style scoped></style>
