import campaignApi from '@/api/campaign'
import { CAMPAIGN_STATUS_TYPE, CAMPAIGN_GROUP_TYPE } from '@/types/campaign-types'

const atLeastOne = ({ campaigns }, message) => ({
  isValid: campaigns && campaigns.length > 0,
  message: campaigns && campaigns.length > 0 ? '' : message
})

const canCheckOnlyOne = ({ campaigns }, message) => ({
  isValid: campaigns && campaigns.length === 1,
  message: campaigns && campaigns.length === 1 ? '' : message
})

const isNotExpired = ({ expiredDateTime }, message) => ({
  isValid: (new Date()).getTime() <= expiredDateTime.getTime(),
  message: (new Date()).getTime() <= expiredDateTime.getTime() ? '' : message
})

const isNotReachedTargetDate = ({ endDateTime }, message) => ({
  isValid: endDateTime.getTime() > (new Date()).getTime(),
  message: endDateTime.getTime() > (new Date()).getTime() ? '' : message
})

const isAvaiableStatus = ({ status, statusList }, message) => ({
  isValid: statusList.includes(status),
  message: statusList.includes(status) ? '' : message
})

const isAuthorized = ({ hasAuthrity }, message) => ({
  isValid: hasAuthrity,
  message: hasAuthrity ? '' : message
})

const CAMPAIGN_ACTIONS = {
  TO_ACTIVE: {
    value: 'TO_ACTIVE',
    displayName: '작동',
    validator: { atLeastOne, canCheckOnlyOne, isNotExpired, isNotReachedTargetDate, isAvaiableStatus, isAuthorized }
  },
  TO_INACTIVE: {
    value: 'TO_INACTIVE',
    displayName: '종료',
    validator: { atLeastOne, isAvaiableStatus, isAuthorized }
  },
  COPY: {
    value: 'COPY',
    displayName: '복사',
    validator: { atLeastOne, canCheckOnlyOne, isAvaiableStatus, isNotExpired, isAuthorized }
  },
  DELETE: {
    value: 'DELETE',
    displayName: '삭제',
    validator: { atLeastOne, isAvaiableStatus }
  }
}

class CampaignManager {
  activateCampaigns (campaigns, hasAuthrity, success) {
    const v = CAMPAIGN_ACTIONS.TO_ACTIVE.validator

    const v1 = v.atLeastOne({ campaigns }, '캠페인을 선택해주세요.')
    if (!v1.isValid) return v1

    const v2 = v.canCheckOnlyOne({ campaigns }, '작동 대상 캠페인은 1개만 선택 가능합니다.')
    if (!v2.isValid) return v2

    const campaign = campaigns[0]
    const a30Days = 1000 * 60 * 60 * 24 * 30
    const expiredDateTime = new Date(campaign.campaignEndDate + a30Days)
    const v3 = v.isNotExpired({ expiredDateTime }, '캠페인 종료일이 30일 이상 경과된 경우 작동 할 수 없습니다.')
    if (!v3.isValid) return v3

    const v4 = v.isNotReachedTargetDate({ endDateTime: new Date(campaign.campaignEndDate) }, '기간이 만료된 캠페인은 작동할 수 없습니다.\n다시 작동하시려면 종료일을 조정해주세요.')
    if (!v4.isValid) return v4

    const statusList = [CAMPAIGN_STATUS_TYPE.ACTIVE, CAMPAIGN_STATUS_TYPE.INACTIVE]
    const v5 = v.isAvaiableStatus({ status: campaign.campaignStatus, statusList }, '캠페인 상태가 [임시 저장, 심사중] 인 경우 작동 할 수 없습니다.')

    if (!v5.isValid) return v5

    const v6 = v.isAuthorized({ hasAuthrity }, '해당 캠페인에 권한이 없어서\n작동 처리할 수 없습니다.')
    if (!v6.isValid) return v6

    const param = {
      campaignCodeList: [campaign.campaignCode],
      campaignStatus: 'ACTIVE',
      campaignApprovalStatus: null
    }

    campaignApi.updateCampaignStatus(param, body => success(body))
    return { isValid: true, message: '' }
  }

  deactivateCampaigns (campaigns, hasAuthrity, success) {
    const v = CAMPAIGN_ACTIONS.TO_INACTIVE.validator

    const v1 = v.atLeastOne({ campaigns }, '캠페인을 선택해주세요.')
    if (!v1.isValid) return v1

    const statusList = [CAMPAIGN_STATUS_TYPE.ACTIVE, CAMPAIGN_STATUS_TYPE.INACTIVE, CAMPAIGN_STATUS_TYPE.WAIT_APPROVAL]
    let v2
    for (const campaign of campaigns) {
      v2 = v.isAvaiableStatus({ status: campaign.campaignStatus, statusList }, '캠페인이 임시 저장 상태인 경우 종료로 변경할 수 없습니다.')
      if (!v2.isValid) return v2
    }

    const v6 = v.isAuthorized({ hasAuthrity }, '해당 캠페인에 권한이 없어서\n종료 처리할 수 없습니다.')
    if (!v6.isValid) return v6

    const param = {
      campaignCodeList: campaigns.map(c => c.campaignCode),
      campaignStatus: 'INACTIVE',
      campaignApprovalStatus: null
    }

    campaignApi.updateCampaignStatus(param, body => success(body))
    return { isValid: true, message: '' }
  }

  copyCampaigns (campaigns, hasAuthrity, confirm, success) {
    const v = CAMPAIGN_ACTIONS.COPY.validator

    const v1 = v.atLeastOne({ campaigns }, '캠페인을 선택해주세요.')
    if (!v1.isValid) return v1

    const v2 = v.canCheckOnlyOne({ campaigns }, '복사 대상 캠페인은 1개만 선택 가능합니다.')
    if (!v2.isValid) return v2

    const campaign = campaigns[0]
    const aYear = 1000 * 60 * 60 * 24 * 365
    const expiredDateTime = new Date(campaign.campaignEndDate + aYear)
    const v3 = v.isNotExpired({ expiredDateTime }, '종료일 기준 1년 이내의 작동 및 종료 상태의 캠페인만 복사 가능합니다.')
    if (!v3.isValid) return v3

    const statusList = [CAMPAIGN_STATUS_TYPE.ACTIVE, CAMPAIGN_STATUS_TYPE.INACTIVE]
    const v4 = v.isAvaiableStatus({ status: campaign.campaignStatus, statusList }, '종료일 기준 1년 이내의 작동 및 종료 상태의 캠페인만 복사 가능합니다.')
    if (!v4.isValid) return v4

    const v5 = v.isAuthorized({ hasAuthrity }, '상단배너, 쇼핑적립 캠페인은 복사 대상이 아닙니다.')
    if (!v5.isValid) return v5

    let copyApi
    let copyText
    switch (campaign.campaignGroupType) {
      case CAMPAIGN_GROUP_TYPE.POINT_HOME:
        copyApi = campaignApi.copyPointHomeBasicCampaign
        copyText = '타겟 및 쿠폰 정보, 프로모션 기간, 쿠폰 ID, 공유하기 설정은 복사되지 않습니다.'
        break
      case CAMPAIGN_GROUP_TYPE.OPTIMAL_TARGET_COUPON:
        copyApi = campaignApi.copyCampaign
        copyText = '쿠폰 및 타겟 설정은 복사되지 않습니다.'
        break
      default:
        copyApi = campaignApi.copyCampaign
        copyText = '쿠폰 및 타겟, 공유하기 설정은 복사되지 않습니다.'
        break
    }

    const yesCallback = () => { copyApi(campaign.campaignCode, success) }
    confirm(yesCallback, copyText)
    return { isValid: true, message: '' }
  }

  deleteCampaigns (campaigns, confirm, success) {
    const v = CAMPAIGN_ACTIONS.DELETE.validator
    const v1 = v.atLeastOne({ campaigns }, '캠페인을 선택해주세요.')
    if (!v1.isValid) return v1

    const statusList = [CAMPAIGN_STATUS_TYPE.CREATING]
    let v2
    for (const campaign of campaigns) {
      v2 = v.isAvaiableStatus({ status: campaign.campaignStatus, statusList }, '임시저장 상태의 캠페인만 삭제 가능합니다.')
      if (!v2.isValid) return v2
    }

    const yesCallback = () => {
      campaignApi.deleteCampaigns(campaigns.map(c => c.campaignCode), success)
    }
    confirm(yesCallback)
    return { isValid: true, message: '' }
  }
}

const campaignManager = new CampaignManager()

export {
  CAMPAIGN_ACTIONS,
  campaignManager
}
