<template lang="pug">
#app
  router-view
  div(ref="errorcontainer")
  confirm(v-if="isShowAlert", ref="confirm", :confirm="alert", @yes="onConfirmYes", @no="onConfirmNo")
  loading
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Snackbar from '@/components/campaign/fragments/Snackbar'
import Confirm from '@/components/campaign/fragments/Confirm'
import ToastAlert from '@/components/common/ToastAlert'
import Loading from '@/components/common/Loading'
import _ from '@/partial'
import $ from '@/util'

var AlertComponent = Vue.extend(Snackbar)
var Toast = Vue.extend(ToastAlert)

export default {
  name: 'App',
  data () {
    return {
      isShowAlert: false,
      alert: {}
    }
  },
  async created () {
    if (this.checkIE()) {
      window.addEventListener('hashchange', () => {
        var currentPath = window.location.hash.slice(1)
        if (this.$route.path !== currentPath) {
          this.$router.push(currentPath)
        }
      }, false)
    }
    // 광고주, 결제사 목록 가져오기
    if (this.isAuthorized) {
      await this.fetchAdvertiserList()
      this.fetchCloPaymentList()
      this.fetchPublisherList()
    }
  },
  methods: {
    ...mapActions(['fetchAdvertiserList', 'fetchCloPaymentList', 'fetchPublisherList']),
    ...mapMutations(['setError', 'setHasShown', 'removeError', 'shiftToast', 'shiftConfirm']),
    checkIE () {
      var agent = navigator.userAgent.toLowerCase()
      return (navigator.appName === 'Netscape' && agent.indexOf('trident') !== -1) || (agent.indexOf('msie') !== -1)
    },
    showError (error) {
      var alert = new AlertComponent({
        propsData: { message: error.message }
      })
      alert.$mount()
      this.$refs.errorcontainer.appendChild(alert.$el)
      alert.showSnackbar('danger')
      this.setHasShown(error.id)
      _.delay(this.removeError, 3000, error.id)
    },
    showToast () {
      var toast = new Toast({
        propsData: {
          type: this.toast.type || 'success',
          message: this.toast.message,
          duration: this.toast.duration || 850
        }
      })
      toast.$on('expired', () => {
        this.$refs.errorcontainer.removeChild(toast.$el)
        this.shiftToast()
      })
      toast.$mount()
      this.$refs.errorcontainer.appendChild(toast.$el)
      toast.show()
    },
    showConfirm () {
      this.$set(this, 'alert', this.confirm)
      this.$set(this, 'isShowAlert', true)
      if (this.alert.onEnter) {
        this.$nextTick(() => this.$refs.confirm.$refs[this.alert.onEnter + 'Button'].focus())
      }
    },
    onConfirmYes () {
      this.alert.onYes && this.alert.onYes()
      this.$set(this, 'isShowAlert', false)
      this.shiftConfirm()
    },
    onConfirmNo () {
      this.alert.onNo && this.alert.onNo()
      this.$set(this, 'isShowAlert', false)
      this.shiftConfirm()
    }
  },
  computed: {
    ...mapGetters(['errors', 'toast', 'confirm', 'isAuthorized'])
  },
  watch: {
    errors () {
      _.go(
        this.errors,
        _.filter(e => !e.hadshown),
        _.each(this.showError)
      )
    },
    toast () {
      this.toast && this.showToast()
    },
    confirm () {
      this.confirm && this.showConfirm()
    },
    isAuthorized: {
      immediate: true,
      async handler () {
        if (this.isAuthorized) {
          await this.fetchAdvertiserList()
          this.fetchCloPaymentList()
          this.fetchPublisherList()
          $.match($.getUserAgent())
            .case(
              ua => ua !== 'chrome',
              () => _.go(
                {
                  subTitle: 'CLO센터는 크롬 브라우저에\n최적화되어 있습니다.',
                  text: '다른 브라우저에서는 일부 기능이 제한될 수 있으니 참고 부탁드립니다.',
                  yes: '확인'
                },
                _(this.$set, this, 'alert'),
                _(this.$set, this, 'isShowAlert', true)
              ))
            .default(_(this.$set, this, 'isShowAlert', false))
        }
      }
    }
  },
  components: { Snackbar, Confirm, ToastAlert, Loading }
}
</script>

<style src='./assets/style/main.css' lang='css'></style>
