<template lang="pug">
#wrap.sub_on
  gnb(v-if='isAuthorized' @onAdvertiserChange='onAdvertiserChange')
  app(v-if="isAuthorized || isAuthenticated")
  page-footer(v-if='isAuthorized')
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Gnb from '@/Gnb'
import App from '@/App'
import PageFooter from '@/PageFooter'
import accountApi from '@/api/account'

const closeUrl = `${process.env.MAIN_URI}/#/close`
const changePasswordPopupUrl = location.protocol + '//' + process.env.LOGIN_HOST + '/campaign/viewPasswordChangeCampaign.nhn?brandId=MARKETING&nextUrl=' + encodeURIComponent(closeUrl)
const isFromLoginPage = document.referrer.includes(process.env.LOGIN_HOST)
const hasSessionCookie = document.cookie.includes('biz_session')
const tfaHash = '/tfa?nextUrl='

export default {
  name: 'PageWrap',
  components: { App, Gnb, PageFooter },
  data () {
    return {
      isAuthenticated: false
    }
  },
  computed: {
    ...mapGetters(['isAuthorized', 'isAdmin', 'commonAdvertiserMerchants'])
  },
  created () {
    this.fetchMyRole(() => {
      this.authorizeUser(isFromLoginPage && !hasSessionCookie)
    })
  },
  methods: {
    ...mapActions({ fetchMyRole: 'fetchMyRole', fetchAdvertiserMerchants: 'fetchAdvertiserMerchants' }),
    ...mapMutations(['setIsNeedTwoFactorAuthentication', 'setIsNeedSession', 'setIsNeedToChangePassword']),
    authorizeUser (needSession) {
      Promise.all([
        this.checkTwoFactorAuthentication(),
        this.checkSession(needSession),
        this.checkPasswordChange()
      ]).then(([tfa, session, passwordChange]) => {
        let nextUrl = window.location.hash.substring(1)
        if (passwordChange) {
          nextUrl = '/password-change-guide?nextUrl=' + encodeURIComponent(nextUrl)
        }
        if (tfa) {
          nextUrl = tfaHash + encodeURIComponent(nextUrl)
        }
        this.setIsNeedTwoFactorAuthentication(tfa)
        this.setIsNeedSession(session)
        this.setIsNeedToChangePassword(passwordChange)
        this.hideLoader()
        if (this.$route.path !== nextUrl) {
          this.$router.replace(nextUrl)
        }
      })
    },
    checkTwoFactorAuthentication () {
      return new Promise((resolve) => {
        if (this.isAdmin) {
          accountApi.getNeedTwoFactorAuthentication(({ result }) => {
            this.isAuthenticated = true
            resolve(result)
          })
        } else {
          resolve(false)
        }
      })
    },
    checkSession (needSession) {
      return new Promise((resolve) => {
        if (!this.isAdmin && needSession) {
          accountApi.postSession(() => resolve(false), () => resolve(true))
        } else {
          resolve(false)
        }
      })
    },
    checkPasswordChange () {
      return new Promise((resolve) => {
        if (this.isAdmin) {
          accountApi.getNeedToChangePassword(({ result }) => {
            resolve(result)
          })
        } else {
          if (isFromLoginPage) {
            accountApi.getNeedToChangePassword(({ result }) => {
              if (result) {
                window.open(changePasswordPopupUrl, '비밀번호 변경 안내 지원', 'width=420,height=550,resizable,scrollbars=yes,status=1')
              }
            })
          }
          // 비밀번호 변경 안내 팝업은 필수가 아니기 때문에 false.
          resolve(false)
        }
      })
    },
    hideLoader () {
      document.getElementById('loader').style.display = 'none'
    },
    async onAdvertiserChange (advertiser) {
      const advId = advertiser.advId
      await this.fetchAdvertiserMerchants({ advertiserCode: advId })
    }
  }
}
</script>
