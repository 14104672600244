import req from './http-request'

const SHOP_URI = {
  GET_SHOP_LIST: 'shop/list',
  GET_SHOP_LIST_EXCEL: 'shop/excel',
  GET_APPROVED_SHOP_INFO: 'shop/list/approved',
  GET_APPROVED_SHOP_ALL: 'shop/list/approved/all',
  GET_SHOP_BY_ADVERTISER: (advertiserCode) => `shop/list/${advertiserCode}/all`,
  CREATE_SHOP: 'shop/register',
  CREATE_BULK_SHOP: 'shop/register/bulk',
  DELETE_SHOP_LIST: 'shop/delete',
  UPDATE_SHOP_LIST: 'shop/update',
  APPROVE_SHOP_LIST: 'shop/approve'
}
export default {
  getShopList (search, success) {
    req.post(SHOP_URI.GET_SHOP_LIST, search, success)
  },
  getShopListAsExcel (search, success) {
    req.postBlob(SHOP_URI.GET_SHOP_LIST_EXCEL, search, success)
  },
  getApprovedShopList (param, success) {
    req.post(`${SHOP_URI.GET_APPROVED_SHOP_INFO}`, param, success)
  },
  getApprovedShopAll (advertiserCode, success) {
    req.get(`${SHOP_URI.GET_APPROVED_SHOP_ALL}?advertiserCode=${advertiserCode}`, success)
  },
  getShopListByAdvertiserCode (advertiserCode, success) {
    req.get(SHOP_URI.GET_SHOP_BY_ADVERTISER(advertiserCode), success)
  },
  registerShop (shop, success, fail) {
    req.post(SHOP_URI.CREATE_SHOP, shop, success, fail)
  },
  registerShopBulk (data, progress, success, fail) {
    req.upload(SHOP_URI.CREATE_BULK_SHOP, data, progress, success, fail)
  },
  deleteShopList (shopIdList, success, fail) {
    req.post(SHOP_URI.DELETE_SHOP_LIST, shopIdList, success, header => {
      if (!header.isSuccessful) fail(header)
    })
  },
  updateShopList (shopList, success, fail) {
    req.post(SHOP_URI.UPDATE_SHOP_LIST, shopList, success, header => {
      if (!header.isSuccessful) fail(header)
    })
  },
  approveShopList (shopList, success, fail) {
    req.post(SHOP_URI.APPROVE_SHOP_LIST, shopList, success, header => {
      if (!header.isSuccessful) fail(header)
    })
  }
}
