<template lang="pug">
#loading(v-if='commonLoading')
  #loading-img(style="background: url('/static/img/logo_payco.png') 50% 50% no-repeat")
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CommonLoading',
  computed: {
    ...mapGetters(['commonLoading'])
  }
}
</script>

<style scoped>
#loading {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgb(0, 0, 0, 0.7);
}

#loading > #loading-img {
  width: 125px;
  height: 30px;
  position: fixed;
  top: calc(50% - 15px);
  left: calc(50% - 62.5px);
  -webkit-animation: breathing 2s ease-out infinite normal;
  animation: breathing 2s ease-out infinite normal;
}

@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }
}

</style>
