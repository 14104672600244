import Vue from 'vue'
import store from '@/store'
import Router from 'vue-router'
import AdvertiserList from '@/components/account/AdvertiserList'
import AdvertiserRegisterModify from '@/components/account/AdvertiserRegisterModify'
import AdvertiserConnect from '@/components/account/AdvertiserConnect'
import CampaignList from '@/components/campaign/CampaignList'
import NotFound from '@/components/common/NotFound'
import FullScreenGrid from '@/components/common/FullScreenGrid'
import CampaignTypeSetting from '@/components/campaign/create/CampaignTypeSetting'
import CampaignSetting from '@/components/campaign/create/CampaignSetting'
import Close from '@/components/common/Close'
import TwoFactorAuthentication from '@/components/authentication/TwoFactorAuthentication'
import AccountManagement from '@/components/account/AccountManagement'
import CloPaymentList from '@/components/clo/account/CloPaymentList'
import CloPaymentRegisterModify from '@/components/clo/account/CloPaymentRegisterModify'
import CloMerchantList from '@/components/clo/merchant/CloMerchantList'
import CloMerchantRegisterModify from '@/components/clo/merchant/CloMerchantRegisterModify'
import PasswordChangeGuide from '@/components/account/PasswordChangeGuide'
import { CLO_CAMPAIGN_PAGE_TYPE } from '@/types/clo-campaign-types'
import { getRedirectPath, USER_ROLE } from '@/types/user-role-types'

['push', 'replace'].forEach(method => {
  const originalMethod = Router.prototype[method]
  Router.prototype[method] = function m (location) {
    return originalMethod.call(this, location).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        // eslint-disable-next-line
        console.error(error)
      }
    })
  }
})

Vue.use(Router)

const checkAuthority = function (authorityCodes) {
  return function (to, from, next) {
    store.dispatch('fetchMyRole', function () {
      store.getters.myRole.some(r => authorityCodes.includes(r))
        ? next()
        : next(false)
    })
  }
}

const goRedirectPath = function () {
  return function (to, from, next) {
    store.dispatch('fetchMyRole', function () {
      if (store.getters.myRole.length === 0) {
        next('/account')
        return
      }
      next(getRedirectPath(store.getters.myRole[0]))
    })
  }
}

export default new Router({
  routes: [
    {
      path: '/',
      beforeEnter: goRedirectPath()
    },
    {
      path: '/account',
      name: 'Account',
      component: AccountManagement,
      children: [
        {
          path: '/account/advertiser',
          name: 'AdvertiserList',
          component: AdvertiserList
        },
        {
          path: '/account/advertiser/registerModify/:advertiserCode?',
          name: 'AdvertiserRegisterModify',
          component: AdvertiserRegisterModify
        },
        {
          path: '/account/advertiser/connect',
          name: 'AdvertiserConnect',
          component: AdvertiserConnect
        },
        {
          path: '/account/clo-payment',
          name: 'CloPaymentList',
          component: CloPaymentList
        },
        {
          path: '/account/clo-payment/registerModify/:cloPaymentCode?',
          name: 'CloPaymentRegisterModify',
          component: CloPaymentRegisterModify
        }
      ]
    },
    {
      path: '/clo-merchant',
      name: 'CloMerchantList',
      component: CloMerchantList
    },
    {
      path: '/clo-merchant/:mode(\\bcreate|update\\b)/:cloMerchantSeq?',
      name: 'CloMerchantRegisterModify',
      component: CloMerchantRegisterModify
    },
    {
      path: '/campaign',
      name: 'CampaignList',
      component: CampaignList,
      beforeEnter: checkAuthority([USER_ROLE.NE_MGR, USER_ROLE.AROUND_ME_MGR, USER_ROLE.CAMPAIGN_NE_MGR, USER_ROLE.NORMAL_MGR])
    },
    {
      path: '/campaign/:mode(\\bcreate|update\\b)',
      name: 'CampaignSetting',
      component: CampaignSetting,
      children: [
        {
          path: ':step(\\bdefault\\b)/:campaignId?',
          component: () => import('@/components/campaign/deciders/BasicSetupDecider'),
          beforeEnter: checkAuthority([USER_ROLE.NE_MGR, USER_ROLE.AROUND_ME_MGR, USER_ROLE.CAMPAIGN_NE_MGR])
        },
        {
          path: ':step(\\btarget\\b)/:campaignId',
          component: () => import('@/components/campaign/deciders/TargetSetupDecider'),
          beforeEnter: checkAuthority([USER_ROLE.NE_MGR, USER_ROLE.CAMPAIGN_NE_MGR])
        },
        {
          path: ':step(\\bbanner\\b)/:campaignId',
          component: () => import('@/components/campaign/deciders/BannerSetupDecider'),
          beforeEnter: checkAuthority([USER_ROLE.NE_MGR, USER_ROLE.AROUND_ME_MGR, USER_ROLE.CAMPAIGN_NE_MGR])
        },
        {
          path: ':step(\\bcoupon\\b)/:campaignId',
          component: () => import('@/components/campaign/deciders/CouponSetupDecider'),
          beforeEnter: checkAuthority([USER_ROLE.NE_MGR, USER_ROLE.CAMPAIGN_NE_MGR])
        },
        {
          path: ':step(\\beventPage\\b)/:campaignId',
          component: () => import('@/components/campaign/deciders/EventPageSetupDecider'),
          beforeEnter: checkAuthority([USER_ROLE.NE_MGR, USER_ROLE.CAMPAIGN_NE_MGR])
        },
        {
          path: ':step(\\bshop\\b)/:campaignId',
          component: () => import('@/components/campaign/deciders/ShopSetupDecider'),
          beforeEnter: checkAuthority([USER_ROLE.NE_MGR, USER_ROLE.AROUND_ME_MGR, USER_ROLE.CAMPAIGN_NE_MGR])
        },
        {
          path: ':step(\\bshopping-reward\\b)/:campaignId',
          component: () => import('@/components/campaign/deciders/ShoppingRewardSetupDecider'),
          beforeEnter: checkAuthority([USER_ROLE.NE_MGR, USER_ROLE.CAMPAIGN_NE_MGR])
        },
        {
          path: ':step(\\breward\\b)/:campaignId',
          component: () => import('@/components/campaign/deciders/RewardSetupDecider'),
          beforeEnter: checkAuthority([USER_ROLE.NE_MGR, USER_ROLE.CAMPAIGN_NE_MGR])
        }
      ]
    },
    {
      path: '/payco-report',
      redirect: '/payco-report/campaign',
      name: 'PaycoReport',
      component: () => import('@/components/report/PaycoReport'),
      children: [
        {
          path: 'campaign',
          component: () => import('@/components/report/CampaignReport'),
          beforeEnter: checkAuthority([USER_ROLE.NE_MGR, USER_ROLE.NORMAL_MGR, USER_ROLE.PUBLISHER_MGR])
        },
        {
          path: 'partyplus',
          component: () => import('@/components/report/PartyplusReport'),
          beforeEnter: checkAuthority([USER_ROLE.NE_MGR])
        }
      ]
    },
    {
      path: '/payment',
      redirect: '/payment/setoff',
      name: 'Payment',
      component: () => import('@/components/payment/Payment'),
      children: [
        {
          path: 'setoff',
          component: () => import('@/components/payment/Setoff'),
          beforeEnter: checkAuthority([USER_ROLE.NE_MGR, USER_ROLE.NORMAL_MGR])
        },
        {
          path: 'account',
          component: () => import('@/components/payment/Account'),
          beforeEnter: checkAuthority([USER_ROLE.NE_MGR])
        }
      ]
    },
    {
      path: '*',
      name: 'NotFound',
      component: NotFound
    },
    {
      path: '/full-screen',
      name: 'FullScreenGrid',
      component: FullScreenGrid,
      props: (route) => ({
        ...route.params
      })
    },
    {
      path: '/campaign/create/type',
      name: 'CampaignTypeSetting',
      component: CampaignTypeSetting
    },
    {
      path: '/admin-management',
      redirect: '/admin-management/search-merchants',
      name: 'AdminManagement',
      component: () => import('@/components/admin/AdminManagement'),
      children: [
        {
          path: 'search-merchants',
          component: () => import('@/components/admin/merchant/Merchants')
        },
        {
          path: 'search-merchants/:merchantCode',
          component: () => import('@/components/admin/merchant/Merchant'),
          props: true
        },
        {
          path: 'malls',
          name: 'Malls',
          component: () => import('@/components/admin/mall/Malls')
        },
        {
          path: 'mall/:mallCode',
          name: 'SearchManagementMall',
          component: () => import('@/components/admin/mall/Mall.vue'),
          props: (route) => ({
            ...route.params
          })
        },
        {
          path: 'search-bar',
          component: () => import('@/components/admin/searchBar/SearchBar.vue')
        },
        {
          path: 'search-terms',
          component: () => import('@/components/admin/searchTerm/SearchTerms')
        },
        {
          path: 'search-report',
          component: () => import('@/components/admin/searchReport/SearchReport.vue')
        },
        {
          path: 'abusing-keywords',
          component: () => import('@/components/admin/abusingKeyword/AbusingKeywords')
        },
        {
          path: 'publishers',
          component: () => import('@/components/admin/publisher/Publishers')
        },
        {
          path: 'publishers/:mode(\\bcreate\\b)',
          component: () => import('@/components/admin/publisher/Publisher'),
          props: true
        },
        {
          path: 'publishers/:mode(\\bupdate\\b)/:publisherCode',
          component: () => import('@/components/admin/publisher/Publisher'),
          props: true
        }
      ]
    },
    {
      path: '/extraction',
      redirect: '/extraction/coupon-bill',
      name: 'Extraction',
      component: () => import('@/components/extraction/Extraction'),
      children: [
        {
          path: 'coupon-bill',
          component: () => import('@/components/extraction/ExtractionCouponBill')
        }
      ]
    },
    {
      path: '/close',
      name: 'Close',
      component: Close
    },
    {
      path: '/tfa',
      name: 'TwoFactorAuthentication',
      component: TwoFactorAuthentication,
      props: (route) => ({ nextUrl: route.query.nextUrl })
    },
    {
      path: '/password-change-guide',
      name: 'PasswordChangeGuide',
      component: PasswordChangeGuide,
      props: (route) => ({ nextUrl: route.query.nextUrl })
    },
    {
      path: '/clo-campaign',
      name: 'CloCampaign',
      component: () => import('@/components/clo/cloCampaign/CloCampaign'),
      children: [
        {
          path: 'budget-management',
          component: () => import('@/components/clo/cloCampaign/CloBudgetManagement')
        },
        {
          path: 'promotion-management',
          component: () => import('@/components/clo/cloCampaign/CloPromotionManagement')
        },
        {
          path: 'promotion-management/promotion/:mode(\\bcreate|update\\b)/:promotionId?',
          name: 'CloPromotion',
          component: () => import('@/components/clo/cloCampaign/promotion/CloPromotion')
        },
        {
          path: 'new-campaign',
          component: () => import('@/components/clo/cloCampaign/CloNewCampaign'),
          props: { pageType: CLO_CAMPAIGN_PAGE_TYPE.NEW.code }
        },
        {
          path: 'progressing-campaign',
          component: () => import('@/components/clo/cloCampaign/CloNewCampaign'),
          props: { pageType: CLO_CAMPAIGN_PAGE_TYPE.PROGRESSING.code }
        },
        {
          path: ':promotionId/:mode(\\bcreate|update\\b)/:cloCampaignId?',
          component: () => import('@/components/clo/cloCampaign/campaign/CloCampaignBasicForm')
        },
        {
          path: ':promotionId/:cloCampaignId',
          component: () => import('@/components/clo/cloCampaign/campaign/CloCampaignBasicForm')
        }
      ]
    },
    {
      path: '/clo/report',
      name: 'CloReport',
      component: () => import('@/components/clo/report/CloReport'),
      children: [
        {
          path: 'advertiser',
          component: () => import('@/components/clo/report/advertiser/CloReportAdvertiser')
        },
        {
          path: 'payment',
          component: () => import('@/components/clo/report/payment/CloReportPayment')
        }
      ]
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})
