<template lang="pug">
.ly_pop_wrap.ly_pop9.store_list_pop
  .ly_pop_layer
    .ly_pop_cont.adv_center
      .page_head
        h1.pop_tit {{ campaign.name }}
        .btn_area.tr
          a.btn_w.btn_export.excel(
            :class='{disabled: isDownloading}'
            @click='downloadExcel'
          ) 엑셀 다운로드
      .tbl_wrap
        .tbl
          .tbl_content
            .tbl_head
              table.form
                colgroup
                  template(v-for='w in colgroups')
                    col(:style='w ? `width: ${w}px` : ""')
                thead
                  tr
                    template(v-for='column in columns')
                      th.align_left(v-if='column.id === "tradeDateTime"' scope='col') {{ column.name }}
                      th.align_center(v-else scope='col') {{ column.name }}
            .tbl_body.scrollbar-inner
              table.form
                colgroup
                  template(v-for='w in colgroups')
                    col(:style='w ? `width: ${w}px` : ""')
                tbody
                  tr(v-for='d in data')
                    template(v-for='column in columns')
                      td(v-if='column.id === "couponId"' :class='align(column.id)') {{ d[column.id] }}
                      td(v-else :class='align(column.id)') {{ numberWithCommasOrString(d[column.id]) }}
          .tbl_bottom_area
            .notice_area
              ul
                li 캠페인에 설정된 '연계매출 조회기간'의 매출 내역 중, 리포트 상단에서 설정한 기간의 매출 내역이 표기됩니다.
                li 매출 취소분은 캠페인에 설정된 '연계매출 조회기간' 기준으로 원 주문 일자에 반영되며, 배치 시점 및 상황에 따라 조회기간 이후의 취소분도 일부 반영될 수 있습니다.
                li 해당 페이지에서는 최대 100건의 데이터만 제공합니다. 전체 내역은 엑셀 다운로드로 확인 부탁드립니다.
    button.btn_close(type='button' @click='$emit("onCloseLinkedRevenueDetail")')
      span.blind 닫기
</template>

<script>
import $ from '@/util.js'
import jQuery from 'jquery'
import reportApi from '@/api/report'
import { mapGetters } from 'vuex'

export default {
  props: ['campaign', 'isDownloading', 'isAllPublisher', 'isAllByCouponId'],
  data () {
    return {
      count: 0,
      data: [],
      currentPage: 1,
      perPage: 100,
      colgroups: [102, 120, 92, 120, 102, 60, 112, 112, 122],
      columns: [
        {
          id: 'tradeDateTime',
          name: '거래발생일'
        },
        {
          id: 'merchantName',
          name: '가맹점명'
        },
        {
          id: 'orderNumber',
          name: '상점주문번호'
        },
        {
          id: 'productName',
          name: '상품명'
        },
        {
          id: 'paymentMethod',
          name: '결제수단'
        },
        {
          id: 'couponId',
          name: '쿠폰ID'
        },
        {
          id: 'totalPaid',
          name: '총결제금액'
        },
        {
          id: 'couponCost',
          name: '쿠폰금액'
        },
        {
          id: 'partialCanceled',
          name: '부분취소여부'
        }
      ]
    }
  },
  mounted () {
    jQuery('.scrollbar-inner').scrollbar()
    this.fetchLinkedRevenueDetail()
  },
  methods: {
    fetchLinkedRevenueDetail () {
      if (this.campaign.coupons.length === 0) {
        return
      }

      reportApi.getLinkedRevenueDetail(
        body => {
          this.data = body.linkedRevenueDetail.orders
          this.count = body.linkedRevenueDetail.count
        },
        {
          campaignIds: [this.campaign.id],
          startDate: this.campaign.startDate,
          endDate: this.campaign.endDate,
          coupons: this.isAllByCouponId ? this.campaign.coupons.map(c => { return { couponId: c.couponId } }) : [],
          publisherCodes: this.campaign.publisherCodes,
          adType: this.campaign.adType,
          bannerTypes: this.campaign.bannerTypes,
          excelMode: false,
          isSpecificAdvertiser: this.isSpecificAdvertiser,
          isAllByCouponId: this.isAllByCouponId,
          page: 1
        }
      )
    },
    numberWithCommasOrString (data) {
      if (isNaN(data)) return data
      return $.numberWithCommas(data)
    },
    downloadExcel () {
      if (!this.isDownloading) {
        this.$emit('downloadExcel')
      }
    }
  },
  computed: {
    ...mapGetters(['advertiser']),
    align (id) {
      return (id) => {
        const lefts = ['tradeDateTime', 'merchantName', 'productName']
        const rights = ['totalPaid', 'couponCost', 'isPartialCanceled']

        if (lefts.includes(id)) {
          return { align_left: true }
        }

        if (rights.includes(id)) {
          return { align_right: true }
        }

        return {}
      }
    },
    isSpecificAdvertiser () {
      return this.advertiser && this.advertiser.advId !== 'ALL'
    }
  }
}
</script>

<style scoped>
.excel.disabled {
  background-color: #eee;
  cursor: not-allowed;
}
</style>
