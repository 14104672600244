<template lang="pug">
  .paging
    span.paging_inner
      template(v-for="i in pageGroupSize")
        strong(v-if="currentPage === pageUnit + i") {{pageUnit + i}}
        a(v-else-if="(pageUnit + i) <= endPage" @click='goPage(pageUnit + i)') {{pageUnit + i}}
      a.btn_nav.btn_prev_all(:class="classNameBtnFirst", @click="goFirstPage")
        span 이전 페이지
      a.btn_nav.btn_prev(:class="classNameBtnPrev", @click="goPrevPage")
        span 이전 페이지
      a.btn_nav.btn_next(:class="classNameBtnNext", @click="goNextPage")
        span 다음 페이지
      a.btn_nav.btn_next_all(:class="classNameBtnEnd", @click="goEndPage")
        span 다음 페이지
</template>

<script>
export default {
  name: 'CommonPaging',
  props: {
    currentPage: {
      required: false
    },
    pageSize: {
      required: false
    },
    totalCount: {
      required: false
    },
    groupSize: {
      required: false
    },
    startWithZero: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data () {
    return {
      pageGroupSize: 10
    }
  },
  created () {
    if (this.groupSize) {
      this.pageGroupSize = this.groupSize
    }
  },
  computed: {
    pageUnit () { // 페이지 단위 (X, 1X, 2X, 3X, ... )
      return parseInt((this.currentPage - 1) / this.pageGroupSize) * this.pageGroupSize
    },
    endPage () {
      return this.startWithZero ? Math.floor(this.totalCount / this.pageSize) : Math.ceil(this.totalCount / this.pageSize)
    },
    classNameBtnNext () {
      return this.endPage > (this.pageUnit + this.pageGroupSize) ? 'on' : 'disable'
    },
    classNameBtnPrev () {
      return this.currentPage > this.pageGroupSize ? 'on' : 'disable'
    },
    classNameBtnEnd () {
      return this.currentPage < this.endPage ? 'on' : 'disable'
    },
    classNameBtnFirst () {
      return this.currentPage > 1 ? 'on' : 'disable'
    }
  },
  methods: {
    goPage (requestPageNumber) {
      this.$emit('onClickPage', requestPageNumber, this.pageSize)
    },
    goNextPage () {
      if (this.currentPage < this.endPage && (this.pageUnit + this.pageGroupSize) < this.endPage) {
        this.$emit('onClickPage', (this.pageUnit + parseInt(this.pageGroupSize + 1)), this.pageSize)
      }
    },
    goEndPage () {
      if (this.endPage > this.currentPage) {
        this.$emit('onClickPage', this.endPage, this.pageSize)
      }
    },
    goFirstPage () {
      if (this.currentPage > 1) {
        this.$emit('onClickPage', 1, this.pageSize)
      }
    },
    goPrevPage () {
      if (this.currentPage > this.pageGroupSize) {
        this.$emit('onClickPage', this.pageUnit - this.pageGroupSize + 1, this.pageSize)
      }
    }
  }
}
</script>
