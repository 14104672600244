import $ from '@/util.js'
import _ from '@/partial'

var regexer = _.memoize((regex, errMsg) =>
  (str) => regex.test(str) ? _.mr(true, '') : _.mr(false, errMsg))

var nullChecker = _.memoize((errMsg) =>
  (target) => (_.isArray(target) ? target.length === 0 : !target)
    ? _.mr(false, errMsg)
    : _.mr(true, ''))

var byteCountChecker = _.memoize((maxByteCount) =>
  (str) => _.go($.getUTF8Length(str),
    (byteCount) => maxByteCount >= byteCount ? _.mr(true, '') : _.mr(false, '최대 글자수를 초과하였습니다.')
  ))
var validTextChecks = function (txt, checks, callback) {
  return _.go(checks,
    _.L.map(check => check(txt)),
    _.L.filter(mr => !mr[0]),
    _.L.take(1),
    result => result.length > 0 ? _.mr(result[0][0], result[0][1]) : _.mr(true, ''),
    callback
  )
}
var validText = function (regex, maxByteCount, nullMsg, errMsg, callback) {
  var checks = [nullChecker(nullMsg), byteCountChecker(maxByteCount), regexer(regex, errMsg)]

  return txt => validTextChecks(txt, checks, callback)
}
var validTextWithNoRegex = function (maxByteCount, nullMsg, callback) {
  var checks = [nullChecker(nullMsg), byteCountChecker(maxByteCount)]

  return txt => validTextChecks(txt, checks, callback)
}
var validNullableText = function (regex, maxByteCount, errMsg, callback) {
  var checks = [byteCountChecker(maxByteCount), regexer(regex, errMsg)]

  return txt => $.match(txt)
    .case(t => t && t.length > 0, txt => validTextChecks(txt, checks, callback))
    .default(() => callback(_.mr(true, '')))
}
var validNullableTextByte = function (maxByteCount, callback) {
  var checks = [byteCountChecker(maxByteCount)]

  return txt => $.match(txt)
    .case(t => t && t.length > 0, txt => validTextChecks(txt, checks, callback))
    .default(() => callback(_.mr(true, '')))
}
var validUnlimitedLengthText = function (regex, nullMsg, errMsg, callback) {
  var checks = [nullChecker(nullMsg), regexer(regex, errMsg)]

  return txt => validTextChecks(txt, checks, callback)
}

function setValidation (obj) {
  return (valid, message) => {
    _.go(
      _.mr(valid, message),
      _.spread(
        _(_.set, obj, 'isValid'),
        _(_.set, obj, 'message')
      )
    )
  }
}

function checkAllValidation (valid) {
  return _.go(_.pairs(valid),
    _.map(entry => entry[1].isValid),
    _.every(_.identity)
  )
}

export {
  nullChecker,
  byteCountChecker,
  checkAllValidation,
  setValidation,
  validUnlimitedLengthText,
  validNullableTextByte,
  validNullableText,
  validTextWithNoRegex,
  validText
}
