import Vue from 'vue'
import resHandler from './response-handler'
import _ from '@/partial'

const requestLocation = () => location.hash

export default {
  get (uri, success, fail) {
    Vue.http.get(uri)
      .then(resHandler.handle(success, fail, requestLocation()), resHandler.error)
  },
  post (uri, body, success, fail = null, config = null) {
    Vue.http.post(uri, body, config)
      .then(resHandler.handle(success, fail, requestLocation()), resHandler.error)
  },
  put (uri, body, success, fail = null, config = null) {
    Vue.http.put(uri, body, config)
      .then(resHandler.handle(success, fail, requestLocation()), resHandler.error)
  },
  delete (uri, success, fail) {
    Vue.http.delete(uri)
      .then(resHandler.handle(success, fail, requestLocation()), resHandler.error)
  },
  getBlob (uri, success) {
    Vue.http.get(uri, { responseType: 'blob' })
      .then(response => response.blob(), resHandler.error)
      .then(success)
  },
  postBlob (uri, body, success, fail = null, config = { responseType: 'blob' }) {
    Vue.http.post(uri, body, config)
      .then(response => { resHandler.handleBlob(response, success, fail) }, resHandler.error)
  },
  upload (uri, body, progress, success, fail) {
    var formData = new FormData()
    _.isObject(body) && _.pairs(body).forEach(e => formData.append(e[0], e[1]))
    _.isArray(body) && body.forEach(b => formData.append(b[0], b[1]))

    this.post(uri, formData, success, fail, {
      headers: {
        'Content-Type': 'multipart/formdata; charset=utf-8',
        Accept: '*/*'
      },
      uploadProgress (e) {
        progress(e)
      }
    })
  },
  makeGetSuffix (...params) {
    const length = params.length
    if (length === 0) {
      return ''
    }
    let getSuffix = '?' + params[0].name + '=' + params[0].value
    for (let i = 1; i < length; i += 1) {
      getSuffix += '&' + params[i].name + '=' + params[i].value
    }
    return getSuffix
  },
  makeGetSuffixByObject (object) {
    return _.go(object,
      _.pairs,
      _.map(arr => arr[0] + '=' + arr[1]),
      _.reduce((acc, e) => acc + '&' + e, '')
    ).substring(1)
  },
  getQueryString (params) {
    return Object.keys(params)
      .filter(key => params[key])
      .map(key => `${key}=${params[key]}`)
      .join('&')
  }
}
