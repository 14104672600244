<template lang="pug">
  div
    div(:class='tableWrapClasses')
      .page_head(v-if='!isPopup')
        .tit_area
          h2(:class='titClass') {{ frame.title }}
        .btn_area.tr
          a.btn_w.btn_open_pop(
            v-if='isCampaignStatus || isCampaignManagement'
            @click='openFullScreen'
          ) {{ isFullScreenMode ? '작게 보기' : '크게 보기' }}
          a.btn_w.btn_export.excel(v-if='!isPopup' @click='downloadExcel') 엑셀 다운로드
          a.btn_bl.btn_add_more(v-if='isCampaignManagement && !isFullScreenMode') 캠페인 생성
      .tbl_scroll(
        v-if='isStaticTable || isFullScreenMode'
        :class='{"static_table": isStaticTable, "static_table_with_full_screen": isStaticTable && isFullScreenMode}'
      )
        .tbl
          .tbl_content
            table
              caption
                span
              colgroup
                template(v-for='fw in widths')
                  col(:style='{width: `${fw}${frame.widthPostfix}`}')
              thead
                tr
                  template(v-for='column in columns')
                    th.txt.align_center(
                      :class='{sort: column.sort}'
                      :rowspan='column.rowspan'
                      :colspan='column.colspan'
                      scope='col')
                      button(v-if='column.sort' @click='sort(column.orderBy)') {{ column.name }}
                      template(v-else) {{ column.name }}
                tr(v-if='frame.child')
                  template(v-for='child in frame.child')
                    th.txt.align_center(
                      v-if='child.name !== ""'
                      :class='{sort: child.sort}'
                      :rowspan='child.rowspan'
                      :colspan='child.colspan'
                      scope='col')
                      button(v-if='child.sort' @click='sort(child.orderBy)') {{ child.name }}
                      template(v-else) {{ child.name }}
              tbody
                tr.total_chart(v-if='frame.isSummary')
                  th(
                    v-if='!(isCampaignStatus && isFullScreenMode)'
                    :colspan='frame.summaryColspan'
                  ) {{ frame.summaryTitle }}
                  template(v-for='name in summaryColumnForFullScreen')
                    th.txt.align_right(v-if='frame.isAccClick && name === "click"') {{ numberWithCommas(summary[name]) }}
                    th.txt.align_right(v-else-if='name === "bannerCtr" || name === "couponDownloadRatePerClick" || name === "couponUsingRatePerClick" || name === "roas" || name === "netRoas" || name === "searchCtr"') {{ parseFloat(summary[name]).toFixed(2) }}
                    th.fixed.col_sum(v-else-if='name === "sum"') SUM
                    th.no_border(v-else-if='name === ""')
                    th(v-else-if='name === "uniqueView"') {{ numberWithCommas(summary[name]) }}
                    th(v-else-if='name === "advertiserCount"') -
                    th.txt.align_right(v-else-if='["cost", "bannerImpressionUnique", "bannerClickUnique", "rewardPointCountUnique"].includes(name) && type.includes(GRID_TYPE.CAMPAIGN_STATUS.ALL_ADVERTISER, GRID_TYPE.CAMPAIGN_STATUS.ALL_ADVERTISER_ADMIN, GRID_TYPE.CAMPAIGN_STATUS.PUBLISHER)') -
                    th.txt.align_right(v-else-if='name ==="paybackFee"' v-show="params.needPaybackFee") {{ numberWithCommas(summary[name]) }}
                    th.txt.align_right(v-else) {{ numberWithCommas(summary[name]) }}
                tr(v-for='d in data')
                  template(v-for='name in frame.dataColumn')
                    td.txt.align_center(v-if='isAdmin && type === GRID_TYPE.TOTALLY && name === "date" && searchedDateFormat === "daily"') {{ d[name] }}
                    td.txt.align_center(v-else-if='type === GRID_TYPE.TOTALLY && name === "advertiserCount"')
                      button(type='button' @click='openPopup(name, d["date"], d["click"], d["cost"])') {{ d[name] }}
                    td.txt.align_center(v-else-if='name === "campaignTargetStatus"')
                      template(v-if="d[name] === '타겟' || d[name] === '타겟(제외)'")
                        em.point_txt {{ d[name] }}
                      template(v-else)
                        | {{ d[name] }}
                    td.txt.align_center(v-else-if='name === "status"')
                      span.status_box(:class='getStatusClass(d[name], d["endDate"])') {{ getStatusWord(d[name], d['endDate'], d['startDate'], d['targetFileUploading'], d['campaignDeactiveYN'], d['campaignDeactivePeriods'] ) }}
                    td.txt.align_center(v-else-if='name ===  "campaignStatus"')
                      span.status_box(:class='getStatusClass(d[name], d["endDate"])') {{ d[name] }}
                    td.txt.align_center(v-else-if='name === "couponStatus"') {{ getCouponStatusWord(d[name]) }}
                    td.txt.align_left(v-else-if='name === "advertiserName"') {{ advertiserCodeToName(d['advertiserCode']) }}
                    td.txt.align_right(v-else-if='frame.isAccClickData && name === "click" && d[name] !== ""') {{ numberWithCommas(d[name]) }}
                    td.txt.align_center(v-else-if='name === "uniqueView"') {{ numberWithCommas(d[name]) }}
                    td.txt.align_center(v-else-if='name === "campaignStartDate" || name === "campaignEndDate"') {{ getDate(d[name]) }}
                    td.txt.align_center(v-else-if='name === "startDate" || name === "endDate"') {{ d[name].split(' ')[0] }}
                    td.txt.align_right(v-else-if='(name === "bannerCtr" && d[name] !== "") || name === "couponDownloadRatePerClick" || name === "couponUsingRatePerClick" || name === "roas" || name === "netRoas" || (name === "searchCtr" && d[name] !== "")') {{ parseFloat(d[name]).toFixed(2) }}
                    td.txt.align_left(v-else-if='name === "campaignName"') {{ d[name] }}
                    td.txt.align_center(v-else-if='["advertiserCode", "campaignId", "campaignCode", "campaignApprovalStatus", "date", "couponId", "feeType"].includes(name)') {{ d[name] }}
                    td.txt.align_right(v-else-if='name === "linkedRevenue"')
                      a(v-if='d["campaignId"] && !hasPublisherRoleOnly' @click='openLinkedRevenueDetail(d["campaignId"], d["campaignName"], d["startDate"], d["endDate"], d["coupons"])') {{ numberWithCommas(d[name]) }}
                      template(v-else) {{ numberWithCommas(d[name]) }}
                    td.txt.align_center(v-else-if='type === GRID_TYPE.CAMPAIGN_MANAGEMENT && name === "budget" && d["campaignGroupType"] === "CLO"') -
                    td.txt.align_center(v-else-if='type === GRID_TYPE.CAMPAIGN_MANAGEMENT') {{ numberWithCommas(d[name]) }}
                    td.txt.align_right(v-else-if='name === "budget" && d["campaignGroupType"] === "CLO"') -
                    td.txt.align_right(v-else-if='name ==="paybackFee"' v-show="params.needPaybackFee") {{ numberWithCommas(d[name]) }}
                    td.txt.align_right(v-else) {{ numberWithCommas(d[name]) }}
      .tbl_scroll(
        v-else-if='isPopup'
        style='width: 420px'
      )
        .tbl
          table
            caption
              span
            colgroup
              template(v-for='fw in widths')
                col(:style='{width: `${fw}${frame.widthPostfix}`}')
            thead
              tr
                template(v-for='column in columns')
                  th.txt.align_center(
                    :class='{sort: column.sort}'
                    :rowspan='column.rowspan'
                    :colspan='column.colspan'
                    scope='col')
                    button(v-if='column.sort' @click='sort(column.orderBy)') {{ column.name }}
                    template(v-else) {{ column.name }}
              tr.total_chart
                th(:colspan='frame.summaryColspan') {{ frame.summaryTitle }}
                template(v-for='name in frame.summaryColumn')
                  th.txt.align_center(v-if='name === "date"') {{ summary[name] }}
                  th.txt.align_right(v-else) {{ numberWithCommas(summary[name]) }}
        .tbl
          .tbl_content.scroll_for_popup
            table
              caption
                span
              colgroup
                template(v-for='fw in widths')
                  col(:style='{width: `${fw}${frame.widthPostfix}`}')
              tbody
                tr(v-for='d in data')
                  template(v-for='name in frame.dataColumn')
                    td.txt.align_left(v-if='name === "advertiserName"')
                      template(v-if="hasPublisherRoleOnly")
                        | {{ advertiserCodeToName(d['advertiserCode']) }}
                      template(v-else)
                        button(@click="goToSelectedAdvertiserReport(d['advertiserCode'])") {{ advertiserCodeToName(d['advertiserCode']) }}
                    td.txt.align_center(v-else-if='name === "hour"') {{ getFormattedHour(d[name]) }}
                    td.txt.align_right(v-else) {{ numberWithCommas(d[name]) }}
          .external-scroll_y
            .scroll-element_outer
              .scroll-element_size
              .scroll-element_track
              .scroll-bar
      .tbl_scroll(
        v-else
        :class='scrollableTableClasses'
      )
        .tbl(:class='scrollableTableWrapClasses')
          .tbl_content(:class='scrollableContentClasses')
            .tbl_head
              table
                caption
                  span
                colgroup
                  template(v-for='fw in fixedColumnWidths')
                    col(:style='{width: `${fw}${frame.widthPostfix}`}')
                thead
                  tr
                    template(v-for='column in columns')
                      th.txt.align_center(
                        :class='{sort: column.sort, fixed: column.name === "광고주명" || column.name === "캠페인명", col1: column.name === "광고주명", col2: column.name === "캠페인명" && !isSpecificAdvertiser, col_advertiser_name: column.name === "광고주명", col_height: column.name === "광고주명" || column.name === "캠페인명"}'
                        :rowspan='column.rowspan'
                        :colspan='column.colspan'
                        scope='col'
                      )
                        button(v-if='column.sort' @click='sort(column.orderBy)') {{ column.name }}
                        template(v-else) {{ column.name }}
                  tr(v-if='frame.child')
                    template(v-for='child in frame.child')
                      th.txt.align_center(:class='{sort: child.sort}'
                        :rowspan='child.rowspan'
                        :colspan='child.colspan'
                        scope='col')
                        button(v-if='child.sort' @click='sort(child.orderBy)') {{ child.name }}
                        template(v-else) {{ child.name }}
                tbody(v-if='frame.isSummary')
                  tr.total_chart
                    template(v-for='name in frame.summaryColumn')
                      th.txt.align_right(v-if='(name === "bannerCtr" && summary[name] !== "") || name === "couponDownloadRatePerClick" || name === "couponUsingRatePerClick" || name === "roas" || name === "netRoas" || (name === "searchCtr" && summary[name] !== "")') {{ parseFloat(summary[name]).toFixed(2) }}
                      th.fixed.col_sum(v-else-if='name === "sum"' :class='{is_not_admin: !isSpecificAdvertiser && !isAdmin }') SUM
                      th.no_border(v-else-if='name === ""')
                      th.txt.align_right(v-else-if='["cost", "bannerImpressionUnique", "bannerClickUnique", "rewardPointCountUnique"].includes(name) && type.includes(GRID_TYPE.CAMPAIGN_STATUS.ALL_ADVERTISER, GRID_TYPE.CAMPAIGN_STATUS.ALL_ADVERTISER_ADMIN, GRID_TYPE.CAMPAIGN_STATUS.PUBLISHER)') -
                      th.txt.align_right(v-else-if='name ==="paybackFee"' v-show="params.needPaybackFee") {{ numberWithCommas(summary[name]) }}
                      th.txt.align_right(v-else) {{ numberWithCommas(summary[name]) }}
            .tbl_body(:class='scrollableBodyClasses')
              .tbl_head_left
                table
                  colgroup
                    template(v-for='lw in leftFixedWidths')
                      col(:style='{width: `${lw}${frame.widthPostfix}`}')
                  tbody
                    tr(v-for='d in data')
                      template(v-for='name in leftFixedColumns')
                        td.txt.align_left(v-if='!isSpecificAdvertiser && name === "advertiserName"')
                          template(v-if="hasPublisherRoleOnly")
                            | {{ advertiserCodeToName(d['advertiserCode']) }}
                          template(v-else)
                            button(v-if="isCampaignStatus" type='button' @click='changeAdvertiser(d["advertiserCode"])')
                              span.txt_line2(style="-webkit-box-orient:vertical;") {{ advertiserCodeToName(d['advertiserCode']) }}
                          button(v-else type='button' @click='changeAdvertiser(d["advertiserCode"])') {{ advertiserCodeToName(d['advertiserCode']) }}
                        td.txt.align_left(v-else-if='!isSpecificAdvertiser && (name === "campaignName")')
                          template(v-if="hasPublisherRoleOnly")
                            | {{ d[name] }}
                          template(v-else)
                            button(type='button' @click='changeAdvertiser(d["advertiserCode"])') {{ d[name] }}
                        td.txt.align_left(v-else) {{ d[name] }}
              .tbl_scroll_area.js-scrollbar-external
                table
                  colgroup
                    template(v-for='sw in scrollableWidths')
                      col(:style='{width: `${sw}${frame.widthPostfix}`}')
                  tbody
                    tr(v-for='d in data')
                      template(v-for='name in scrollableColumns')
                        td.txt.align_left(v-if='isSpecificAdvertiser && name === "advertiserName"') {{ advertiserCodeToName(d['advertiserCode']) }}
                        td.txt.align_right(v-else-if='frame.isAccClickData && name === "click" && d[name] !== ""') {{ numberWithCommas(d[name]) }}
                        td.txt.align_center(v-else-if='name === "status"')
                          span.status_box(:class='getStatusClass(d[name], d["endDate"])') {{ getStatusWord(d[name], d['endDate'], d['startDate'], d['targetFileUploading'], d['campaignDeactiveYN'], d['campaignDeactivePeriods']) }}
                        td.txt.align_center(v-else-if='name === "couponStatus"') {{ getCouponStatusWord(d[name]) }}
                        td.txt.align_center(v-else-if='["couponId", "date", "feeType"].includes(name)') {{ d[name] }}
                        td.txt.align_center(v-else-if='name === "startDate" || name === "endDate"') {{ d[name].split(' ')[0] }}
                        td.txt.align_right(v-else-if='(name === "bannerCtr" && d[name] !== "") || name === "couponDownloadRatePerClick" || name === "couponUsingRatePerClick" || name === "roas" || name === "netRoas" || (name === "searchCtr" && d[name] !== "")') {{ parseFloat(d[name]).toFixed(2) }}
                        td.txt.align_left(v-else-if='isSpecificAdvertiser && name === "campaignName"') {{ d[name] }}
                        td.txt.align_right(v-else-if='name === "linkedRevenue"')
                          a(v-if='d["campaignId"] && !hasPublisherRoleOnly' @click='openLinkedRevenueDetail(d["campaignId"], d["campaignName"], d["startDate"], d["endDate"], d["coupons"])') {{ numberWithCommas(d[name]) }}
                          template(v-else) {{ numberWithCommas(d[name]) }}
                        td.txt.align_right(v-else-if='name === "budget" && d["campaignGroupType"] === "CLO"') -
                        td.txt.align_right(v-else-if='name ==="paybackFee"' v-show="params.needPaybackFee") {{ numberWithCommas(d[name]) }}
                        td.txt.align_right(v-else) {{ numberWithCommas(d[name]) }}
          .external-scroll_x
            .scroll-element_outer
              .scroll-element_size
              .scroll-element_track
              .scroll-bar
          .external-scroll_y(v-if='isCampaignStatus')
            .scroll-element_outer
              .scroll-element_size
              .scroll-element_track
              .scroll-bar
    p.note(v-if='type !== GRID_TYPE.TOTALLY && type !== GRID_TYPE.CAMPAIGN_TRANSITION && type !== GRID_TYPE.UNIQUE_VIEW && headType !== "popup" && type !== GRID_TYPE.CAMPAIGN_MANAGEMENT')
      | 배너 노출 수, 배너 클릭 수는 10분 간격으로 업데이트됩니다.
      template(v-if="type !== GRID_TYPE.CAMPAIGN_STATUS.PUBLISHER")
        br
        | 푸시+알림+기타는 ‘유저가 공유한 링크’, ‘Payco 제휴 매체 노출 링크’, ‘알림 및 푸시’, ‘메인 팝업’, ‘캠퍼스’, ‘검색’ 등의 클릭 수가 포함됩니다.
      br
      | 쿠폰 다운로드 수는 발급 취소분이 반영되지 않은 모든 쿠폰 다운로드 수의 합계입니다.
      template(v-if="type !== GRID_TYPE.CAMPAIGN_STATUS.PUBLISHER")
        br
        | 연계매출은 1시간 간격으로 업데이트 됩니다.
      template(v-if="isAdmin")
        br
        | CPS, CPA 캠페인의 경우 광고비(참고용), 연계매출(참고용)이 1분 간격으로 업데이트되며, 연계매출(참고용) 데이터는 CPS 캠페인만 제공됩니다.
    .paging(v-if='type !== GRID_TYPE.HOURLY')
      span.paging_inner
        template(v-for='n in pageNumbers')
          a(v-if='pagination.page !== n'
            @click='moveTo(n)') {{ n }}
          strong(v-else)
            span.blind 현재 페이지
            | {{ n }}
        a.btn_nav.btn_prev_all(
          :disabled='pageGroup === 1'
          :class='{disable: pagination.page === 1, on: pagination.page !== 1}'
          @click='moveTo(1)')
          span 처음 페이지
        a.btn_nav.btn_prev(
          :disabled='pageGroup === 1'
          :class='{disable: pageGroup === 1, on: pageGroup !== 1}'
          @click='moveTo((pageGroup - 2) * pagination.visablePage + 1)')
          span 이전 페이지
        a.btn_nav.btn_next(
          :disabled='pageGroup === lastPageGroup'
          :class='{disable: pageGroup === lastPageGroup, on: pageGroup !== lastPageGroup}'
          @click='moveTo(pageGroup * pagination.visablePage + 1)')
          span 다음 페이지
        a.btn_nav.btn_next_all(
          :disabled='pageGroup === lastPageGroup'
          :class='{disable: pagination.page === lastPage, on: pagination.page !== lastPage}'
          @click='moveTo(lastPage)')
          span 마지막 페이지
      confirm(v-if="showMessage", :confirm="confirm", @yes="showMessage = false")
</template>

<script>
import Confirm from '../campaign/fragments/Confirm'
import { mapGetters } from 'vuex'
import jQuery from 'jquery'
import $ from '@/util.js'

const PER_PAGE = {
  TOTALLY: 10,
  CAMPAIGN_STATUS: 50,
  CAMPAIGN_TRANSITION: 10,
  UNIQUE_VIEW: 30,
  POPUP: 200,
  CAMPAIGN_MANAGEMENT: 50
}

export default {
  props: ['type', 'frame', 'totalCount', 'data', 'summary', 'isAdmin', 'excelUrl', 'isFullScroll', 'headType', 'head',
    'isSpecificAdvertiser', 'searchedDateFormat', 'isFullScreenMode', 'params', 'hasPublisherRoleOnly'],
  name: 'CommonGrid',
  components: {
    Confirm
  },
  created () {
    this.pagination.perPage = this.getPerPageByType()
  },
  mounted () {
    const needScroll = this.headType === 'popup' ||
        this.type === this.GRID_TYPE.CAMPAIGN_STATUS.ALL_ADVERTISER ||
        this.type === this.GRID_TYPE.CAMPAIGN_STATUS.ALL_ADVERTISER_ADMIN ||
        this.type === this.GRID_TYPE.CAMPAIGN_STATUS.SPECIFIC_ADVERTISER ||
        this.type === this.GRID_TYPE.CAMPAIGN_STATUS.SPECIFIC_ADVERTISER_ADMIN ||
        this.type === this.GRID_TYPE.CAMPAIGN_STATUS.PUBLISHER
    if (needScroll) this.activateScroll()
  },
  data () {
    return {
      pagination: {
        page: 1,
        perPage: 10,
        visablePage: this.headType === 'popup' ? 5 : 10
      },
      GRID_TYPE: {
        TOTALLY: 'totally',
        HOURLY: 'hourly',
        BY_ADVERTISER: 'byAdvertiser',
        CAMPAIGN_STATUS: {
          ALL_ADVERTISER: 'campaignStatusAllAdvertiser',
          ALL_ADVERTISER_ADMIN: 'campaignStatusAllAdvertiserAdmin',
          SPECIFIC_ADVERTISER: 'campaignStatusSpecificAdvertiser',
          SPECIFIC_ADVERTISER_ADMIN: 'campaignStatusSpecificAdvertiserAdmin',
          PUBLISHER: 'campaignStatusForPublisher'
        },
        UNIQUE_VIEW: 'uniqueView',
        CAMPAIGN_TRANSITION: 'campaignTransition',
        CAMPAIGN_MANAGEMENT: 'campaignManagement'
      },
      confirm: {
        subTitle: '',
        text: '',
        yes: '',
        no: ''
      },
      showMessage: ''
    }
  },
  methods: {
    getPerPageByType () {
      switch (this.type) {
        case this.GRID_TYPE.TOTALLY:
          return PER_PAGE.TOTALLY
        case this.GRID_TYPE.UNIQUE_VIEW:
          return PER_PAGE.UNIQUE_VIEW
        case this.GRID_TYPE.HOURLY:
        case this.GRID_TYPE.BY_ADVERTISER:
          return PER_PAGE.POPUP
        case this.GRID_TYPE.CAMPAIGN_TRANSITION:
          return PER_PAGE.CAMPAIGN_TRANSITION
        case this.GRID_TYPE.CAMPAIGN_STATUS.ALL_ADVERTISER:
        case this.GRID_TYPE.CAMPAIGN_STATUS.ALL_ADVERTISER_ADMIN:
        case this.GRID_TYPE.CAMPAIGN_STATUS.SPECIFIC_ADVERTISER:
        case this.GRID_TYPE.CAMPAIGN_STATUS.SPECIFIC_ADVERTISER_ADMIN:
        case this.GRID_TYPE.CAMPAIGN_STATUS.PUBLISHER:
          return PER_PAGE.CAMPAIGN_STATUS
        case this.GRID_TYPE.CAMPAIGN_MANAGEMENT:
          return PER_PAGE.CAMPAIGN_MANAGEMENT
        default:
          return this.data.length
      }
    },
    sort (orderBy) {
      this.$emit('sort', {
        type: this.type,
        orderBy: orderBy
      })
    },
    moveTo (page) {
      const lastPage = parseInt(this.totalCount / this.pagination.perPage, 10) +
          (this.totalCount % this.pagination.perPage === 0 ? 0 : 1)
      if (page < 1 || page > lastPage) return
      this.pagination.page = page
      this.$emit('moveTo', {
        type: this.type,
        page: page
      })
    },
    openPopup (name, date, click, cost) {
      this.$emit('openPopup', {
        name: name,
        date: date,
        click: click,
        cost: cost
      })
    },
    formatNumber (num) {
      return $.formatNumber(num, 2)
    },
    advertiserCodeToName (code) {
      const advertiser = this.advertiserList.filter(a => a.advId === code)[0]
      if (!advertiser) return '광고주명 없음'
      return advertiser.advName
    },
    checkCampaignDeactiveNow (campaignDeactivePeriods) {
      return !campaignDeactivePeriods.every(function (item) {
        return item.deactiveStartDateTime > Date.now() || Date.now() > item.deactiveEndDateTime
      })
    },
    getStatusWord (status, endDate, startDate, isTargetFileUploading, campaignDeactiveYN, campaignDeactivePeriods) {
      switch (status) {
        case 'CREATING':
          return '임시저장'
        case 'WAIT_APPROVAL':
          return '심사중'
        case 'ACTIVE': {
          if (new Date(startDate).getTime() > Date.now()) {
            return '작동(대기)'
          } else if (campaignDeactiveYN && this.checkCampaignDeactiveNow(campaignDeactivePeriods)) {
            return '작동(중지)'
          } else if (isTargetFileUploading) {
            return '작동(업로드)'
          } else {
            return '작동'
          }
        }
        case 'INACTIVE':
          return '종료'
        default: {
          if (new Date(endDate).getTime() < new Date().getTime()) return '종료'
          switch (status) {
            case 'EVNT_WAIT':
            case 'EVNT_RJCT':
            case 'EVNT_CNCL':
              return '심사중'
            case 'EVNT_CMPL':
            case 'EVNT_TARGET':
            case 'EVNT_EXCEPT':
            case 'EVNT_BETA':
              return '작동'
          }
        }
      }
    },
    getStatusClass (status, date) {
      if (this.type === this.GRID_TYPE.CAMPAIGN_MANAGEMENT) {
        switch (status) {
          case '임시저장':
            return 'save_storage'
          case '심사중':
            return 'checking'
          case '작동':
            return 'work'
          case '종료':
            return 'end'
        }
        return '-'
      }
      switch (status) {
        case 'CREATING':
          return 'save_storage'
        case 'WAIT_APPROVAL':
          return 'checking'
        case 'ACTIVE':
          return 'work'
        case 'INACTIVE':
          return 'end'
        default: {
          if (new Date(date).getTime() < new Date().getTime()) return 'end'
          switch (status) {
            case 'EVNT_WAIT':
            case 'EVNT_RJCT':
            case 'EVNT_CNCL':
              return 'checking'
            case 'EVNT_CMPL':
            case 'EVNT_TARGET':
            case 'EVNT_EXCEPT':
            case 'EVNT_BETA':
              return 'work'
          }
        }
      }
    },
    getDate (dateTime) {
      const date = new Date(dateTime)
      return date <= new Date('1980-01-01') ? '-' : $.formatDate(date)
    },
    getCouponStatusWord (status) {
      switch (status) {
        case 'WAIT':
          return '발행 대기'
        case 'STOP':
          return '발급 종료'
        case 'EXPIRE':
          return '기간 만료'
        case 'EXCEED_AMT':
          return '수량 만료'
        case 'PUBLISH':
          return '발행중'
        case '-':
          return '-'
      }
    },
    getFormattedHour (hour) {
      return `${this.formatNumber(hour)}:00 ~ ${this.formatNumber(hour + 1)}:00`
    },
    numberWithCommas (x) {
      if (Number.isInteger(x)) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x
    },
    downloadExcel () {
      this.$emit('download', this.type)
    },
    changeAdvertiser (advertiserCode) {
      this.$emit('changeAdvertiser', advertiserCode)
    },
    resetPage () {
      this.pagination.page = 1
      this.pagination.perPage = this.getPerPageByType()
    },
    activateScroll () {
      const targetExternal = this.headType === 'popup' ? '.scroll_for_popup' : '.js-scrollbar-external'
      const scrollX = this.headType === 'popup' ? '.scroll_x_for_popup' : '.external-scroll_x'
      const scrollY = this.headType === 'popup' ? '.scroll_y_for_popup' : '.external-scroll_y'
      const callback = this.headType === 'popup'
        ? null
        : () => {
          document.querySelector(targetExternal).firstElementChild.addEventListener('scroll', () => {
            document.querySelector('.tbl_head').scrollLeft = document.querySelector(targetExternal).firstElementChild.scrollLeft
            document.querySelector('.tbl_head_left').scrollTop = document.querySelector(targetExternal).firstElementChild.scrollTop
          })
        }
      jQuery(targetExternal).scrollbar({
        autoScrollSize: true,
        scrollx: jQuery(scrollX),
        scrolly: jQuery(scrollY),
        onInit: callback
      })
    },
    openFullScreen () {
      if (this.isFullScreenMode) this.$emit('closeFullScreen')
      this.$emit('openFullScreen', {
        name: 'FullScreenGrid',
        params: {
          reportType: 'campaign-status',
          type: this.type,
          params: this.params,
          frame: this.frame,
          totalCount: this.totalCount,
          data: this.data,
          summary: this.summary,
          isAdmin: this.isAdmin,
          headType: this.headType,
          head: this.head,
          isSpecificAdvertiser: this.isSpecificAdvertiser
        }
      })
    },
    formatDate (date) {
      return $.formatDate(date)
    },
    openLinkedRevenueDetail (campaignId, campaignName, startDate, endDate, coupons) {
      this.$emit(
        'openLinkedRevenueDetail',
        {
          id: campaignId,
          name: campaignName,
          startDate: new Date(startDate).getTime(),
          endDate: new Date(endDate).getTime(),
          coupons: coupons
        }
      )
    },
    goToSelectedAdvertiserReport (advertiserCode) {
      this.$emit('goToSelectedAdvertiserReport', {
        advertiserCode: advertiserCode
      })
    }
  },
  computed: {
    ...mapGetters(['advertiserList', 'advertiser']),
    lastPage () {
      const lastPage = Math.ceil(this.totalCount / this.pagination.perPage)
      return lastPage === 0 ? 1 : lastPage
    },
    pageGroup () {
      return Math.ceil(this.pagination.page / this.pagination.visablePage)
    },
    lastPageGroup () {
      return Math.ceil(this.lastPage / this.pagination.visablePage)
    },
    pageNumbers () {
      const start = (this.pageGroup - 1) * this.pagination.visablePage + 1
      const end = Math.min(start + this.pagination.visablePage - 1, this.lastPage)
      const numbers = []
      for (let i = start; i <= end; i += 1) {
        numbers.push(i)
      }
      return numbers
    },
    summaryColumnForFullScreen () {
      const copied = JSON.parse(JSON.stringify(this.frame.summaryColumn))
      if (this.isFullScreenMode && (this.GRID_TYPE.CAMPAIGN_STATUS.ALL_ADVERTISER || this.type === this.GRID_TYPE.CAMPAIGN_STATUS.ALL_ADVERTISER_ADMIN || this.GRID_TYPE.CAMPAIGN_STATUS.PUBLISHER)) {
        copied.splice(1, 0, '')
      }
      return copied
    },
    isStaticTable () {
      return this.type === this.GRID_TYPE.TOTALLY || this.type === this.GRID_TYPE.UNIQUE_VIEW || this.type === this.GRID_TYPE.CAMPAIGN_TRANSITION
    },
    isPopup () {
      return this.headType === 'popup'
    },
    isCampaignStatus () {
      return this.type === this.GRID_TYPE.CAMPAIGN_STATUS.ALL_ADVERTISER ||
          this.type === this.GRID_TYPE.CAMPAIGN_STATUS.ALL_ADVERTISER_ADMIN ||
          this.type === this.GRID_TYPE.CAMPAIGN_STATUS.SPECIFIC_ADVERTISER ||
          this.type === this.GRID_TYPE.CAMPAIGN_STATUS.SPECIFIC_ADVERTISER_ADMIN ||
          this.type === this.GRID_TYPE.CAMPAIGN_STATUS.PUBLISHER
    },
    isCampaignManagement () {
      return this.type === this.GRID_TYPE.CAMPAIGN_MANAGEMENT
    },
    tableWrapClasses () {
      return {
        ads_chart: !this.isCampaignManagement,
        full_scroll: this.isCampaignStatus,
        custom_coupon: this.type === this.GRID_TYPE.UNIQUE_VIEW,
        camp_trend: this.type === this.GRID_TYPE.CAMPAIGN_TRANSITION,
        medium_height: this.type === this.GRID_TYPE.BY_ADVERTISER,
        full_height: this.type === this.GRID_TYPE.HOURLY
      }
    },
    titClass () {
      return {
        stit: this.type === this.GRID_TYPE.TOTALLY || this.isCampaignManagement,
        tit: this.isCampaignStatus || this.type === this.GRID_TYPE.UNIQUE_VIEW,
        pop_tit: this.isPopup
      }
    },
    scrollableTableClasses () {
      return {
        tbl_scroll_all: this.isCampaignStatus && !this.isFullScreenMode,
        tbl_scroll_all2: (this.type === this.GRID_TYPE.CAMPAIGN_STATUS.ALL_ADVERTISER_ADMIN || this.type === this.GRID_TYPE.CAMPAIGN_STATUS.PUBLISHER) && !this.isFullScreenMode,
        tbl_scroll_all3: (this.type === this.GRID_TYPE.CAMPAIGN_STATUS.SPECIFIC_ADVERTISER || this.type === this.GRID_TYPE.CAMPAIGN_STATUS.SPECIFIC_ADVERTISER_ADMIN) && !this.isFullScreenMode,
        has_sum: this.type === this.GRID_TYPE.CAMPAIGN_STATUS.ALL_ADVERTISER || this.type === this.GRID_TYPE.CAMPAIGN_STATUS.ALL_ADVERTISER_ADMIN || this.type === this.GRID_TYPE.CAMPAIGN_STATUS.PUBLISHER,
        tbl_scroll_x: this.isCampaignManagement && !this.isFullScreenMode,
        tbl_scroll_x2: this.isCampaignManagement && this.isSpecificAdvertiser && !this.isFullScreenMode
      }
    },
    scrollableTableWrapClasses () {
      return {
        'scrollbar-external_wrapper': this.isCampaignManagement
      }
    },
    scrollableContentClasses () {
      return {
        'scrollbar-external': this.isCampaignManagement
      }
    },
    scrollableBodyClasses () {
      return {
        'scrollbar-external': this.isCampaignStatus
      }
    },
    fixingSize () {
      return this.isSpecificAdvertiser ? 1 : 2
    },
    fixedColumnWidths () {
      let fixed = this.widths.slice(0, this.fixingSize)
      const scrollable = this.widths.slice(this.fixingSize)
      if (this.fixingSize === 2) {
        const add = (a, b) => a + b
        fixed = [fixed.reduce(add)]
      }
      return fixed.concat(scrollable)
    },
    leftFixedWidths () {
      return this.widths.slice(0, this.fixingSize)
    },
    scrollableWidths () {
      return this.widths.slice(this.fixingSize)
    },
    leftFixedColumns () {
      return this.frame.dataColumn.slice(0, this.fixingSize)
    },
    scrollableColumns () {
      return this.frame.dataColumn.slice(this.fixingSize)
    },
    widths () {
      if (!this.params.needPaybackFee) {
        const idx = this.frame.dataColumn.findIndex(it => it === 'paybackFee')
        return this.frame.widths.filter((value, i) => i !== idx)
      }
      return this.frame.widths
    },
    columns () {
      return this.frame.columns
        .filter(it => typeof it.invisible === 'function' ? !it.invisible(this.params) : !it.invisible)
    }
  },
  watch: {
    advertiser: function (newVal, oldVal) {
      const needScroll = this.headType === 'popup' ||
          this.type === this.GRID_TYPE.CAMPAIGN_STATUS.ALL_ADVERTISER ||
          this.type === this.GRID_TYPE.CAMPAIGN_STATUS.ALL_ADVERTISER_ADMIN ||
          this.type === this.GRID_TYPE.CAMPAIGN_STATUS.SPECIFIC_ADVERTISER ||
          this.type === this.GRID_TYPE.CAMPAIGN_STATUS.SPECIFIC_ADVERTISER_ADMIN
      if (needScroll) this.$nextTick().then(this.activateScroll)
    },
    data: function (n, o) {
      this.$forceUpdate()
    },
    summary: function (n, o) {
      this.$forceUpdate()
    }
  }
}
</script>
