import $ from '@/util.js'
import store from '@/store'
import router from '@/router'
import _ from '@/partial'

const loginUrl = 'https://' + process.env.LOGIN_HOST + '/login.nhn?serviceProviderCode=MARKETING&nextUrl='
const logoutUrl = 'https://' + process.env.LOGIN_HOST + '/logout.nhn?serviceProviderCode=MARKETING&nextUrl='
const marketingUrl = 'https://' + process.env.GNB_HOST
const tfaHash = '/tfa?nextUrl='
const fullScreenHash = '/#/full-screen'

export default {
  handle (success, fail, requestLocation) {
    return (response) => {
      $.match(response.body)
        .case(res => res.header.isSuccessful || res.header.resultCode === 0, _(success, response.body.body))
        .case(res => res.header.resultCode === 302, _(_.set, window.location, 'href', response.body.body.location + encodeURIComponent(location.href)))
        .case(requestLocation !== location.hash, _.identity)
        .default(
          _.pipe(
            _.tap((res) => _.isFunction(fail) && fail(res.header)),
            (res) => store.commit('setError', {
              id: Date.now(),
              message: res.body.exception,
              hadshown: false
            })
          )
        )
    }
  },
  handleBlob (response, success, fail) {
    if (response.status === 200) {
      if (response.body === null) {
        response.blob()
          .then(success)
      } else {
        fail(response.body.header.resultMessage)
      }
    } else {
      // eslint-disable-next-line no-console
      console.error(response)
    }
  },
  error (response) {
    $.match(response)
      .case(
        r => r.status === 401 && r.body.message === 'UnAuthorized: 페이코 인증이 필요합니다.'
        , _(_.set, window.location, 'href', loginUrl + encodeURIComponent(location.href))
      )
      .case(
        r => r.status === 401 && r.body.message === 'UnAuthorized: 2차 인증이 필요합니다.' && !window.location.href.includes('/#/tfa?nextUrl=')
        , () => router.replace(tfaHash + encodeURIComponent(window.location.hash.substring(1)))
      )
      .case(
        r => r.status === 401 && r.body.message === 'UnAuthorized: 재로그인이 필요합니다.'
        , () => store.commit('pushConfirm', {
          text: '현재 브라우저에서 CLO센터를 이용하시려면 재로그인이 필요합니다. 재로그인 시 다른 브라우저에서 CLO센터의 접속은 해제됩니다. 재로그인 하시겠습니까?',
          yes: '재로그인 하기',
          onYes: () => {
            const reportHash = store.getters.fullScreenType === 'campaignManagement' ? '/#/campaign' : '/#/campaign'
            const nextUrl = window.location.href.includes(fullScreenHash) ? encodeURIComponent(process.env.MAIN_URI + reportHash) : encodeURIComponent(location.href)
            window.location.href = logoutUrl + nextUrl
          },
          no: '다음에 이용하기',
          onNo: () => {
            window.location.href = marketingUrl
          }
        })
      )
      .case(r => r.status === 403, _(_.set, window.location, 'href', process.env.MAIN_URI))
      .case(r => r.status === 500, () => {
        // eslint-disable-next-line no-console
        console.error('Internal Server Error')
      })
      .default(() => {
        // eslint-disable-next-line no-console
        console.error(response)
      })
    if (store.getters.commonLoading) store.commit('setCommonLoadingOff')
  }
}
