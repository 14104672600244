<template lang="pug">
.ly_pop.ly_pop_wrap.ly_pop17.dropdown_in_ly.new_partner
  .ly_pop_layer
    .ly_pop_cont
      h1.pop_tit 가맹점번호 신규 등록
      .budget_form
        .form
          table
            caption
              span 가맹점번호
            colgroup
              col(style='width:75px')
              col
            tbody
              tr
                th(scope='row')
                  span.lbl 가맹점명
                td
                  .inp_area
                    p.txt_des {{ cloMerchantName }}
              tr
                th.valign_top(scope='row')
                  label.lbl
                    | PG사
                    em.required
                      span.blind 필수
                td
                  .sel_cont(:class='{"has_error" : selectedPgCompany == null}')
                    // [D] 목록 레이어 노출시 .sel에 .on 클래스 추가
                    .sel(style='width:234px',
                      :class='{"on" : isOpenedDropdownPg}',
                      v-click-outside="clickOutsidePg")
                      // [D] dropdown의 항목이 선택 시 a 태그에 "selected"클래스를 추가
                      a(@click='isOpenedDropdownPg = !isOpenedDropdownPg',
                        :class='{"selected" : selectedPgCompany !== null}')
                        | {{ selectedPgCompany !== null? selectedPgCompany.name : 'PG사를 선택해주세요.' }}
                        span.ic_arrow_lg
                      ul
                        li
                          .dropdown_wrap.scrollbar-inner
                            ul.sch_lst
                              li(v-for='item in pgCompanyList')
                                a(@click='selectPgCompany(item)') {{ item.name }}
                    p.txt_error PG사는 필수 입력값입니다.
              tr
                th.valign_top(scope='row')
                  label.lbl_type4
                    | 등록방법
                    em.required
                      span.blind 필수
                td
                  .inp_area
                    ul.lst_rdo
                      li.rdo
                        span.inp_radio
                          // [D] 선택 시 .is_checked 추가
                          input#radio_input.inp_rdo(type='radio'
                            name='rdo5' value=''
                            v-model='radioValue')
                          label.rdo_control_label(for='radio_input')
                            span.ico_rdo
                            | 직접등록
                      li.rdo
                        span.inp_radio
                          // [D] 선택 시 .is_checked 추가
                          input#radio_bulk.inp_rdo(type='radio'
                            name='rdo5' value='bulk'
                            v-model='radioValue')
                          label.rdo_control_label(for='radio_bulk')
                            span.ico_rdo
                            | 벌크등록
                  .reg_bulk(v-if='radioValue == "bulk"')
                    // [D] 파일 업로드 버튼 및 양식 다운로드
                    .addfile_wrap
                      span.inp_file
                        span.txt_file 파일업로드
                        input(type='button',
                          @click='selectCsvFile')
                      a.download_file_form(href='/static/csv/clo_merchant_number_sample.csv') 파일양식다운로드
                    // [D] 파일 업로드 완료 시
                    .bx_add_file
                      ul.lst_einfo
                        li(v-if='csvFile != null')
                          p.file_name
                            a.txt_link(href='#') {{ csvFile.name }}
                          button.btn_del_file(type='button', @click='csvFile = null')
                            span.blind 첨부파일 삭제
                    // [D] 유의사항
                    .note_list
                      strong 파일업로드 유의사항
                      ul
                        li - CSV UTF-8 형식의 파일만 허용됩니다.
                        li - 첫 행은 유지하고 두번째 행부터 입력해주세요.
                        li - 가맹점번호는 30byte 까지 입력 가능합니다.
                        li - 중복된 행은 한 번만 등록됩니다.
                        li
                          | - 잘못 입력된 값은 등록되지 않습니다.
                          br
                          |  (빈 셀, 잘못된 결제사ID, 가맹점번호 30byte 초과 시 등)
              tr(v-if='radioValue == ""')
                th.valign_top(scope='row')
                  label.lbl
                    | 결제사
                    em.required
                      span.blind 필수
                td
                  .sel_cont(:class='{"has_error" : selectedCloPayment == null}')
                    // [D] 목록 레이어 노출시 .sel에 .on 클래스 추가
                    .sel(style='width:234px',
                      :class='{"on" : isOpenedDropdownPa}',
                      v-click-outside="clickOutsidePa")
                      // [D] dropdown의 항목이 선택 시 a 태그에 "selected"클래스를 추가
                      a(@click='isOpenedDropdownPa = !isOpenedDropdownPa', :class='{"selected" : selectedCloPayment != null}')
                        | {{ selectedCloPayment != null? selectedCloPayment.cloPaymentName : '결제사를 선택해주세요.' }}
                        span.ic_arrow_lg
                      ul
                        li
                          .dropdown_wrap.scrollbar-inner
                            ul.sch_lst
                              li(v-for='item in cloPaymentList')
                                a(@click='selectCloPayment(item)') {{ item.cloPaymentName }}
                    p.txt_error 결제사는 필수 입력값입니다.
              tr(v-if='radioValue == ""')
                th.valign_top(scope='row')
                  label.lbl(for='input_pg_merchant_number')
                    | 가맹점번호
                    em.required
                      span.blind 필수
                td
                  .inp_area(:class='{"has_error" : !isValidPgMerchantNumber}')
                    .inp_bx(style='width:194px')
                      // [D] input에 입력된 내용 있으면 .txt_lbl 숨김 처리
                      span.txt_lbl(v-if='pgMerchantNumber === ""') 가맹점번호를 입력해주세요.
                      input#input_pg_merchant_number(type='text', @input='inputPgMerchantNumber')
                      // [D] input에 focus와 입력값이 존재할때 "on_typing" 클래스 추가
                    span.txt_count {{`${pgMerchantNumberLength}/${MAX_LENGTH_CLO_MERCHANT_NUMBER}`}}
                    p.txt_error 가맹점번호는 필수 입력값입니다.
    .ly_btn
      button.btn.btn_bl_gray3(type='button' @click='closeModal()') 취소
      button.btn.btn_bl(type='button',
        :class='{disabled: !isValidAll}', :disabled="!isValidAll",
        @click='preRegister()') 등록
    button.btn_close(type='button' @click='closeModal()')
      span.blind 닫기
    single-text-notice-confirm(
      v-show="popup.showModal.confirm"
      :text="popup.text"
      @yes="popup.yesFunc"
      @no="popup.showModal.confirm = false")
    single-text-notice-popup(
      v-show="popup.showModal.notice"
      :text="popup.text"
      @yes="popup.yesFunc")
</template>

<script>
import $ from '@/util.js'
import cloPaymentApi from '@/api/clo/clo-payment.js'
import cloMerchantApi from '@/api/clo/clo-merchant.js'
import SingleTextNoticePopup from '@/components/clo/common/SingleTextNoticePopup'
import SingleTextNoticeConfirm from '@/components/clo/common/SingleTextNoticeConfirm'
import FileManager from '@/components/common/file-manager'
import { mapMutations } from 'vuex'

export default {
  props: ['cloMerchantSeq', 'cloMerchantName'],
  name: 'CloMerchantNumberForm',
  components: { SingleTextNoticePopup, SingleTextNoticeConfirm },
  data: function () {
    return {
      MAX_LENGTH_CLO_MERCHANT_NUMBER: 30,
      cloPaymentList: [],
      pgCompanyList: [],
      selectedPgCompany: null,
      selectedCloPayment: null,
      pgMerchantNumber: '',
      isOpenedDropdownPg: false,
      isOpenedDropdownPa: false,
      radioValue: '',
      csvFile: null,
      popup: {
        showModal: {
          notice: false,
          confirm: false
        },
        yesFunc: () => { },
        text: ''
      }
    }
  },
  mounted () {
    this.fetchCloPgCompanies()
    this.fetchCloPaymentList()
  },
  methods: {
    ...mapMutations(['pushToast']),
    fetchCloPgCompanies: function () {
      cloMerchantApi.getCloUsedPgCompanies(this.cloMerchantSeq, ({ result }) => { this.pgCompanyList = result })
    },
    fetchCloPaymentList: function () {
      cloPaymentApi.getSimpleCloPaymentList(({ cloPaymentList }) => {
        this.cloPaymentList = cloPaymentList.filter(i => i.cloPaymentType !== 'PAYCO')
      })
    },
    preRegister: function () {
      this.popup.text = '등록하시겠습니까?'
      this.popup.showModal.confirm = true
      this.popup.yesFunc = () => { this.radioValue === 'bulk' ? this.bulk() : this.register() }
    },
    register: function () {
      cloMerchantApi.registerCloMerchantNumber({
        cloMerchantSeq: this.cloMerchantSeq,
        pgCompanyCode: this.selectedPgCompany.code,
        cloPaymentCode: this.selectedCloPayment.cloPaymentCode,
        pgMerchantNumber: this.pgMerchantNumber
      }, body => {
        if (body.code === 500) {
          this.popupMessage(body.msg)
          return
        }
        this.popupRegisterComplete()
      }, body => {
        this.popupMessage(body.resultMessage)
      })
    },
    bulk: function () {
      cloMerchantApi.bulkCloMerchantNumber({
        cloMerchantSeq: this.cloMerchantSeq,
        pgCompanyCode: this.selectedPgCompany.code,
        csvFile: this.csvFile.data
      }, body => {
        if (body.code === 500) {
          this.popupMessage(body.msg)
          return
        }
        this.popupRegisterComplete()
      }, body => {
        this.popupMessage(body.resultMessage)
      })
    },
    popupRegisterComplete: function () {
      this.popup.showModal.confirm = false
      this.popup.showModal.notice = true
      this.popup.text = '등록되었습니다.'
      this.popup.yesFunc = () => this.closeModal(true)
    },
    popupMessage: function (msg) {
      this.popup.showModal.confirm = false
      this.popup.showModal.notice = true
      this.popup.text = msg.replace('\n', '<br>')
      this.popup.yesFunc = () => { this.popup.showModal.notice = false }
    },
    inputPgMerchantNumber: function ({ target }) {
      if ($.getUTF8Length(target.value) <= this.MAX_LENGTH_CLO_MERCHANT_NUMBER) {
        this.pgMerchantNumber = target.value
      } else {
        target.value = this.pgMerchantNumber
      }
    },
    selectPgCompany: function (pgCompany) {
      this.isOpenedDropdownPg = false
      this.selectedPgCompany = pgCompany
    },
    selectCloPayment: function (cloPayment) {
      this.isOpenedDropdownPa = false
      this.selectedCloPayment = cloPayment
    },
    selectCsvFile: function () {
      if (this.csvFile != null) {
        this.popupMessage('파일 삭제 후, 다시 업로드해주세요.')
        return
      }
      FileManager.openFile().then(file => {
        this.csvFile = file
      })
    },
    clickOutsidePg: function () {
      this.isOpenedDropdownPg = false
    },
    clickOutsidePa: function () {
      this.isOpenedDropdownPa = false
    },
    closeModal: function (refresh = false) {
      this.$emit('showModal', false, refresh)
    }
  },
  computed: {
    pgMerchantNumberLength () {
      return $.getUTF8Length(this.pgMerchantNumber)
    },
    isValidPgMerchantNumber () {
      return this.pgMerchantNumber !== '' && this.pgMerchantNumberLength <= this.MAX_LENGTH_CLO_MERCHANT_NUMBER
    },
    isValidAll () {
      if (this.radioValue === 'bulk') {
        return this.selectedPgCompany != null && this.csvFile != null
      } else {
        return this.isValidPgMerchantNumber &&
          this.selectedCloPayment != null &&
          this.selectedPgCompany != null
      }
    }
  },
  directives: {
    'click-outside': $.clickOutside()
  }
}
</script>

<style scoped></style>
