function setCookie (cname, cvalue, exdays = 1) {
  const d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  const expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

function getCookie (cname) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

function setItemToSessionStorage (name, value) {
  window.sessionStorage.setItem(name, value)
}

function getItemFromSessionStorage (name) {
  return window.sessionStorage.getItem(name)
}

export { setCookie, getCookie, setItemToSessionStorage, getItemFromSessionStorage }
