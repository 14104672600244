<template lang="pug">
.wrap.secondary_auth
  header.header_logo
    h1.logo_area
      span.logo_payco PAYCO
      span.txt
        | PAYCO 아이디로&nbsp
        em CLO센터
        | 를 이용합니다.
  main.container
    .content
      .tit_area
        p.sub_txt
          em 로그인하신 계정
          | 으로 인증번호가 발송되었으며
          br
          |  2차 인증 후 CLO센터 이용 가능합니다.
      .inp_wrap
        .inp_group
          // [D] 인증시간 초과 시 .inp_bx 에 .disabled 클래스 추가하여 색 변경
          span.inp_bx.time_add(:class="{disabled: !hasTime}")
            span.inp_txt
              // [D] 인풋입력시 inp_placeholder class is_disabled 추가
              span.inp_placeholder(v-show="!authenticationCode" :class="{is_disabled: !hasTime}") 6자리 (예. 123456)
              // [D] 인증시간 초과 시 input 에 disabled 속성 추가하여 비활성화
              input.inp(
                type='text'
                title='인증번호 6자리 숫자 입력'
                :disabled='!hasTime'
                :value='authenticationCode'
                @input='inputAuthenticationCode'
                @keyup.enter="checkAuthenticationCode"
              )
              // [D] .txt_point 에 남은 인증 시간 count
              span.time_bx
                em.txt_point {{timeRemaining}}
          // [D] 인증시간 초과 시 .btn 에 .is_active 클래스 추가하여 색 변경
          button.btn(type='button' :class='{is_active: !hasTime}' :style='{cursor: hasTime ? "default" : "pointer"}' @click='sendAuthenticationCode' :disabled='hasTime') 인증번호 재발송
      .btn_area
        button.btn.btn_full.btn_point(type='submit' @click="checkAuthenticationCode") 확인
</template>

<script>
import { mapMutations } from 'vuex'
import userApi from '@/api/account'
import store from '@/store'

export default {
  name: 'TwoFactorAuthentication',
  data () {
    return {
      limit: 0,
      interval: null,
      authenticationCode: '',
      authenticationCodeCorrect: false
    }
  },
  props: ['nextUrl'],
  computed: {
    timeRemaining () {
      const date = new Date(this.limit)
      const seconds = date.getSeconds() >= 10 ? date.getSeconds() : '0' + date.getSeconds()
      return `${date.getMinutes()}:${seconds}`
    },
    hasTime () {
      return this.limit > 0
    }
  },
  methods: {
    ...mapMutations(['pushConfirm', 'setIsNeedTwoFactorAuthentication']),
    startTimer () {
      this.limit = 5 * 60 * 1000
      this.interval = setInterval(() => { this.limit -= 1000 }, 1000)
    },
    inputAuthenticationCode (e) {
      this.authenticationCode = e.target.value = e.target.value.replace(/\D/g, '').substring(0, 6)
    },
    sendAuthenticationCode () {
      userApi.postTwoFactorAuthenticationCode(({ result: { code, message } }) => {
        switch (code) {
          case 0:
            this.startTimer()
            break
          case 1001:
            this.startTimer()
            this.showError(message)
            break
          case 1002:
          case 1003:
            this.pushConfirm({ text: message, yes: '확인' })
            break
        }
      })
    },
    checkAuthenticationCode () {
      if (!this.hasTime) {
        this.pushConfirm({ text: '입력 시간을 초과했습니다. 인증번호 재발송 버튼을 통해 인증번호를 발송해주세요.', yes: '확인', onEnter: 'yes' })
        return
      }
      userApi.deleteTwoFactorAuthenticationCode(this.authenticationCode, ({ result: { success, message } }) => {
        this.authenticationCodeCorrect = success
        this.pushConfirm({
          text: message,
          yes: '확인',
          onYes: () => {
            if (this.authenticationCodeCorrect) {
              this.setIsNeedTwoFactorAuthentication(false)
              this.$router.replace(this.nextUrl, () => {
              })
            }
          },
          onEnter: 'yes'
        })
      })
    },
    checkLeave (event) {
      if (this.authenticationCodeCorrect) return
      // 표준에 따라 기본 동작 방지
      event.preventDefault()
      // Chrome에서는 returnValue 설정이 필요함
      const alertMessage = '화면을 이탈하시는 경우 인증 절차가 취소되며, 새로고침 하시는 경우 새로운 인증번호가 발송됩니다.'
      event.returnValue = alertMessage
      return alertMessage
    },
    showError (message) {
      store.commit('setError', {
        id: Date.now(),
        message: message,
        hadshown: false
      })
    }
  },
  created () {
    this.sendAuthenticationCode()
  },
  mounted () {
    this.setIsNeedTwoFactorAuthentication(true)
    window.addEventListener('beforeunload', this.checkLeave)
  },
  beforeDestroy () {
    clearInterval(this.interval)
    window.removeEventListener('beforeunload', this.checkLeave)
  },
  beforeRouteLeave (to, from, next) {
    this.authenticationCodeCorrect
      ? next()
      : this.pushConfirm({
        text: '화면을 이탈하시는 경우 인증 절차가 취소되며, 새로고침 하시는 경우 새로운 인증번호가 발송됩니다.',
        yes: '확인',
        onYes: () => next(),
        no: '취소',
        onNo: () => next(false)
      })
  },
  watch: {
    limit () {
      if (this.limit < 1000) {
        clearInterval(this.interval)
        this.authenticationCode = ''
        this.pushConfirm({ text: '입력 시간을 초과했습니다. 인증번호 재발송 버튼을 통해 인증번호를 발송해주세요.', yes: '확인', onEnter: 'yes' })
      }
    }
  }
}
</script>

<style scoped></style>
