import req from '@/api/http-request'

const CLO_MERCHANT_URI = {
  GET_CLO_SIMPLE_MERCHANTS_ALL: 'clo/simple-merchants-all',
  GET_CLO_MERCHANT: merchantSeq => `clo/merchant/${merchantSeq}`,
  GET_CLO_MERCHANT_CATEGORIES: 'clo/merchant-categories',
  GET_MERCHANT_PAGING: 'clo/merchant/paging',
  GET_MERCHANT_NUMBER_PAGING: 'clo/merchant-number/paging',
  GET_CHART_VALUES: (cloMerchantSeq) => `clo/merchant/chart-values?cloMerchantSeq=${cloMerchantSeq}`,
  GET_SEARCH_MERCHANT_INFO: (merchantCode) => `clo/merchant/search-merchant-info?merchantCode=${merchantCode}`,
  GET_PG_COMPANIES: 'clo/merchant/pgCompanies',
  GET_USED_PG_COMPANIES: (cloMerchantSeq) => `clo/merchant/usedPgCompanies?cloMerchantSeq=${cloMerchantSeq}`,
  GET_LINKED_CLO_MERCHANT_LIST: (advertiserCode) => `clo/merchant/linked-list?advertiserCode=${advertiserCode}`,
  EXCEL_CLO_MERCHANT_NUMBER: 'clo/merchant-number/excel',
  REGISTER_CLO_MERCHANT: 'clo/merchant/register',
  REGISTER_CLO_MERCHANT_NUMBER: 'clo/merchant-number/register',
  BULK_CLO_MERCHANT_NUMBER: 'clo/merchant-number/bulk',
  MODIFY_CLO_MERCHANT: (cloMerchantSeq) => `clo/merchant/${cloMerchantSeq}`,
  MODIFY_CLO_MERCHANT_NUMBER: 'clo/merchant-number/update',
  UPLOAD_LOGO_FILE: 'clo/merchant/upload-logo',
  DELETE_CLO_MERCHANT_NUMBER: 'clo/merchant-number/delete'
}

export default {
  getCloSimpleMerchants (success) {
    const uri = CLO_MERCHANT_URI.GET_CLO_SIMPLE_MERCHANTS_ALL
    req.get(uri, success)
  },
  getCloMerchant (merchantSeq, success, fail) {
    req.get(CLO_MERCHANT_URI.GET_CLO_MERCHANT(merchantSeq), success, fail)
  },
  getCloMerchantCategories (success) {
    const uri = CLO_MERCHANT_URI.GET_CLO_MERCHANT_CATEGORIES
    req.get(uri, success)
  },
  getCloMerchantPaging (search, success) {
    req.post(CLO_MERCHANT_URI.GET_MERCHANT_PAGING, search, success)
  },
  getCloMerchantNumberPaging (search, success) {
    req.post(CLO_MERCHANT_URI.GET_MERCHANT_NUMBER_PAGING, search, success)
  },
  getChartValues (cloMerchantSeq, success) {
    req.get(CLO_MERCHANT_URI.GET_CHART_VALUES(cloMerchantSeq), success)
  },
  getSearchMerchantInfo (merchantCode, success) {
    req.get(CLO_MERCHANT_URI.GET_SEARCH_MERCHANT_INFO(merchantCode), success)
  },
  getCloMerchantNumberExcel (param, success) {
    req.postBlob(CLO_MERCHANT_URI.EXCEL_CLO_MERCHANT_NUMBER, param, success)
  },
  getCloPgCompanies (success) {
    const uri = CLO_MERCHANT_URI.GET_PG_COMPANIES
    req.get(uri, success)
  },
  getCloUsedPgCompanies (cloMerchantSeq, success) {
    req.get(CLO_MERCHANT_URI.GET_USED_PG_COMPANIES(cloMerchantSeq), success)
  },
  getLinkedCloMerchantListByAdvertiserCode (advertiserCode, success) {
    req.get(CLO_MERCHANT_URI.GET_LINKED_CLO_MERCHANT_LIST(advertiserCode), success)
  },
  uploadLogoFile (data, success, fail) {
    req.upload(CLO_MERCHANT_URI.UPLOAD_LOGO_FILE, data, () => {}, success, fail)
  },
  registerCloMerchant (data, success, fail) {
    req.upload(CLO_MERCHANT_URI.REGISTER_CLO_MERCHANT, data, () => {}, success, fail)
  },
  registerCloMerchantNumber (data, success, fail) {
    req.post(CLO_MERCHANT_URI.REGISTER_CLO_MERCHANT_NUMBER, data, success, fail)
  },
  bulkCloMerchantNumber (data, success, fail) {
    req.upload(CLO_MERCHANT_URI.BULK_CLO_MERCHANT_NUMBER, data, () => {}, success, fail)
  },
  modifyCloMerchant (data, seq, success, fail) {
    req.upload(CLO_MERCHANT_URI.MODIFY_CLO_MERCHANT(seq), data, () => {}, success, fail)
  },
  modifyCloMerchantNumber (data, success, fail) {
    req.post(CLO_MERCHANT_URI.MODIFY_CLO_MERCHANT_NUMBER, data, success, fail)
  },
  deleteCloMerchantNumber (data, success) {
    req.post(CLO_MERCHANT_URI.DELETE_CLO_MERCHANT_NUMBER, data, success)
  }
}
