<template lang="pug">
.sel_cont
  // [D] 목록 레이어 노출시 .sel에 .on 클래스 추가
  .sel(
    style='width: 226px'
    :class='{"on" : isOpened}'
    v-click-outside="clickOutside"
  )
    // [D] dropdown의 항목이 선택 시 a 태그에 "selected"클래스를 추가
    a(@click='toggleSelectBox'
    :class='{"selected" : selectedAdvId!==""}') {{ selectedAdvName || '광고주를 선택해주세요' }}
      span.ic_arrow_lg
    ul
      li.sch
        .bx_sch
          .inp_bx
            // [D] input에 입력된 내용 있으면 .txt_lbl 숨김 처리
            span.txt_lbl(v-if='searchValue === ""') 광고주 검색
            input(
              type='text',
              title='검색어를 입력',
              :value='searchValue'
              @input='searchValue = $event.target.value'
            )
          button.btn_sch(type='button')
            span.ic_sch_sm 검색
      li
        .dropdown_wrap.scrollbar-inner
          ul.sch_lst
            template(v-for='(item, index) in filteredAdvertiserList')
              li
                a(
                  v-if='item.advId!=="ALL" && item.svcCode=="ADVERTISER"'
                  @click='selectAdvertiser(item.advId, item.advName)'
                ) {{ item.advName }} ({{ item.advId }})
</template>

<!--
광고주 select box
- 전체 항목 없음
- 서비스 코드 = 'ADVERTISER'
-->
<script>
import { mapGetters } from 'vuex'
import $ from '@/util.js'
import _ from '@/partial'

export default {
  data () {
    return {
      isOpened: false,
      selectedAdvId: '',
      selectedAdvName: '',
      searchValue: ''
    }
  },
  name: 'AdvertiserSelectBox',
  methods: {
    selectAdvertiser (advId, advName) {
      this.selectedAdvId = advId
      this.selectedAdvName = advName
      this.toggleSelectBox()
      this.$emit('selectAdvertiser', { advId: advId, advName: advName })
    },
    toggleSelectBox () {
      this.isOpened = !this.isOpened
    },
    clearSelectBox () {
      this.selectedAdvId = ''
      this.selectedAdvName = ''
    },
    clickOutside () {
      this.isOpened = false
    },
    getAdvertiserListByIdList (advIdList, callback) {
      this.$emit(callback, _.map(advIdList, advId => _.where(this.advertiserList, { advId: advId })[0]))
    }
  },
  computed: {
    ...mapGetters(['advertiserList']),
    filteredAdvertiserList () {
      if (this.searchValue === '') {
        return this.advertiserList
      }
      const lowerSearchValue = this.searchValue.toLowerCase()
      return this.advertiserList
        .filter(adv => adv.advId.toLowerCase().indexOf(lowerSearchValue) > -1 ||
          adv.advName.toLowerCase().indexOf(lowerSearchValue) > -1)
    }
  },
  directives: {
    'click-outside': $.clickOutside()
  }
}
</script>

<style scoped></style>
