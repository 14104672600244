<template lang="pug">
.ly_pop.ly_pop_wrap.ly_pop4.ly_pop_reply
  .ly_pop_layer
    .ly_pop_cont.ly_pop_cont_long
      h1.blind 알림
      .scrollbar-inner
        .ly_pop_main
          h1.blind 알림
          p.pop_txt_v2(v-html="text") {{text}}
      .ly_btn
        button.btn.btn_bl.btn_pop_lg(type="button", @click="close") 확인
</template>

<script>
import jQuery from 'jquery'

export default {
  name: 'RejectRead',
  props: ['rejectMessage'],
  mounted () {
    this.$nextTick(function () {
      this.changeDivHeight()
      jQuery('.scrollbar-inner').scrollbar()
    })
  },
  methods: {
    close () {
      this.$emit('close')
    },
    changeDivHeight () { // 반려사유 내용에 따라 팝업 크기 제어
      const minHeight = 111 // ly_pop_main min height
      const maxHeight = 264 // ly_pop_main max height
      let calculatedHeight = maxHeight
      const messageHeight = this.$el.querySelector('.pop_txt_v2').offsetHeight

      if (messageHeight < minHeight) {
        calculatedHeight = minHeight
      } else if (messageHeight < maxHeight) {
        calculatedHeight = messageHeight
      }
      jQuery('.ly_pop_main').css('height', calculatedHeight)
    }
  },
  computed: {
    text () {
      return this.rejectMessage.includes('\n')
        ? this.rejectMessage.split('\n').join('<br/>')
        : this.rejectMessage
    }
  }
}
</script>
