import cloCampaignApi from '@/api/clo/clo-campaign'
import _ from '@/partial'

export default {
  state: {
    paymentReport: {
      campaigns: []
    }
  },
  getters: {
    cloPaymentReportTarget: state => state.paymentReport
  },
  actions: {
    fetchCloCampaignInReport ({ commit }, { cloPaymentCodes, advertiserCodes }) {
      return new Promise(resolve => {
        cloCampaignApi.getCloCampaignsForReport({
          cloPaymentCodes: cloPaymentCodes,
          advertiserCodes: advertiserCodes
        }, body => {
          commit('setCloCampaignsInReport', body.campaigns)
          resolve()
        })
      })
    }
  },
  mutations: {
    setCloCampaignsInReport (state, campaigns) {
      if (state.paymentReport) {
        _.set(state.paymentReport, 'campaigns', campaigns)
      }
    }
  }
}
