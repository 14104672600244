<template lang="pug">
a.download_file_form(:href='filePath', :download='fileName' @click="isValidSession") 파일양식다운로드
</template>

<script>
import userApi from '@/api/account'

export default {
  name: 'DownloadFileForm',
  props: ['filePath', 'fileName'],
  methods: {
    isValidSession (e) {
      e.preventDefault()
      userApi.getIsValidSession(({ result }) => {
        const a = document.createElement('a')
        a.href = this.filePath
        a.download = this.fileName
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
    }
  }
}
</script>

<style scoped></style>
