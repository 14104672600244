const CAMPAIGN_GROUP_TYPE = {
  CUSTOMIZED_COUPON: 'CUSTOMIZED_COUPON',
  AROUND_ME: 'AROUND_ME',
  GLOBAL_COUPON: 'GLOBAL_COUPON',
  MALL_REWARD: 'MALL_REWARD',
  DISPLAYAD: 'DISPLAYAD',
  CLO: 'CLO',
  CLICK_REWARD: 'CLICK_REWARD',
  POINT_HOME: 'POINT_HOME',
  OPTIMAL_TARGET_COUPON: 'OPTIMAL_TARGET_COUPON'
}

const CAMPAIGN_TYPE = {
  BASIC: 'BASIC',
  CUSTOM: 'CUSTOM',
  OUTER: 'OUTER',
  INFO: 'INFO',
  DISPLAYAD_RETARGET: 'DISPLAYAD_RETARGET'
}

const CAMPAIGN_STATUS_TYPE = {
  ACTIVE: '작동',
  INACTIVE: '종료',
  WAIT_APPROVAL: '심사중',
  CREATING: '임시저장',
  DELETE: '삭제',
  UNKNOWN: '알수없음'
}

function getCampaignStatusNameByCode (campaignStatusCode) {
  return CAMPAIGN_STATUS_TYPE[campaignStatusCode] ? CAMPAIGN_STATUS_TYPE[campaignStatusCode] : CAMPAIGN_STATUS_TYPE.UNKNOWN
}

const SALE_UP_TYPE = {
  NORMAL: '일반',
  SALE_UP: '할인율업'
}

function getSaleUpTypeNameByCode (saleUpTypeCode) {
  return SALE_UP_TYPE[saleUpTypeCode] ? SALE_UP_TYPE[saleUpTypeCode] : SALE_UP_TYPE.NORMAL
}

export { CAMPAIGN_GROUP_TYPE, CAMPAIGN_TYPE, CAMPAIGN_STATUS_TYPE, getCampaignStatusNameByCode, SALE_UP_TYPE, getSaleUpTypeNameByCode }
