import Vue from 'vue'
import Vuex from 'vuex'
import common from './modules/common'
import campaign from './modules/campaign'
import advertiser from './modules/advertiser'
import report from './modules/report'
import error from './modules/error'
import account from './modules/account'
import cloPayment from './modules/clo/clo-payment'
import cloReport from './modules/clo/clo-report'
import publisher from './modules/publisher'
import pointHomeCampaign from './modules/campaign/point-home'
import optimalTargetCoupon from './modules/campaign/optimal-target-coupon'
import campaignCommon from './modules/campaign/common'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    common,
    campaign,
    advertiser,
    report,
    error,
    account,
    cloPayment,
    cloReport,
    publisher,
    pointHomeCampaign,
    optimalTargetCoupon,
    campaignCommon
  },
  strict: debug
})
