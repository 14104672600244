import req from './http-request'

const ACCOUNT_URI = {
  GET_MERCHANT_LINK_LIST: 'account/merchant/link/list',
  REGISTER_ADVERTISER: 'account/advertiser',
  GET_ADVERTISER_REGISTRATION: 'account/advertiser',
  AUTH_MANAGER: 'account/manager/authentication-key',
  GET_ADVERTISER_LIST: 'account/advertiser/list',
  GET_ADVERTISER_LIST_EXCEL: 'account/advertiser/list/download',
  GET_ADVERTISER_LIST_FOR_MANAGER: 'account/manager/advertiser/list',
  INVITE_MANAGER: 'account/manager/invite',
  UPLOAD_BUSINESS_REGISTRATION_IMAGE: 'account/advertiser/upload/business-registration',
  UPLOAD_BANKBOOK_IMG: 'account/advertiser/upload/bank-book',
  GET_MERCHANT_BI_URL: (campaignGroupType, merchantCode) => `merchant/${merchantCode}/bi?campaignGroupType=${campaignGroupType}`,
  GET_MERCHANT_BY_CODE: (merchantCode) => `merchant/${merchantCode}`,
  SET_ADVERTISER_CODE_COOKIE: 'account/advertiser/cookie',
  GET_ADVERTISER_CODE_COOKIE: 'account/advertiser/cookie',
  GET_ADVERTISER_BANK_BOOK_IMAGE: (advertiserCode) => `account/advertiser/bank-book?advertiserCode=${advertiserCode}`,
  GET_LINKED_ADVERTISER_BY_MERCHANT_CODE: (merchantCode) => `account/linked-advertiser/list?merchantCode=${merchantCode}`
}
export default {
  getMerchantLinkList (advertiserCode, success) {
    const uri = ACCOUNT_URI.GET_MERCHANT_LINK_LIST + req.makeGetSuffix(
      { name: 'advertiserCode', value: advertiserCode })
    req.get(uri, success)
  },
  registerAdvertiser (advertiser, progress, success, fail) {
    req.upload(ACCOUNT_URI.REGISTER_ADVERTISER, advertiser, progress, success, fail)
  },
  authManager (advertiserLinkKey, success) {
    req.post(ACCOUNT_URI.AUTH_MANAGER, advertiserLinkKey, success)
  },
  getAdvertiserList (search, success) {
    const uri = ACCOUNT_URI.GET_ADVERTISER_LIST + req.makeGetSuffix(
      { name: 'advertiserName', value: search.advertiserName },
      { name: 'page', value: search.page.now },
      { name: 'size', value: search.page.size },
      { name: 'column', value: search.sort.column },
      { name: 'direction', value: search.sort.direction },
      { name: 'excelMode', value: search.excelMode })
    req.get(uri, success)
  },
  downloadAdvertiserListExcel (search, success) {
    const body = {
      advertiserName: search.advertiserName,
      page: search.page.now,
      size: search.page.size,
      column: search.sort.column,
      direction: search.sort.direction,
      excelMode: search.excelMode
    }
    req.postBlob(ACCOUNT_URI.GET_ADVERTISER_LIST_EXCEL, body, success)
  },
  getAdvertiserListForManager (success) {
    req.get(ACCOUNT_URI.GET_ADVERTISER_LIST_FOR_MANAGER, success)
  },
  inviteManager (inviteInfo, success) {
    req.post(ACCOUNT_URI.INVITE_MANAGER, inviteInfo, success)
  },
  uploadImageForAdvertiser (type, file, progress, success) {
    const url = type === 'businessRegistration' ? ACCOUNT_URI.UPLOAD_BUSINESS_REGISTRATION_IMAGE : ACCOUNT_URI.UPLOAD_BANKBOOK_IMG
    req.upload(url,
      {
        file: file
      },
      progress,
      success)
  },
  getAdvertiserRegistration (advertiserCode, success) {
    const uri = ACCOUNT_URI.GET_ADVERTISER_REGISTRATION + req.makeGetSuffix(
      { name: 'advertiserCode', value: advertiserCode })
    req.get(uri, success)
  },
  getMerchantBiUrl ({ campaignGroupType, merchantCode }, success) {
    const url = ACCOUNT_URI.GET_MERCHANT_BI_URL(campaignGroupType, merchantCode)
    req.get(url, success)
  },
  getMerchantByCode (merchantCode, success) {
    req.get(ACCOUNT_URI.GET_MERCHANT_BY_CODE(merchantCode), success)
  },
  setAdvertiserCodeCookie (advertiserCode, success) {
    req.post(ACCOUNT_URI.SET_ADVERTISER_CODE_COOKIE, advertiserCode, success)
  },
  getAdvertiserCodeFromCookie (success) {
    req.get(ACCOUNT_URI.GET_ADVERTISER_CODE_COOKIE, success)
  },
  getAdvertiserBankBookImage (advertiserCode, success) {
    req.getBlob(ACCOUNT_URI.GET_ADVERTISER_BANK_BOOK_IMAGE(advertiserCode), success)
  },
  getLinkedAdvertiserList (merchantCode, success) {
    req.get(ACCOUNT_URI.GET_LINKED_ADVERTISER_BY_MERCHANT_CODE(merchantCode), success)
  }
}
