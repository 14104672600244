<template lang="pug">
.tab_cont
  .tbl_wrap.ads_chart.tbl_lg_size.align_fst_col.tbl_sm_mt
    .page_head
      .tit_area
        h2.stit {{'총 ' + table.cloPaymentList.totalCountOfCloPayment + '건'}}
      .btn_area.tr
        a.btn_bl.btn_add_more(v-if='isAdmin' @click="$router.push('/account/clo-payment/registerModify')") 결제사 등록
    .tbl_scroll(style='width: 1024px;')
      .tbl
        table
          caption
            span 결제사 관리
          colgroup
            col
            col(style='width:250px')
            col(style='width:250px')
          thead
            tr
              th.sort(scope='col')
                button(type='button', @click="sortCloPayment('CLO_PAYMENT_NAME')") 결제사명
              th(scope='col') 결제사 ID
              th.sort(scope='col')
                button(type='button', @click="sortCloPayment('REG_YMDT')") 등록일
          tbody
            template(v-if="table.cloPaymentList.totalCountOfCloPayment !== 0")
              tr(v-for="item in table.cloPaymentList.listOfCloPayment")
                td.txt.align_left
                  a.txt_hover_ul(@click="$router.push('/account/clo-payment/registerModify/' + item.cloPaymentCode)") {{item.cloPaymentName}}
                td {{item.cloPaymentCode}}
                td {{item.regYmdt}}
            template(v-else)
              tr
                td(colspan="3")
                  .nodata_area
                    p.txt 조회 결과가 없습니다.
    paging(:currentPage="this.table.search.page.now",
      :pageSize="this.table.search.page.size"
      :totalCount="this.table.cloPaymentList.totalCountOfCloPayment"
      @onClickPage='searchCloPayment')
</template>

<script>
import cloPaymentApi from '@/api/clo/clo-payment.js'
import { mapGetters } from 'vuex'
import Paging from '@/components/common/fragments/Paging'
import $ from '@/util.js'

export default {
  components: { Paging },
  data: function () {
    return {
      table: {
        search: {
          page: {
            now: 1,
            size: 30
          },
          sort: {
            column: 'CLO_PAYMENT_NAME',
            direction: 'ASC'
          }
        },
        cloPaymentList: {
          listOfCloPayment: [],
          totalCountOfCloPayment: 0
        }
      }
    }
  },
  mounted () {
    this.searchCloPayment(1)
  },
  methods: {
    searchCloPayment (requestPageNumber) {
      const search = JSON.parse(JSON.stringify(this.table.search))// deep copy - 값만 복사
      search.page.now = requestPageNumber - 1
      cloPaymentApi.getCloPaymentList(
        search,
        body => {
          this.table.cloPaymentList = body.cloPaymentList
          this.table.search.page.now = requestPageNumber
        }
      )
    },
    sortCloPayment (newSortingColumn) {
      this.table.search.sort.direction = $.getReverseSortDirection(this.table.search.sort.direction)
      this.table.search.sort.column = newSortingColumn
      this.searchCloPayment(this.table.search.page.now)
    }
  },
  computed: {
    ...mapGetters(['isAdmin'])
  }
}
</script>

<style scoped>
button:hover {
  text-decoration: var(--button-text-decoration--hover);
}
</style>
