<template lang="pug">
div
  #content.adv_center
    .tit_area.desc
      h1.tit CLO가맹점 관리
      span *어드민 전용 노출
    form(onSubmit="return false")
      fieldset
        .query_form
          dl.g_row
            dt.blind 광고주검색
            dd
              .form.form_row
                .bx_item
                  dl
                    dt.blind 검색
                    dd
                      .inp_area
                        .inp_bx(style='width:350px')
                          // [D] input에 입력된 내용 있으면 .txt_lbl 숨김 처리
                          span.txt_lbl(v-if="search.keyword === ''") 가맹점명 혹은 가맹점코드를 입력해주세요
                          // [D] input에 focus와 입력값이 존재할때 "on_typing" 클래스 추가
                          input#keywordInput(
                            type='text',
                            :value="search.keyword",
                            :class='{"on_typing" : isOnTypingKeyword}',
                            style='width:100%',
                            @input='toggleKeywordInputClass($event)',
                            @focusout='toggleKeywordInputClass($event)'
                          )
                .confirm_area
                  button.btn.btn_.btn_red(type='submit', @click='searchCloMerchantList(1)') 조회
    .tbl_wrap.ads_chart.tbl_lg_size.align_fst_col.tbl_sm_mt
      .page_head
        .btn_area.tr
          button.btn_bl.btn_add_more.larger(type='button', @click='popup.showModal.options = true') CLO가맹점 등록
      .tbl_scroll(style='width: 1024px;')
        .tbl
          table
            caption
              span CLO가맹점 관리
            colgroup
              col
              col(style='width:150px')
              col(style='width:150px')
              col(style='width:150px')
              col(style='width:150px')
              col(style='width:150px')
            thead
              tr
                th(scope='col') 가맹점명
                th(scope='col') 가맹점코드
                th(scope='col') 카테고리
                th(scope='col') 주요고객
                th.sort(scope='col')
                  button(type='button', @click="sortCloMerchantList('MOD_YMDT')") 등록/수정일
                th(scope='col') 가맹점번호
            tbody(v-if='search.totalCount > 0')
              tr(v-for='item in search.cloMerchantList')
                td
                  a(@click="goCloMerchantPage(item.cloMerchantSeq)") {{ item.cloMerchantName }}
                td {{ item.cloMerchantCode }}
                td {{ item.categoryNames }}
                td
                  template(v-if='item.hasCustomerChartValues')
                    a.tbl_chart(@click="popupCustomerChart(item.cloMerchantSeq, item.cloMerchantName)")
                      img(src='/static/img/ico_chart_table.png', alt='고객 데이터 팝업 열기')
                  template(v-else)
                    | -
                td {{ item.modYmdtStr }}
                td
                  button.btn_invite(type='button', @click="popupNumbers(item)") {{ item.hasCloMerchantNumber? '수정': '등록'}}
            tbody(v-if='search.totalCount == 0')
              tr
                td(colspan=6)
                  .nodata_area
                    p.txt 조회 결과가 없습니다.
    paging(:currentPage="this.search.page.now",
            :pageSize="this.search.page.size"
            :totalCount="this.search.totalCount"
            @onClickPage='searchCloMerchantList')
    customer-chart(
      v-if='popup.showModal.chart'
      :cloMerchantSeq='popup.cloMerchantSeq'
      :cloMerchantName='popup.cloMerchantName'
      @showModal='setChartModal')
    clo-merchant-options(
      v-if='popup.showModal.options'
      @showModal='setOptionsModal')
  // [D] #content.adv_center와 같은 레벨에 위치
  clo-merchant-number-list(
    v-if='popup.showModal.numbers'
    :cloMerchantSeq='popup.cloMerchantSeq'
    :cloMerchantName='popup.cloMerchantName'
    @showModal='setNumbersModal')
</template>

<script>
import $ from '@/util.js'
import CustomerChart from './fragments/CustomerChart'
import Paging from '@/components/common/fragments/Paging'
import cloMerchantApi from '@/api/clo/clo-merchant.js'
import CloMerchantOptions from './fragments/CloMerchantOptions'
import CloMerchantNumberList from './fragments/CloMerchantNumberList'

export default {
  name: 'CloMerchantList',
  components: { Paging, CustomerChart, CloMerchantOptions, CloMerchantNumberList },
  data: function () {
    return {
      search: {
        cloMerchantList: [],
        keyword: '',
        totalCount: 0,
        page: {
          now: 1,
          size: 30
        },
        sort: {
          column: 'MOD_YMDT',
          direction: 'DESC'
        }
      },
      popup: {
        cloMerchantSeq: '',
        cloMerchantName: '',
        showModal: {
          chart: false,
          options: false,
          numbers: false
        }
      },
      isOnTypingKeyword: false
    }
  },
  mounted () {
    this.searchCloMerchantList(1)
  },
  methods: {
    searchCloMerchantList (requestPageNumber) {
      cloMerchantApi.getCloMerchantPaging({
        keyword: this.search.keyword,
        sort: this.search.sort,
        page: {
          now: requestPageNumber - 1,
          size: this.search.page.size
        }
      }, body => {
        this.search.totalCount = body.result.totalCount
        this.search.cloMerchantList = body.result.list
        this.search.page.now = requestPageNumber
      })
    },
    sortCloMerchantList: function (column) {
      this.search.sort.direction = $.getReverseSortDirection(this.search.sort.direction)
      this.search.sort.column = column
      this.searchCloMerchantList(this.search.page.now)
    },
    setChartModal (showModal) {
      this.popup.showModal.chart = showModal
    },
    setOptionsModal (showModal) {
      this.popup.showModal.options = showModal
    },
    setNumbersModal (showModal) {
      this.popup.showModal.numbers = showModal
      this.searchCloMerchantList(this.search.page.now)
    },
    toggleKeywordInputClass (e) {
      this.search.keyword = e.target.value
      this.isOnTypingKeyword = (e.target === 'input')
    },
    popupCustomerChart (cloMerchantSeq, cloMerchantName) {
      this.popup.cloMerchantSeq = cloMerchantSeq
      this.popup.cloMerchantName = cloMerchantName
      this.popup.showModal.chart = true
    },
    popupNumbers (obj) {
      this.popup.cloMerchantSeq = obj.cloMerchantSeq
      this.popup.cloMerchantName = obj.cloMerchantName
      this.popup.showModal.numbers = true
    },
    goCloMerchantPage (cloMerchantSeq) {
      this.$router.push('/clo-merchant/update/' + cloMerchantSeq)
    }
  }
}
</script>
