<template lang="pug">
  .bx_add_file
    ul.lst_einfo
      li(v-if="fileUrl")
        p.file_name(:style="disable && { 'margin-left': '-20px' } ")
          a.txt_link(:href="fileUrl", target="_blank", rel="noreferrer noopener") {{ fileName }}
          slot
        button.btn_del_file(
          v-if='!disEditable'
          type="button"
          @click="removeFile")
          span.blind 첨부파일 삭제
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  props: ['api', 'file', 'fileName', 'fileUrl', 'isHiddenRemoveButton', 'disable'],
  data () {
    return {
      progress: 0
    }
  },
  mounted () {
    if (!this.fileUrl) {
      this.api(
        this.file.data,
        e => {
          this.progress = e.loaded / e.total * 100
        },
        body => {
          this.setCommonLoadingOff()
          this.$emit('onUploaded', body)
        },
        () => {
          this.removeFile()
          this.setCommonLoadingOff()
        }
      )
    } else {
      this.show = true
      this.progress = 100
    }
  },
  methods: {
    ...mapMutations({
      setCommonLoadingOn: 'setCommonLoadingOn',
      setCommonLoadingOff: 'setCommonLoadingOff'
    }),
    removeFile () {
      this.$emit('onClickRemove')
    }
  },
  computed: {
    disEditable () {
      return this.isHiddenRemoveButton || this.disable
    }
  },
  watch: {
    file: function (newFile) {
      if (!this.fileUrl && newFile.data !== undefined) {
        this.setCommonLoadingOn()
        this.api(
          newFile.data,
          e => {
            this.progress = e.loaded / e.total * 100
          },
          body => {
            this.setCommonLoadingOff()
            this.$emit('onUploaded', body)
          },
          () => {
            this.removeFile()
            this.setCommonLoadingOff()
          }
        )
      }
    }
  }
}
</script>
