<template lang="pug">
.bx_item
  dl
    dt.sel_tit 광고주명
    dd.sel_cont
      // [D] 목록 레이어 노출시 .sel에 .on 클래스 추가
      .sel(style='width:250px',
        :class="{'on' : queryForm.isSelectAdvertiser}",
        @click="toggleAdvertiserName($event)")
        // [D] dropdown의 항목이 선택 시 a 태그에 "selected"클래스를 추가
        a.selected
          | {{queryForm.selectedAdvertiser}}
          span.ic_arrow_lg
        ul
          li.sch
            .bx_sch
              .inp_bx
                // [D] input에 입력된 내용 있으면 .txt_lbl 숨김 처리
                span.txt_lbl(v-if="queryForm.searchValue === ''") 광고주 검색
                input(type="text", title="검색어를 입력", :value="queryForm.searchValue", @input="inputAdvertiserValue($event)")
              button.btn_sch(type='button')
                span.ic_sch_sm 검색
          li
            //
              [D]
              스크롤바 라이브러리 사용
              div.dropdown_wrap scrollbar-inner 클래스 추가 시 라이브러리 동작 함.
            .dropdown_wrap.scrollbar-inner
              ul.sch_lst
                li
                  a(@click="setAdvertiserQuery('ALL', '전체')") 전체
                template(v-for="item in filteredAdvertisers")
                  li
                    a(@click="setAdvertiserQuery([item], item.advertiserName)") {{item.advertiserName}} ({{item.advertiserCode}})
</template>

<script>
import filterListBySearchValue from '@/components/common/mixins/filter-list-by-search-value-mixin.js'
export default {
  name: 'AdvertiserBoxItem',
  props: ['advertiserListAll'],
  mixins: [filterListBySearchValue],
  mounted () {
    this.emitSelectedAdvertiser()
  },
  data () {
    return {
      queryForm: {
        isSelectAdvertiser: false,
        selectedAdvertiser: '전체',
        searchValue: ''
      }
    }
  },
  methods: {
    inputAdvertiserValue ({ target }) {
      this.queryForm.searchValue = target.value
    },
    close () {
      this.queryForm.isSelectAdvertiser = false
    },
    toggleAdvertiserName (e) {
      if (e.target.tagName === 'A') {
        this.queryForm.isSelectAdvertiser = !this.queryForm.isSelectAdvertiser
      }
    },
    emitSelectedAdvertiser () {
      this.$emit('selectedAdvertiser', this.queryForm.selectedAdvertiser)
    },
    setAdvertiserQuery (advertiserList, advertiserName) {
      this.queryForm.selectedAdvertiser = advertiserName
      this.queryForm.searchValue = ''
      this.$emit('select', advertiserList)
      this.emitSelectedAdvertiser()
    }
  },
  computed: {
    filteredAdvertisers () {
      return this.filterListBySearchValue(this.advertiserListAll, this.queryForm.searchValue, false, 'advertiserName', 'advertiserCode')
    }
  }
}
</script>

<style scoped></style>
