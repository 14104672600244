import campaignApi from '@/api/campaign'
import _ from '@/partial'

export default {
  state: {
    report: {
      campaigns: [],
      coupons: []
    },
    fullScreenType: ''
  },
  getters: {
    reportTarget: state => state.report,
    reportFullScreen: state => state.reportFullScreen,
    fullScreenType: state => state.fullScreenType
  },
  actions: {
    fetchCampaignInReport ({ commit }, { advertiserCodes, adType }) {
      return new Promise(resolve => {
        campaignApi.getCampaignsIncludedInAdvertiserCodes(advertiserCodes, adType, body => {
          commit('setCampaignsInReport', body.campaigns)
          resolve()
        })
      })
    },
    fetchCouponInReport ({ commit }, { advertiserCodes }) {
      return new Promise(resolve => {
        campaignApi.getCouponsIncludeAdvertiserCodes(advertiserCodes, body => {
          commit('setCouponsInReport', body.coupons)
          resolve()
        })
      })
    }
  },
  mutations: {
    setCampaignsInReport (state, campaigns) {
      if (state.report) {
        _.set(state.report, 'campaigns', campaigns)
      }
    },
    setCouponsInReport (state, coupons) {
      if (state.report) {
        _.set(state.report, 'coupons', coupons)
      }
    },
    setFullScreenType (state, type) {
      state.fullScreenType = type
    }
  }
}
