<template lang="pug">
#container
  #content
    .error
      span.bg_arror
      h1.tit 유효한 접근 경로가 아닙니다.
      p.desc
        | 연결하려는 페이지의 URL이 잘못되었습니다.
        br
        | 주소를 확인해 주세요.
      a.btn_cfm(@click="$router.push('/')")
        span.btn_bg 확인
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style scoped>

</style>
