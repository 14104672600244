<template lang="pug">
.ly_pop.ly_pop_wrap.ly_pop17.dropdown_in_ly
  .ly_pop_layer
    .ly_pop_cont
      h1.pop_tit CLO가맹점 등록
      .select_branch_area
        // [D] branch_item_box 에 is_error 클래스 추가시 에러 메시지 노출
        .branch_item_box(:class='{"is_error" : isOptionError}')
          span.inp_radio
            input.inp_rdo(type='radio', id='rdo1', name='options',
              v-model='selectedCloMerchantType'
              :value='CLO_MERCHANT_TYPE.PAYCO')
            label.rdo_control_label(for='rdo1')
              span.ico_rdo
              | PAYCO광고가맹점
          .sel_cont(:class='{"has_error": isOptionError}')
            search-merchant-select-box(@selectSearchMerchant='setSearchMerchantCode')
          p.text
            | CLO가맹점 정보를 등록할 PAYCO광고가맹점을
            br
            | 선택해 주세요.
          p.text_error(v-if='this.selectedCloMerchantCode === ""') 가맹점 선택은 필수 입력값입니다.
        .branch_item_box
          span.inp_radio
            input.inp_rdo(type='radio', id='rdo2', name='options',
              v-model='selectedCloMerchantType'
              :value='CLO_MERCHANT_TYPE.ONLY_CLO')
            label.rdo_control_label(for='rdo2')
              span.ico_rdo
              | Only CLO가맹점
          p.text
            | CLO마케팅을 진행할 신규 가맹점 등록 시
            br
            | 선택해 주세요.
    .ly_btn
      button.btn.btn_bl_gray3(type='button', @click='closeModal') 취소
      button.btn.btn_bl(type='button', @click='createCloMerchant', :class='{disable: isOptionError}', :disabled="isOptionError") 다음
    button.btn_close(type='button', @click='closeModal')
      span.blind 닫기
</template>

<script>
import SearchMerchantSelectBox from './SearchMerchantSelectBox'
import { CLO_MERCHANT_TYPE } from '@/types/clo-merchant-types'

export default {
  components: { SearchMerchantSelectBox },
  name: 'CloMerchantOptions',
  data: function () {
    return {
      CLO_MERCHANT_TYPE: CLO_MERCHANT_TYPE,
      selectedCloMerchantType: CLO_MERCHANT_TYPE.PAYCO,
      selectedCloMerchantCode: '',
      selectedCloMerchantName: ''
    }
  },
  created () {
  },
  methods: {
    createCloMerchant () {
      if (this.isOptionError) return
      let location = '/clo-merchant/create?cloMerchantType=' + this.selectedCloMerchantType
      if (this.selectedCloMerchantType === CLO_MERCHANT_TYPE.PAYCO) {
        location = location + '&cloMerchantCode=' + this.selectedCloMerchantCode + '&cloMerchantName=' + encodeURI(this.selectedCloMerchantName)
      }
      this.$router.push(location)
    },
    setSearchMerchantCode (merchant) {
      this.selectedCloMerchantCode = merchant.merchantCode
      this.selectedCloMerchantName = merchant.merchantName
    },
    closeModal: function () {
      this.$emit('showModal', false)
    }
  },
  computed: {
    isOptionError () {
      return this.selectedCloMerchantType === CLO_MERCHANT_TYPE.PAYCO && this.selectedCloMerchantCode === ''
    }
  }
}
</script>

<style scoped>

</style>
