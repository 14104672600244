<template lang="pug">
.tab_cont
  .tbl_wrap.ads_chart.tbl_lg_size.align_fst_col.tbl_sm_mt
    .page_head
      form(onSubmit="return false", @keyup.enter="searchAdvertiser(0)")
        fieldset
          .query_form
            dl.q_row
              dt.blind 광고주 검색
              dd
                .form.form_row
                  .bx_item
                    dl
                      dt.blind 검색
                      dd
                        .inp_area
                          .inp_bx(style='width:290px')
                            // [D] input에 입력된 내용 있으면 .txt_lbl 숨김 처리
                            span.txt_lbl(v-if="table.search.advertiserName === ''") 검색어를 입력해주세요
                            input(
                              type='text',
                              :value="table.search.advertiserName",
                              @input="addSearchStyle($event)",
                              @focusout="removeSearchStyle($event)")
                            // [D] input에 focus와 입력값이 존재할때 "on_typing" 클래스 추가
                  .confirm_area
                    button.btn.btn_.btn_red(type="button", @click="searchAdvertiser(0)") 조회
                  .btn_area.tr
                    a.btn_bl.btn_user_register(@click="$router.push('/account/advertiser/registerModify')") 광고주 등록
                    a.btn_bl.btn_user_register(@click="$router.push('/account/advertiser/connect')") 광고주 인증키 등록
      merchant-link-list(
        v-if="popUp.showModal.merchant",
        :merchantLinkList="popUp.merchantLinkList",
        @showModal="setMerchantModal")
      invite-manager(
        v-if="popUp.showModal.invite",
        :advertiserCode="popUp.invite.advertiserCode",
        @showModal="setInviteModal")
      .tit_area
        h2.stit {{'총 ' + table.advertiserList.totalCountOfAdvertiser + '건'}}
      .btn_area.tr
        a.btn_w.btn_export.excel(v-if="isAdmin" style="margin-top:12px" @click="downExcel") 엑셀 다운로드
    .tbl_scroll(style='width: 1024px;')
      .tbl
        table
          caption
            span 광고주 관리
          colgroup
            col
            col(style='width:150px')
            col(style='width:110px')
            col(style='width:110px')
            col(style='width:110px', v-if='isAdmin')
            col(style='width:130px')
            col(style='width:130px')
            col(style='width:130px')
          thead
            tr
              th.sort(scope='col')
                button(type='button', @click="sortAdvertiser('ADVERTISER_NAME')") 광고주명
              th(scope='col') 광고주 ID
              th.sort(scope='col')
                button(type='button', @click="sortAdvertiser('REGISTER_YMDT')") 등록일
              th.sort(scope='col')
                button(type='button', @click="sortAdvertiser('ADVERTISER_ADMISSION_STATE_CODE')") 등록상태
              th(scope='col', v-if='isAdmin') 신용정보 동의여부
              th(scope='col') 회원 초대하기
              th(scope='col') 가맹점 연결여부
              th(scope='col') 등록매장
          tbody
            template(v-if="table.advertiserList.totalCountOfAdvertiser !== 0")
              tr(v-for="item in table.advertiserList.listOfAdvertiser")
                td.txt.align_left.no_underline
                  a(@click="$router.push('/account/advertiser/registerModify/' + item.advertiserCode)") {{item.advertiserName}}
                td {{item.advertiserCode}}
                td {{item.registerDate}}
                td.txt {{item.approvalStatus}}
                td.txt(v-if='isAdmin') {{item.creditInformationOptionalYn === 'N'? '-':'Y'}}
                td.txt(v-if="item.invitingEnabled")
                  button.btn_invite(type='button', @click="popUpInviteManager(item.advertiserCode)") 초대
                td.txt(v-else) -
                td.txt(v-if="item.merchantLinkStatus === 'LINKED'")
                  button(type='button', :style="{'--button-text-decoration--hover':'underline'}", @click="popUpConnectedMerchant(item.advertiserCode)") 연결 완료
                td.txt(v-else-if="item.merchantLinkStatus === 'WAIT'") -
                td.txt(v-else) 가맹점 없음
                td.txt(v-if="item.approvalStatusCode === 'CMPL'")
                  button.btn_invite.btn_store_lst(type='button', @click="popUpShopList(item.advertiserCode, item.advertiserName)") 매장 리스트 보기
                td.txt(v-else) -
            template(v-else)
              tr
                td(:colspan="isAdmin? 8:7")
                  .nodata_area
                    p.txt 조회 결과가 없습니다.
    .paging
      span.paging_inner
        template(v-for="n in 10")
          strong(v-if="pageNumberDroppedByOneDigit + n - 1 === table.search.page.now") {{pageNumberDroppedByOneDigit + n}}
          a(v-else-if="pageNumberDroppedByOneDigit + n - 1 <= lastPage",
            @click="searchAdvertiser(pageNumberDroppedByOneDigit + n - 1)") {{pageNumberDroppedByOneDigit + n}}
        a.btn_nav.btn_prev_all(@click="searchAdvertiser(0, table.search.page.now === 0)", :class="[table.search.page.now !== 0 ? 'on' : 'disable']")
          span 이전 페이지
        a.btn_nav.btn_prev(@click="searchAdvertiser(pageNumberDroppedByOneDigit - 10, table.search.page.now <= 9)", :class="[table.search.page.now > 9 ? 'on' : 'disable']")
          span 이전 페이지
        // [D] 버튼 활성화시 .on 클래스 추가
        a.btn_nav.btn_next(@click="searchAdvertiser(pageNumberDroppedByOneDigit + 10, pageNumberDroppedByOneDigit + 10 >= lastPage)", :class="[pageNumberDroppedByOneDigit + 10 < lastPage ? 'on' : 'disable']")
          span 다음 페이지
        a.btn_nav.btn_next_all(@click="searchAdvertiser(lastPage, table.search.page.now === lastPage)", :class="[table.search.page.now !== lastPage ? 'on' : 'disable']")
          span 다음 페이지
</template>

<script>
import advertiserApi from '@/api/advertiser.js'
import cloMerchantApi from '@/api/clo/clo-merchant.js'
import MerchantLinkList from './fragments/MerchantLinkList'
import InviteManager from './fragments/InviteManager'
import { mapGetters } from 'vuex'

export default {
  components: { InviteManager, MerchantLinkList },
  data: function () {
    return {
      table: {
        search: {
          advertiserName: '',
          page: {
            now: 0,
            size: 30
          },
          sort: {
            column: 'ADVERTISER_NAME',
            direction: 'ASC'
          },
          excelMode: false
        },
        advertiserList: {
          listOfAdvertiser: [],
          totalCountOfAdvertiser: 0
        }
      },
      popUp: {
        merchantLinkList: [],
        invite: {
          advertiserCode: ''
        },
        showModal: {
          merchant: false,
          invite: false
        }
      }
    }
  },
  mounted () {
    this.searchAdvertiser(0)
  },
  methods: {
    addSearchStyle ({ target }) {
      this.table.search.advertiserName = target.value
      if (target.value !== '') {
        target.classList.add('on_typing')
      }
    },
    removeSearchStyle ({ target }) {
      target.classList.remove('on_typing')
    },
    searchAdvertiser (requestPageNumber, disable = false) {
      if (disable) {
        return
      }
      if (requestPageNumber > this.lastPage) {
        requestPageNumber = this.lastPage
      } else if (requestPageNumber < 0) {
        requestPageNumber = 0
      }

      const search = JSON.parse(JSON.stringify(this.table.search))// deep copy - 값만 복사
      search.page.now = requestPageNumber
      advertiserApi.getAdvertiserList(
        search,
        body => {
          this.table.advertiserList = body.advertiserList
          this.table.search.page.now = requestPageNumber
        }
      )
    },
    popUpInviteManager (advertiserCode) {
      this.popUp.invite.advertiserCode = advertiserCode
      this.popUp.showModal.invite = true
    },
    popUpConnectedMerchant (advertiserCode) {
      const merchantPromise = new Promise((resolve) => {
        advertiserApi.getMerchantLinkList(
          advertiserCode,
          body => {
            resolve(body.listOfMerchantLink.length > 0 ? body.listOfMerchantLink : [])
          })
      })
      const cloMerchantPromise = new Promise((resolve) => {
        cloMerchantApi.getLinkedCloMerchantListByAdvertiserCode(advertiserCode, body => {
          resolve(body.result.map(cm => {
            return { merchantCode: cm.cloMerchantCode, merchantName: cm.cloMerchantName }
          }))
        })
      })
      Promise.all([merchantPromise, cloMerchantPromise]).then((values) => {
        const list = values[0].concat(values[1])
        this.popUp.merchantLinkList = list.length > 0 ? list : ['가맹점 없음']
        this.popUp.showModal.merchant = true
      })
    },
    popUpShopList (advertiserCode, advertiserName) {
      this.$emit('popUpShopList', { advertiserCode, advertiserName })
    },
    setMerchantModal (showModal) {
      this.popUp.showModal.merchant = showModal
    },
    setInviteModal (showModal) {
      this.popUp.showModal.invite = showModal
    },
    sortAdvertiser (newSortingColumn) {
      const originSortingColumn = this.table.search.sort.column
      if (originSortingColumn === newSortingColumn) {
        this.table.search.sort.direction === 'ASC'
          ? this.table.search.sort.direction = 'DESC'
          : this.table.search.sort.direction = 'ASC'
      }
      this.table.search.sort.column = newSortingColumn
      this.searchAdvertiser(this.table.search.page.now)
    },
    downExcel () {
      const search = JSON.parse(JSON.stringify(this.table.search))// deep copy - 값만 복사
      search.excelMode = true
      advertiserApi.downloadAdvertiserListExcel(
        search,
        blob => {
          if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, 'advertiser-list.xlsx')
          } else {
            const a = document.createElement('a')
            a.href = URL.createObjectURL(blob)
            a.setAttribute('download', 'advertiser-list.xlsx')
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          }
        }
      )
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    lastPage () {
      return parseInt((this.table.advertiserList.totalCountOfAdvertiser - 1) / this.table.search.page.size)
    },
    pageNumberDroppedByOneDigit () {
      return parseInt(this.table.search.page.now / 10) * 10
    }
  }
}
</script>

<style scoped>
button:hover {
  text-decoration: var(--button-text-decoration--hover);
}
</style>
