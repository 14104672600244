import req from '@/api/http-request'

const MERCHANT_URI = {
  SEARCH_MERCHANTS: (keyword, size, page, column, direction) =>
    `search-management/merchants?keyword=${keyword}&size=${size}&page=${page}&column=${column}&direction=${direction}`,
  DOWNLOAD_MERCHANTS: (param) => `search-management/merchants/download?${param}`,
  GET_MERCHANT: (merchantCode) => `search-management/merchants/${merchantCode}`,
  GET_MERCHANTS_FOR_CLOMERCHANT: 'search-management/merchants-for-clomerchant',
  GET_MERCHANT_VALID_EP_URL: (checkUrl) => `search-management/valid/ep?epUrl=${checkUrl}`,
  GET_MERCHANT_VALID_CRAWLING_ADV_CODE: (advCode) => `search-management/valid/crawling?advCode=${advCode}`,
  UPDATE_MERCHANT: 'search-management/merchants',
  REGISTER_LABEL_KEYWORDS: (uploadType) => `search-management/register-label-keyword/${uploadType}`,
  GET_LABEL_KEYWORD_MERCHANT_MAPPINGS: (param) => `search-management/label-keyword-merchant-mappings?${param}`,
  DOWNLOAD_LABEL_KEYWORD_MERCHANT_MAPPINGS: 'search-management/label-keyword-merchant-mappings/download',
  GET_USAGE_MERCHANT_BY_CODE: (merchantCode) => `search-management/usageMerchant/${merchantCode}`,
  GET_MERCHANT_BENEFITS: (merchantCode) => `search-management/merchants/${merchantCode}/benefits`,
  PUT_MERCHANT_BENEFITS: (merchantCode) => `search-management/merchants/${merchantCode}/benefits`
}

export default {
  getMerchants ({ keyword, size, page, column, direction }, success) {
    req.get(MERCHANT_URI.SEARCH_MERCHANTS(keyword, size, page, column, direction), success)
  },
  downloadMerchants (param, success) {
    req.getBlob(MERCHANT_URI.DOWNLOAD_MERCHANTS(req.makeGetSuffixByObject(param)), success)
  },
  getMerchantsForCloMerchant (success) {
    req.get(MERCHANT_URI.GET_MERCHANTS_FOR_CLOMERCHANT, success)
  },
  getMerchant (merchantCode, success) {
    req.get(MERCHANT_URI.GET_MERCHANT(merchantCode), success)
  },
  getMerchantValidEpUrl (epUrl, success) {
    req.get(MERCHANT_URI.GET_MERCHANT_VALID_EP_URL(epUrl), success)
  },
  getMerchantValidCrawlingAdvCode (advCode, success) {
    req.get(MERCHANT_URI.GET_MERCHANT_VALID_CRAWLING_ADV_CODE(advCode), success)
  },
  updateMerchant (merchant, success, fail) {
    req.put(MERCHANT_URI.UPDATE_MERCHANT, merchant, success, fail)
  },
  registerLabelKeywords ({ uploadType, data }, success, fail) {
    req.post(MERCHANT_URI.REGISTER_LABEL_KEYWORDS(uploadType), data, success, fail)
  },
  getLabelKeywordMerchantMapping (param, success) {
    req.get(MERCHANT_URI.GET_LABEL_KEYWORD_MERCHANT_MAPPINGS(req.makeGetSuffixByObject(param)), success)
  },
  downloadLabelKeywordMerchantMapping (param, success) {
    req.postBlob(MERCHANT_URI.DOWNLOAD_LABEL_KEYWORD_MERCHANT_MAPPINGS, param, success)
  },
  getUsageMerchantByCode (merchantCode, success) {
    req.get(MERCHANT_URI.GET_USAGE_MERCHANT_BY_CODE(merchantCode), success)
  },
  getMerchantByCode (merchantCode) {
    return new Promise((resolve, reject) => {
      this.getMerchant(
        merchantCode,
        result => {
          resolve(result)
        }
      )
    })
  },
  getMerchantBenefits (merchantCode, success) {
    req.get(MERCHANT_URI.GET_MERCHANT_BENEFITS(merchantCode), success)
  },
  putMerchantBenefits (merchantCode, merchantBenefits, success) {
    req.put(MERCHANT_URI.PUT_MERCHANT_BENEFITS(merchantCode), merchantBenefits, success)
  }
}
