const PERSONAL_INFORMATION_COMMON = {
  title: '개인정보 수집/이용',
  names: [
    '1. 수집하는 개인정보의 항목',
    '2. 수집 및 이용 목적',
    '3. 개인정보의 보유 및 이용기간'
  ],
  emphasis: [
    false,
    false,
    true
  ],
  bottomAdditionalInformations: ['동의를 거부하실 수 있으며, 필수/선택항목의 동의 거부 시 전체 또는 일부 서비스 이용이 제한 될 수 있습니다.']
}

const CREDIT_INFORMATION_OPTIONAL = {
  title: '신용정보 제3자 제공',
  names: [
    '1. 신용정보를 제공받는 자',
    '2. 신용정보를 제공받는 자의 이용목적',
    '3. 제공하는 신용정보 항목',
    '4. 제공받는 자의 신용정보 보유이용 기간'
  ],
  emphasis: [
    true,
    true,
    false,
    true
  ],
  topAdditionalInformation: '광고주회원인 본인은 PAYCO 마케팅센터에서 제공하는 서비스를 이용하기 위하여 매체사 회원(카드사 등)이 엔에이치엔페이코 주식회사에 아래와 같이 신용정보를 제공하는 것에 동의합니다.',
  bottomAdditionalInformations: [
    '동의를 거부하실 권리가 있으며, 동의 후에도 언제든지 동의 철회가 가능합니다. 다만, 동의 거부 및 동의 철회 시 서비스를 이용하실 수 없습니다.',
    '동의 철회를 원하시는 경우 help_biz_payco@nhnpayco.com으로 연락주시기 바랍니다.'
  ]
}

const PERSONAL_INFORMATION_MANDATORY_DESCRIPTION = [
  '사업자명(상호명), 실무자 연락처(E-mail), 사업자등록번호, 사업자등록증',
  '사업자 회원 및 담당자 확인, 사업자 회원과 제휴한 계약 이행 및 요금정산, 사업자 회원 관리, CS 등 민원처리',
  '거래 종료 시까지. 단, 관련 법령상 보관의무가 있는 경우 해당 기간동안 보존함'
]
const PERSONAL_INFORMATION_OPTIONAL_DESCRIPTION = [
  '광고주 실무자명, 실무자 연락처(대표번호), 통장사본',
  '광고주 및 담당자 확인, 광고주와 제휴한 계약 이행 및 요금정산, 광고주 관리, CS 등 민원처리',
  '거래 종료 시까지. 단, 관련 법령상 보관의무가 있는 경우 해당 기간동안 보존함'
]
const CREDIT_INFORMATION_OPTIONAL_DESCRIPTION = [
  '엔에이치엔페이코 주식회사',
  '광고 집행에 수반되는 광고별 예산관리 및 정산업무 처리',
  '[필수] 주문번호, 주문 승인 일시, 매출전표 접수일자, 카드사 승인번호, 결제 금액, 쿠폰 금액, 주문상태(주문/취소/부분취소), [선택] 주문 부분취소/취소 일시, 가맹점명, 가맹점번호(코드), CLO캠페인키, 결제사의 혜택 아이디',
  '제공받은 날로부터 5년간 보관 후 파기'
]

const PERSONAL_INFORMATION_MANDATORY_NOTICE = {
  title: PERSONAL_INFORMATION_COMMON.title,
  items: PERSONAL_INFORMATION_COMMON.names.map((value, index) => {
    return {
      name: value,
      description: PERSONAL_INFORMATION_MANDATORY_DESCRIPTION[index],
      emphasis: PERSONAL_INFORMATION_COMMON.emphasis[index]
    }
  }),
  bottomAdditionalInformations: PERSONAL_INFORMATION_COMMON.bottomAdditionalInformations
}

const PERSONAL_INFORMATION_OPTIONAL_NOTICE = {
  title: PERSONAL_INFORMATION_COMMON.title,
  items: PERSONAL_INFORMATION_COMMON.names.map((value, index) => {
    return {
      name: value,
      description: PERSONAL_INFORMATION_OPTIONAL_DESCRIPTION[index],
      emphasis: PERSONAL_INFORMATION_COMMON.emphasis[index]
    }
  }),
  bottomAdditionalInformations: PERSONAL_INFORMATION_COMMON.bottomAdditionalInformations
}

const CREDIT_INFORMATION_OPTIONAL_NOTICE = {
  title: CREDIT_INFORMATION_OPTIONAL.title,
  items: CREDIT_INFORMATION_OPTIONAL.names.map((value, index) => {
    return {
      name: value,
      description: CREDIT_INFORMATION_OPTIONAL_DESCRIPTION[index],
      emphasis: CREDIT_INFORMATION_OPTIONAL.emphasis[index]
    }
  }),
  topAdditionalInformation: CREDIT_INFORMATION_OPTIONAL.topAdditionalInformation,
  bottomAdditionalInformations: CREDIT_INFORMATION_OPTIONAL.bottomAdditionalInformations
}

const PERSONAL_INFORMATION_MANDATORY_CONFIRM = {
  subTitle: '',
  text: '(필수) 개인정보 수집/이용에 동의해주세요.',
  yes: '확인',
  no: ''
}

const PERSONAL_INFORMATION_OPTIONAL_CONFIRM = {
  subTitle: '',
  text: '(선택) 개인정보 수집/이용에 동의해주세요.',
  yes: '확인',
  no: ''
}

export {
  PERSONAL_INFORMATION_MANDATORY_NOTICE,
  PERSONAL_INFORMATION_OPTIONAL_NOTICE,
  CREDIT_INFORMATION_OPTIONAL_NOTICE,
  PERSONAL_INFORMATION_MANDATORY_CONFIRM,
  PERSONAL_INFORMATION_OPTIONAL_CONFIRM
}
