import CampaignApi from '@/api/campaign'

export default {
  state: {
    campaignTypesList: []
  },
  getters: {
    campaignTypesList: state => state.campaignTypesList,
    getTypesByCampaign: state => campaignId => state.campaignTypesList.find(t => t.campaignId === campaignId)
  },
  actions: {
    fetchCampaignTypes ({ state, commit }, campaignId) {
      return new Promise((resolve, reject) => {
        const typesOfCampaign = state.campaignTypesList.find(t => t.campaignId === campaignId)
        if (typesOfCampaign) {
          resolve(typesOfCampaign)
          return
        }
        CampaignApi.getCampaignTypes(campaignId, body => {
          commit('addCampaignTypes', body.types)
          resolve(body.types)
        }, header => reject(header.resultMessage))
      })
    }
  },
  mutations: {
    addCampaignTypes (state, types) {
      state.campaignTypesList.push(types)
    }
  }
}
