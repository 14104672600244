<template lang="pug">
div
  .inp_area.agree_policy(v-if='personalInformationMandatoryAgreeView')
    .chk
      span.inp_checkbox2(:class='{ is_checked: personalInformationMandatoryAgree }')
        input#personal-information-mandatory.inp_chk(type='checkbox' :checked='personalInformationMandatoryAgree' @change='$emit("update:personalInformationMandatoryAgree", $event.target.checked)')
        label.check_control_label.txt_caution(for='personal-information-mandatory')
          span.ico_chk
          | (필수) 개인정보 수집/이용에 동의합니다.
      button#guide-button-mandatory.desc_txt(type='button' @click='openNotice("PERSONAL-INFORMATION-MANDATORY", $event)') 내용보기
  .inp_area.agree_policy(v-if='personalInformationOptionalAgreeView' :class='personalInformationOptionalAgreeCss')
    .chk
      span.inp_checkbox2(:class='{ is_checked: personalInformationOptionalAgree }')
        input#personal-information-optional.inp_chk(type='checkbox' :checked='personalInformationOptionalAgree' :disabled='personalInformationOptionalAgreeDisable' @change='$emit("update:personalInformationOptionalAgree", $event.target.checked)')
        label.check_control_label.txt_caution(for='personal-information-optional')
          span.ico_chk
          | (선택) 개인정보 수집/이용에 동의합니다.
      button#guide-button-optional.desc_txt(type='button' @click='openNotice("PERSONAL-INFORMATION-OPTIONAL", $event)') 내용보기
  .inp_area.agree_policy(v-if='creditInformationOptionalAgreeView')
    .chk
      span.inp_checkbox2(:class='{ is_checked: creditInformationOptionalAgree }')
        input#credit-information-optional.inp_chk(type='checkbox' :checked='creditInformationOptionalAgree' @change='$emit("update:creditInformationOptionalAgree", $event.target.checked)')
        label.check_control_label.txt_caution(for='credit-information-optional')
          span.ico_chk
          | (선택) 신용정보 제3자 제공에 동의합니다.
      button#guide-button-optional.desc_txt(type='button' @click='openNotice("CREDIT-INFORMATION-OPTIONAL", $event)') 내용보기
  notice-pop-up(v-show='notice.view' @close='notice.view = false' :notice='notice.contents')
</template>

<script>
import NoticePopUp from '../../common/NoticePopUp'
import { PERSONAL_INFORMATION_MANDATORY_NOTICE, PERSONAL_INFORMATION_OPTIONAL_NOTICE, CREDIT_INFORMATION_OPTIONAL_NOTICE } from '../data/advertiser-personal-information-collect-use-agree'

export default {
  name: 'AdvertiserAgree',
  props: [
    'personalInformationMandatoryAgreeView',
    'personalInformationMandatoryAgree',
    'personalInformationOptionalAgreeView',
    'personalInformationOptionalAgree',
    'creditInformationOptionalAgreeView',
    'creditInformationOptionalAgree',
    'personalInformationOptionalAgreeDisable',
    'personalInformationOptionalAgreeChangeable'
  ],
  data () {
    return {
      noticeKindMap: {
        'PERSONAL-INFORMATION-MANDATORY': PERSONAL_INFORMATION_MANDATORY_NOTICE,
        'PERSONAL-INFORMATION-OPTIONAL': PERSONAL_INFORMATION_OPTIONAL_NOTICE,
        'CREDIT-INFORMATION-OPTIONAL': CREDIT_INFORMATION_OPTIONAL_NOTICE
      },
      notice: {
        contents: PERSONAL_INFORMATION_MANDATORY_NOTICE,
        view: false
      }
    }
  },
  computed: {
    personalInformationOptionalAgreeCss () {
      return {
        is_disabled: this.personalInformationOptionalAgreeDisable && this.personalInformationOptionalAgree,
        is_unchecked: this.personalInformationOptionalAgreeDisable && !this.personalInformationOptionalAgree
      }
    }
  },
  methods: {
    openNotice (noticeKind, e) {
      e.stopPropagation()
      this.notice.contents = this.noticeKindMap[noticeKind]
      this.notice.view = true
    }
  },
  watch: {
    personalInformationOptionalAgreeDisable () {
      if (this.personalInformationOptionalAgreeDisable && this.personalInformationOptionalAgreeChangeable) {
        this.$emit('update:personalInformationOptionalAgree', false)
      }
    }
  },
  components: { NoticePopUp }
}
</script>

<style scoped></style>
