<template lang="pug">
#content.adv_center(:class="additionalClasses")
  .tit_area
    h1.tit {{title}}
  step-campaign
  router-view
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import StepCampaign from '@/components/campaign/fragments/StepCampaign'

export default {
  name: 'CampaignSetting',
  components: { StepCampaign },
  methods: {
    ...mapActions(['fetchCampaign', 'fetchShoppingRewardCampaign'])
  },
  computed: {
    ...mapGetters(['campaign']),
    title () {
      return this.$route.params.mode === 'create' ? '캠페인 생성하기' : '캠페인 수정하기'
    },
    shopUsageType () {
      if (!this.campaign || !this.campaign.campaignShop) return 0
      return this.campaign.campaignShop.campaignShopUsageType
    },
    isInfo () {
      return this.campaign && this.campaign.campaignType === 'INFO'
    },
    additionalClasses () {
      return {
        camp_create_store: this.$route.params.step === 'shop',
        around_delivery: !this.isInfo && (this.shopUsageType === 2 || this.shopUsageType === 3),
        around_visit: !this.isInfo && this.shopUsageType === 1,
        store_guide: this.isInfo
      }
    }
  }
}
</script>
