import req from '@/api/http-request'

const URI = {
  GET_OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_BASIC: campaignId => `optimalTargetCoupon/custom/campaigns/${campaignId}/basic`,
  GET_OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_TARGET: campaignId => `optimalTargetCoupon/custom/campaigns/${campaignId}/target`,
  GET_OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_BANNER: campaignId => `optimalTargetCoupon/custom/campaigns/${campaignId}/banner`,
  GET_OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_COUPON: campaignId => `optimalTargetCoupon/custom/campaigns/${campaignId}/coupon`,
  GET_OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_EVENT_PAGE: campaignId => `optimalTargetCoupon/custom/campaigns/${campaignId}/eventPage`,
  GET_COUPON: couponId => `optimalTargetCoupon/custom/campaigns/coupons/${couponId}`,
  OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_BASIC: 'optimalTargetCoupon/custom/campaigns/basic',
  OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_TARGET: 'optimalTargetCoupon/custom/campaigns/target',
  OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_BANNER: 'optimalTargetCoupon/custom/campaigns/banner',
  OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_COUPON: 'optimalTargetCoupon/custom/campaigns/coupon',
  OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_EVENT_PAGE: 'optimalTargetCoupon/custom/campaigns/eventPage'
}

export default {
  getOptimalTargetCouponCustomCampaignBasic (campaignId, success, fail) {
    req.get(URI.GET_OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_BASIC(campaignId), success, fail)
  },
  getOptimalTargetCouponCustomCampaignTarget (campaignId, success, error) {
    req.get(URI.GET_OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_TARGET(campaignId), success, error)
  },
  getOptimalTargetCouponCustomCampaignBanner (campaignId, success, error) {
    req.get(URI.GET_OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_BANNER(campaignId), success, error)
  },
  getOptimalTargetCouponCustomCampaignCoupon (campaignId, success, fail) {
    req.get(URI.GET_OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_COUPON(campaignId), success, fail)
  },
  getCoupon (couponId, success, fail) {
    req.get(URI.GET_COUPON(couponId), success, fail)
  },
  getOptimalTargetCouponCustomEventPage (campaignId, success, fail) {
    req.get(URI.GET_OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_EVENT_PAGE(campaignId), success, fail)
  },
  createOptimalTargetCouponCustomCampaignBasic (basic, success, fail) {
    req.post(URI.OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_BASIC, basic, success, fail)
  },
  createOptimalTargetCouponCustomCampaignTarget (target, success, fail) {
    req.post(URI.OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_TARGET, target, success, fail)
  },
  createOptimalTargetCouponCustomCampaignBanner (banner, success, fail) {
    req.post(URI.OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_BANNER, banner, success, fail)
  },
  createOptimalTargetCouponCustomCampaignCoupon (coupon, success, fail) {
    req.post(URI.OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_COUPON, coupon, success, fail)
  },
  createOptimalTargetCouponCustomEventPage (eventPage, success, fail) {
    req.post(URI.OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_EVENT_PAGE, eventPage, success, fail)
  },
  updateOptimalTargetCouponCustomCampaignBasic (basic, success, fail) {
    req.put(URI.OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_BASIC, basic, success, fail)
  },
  updateOptimalTargetCouponCustomCampaignTarget (target, success, fail) {
    req.put(URI.OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_TARGET, target, success, fail)
  },
  updateOptimalTargetCouponCustomCampaignBanner (banner, success, fail) {
    req.put(URI.OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_BANNER, banner, success, fail)
  },
  updateOptimalTargetCouponCustomCampaignCoupon (coupon, success, fail) {
    req.put(URI.OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_COUPON, coupon, success, fail)
  },
  updateOptimalTargetCouponCustomEventPage (eventPage, success, fail) {
    req.put(URI.OPTIMAL_TARGET_COUPON_CUSTOM_CAMPAIGN_EVENT_PAGE, eventPage, success, fail)
  }
}
