import req from './http-request'

const URI = {
  GET_REPORTS: 'report',
  GET_REPORT_SUMMARY: 'report/summary',
  GET_REPORT_TOTAL_COUNT: 'report/total-count',
  GET_REPORT_CHART: 'report/chart',
  GET_REPORT_BY_ADVERTISER_TOTAL_COUNT: 'report/by-advertiser/total-count',
  GET_REPORT_BY_ADVERTISER: 'report/by-advertiser',
  GET_CAMPAIGN_REPORTS: 'report/campaign',
  GET_CAMPAIGN_REPORT_SUMMARY: 'report/campaign/summary',
  GET_CAMPAIGN_REPORT_TOTAL_COUNT: 'report/campaign/total-count',
  GET_CAMPAIGN_TRANSITION_REPORTS: 'report/campaign-transition',
  GET_CAMPAIGN_TRANSITION_REPORT_SUMMARY: 'report/campaign-transition/summary',
  GET_CAMPAIGN_TRANSITION_REPORT_TOTAL_COUNT: 'report/campaign-transition/total-count',
  GET_CAMPAIGN_TRANSITION_CHART: 'report/campaign-transition/chart',
  GET_UNIQUE_VIEW: 'report/unique-view',
  GET_UNIQUE_VIEW_SUMMARY: 'report/unique-view/summary',
  GET_UNIQUE_VIEW_TOTAL_COUNT: 'report/unique-view/total-count',
  GET_LINKED_REVENUE_DETAIL: 'report/linked-revenue-detail',
  DOWNLOAD_COUPON_BILL_EXCEL: 'report/download-coupon-bill'
}
export default {
  getReport (success, params) {
    req.post(URI.GET_REPORTS, params, success)
  },
  getReportSummary (success, params) {
    req.post(URI.GET_REPORT_SUMMARY, params, success)
  },
  getTotalCountOfReport (success, params) {
    req.post(URI.GET_REPORT_TOTAL_COUNT, params, success)
  },
  getChart (success, params) {
    req.post(URI.GET_REPORT_CHART, params, success)
  },
  getTotalCountOfReportByAdvertiser (success, params) {
    req.post(URI.GET_REPORT_BY_ADVERTISER_TOTAL_COUNT, params, success)
  },
  getReportByAdvertiser (success, params) {
    req.post(URI.GET_REPORT_BY_ADVERTISER, params, success)
  },
  getCampaignTotalCount (success, params) {
    req.post(URI.GET_CAMPAIGN_REPORT_TOTAL_COUNT, params, success)
  },
  getCampaignReport (success, params) {
    req.post(URI.GET_CAMPAIGN_REPORTS, params, success)
  },
  getCampaignReportSummary (success, params) {
    req.post(URI.GET_CAMPAIGN_REPORT_SUMMARY, params, success)
  },
  getCampaignTransitionTotalCount (success, params) {
    req.post(URI.GET_CAMPAIGN_TRANSITION_REPORT_TOTAL_COUNT, params, success)
  },
  getCampaignTransitionReport (success, params) {
    req.post(URI.GET_CAMPAIGN_TRANSITION_REPORTS, params, success)
  },
  getCampaignTransitionSummary (success, params) {
    req.post(URI.GET_CAMPAIGN_TRANSITION_REPORT_SUMMARY, params, success)
  },
  getCampaignTransitionChart (success, params) {
    req.post(URI.GET_CAMPAIGN_TRANSITION_CHART, params, success)
  },
  getTotalCountOfUniqueView (success, params) {
    req.post(URI.GET_UNIQUE_VIEW_TOTAL_COUNT, params, success)
  },
  getUniqueView (success, params) {
    req.post(URI.GET_UNIQUE_VIEW, params, success)
  },
  getUniqueViewSummary (success, params) {
    req.post(URI.GET_UNIQUE_VIEW_SUMMARY, params, success)
  },
  downloadExcel (uri, method, params, success) {
    if (method === 'GET') {
      const queries = req.getQueryString(params)
      const getUri = `${uri}?${queries}`
      req.getBlob(getUri, success)
    } else {
      req.postBlob(uri, params, success)
    }
  },
  getLinkedRevenueDetail (success, params) {
    req.post(URI.GET_LINKED_REVENUE_DETAIL, params, success)
  },
  downloadCouponBillExcel (params, success, fail) {
    req.postBlob(URI.DOWNLOAD_COUPON_BILL_EXCEL, params, success, fail)
  }
}
