// The Vue build version to load with the `import` command
import Vue from 'vue'
import router from './router'
import store from './store'
import vueResource from 'vue-resource'
import $ from '@/util'
import Wrap from '@/Wrap'
import { USER_ROLE } from '@/types/user-role-types'

Vue.config.productionTip = false
Vue.use(vueResource)

Vue.http.options.root = location.protocol + '//' + location.hostname +
  (process.env.BACKEND_PORT !== null ? ':' + process.env.BACKEND_PORT : '') + process.env.BACKEND_URI

Vue.http.interceptors.push((request, next) => {
  request.credentials = true
  request.headers.set('Cache-Control', 'no-cache, no-store, must-revalidate')
  request.headers.set('Pragma', 'no-cache')
  request.headers.set('Expires', '0')
  request.headers.set('X-ACT-ROLE', store.getters.actCampaignAdminRole ? USER_ROLE.CAMPAIGN_NE_MGR : '')
  next()
})

router.beforeEach((to, from, next) => {
  store.dispatch('updateCurrentPath', to.fullPath)
  next()
})

Vue.filter('numberWithCommas', function (number) {
  return $.numberWithCommas(number)
})

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(Wrap)
}).$mount('#wrap')
