<template lang="pug">
  // [D] 목록 레이어 노출시 .sel에 .on 클래스 추가
  .sel(
    style='width: 226px'
    :class='{"on" : isOpened}'
    v-click-outside="clickOutside"
  )
    // [D] dropdown의 항목이 선택 시 a 태그에 "selected"클래스를 추가
    a(@click='toggleSelectBox'
      :class='{"selected" : selectedMerchantCode!==""}') {{ selectedMerchantName || '가맹점을 선택해주세요' }}
      span.ic_arrow_lg
    ul
      li.sch
        .bx_sch
          .inp_bx
            // [D] input에 입력된 내용 있으면 .txt_lbl 숨김 처리
            span.txt_lbl(v-if='searchValue === ""') 가맹점 검색
            input(
              type='text',
              title='검색어를 입력',
              :value='searchValue'
              @input='searchValue = $event.target.value'
            )
          button.btn_sch(type='button')
            span.ic_sch_sm 검색
      li
        .dropdown_wrap.scrollbar-inner
          ul.sch_lst
            template(v-for='(item, index) in filteredSearchMerchantList')
              li
                a(@click='selectMerchant(item.merchantCode, item.merchantName, item.isUsed)'
                  :style="{cursor: item.isUsed?'not-allowed':'pointer', color: item.isUsed? 'gray':''}"
                ) {{ item.merchantName }} ({{ item.merchantCode }})
</template>

<script>
import $ from '@/util.js'
import MerchantApi from '@/api/admin/merchant'
import jQuery from 'jquery'

export default {
  data () {
    return {
      searchMerchantList: [],
      selectedMerchantCode: '',
      selectedMerchantName: '',
      searchValue: '',
      isOpened: false
    }
  },
  name: 'SearchMerchantSelectBox',
  mounted () {
    jQuery('.scrollbar-inner').scrollbar()
    this.fetchSimpleMerchantList()
  },
  methods: {
    fetchSimpleMerchantList () {
      MerchantApi.getMerchantsForCloMerchant(body => {
        this.searchMerchantList = body.merchantsResponse
      })
    },
    selectMerchant (merchantCode, merchantName, isUsed) {
      if (isUsed) return
      this.selectedMerchantCode = merchantCode
      this.selectedMerchantName = merchantName
      this.toggleSelectBox()
      this.$emit('selectSearchMerchant', {
        merchantCode: merchantCode,
        merchantName: merchantName
      })
    },
    toggleSelectBox () {
      this.isOpened = !this.isOpened
    },
    clickOutside () {
      this.isOpened = false
    }
  },
  computed: {
    filteredSearchMerchantList () {
      if (this.searchValue === '') {
        return this.searchMerchantList
      }
      const lowerSearchValue = this.searchValue.toLowerCase()
      return this.searchMerchantList
        .filter(m => m.merchantCode.toLowerCase().indexOf(lowerSearchValue) > -1 ||
              m.merchantName.toLowerCase().indexOf(lowerSearchValue) > -1)
    }
  },
  directives: {
    'click-outside': $.clickOutside()
  }
}
</script>

<style scoped>
.bx_sch {
  border-bottom: 1px solid #ececed;
}
</style>
