<template lang="pug">
#snackbar(:style="style", v-html="snackBarMessage", v-if="snackBarMessage !== ''")
</template>

<script>
export default {
  name: 'CommonSnackbar',
  props: {
    message: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      style: {
        color: '#31708f',
        backgroundColor: '#d9edf7',
        borderColor: '#bce8f1'
      }
    }
  },
  methods: {
    setStyle (style) {
      switch (style) {
        case 'success': this.style = {
          color: '#3c763d',
          backgroundColor: '#dff0d8',
          borderColor: '#d6e9c6'
        }
          break
        case 'info': this.style = {
          color: '#31708f',
          backgroundColor: '#d9edf7',
          borderColor: '#bce8f1'
        }
          break
        case 'warning': this.style = {
          color: '#8a6d3b',
          backgroundColor: '#fcf8e3',
          borderColor: '#faebcc'
        }
          break
        case 'danger': this.style = {
          color: '#a94442',
          backgroundColor: '#f2dede',
          borderColor: '#ebccd1'
        }
          break
        default: this.style = {
          color: '#31708f',
          backgroundColor: '#d9edf7',
          borderColor: '#bce8f1'
        }
      }
    },
    showSnackbar (newStyle) {
      if (newStyle) {
        this.setStyle(newStyle)
      }
      this.$el.className = 'show'
      setTimeout(function () {
        this.$el.className = this.$el.className.replace('show', '')
      }.bind(this), 3000)
    }
  },
  computed: {
    snackBarMessage () {
      return this.message.split('\n').join('<br>').trim()
    }
  }
}
</script>

<style scoped>
#snackbar {
  visibility: hidden;
  width: 60%;
  left: 20%;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 600;
  bottom: 30%;
  font-size: 17px;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30%; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30%; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30%; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30%; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
</style>
