<template lang="pug">
  .search_area
    .sel_area(:class="{on: displayComboList}", :style="width='280px'" v-click-outside="clickOutside") <!-- [D] 목록 활성화시 .on 클래스 추가 -->
      a.sel(
        @click="toggleSelectedBox"
        :class="{'on' : displayComboList}"
      ) {{ advertiser && advertiser.advName }}
        span.ic_arrow
      .lst_sel_bx(:class="{'on' : displayComboList}")
        ul.lst_sel(:class="{'on' : displayComboList}")
          li.sch
            .bx_sch
              .inp_bx
                // [D] input에 입력된 내용 있으면 .txt_lbl 숨김 처리
                span.txt_lbl(v-if="queryForm.searchValue === ''")  검색어를 입력하세요.
                input(type="text", title="검색어를 입력", :value="queryForm.searchValue", @input="inputSearchValue($event.target.value)", :class="{'on_typing' : queryForm.searchValue !== ''}")
              button.btn_sch(type='button')
                span.ic_sch_m 검색
          li(v-for="adv in filteredAdvertiserList", @click="storeAdvertiser(adv)")
            a(v-if="adv.svcCode === 'ADVERTISER'") {{ adv.advId === 'ALL' ? adv.advName : `${namePrefix(adv.svcCode)}${adv.advName}` }} {{displayAdvIdFormat(adv.advId)}}
            a(v-else, :href="memberShipPath + adv.advId") {{ `${namePrefix(adv.svcCode)} ${adv.advName}` }} {{displayAdvIdFormat(adv.advId)}}
        span.bg_sh_b
        span.bg_sh_rb
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _ from '@/partial'
import $ from '@/util.js'

const disabledUri = ['/campaign/create/', '/campaign/update/']
const universalUri = ['/account', '/admin-management', '/clo-merchant', '/payment/account']
const memberShipPath = 'https://' + process.env.GNB_HOST + '/changeMembership.nhn?membershipCode='
export default {
  data () {
    return {
      queryForm: {
        selectedName: '전체',
        selectedValue: '',
        searchValue: ''
      },
      displayComboList: false,
      memberShipPath: memberShipPath
    }
  },
  created () {
    this.goCampaignIfUniversal()
  },
  methods: {
    ...mapActions(['setAdvertiser', 'setAdvertiserAsTotal']),
    storeAdvertiser (selectedAdv) {
      _.go(
        this.advertiserList,
        _.find(adv => adv.advId === selectedAdv.advId),
        _.tap(_(this.setAdvertiser)),
        this.selectedItem
      )
    },
    namePrefix (svcCode) {
      return svcCode === 'ADVERTISER' ? '(광고주) ' : '(멤버쉽) '
    },
    displayAdvIdFormat (advId) {
      return $.formatDisplayCodeWithOutAllValue(advId)
    },
    setAdvertiserAsTotalIfUniversal () {
      if (this.isUniversal) {
        const advertiser = {
          advId: 'ALL',
          advName: '전체',
          svcCode: 'ADVERTISER'
        }
        this.setAdvertiserAsTotal(advertiser)
        this.selectedItem(advertiser)
      }
    },
    goCampaign () {
      this.$router.push('/campaign')
    },
    goCampaignIfUniversal () {
      this.isUniversal && this.advertiser && this.advertiser.advId !== 'ALL' && this.goCampaign()
    },
    toggleSelectedBox () {
      this.displayComboList = !this.displayComboList
    },
    selectedItem (selectedAdv) {
      this.queryForm.selectedName = selectedAdv.advName
      this.queryForm.selectedValue = selectedAdv.advId
      this.displayComboList = false

      this.inputSearchValue('')
    },
    inputSearchValue (searchValue) {
      this.queryForm.searchValue = searchValue
    },
    clickOutside () {
      this.displayComboList = false
    }
  },
  computed: {
    ...mapGetters(['advertiserList', 'advertiser']),
    disabled () {
      return _.go(
        disabledUri,
        _.findIndex(uri => this.$route.path.startsWith(uri)),
        (i) => i >= 0
      )
    },
    isUniversal () {
      return _.go(
        universalUri,
        _.findIndex(uri => this.$route.path.startsWith(uri)),
        (i) => i >= 0
      ) || this.$route.path === '/'
    },
    filteredAdvertiserList () {
      if (this.queryForm.searchValue === '') {
        return this.advertiserList
      }
      const lowerSearchValue = this.queryForm.searchValue.toLowerCase()
      return this.advertiserList
        .filter(adv => adv.advId.toLowerCase().indexOf(lowerSearchValue) > -1 ||
              adv.advName.toLowerCase().indexOf(lowerSearchValue) > -1 ||
              this.namePrefix(adv.svcCode).trim().indexOf(lowerSearchValue) > -1)
        .filter(adv => adv.advId !== 'ALL')
    }
  },
  watch: {
    '$route.path' () {
      this.setAdvertiserAsTotalIfUniversal()
    },
    advertiser (n, o) {
      this.goCampaignIfUniversal()
      this.$emit('onAdvertiserChange', n)
    }
  },
  directives: {
    'click-outside': $.clickOutside()
  }
}
</script>
