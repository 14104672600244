import CampaignApi from '@/api/campaign'

export default {
  state: {
    campaign: {
      basic: null,
      target: null,
      banner: null,
      coupon: null,
      eventPage: null
    }
  },
  getters: {
    pointHomeCampaign: state => state.campaign,
    pointHomeCampaignBaisc: state => state.campaign.basic,
    pointHomeCampaignTarget: state => state.campaign.target,
    pointHomeCampaignBanner: state => state.campaign.banner,
    pointHomeCampaignCoupon: state => state.campaign.coupon,
    pointHomeCampaignEventPage: state => state.campaign.eventPage
  },
  actions: {
    fetchPointHomeCampaignBasic ({
      state,
      commit
    }, campaignId) {
      return new Promise((resolve, reject) => {
        CampaignApi.getPointHomeBasicCampaignBasic(campaignId, body => {
          commit('setPointHomeCampaignBasic', body.campaign || null)
          resolve(body.campaign)
        }, header => reject(header.resultMessage))
      })
    },
    fetchPointHomeCampaignTarget ({
      state,
      commit
    }, campaignId) {
      return new Promise((resolve, reject) => {
        CampaignApi.getPointHomeBasicCampaignTarget(campaignId, body => {
          commit('setPointHomeCampaignTarget', body.campaignTarget || null)
          resolve(body.campaignTarget)
        },
        header => header.resultCode === 400
          ? commit('setPointHomeCampaignTarget', null)
          : reject(header.resultMessage)
        )
      })
    },
    fetchPointHomeCampaignBanner ({
      state,
      commit
    }, campaignId) {
      return new Promise((resolve, reject) => {
        CampaignApi.getPointHomeBasicCampaignBanner(campaignId, body => {
          commit('setPointHomeCampaignBanner', body.campaignBanner || null)
          resolve(body.campaignBanner)
        }, header => reject(header.resultMessage))
      })
    },
    fetchPointHomeCampaignCoupon ({
      state,
      commit
    }, campaignId) {
      return new Promise((resolve, reject) => {
        CampaignApi.getPointHomeBasicCampaignCoupon(campaignId, body => {
          commit('setPointHomeCampaignCoupon', body.result || null)
          resolve(body.result)
        })
      })
    },
    fetchPointHomeCampaignEventPage ({
      state,
      commit
    }, campaignId) {
      return new Promise((resolve, reject) => {
        CampaignApi.getPointHomeBasicCampaignEventPage(campaignId, body => {
          commit('setPointHomeCampaignEventPage', body.eventPages || null)
          resolve(body.eventPages)
        })
      })
    }
  },
  mutations: {
    setPointHomeCampaignBasic (state, basic) {
      state.campaign.basic = basic
    },
    setPointHomeCampaignTarget (state, target) {
      state.campaign.target = target
    },
    setPointHomeCampaignBanner (state, banner) {
      state.campaign.banner = banner
    },
    setPointHomeCampaignCoupon (state, coupon) {
      state.campaign.coupon = coupon
    },
    setPointHomeCampaignEventPage (state, eventPage) {
      state.campaign.eventPage = eventPage
    }
  }
}
