import { setItemToSessionStorage, getItemFromSessionStorage } from '@/browser-util'
import advertiserApi from '@/api/advertiser'

const ADVERTISER_COOKIE_NAME = '_selected_advertiser_code_'
const ADVERTISER_SESSION_STORAGE_NAME = 'advertiser'

function getAdvFromServerCookie () {
  return new Promise((resolve, reject) => {
    advertiserApi.getAdvertiserCodeFromCookie(
      body => resolve(body.advertiserCode),
      header => reject(header.message)
    )
  })
}

function syncAdvertiserWithSessionStorage (dispatch, advertiserList, callback) {
  const advCodeFromSessionStorage = getItemFromSessionStorage(ADVERTISER_SESSION_STORAGE_NAME)
  const advertiser = advertiserList.find(a => a.advId === advCodeFromSessionStorage) || advertiserList[0]
  if (advertiser) {
    dispatch('setAdvertiser', advertiser)
  }
  callback && callback()
}

function setAdvertiserToSessionStorage (advertiser) {
  if (advertiser && advertiser.svcCode === 'ADVERTISER') {
    setItemToSessionStorage(ADVERTISER_SESSION_STORAGE_NAME, advertiser.advId)
  }
}

export default {
  state: {
    noMerchantCode: 'EVT_9999',
    advertiser: null,
    advertiserList: [],
    onlyAdvertiserList: [],
    hasApprovedAdvertiser: false
  },
  getters: {
    noMerchantCode: state => state.noMerchantCode,
    advertiser: state => state.advertiser,
    advertiserList: state => state.advertiserList,
    onlyAdvertiserList: state => state.onlyAdvertiserList,
    hasApprovedAdvertiser: state => state.hasApprovedAdvertiser
  },
  actions: {
    fetchAdvertiserList ({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        advertiserApi.getAdvertiserListForManager(body => {
          const advertiserList = [{ advId: 'ALL', advName: '전체', svcCode: 'ADVERTISER' }].concat(
            body.advertisers.map(a => ({
              advId: a.serviceCode,
              advName: a.serviceName,
              svcCode: a.serviceTypeCode,
              linkUrl: a.linkUrl,
              isRewardShoppingMall: a.rewardShoppingMall
            }))
          )

          const processAfter = () => {
            commit('setAdvertiserList', advertiserList)
            commit('setOnlyAdvertiserList')
            commit('setHasApprovedAdvertiser')
            syncAdvertiserWithSessionStorage(dispatch, advertiserList, resolve)
          }

          const isAdvBrowserCookie = document.cookie.includes(ADVERTISER_COOKIE_NAME)
          if (isAdvBrowserCookie) {
            getAdvFromServerCookie()
              .then(advCode => {
                const advertiser = advertiserList.find(a => a.advId === advCode)
                if (advertiser) {
                  dispatch('setAdvertiser', advertiser)
                }
                processAfter()
              })
          } else {
            processAfter()
          }
        })
      })
    },
    setAdvertiser ({ commit }, advertiser) {
      commit('setAdvertiser', advertiser)
      advertiserApi.setAdvertiserCodeCookie(advertiser.advId, () => { })
      setAdvertiserToSessionStorage(advertiser)
    },
    setAdvertiserAsTotal ({ dispatch }, advertiser) {
      dispatch('setAdvertiser', advertiser)
    },
    setAdvertiserForReportPopup ({ commit }, advertiser) {
      advertiserApi.setAdvertiserCodeCookie(advertiser.advId, () => { })
    }
  },
  mutations: {
    setAdvertiser (state, advertiser) {
      state.advertiser = advertiser
    },
    setAdvertiserList (state, advertiserList) {
      state.advertiserList = advertiserList
    },
    setOnlyAdvertiserList (state) {
      state.onlyAdvertiserList = state.advertiserList.filter(a => a.svcCode === 'ADVERTISER')
    },
    setHasApprovedAdvertiser (state) {
      state.hasApprovedAdvertiser = state.onlyAdvertiserList.some(advertiser => advertiser.advId !== 'ALL')
    }
  }
}
