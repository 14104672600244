import accountApi from '@/api/account'
import _ from '@/partial'
import { USER_ROLE } from '@/types/user-role-types'

export default {
  state: {
    myRole: [],
    isNeedTwoFactorAuthentication: true,
    isNeedSession: true,
    isNeedToChangePassword: false,
    currentPath: ''
  },
  getters: {
    myRole: state => state.myRole,
    isAdmin: (state, getters) => state.myRole.some(r => r === USER_ROLE.NE_MGR) || getters.actCampaignAdminRole,
    isAuthorized: state => !state.isNeedTwoFactorAuthentication && !state.isNeedToChangePassword && !state.isNeedSession,
    hasPaymentRole: state => state.myRole.some(r => r === USER_ROLE.PAYMENT_MGR),
    hasPaymentRoleOnly: state => state.myRole.every(r => r === USER_ROLE.PAYMENT_MGR),
    hasAroundMeRole: state => state.myRole.some(r => r === USER_ROLE.AROUND_ME_MGR),
    hasPublisherRole: state => state.myRole.some(r => r === USER_ROLE.PUBLISHER_MGR),
    hasPublisherRoleOnly: state => state.myRole.every(r => r === USER_ROLE.PUBLISHER_MGR),
    actCampaignAdminRole: state => state.myRole.some(r => r === USER_ROLE.CAMPAIGN_NE_MGR) && state.currentPath.startsWith('/campaign'),
    isNeedTwoFactorAuthentication: state => state.isNeedTwoFactorAuthentication,
    isNeedSession: state => state.isNeedSession,
    isNeedToChangePassword: state => state.isNeedToChangePassword
  },
  actions: {
    fetchMyRole ({ state, commit }, callback) {
      state.myRole.length > 0
        ? callback && callback()
        : accountApi.getMyRole(
          (res) => _.go(
            res.myRole,
            _.tap(_(commit, 'setMyRole')),
            callback
          )
        )
    },
    updateCurrentPath ({ commit }, path) {
      commit('setCurrentPath', path)
    }
  },
  mutations: {
    setMyRole (state, role) {
      _.set(state, 'myRole', role)
    },
    setIsNeedTwoFactorAuthentication (state, need) {
      _.set(state, 'isNeedTwoFactorAuthentication', need)
    },
    setIsNeedSession (state, need) {
      _.set(state, 'isNeedSession', need)
    },
    setIsNeedToChangePassword (state, need) {
      _.set(state, 'isNeedToChangePassword', need)
    },
    setCurrentPath (state, path) {
      state.currentPath = path
    }
  }
}
