<template lang="pug">
#content.adv_center
  .tit_area
    h1.tit 캠페인 관리
  form(onSubmit="return false")
    fieldset
      .query_form
        dl.q_row
          dt.blind 조회 옵션
          dd.inp_col
            .form_row.full_width
              .bx_item
                dl
                  dt.sel_tit 기간
                  dd.sel_cont
                    date-range-picker(
                      ref='dateRange',
                      v-on:change:start="setDateByRangePicker('start', $event)",
                      v-on:change:end="setDateByRangePicker('end', $event)",
                      :max-range="maxRange")
            .form_row.full_width
              advertiser-box-item(
                v-if="isAllAdvertiser",
                ref="advertiserName",
                :advertiser="advertiser",
                :advertiserListAll="advertiserListAll",
                @select="setAdvertiserQuery",
                @selectedAdvertiser="queryForm.selectedAdvertiser = $event",
                v-click-outside="toggleAdvertiserName")
              campaign-box-item(
                ref="campaignName",
                :advertiser="advertiser",
                :campaignNameList="queryForm.campaignNameList",
                :selectedAdvertiser="queryForm.selectedAdvertiser",
                @select="setCampaignQuery",
                v-click-outside="toggleCampaignName")
              campaign-status-box-item(
                ref="campaignStatus",
                @select="setCampaignStatusQuery",
                v-click-outside="toggleCampaignStatus")
              .confirm_area
                button.btn.btn_red(type="button", @click="searchCampaignList(1)") 조회
  .tbl_wrap
    .page_head
      .tit_area(v-if="isAllAdvertiser")
        h2.stit {{'총 ' + table.totalCountOfCampaign + '건'}}
      .btn_group(v-if="!isAllAdvertiser")
        button.btn_item(type='button', @click="updateCampaignStatus('ACTIVE')") 작동
        button.btn_item(type='button', @click="updateCampaignStatus('INACTIVE')") 종료
        template(v-if="isAdmin")
          button.btn_item(type='button', @click="copyCampaign") 복사
          button.btn_item(type='button', @click="deleteCampaigns") 삭제
      .btn_area.tr
        a.btn_w.btn_open_pop(@click='openFullScreen') 크게 보기
        a.btn_w.btn_export.excel(@click="downloadExcel") 엑셀 다운로드
        // 캠페인 가이드 히든 (2022.10.17)
        campaign-guide(ref="campaignGuide"
          v-if="false"
          :hasCampaign="table.campaignList.length > 0"
          @showConfirm="showConfirm"
        )
        a.btn_bl.btn_add_more(v-if="!isAllAdvertiser", @click="createCampaign") 캠페인 생성
    .tbl_scroll.tbl_scroll_x(:class='{tbl_scroll_x2: !isAllAdvertiser}')
      .tbl
        .tbl_content
          .tbl_head
            table
              caption
                span 캠페인 승인
              colgroup
                template(v-if='isAllAdvertiser')
                  col(v-for='c in allAdvColumns' :style='{width: c.width}')
                template(v-else)
                  col(v-for='c in specificAdvColumns' :key='c.id' :style='{width: c.width}')
                col(v-for='c in defaultColumns' :key='c.id' :style='{width: c.width}')
              thead
                tr
                  th.fixed.col1(scope='col', v-if="!isAllAdvertiser")
                    span.inp_checkbox2
                      // [D] 선택 시 .is_checked 추가
                      input#campaign-checkbox-all.inp_chk(type='checkbox', :checked='table.campaignList.length && table.campaignList.length === table.campaignCheckBox.length' @click="campaignCheckBoxAll($event)")
                      label.check_control_label(for='campaign-checkbox-all')
                        span.ico_chk
                  th.align_left.fixed(scope='col', v-if="isAllAdvertiser") 광고주명
                  th(scope='col', v-if="isAllAdvertiser") 광고주ID
                  th.sort.align_left(scope='col' :class='{fixed: !isAllAdvertiser, col2: !isAllAdvertiser}')
                    button(type='button', @click="sortReport('CAMPAIGN_NAME')") 캠페인명
                  th(v-for='c in defaultColumns' scope='col' :class='{sort: c.sortable}')
                    button(v-if='c.sortable' type='button', @click="sortReport(c.id)") {{ c.name }}
                    template(v-else) {{ c.name }}
          .tbl_body
            .tbl_head_left
              table
                colgroup
                  col(style='width:124px', v-if="isAllAdvertiser")
                  template(v-else)
                    col(style='width:39px')
                    col(style='width:130px')
                tbody
                  tr(v-for='(item, index) in table.campaignList')
                    td.txt.align_left(
                      v-if="isAllAdvertiser", :style="{'--td-text-decoration--hover':'underline', cursor:'pointer'}",
                      @click="changeAdvertiser(item.advertiserCode)"
                    ) {{item.advertiserName}}
                    td(v-if="!isAllAdvertiser")
                      span.inp_checkbox2
                        // [D] 선택 시 .is_checked 추가
                        input.inp_chk(:id="'campaign-checkbox-' + index", :value="item.campaignCode",
                          type='checkbox', v-model="table.campaignCheckBox")
                        label.check_control_label(:for="'campaign-checkbox-' + index")
                          span.ico_chk
                          span.blind 종료
                    td.txt.align_left(
                      v-if="!isAllAdvertiser"
                      :style="{'--td-text-decoration--hover':'underline', cursor:'pointer'}",
                      @click="goCampaign(item)"
                    ) {{item.campaignName}}
            .tbl_scroll_area.js-scrollbar-external.tbl_no_scroll_y
              table
                colgroup
                  template(v-if='isAllAdvertiser')
                    col(style='width:88px')
                    col(style='width:130px')
                  col(v-for='c in defaultColumns' :style='{width: c.width}')
                tbody
                  tr(v-for="item in table.campaignList")
                    td(v-if="isAllAdvertiser") {{item.advertiserCode}}
                    td.txt.align_left(
                      v-if='isAllAdvertiser'
                      :style="{'--td-text-decoration--hover':'underline', cursor:'pointer'}",
                      @click="goCampaign(item)"
                    ) {{item.campaignName}}
                    // [D] 한글 기준 최대 35자 제한
                    td {{item.campaignCode}}
                    td {{item.feeType}}
                    td {{item.feeValue}}
                    td
                      template(v-if="item.campaignTargetStatus === '타겟' || item.campaignTargetStatus === '타겟(제외)'")
                        em.point_txt {{item.campaignTargetStatus}}
                      template(v-else)
                        | {{ item.campaignTargetStatus }}
                    td.txt(v-if="item.campaignApprovalStatus === '반려'")
                      button.imp_box(@click="setRejectMessage(item.rejectMessage)") {{item.campaignApprovalStatus}}
                    td.txt(v-else) {{item.campaignApprovalStatus}}
                    td.txt
                      span.status_box(:class="getStatusBoxClass(item.campaignStatus, item.campaignEndDate)") {{getStatusBoxDisplay(item)}}
                    td {{formatDate(new Date(item.campaignStartDate))}}
                    td {{formatDate(new Date(item.campaignEndDate))}}
                    td(v-if="item.campaignGroupType === 'CLO'") -
                    td(v-else) {{item.budget}}
                    td {{item.bannerImpression}}
                    td {{item.bannerClick}}
                    td {{item.totalClick}}
                    td {{item.ctr}}
        .external-scroll_x
          .scroll-element_outer
            .scroll-element_size
            .scroll-element_track
            .scroll-bar
        .external-scroll_y
          .scroll-element_outer
            .scroll-element_size
            .scroll-element_track
            .scroll-bar
  paging(
    :currentPage='table.search.page.now'
    :pageSize='table.search.page.size'
    :totalCount='table.totalCountOfCampaign'
    @onClickPage='searchCampaignList'
  )
  confirm(v-if="showMessage", :confirm="confirm", @yes="confirm.yesCallback()", @no="confirm.noCallback()")
  reject-read(v-if="showRejectMessage", :rejectMessage="this.showRejectMessage" v-on:close="closeRejectMessage")
</template>

<script>
import { campaignManager } from './campaign-list'
import { mapActions, mapGetters } from 'vuex'
import DateRangePicker from '../common/DateRangePicker'
import campaignApi from '@/api/campaign'
import Confirm from './fragments/Confirm'
import jQuery from 'jquery'
import { CAMPAIGN_GROUP_TYPE, CAMPAIGN_TYPE } from '@/types/campaign-types'
// eslint-disable-next-line no-unused-vars
import $ from '@/util.js'
import AdvertiserBoxItem from './fragments/CampaignList/AdvertiserBoxItem'
import CampaignBoxItem from './fragments/CampaignList/CampaignBoxItem'
import CampaignStatusBoxItem from './fragments/CampaignList/CampaignStatusBoxItem'
import RejectRead from './fragments/RejectRead'
import store from '@/store'
import Paging from '../common/fragments/Paging'
import setAdvertiserMixin from '@/components/common/mixins/set-advertiser-mixin'
import CampaignGuide from './fragments/CampaignList/CampaignGuide'

export default {
  components: {
    CampaignGuide,
    CampaignStatusBoxItem,
    CampaignBoxItem,
    AdvertiserBoxItem,
    Confirm,
    DateRangePicker,
    RejectRead,
    Paging
  },
  mixins: [setAdvertiserMixin],
  created () {
    this.fetchMyRole()
  },
  mounted () {
    this.initDate()
    if (this.advertiser && this.onlyAdvertiserList.length) {
      this.table.search.advertiserList = this.advertiser.advId === 'ALL'
        ? 'ALL'
        : [{
          advertiserCode: this.advertiser.advId,
          advertiserName: this.advertiser.advName
        }]
      this.searchCampaignList(1)
      !this.isAllAdvertiser && this.getCampaignNameList()
    }
    this.$nextTick().then(this.activateScroll)
  },
  data () {
    return {
      columns: [],
      allAdvColumns: [
        {
          id: 'ADVERTISER_NAME',
          name: '광고주명',
          width: '124px',
          sortable: false
        },
        {
          id: 'ADVERTISER_CODE',
          name: '광고주ID',
          width: '88px',
          sortable: false
        },
        {
          id: 'CAMPAIGN_NAME',
          name: '캠페인명',
          width: '130px',
          sortable: true
        }
      ],
      specificAdvColumns: [
        {
          id: 'CAMPAIGN_NAME',
          name: '캠페인명',
          width: '169px',
          sortable: true
        }
      ],
      defaultColumns: [
        {
          id: 'CAMPAIGN_ID',
          name: '캠페인ID',
          width: '88px',
          sortable: false
        },
        {
          id: 'FEE_TYPE',
          name: '과금 방식',
          width: '60px',
          sortable: true
        },
        {
          id: 'FEE_VALUE',
          name: '단가(원, %)',
          width: '60px',
          sortable: false
        },
        {
          id: 'CAMPAIGN_TARGET',
          name: '타겟',
          width: '55px',
          sortable: false
        },
        {
          id: 'CAMPAIGN_APPROVAL_STATUS',
          name: '심사',
          width: '60px',
          sortable: true
        },
        {
          id: 'CAMPAIGN_STATUS',
          name: '상태',
          width: '60px',
          sortable: true
        },
        {
          id: 'CAMPAIGN_START_DATE',
          name: '시작일',
          width: '76px',
          sortable: false
        },
        {
          id: 'CAMPAIGN_END_DATE',
          name: '종료일',
          width: '76px',
          sortable: false
        },
        {
          id: 'BUDGET',
          name: '총예산',
          width: '110px',
          sortable: true
        },
        {
          id: 'BANNER_IMPRESSION',
          name: '노출수',
          width: '100px',
          sortable: true
        },
        {
          id: 'BANNER_CLICK',
          name: '배너클릭',
          width: '76px',
          sortable: true
        },
        {
          id: 'TOTAL_CLICK',
          name: '총클릭',
          width: '80px',
          sortable: true
        },
        {
          id: 'CTR',
          name: 'CTR(%)',
          width: '65px',
          sortable: true
        }
      ],
      queryForm: {
        campaignNameList: [],
        selectedAdvertiser: '전체'
      },
      table: {
        marginLeft: 165,
        search: {
          date: {
            start: '',
            end: ''
          },
          advertiserList: 'ALL',
          campaignName: '전체',
          campaignStatus: ['CREATING', 'WAIT_APPROVAL', 'ACTIVE', 'INACTIVE'],
          sort: {
            column: 'CAMPAIGN_ID',
            direction: 'DESC'
          },
          page: {
            now: 1,
            size: 50
          }
        },
        campaignList: [],
        totalCountOfCampaign: 0,
        campaignCheckBox: []
      },
      showMessage: '',
      showRejectMessage: '',
      confirm: {
        subTitle: '',
        text: '',
        yes: '',
        no: '',
        yesCallback: () => {
          this.showMessage = false
        },
        noCallback: () => {
        }
      },
      isReadCampaignGuide: false,
      AD_TYPES: []
    }
  },
  methods: {
    ...mapActions(['setAdvertiser', 'fetchMyRole']),
    initDate () {
      const rangePicker = this.$refs.dateRange.rangepicker
      const range = [this.ymd.limitedStartDate, this.ymd.today]
      rangePicker.setRanges([range])
      rangePicker.setStartDate(this.ymd.aWeekAgo)
      rangePicker.setEndDate(this.ymd.today)
    },
    setDateByRangePicker (target, e) {
      target === 'start' ? this.table.search.date.start = e : this.table.search.date.end = e
    },
    setAdvertiserQuery (advertiserList) {
      this.table.search.advertiserList = advertiserList
      this.getCampaignNameList()
    },
    setCampaignQuery (campaignName) {
      this.table.search.campaignName = campaignName
    },
    setCampaignStatusQuery (campaignStatus) {
      this.table.search.campaignStatus = campaignStatus
    },
    toggleAdvertiserName (e) {
      this.$refs.advertiserName.close()
    },
    toggleCampaignName (e) {
      this.$refs.campaignName.close()
    },
    toggleCampaignStatus (e) {
      this.$refs.campaignStatus.close()
    },
    searchCampaignList (requestPageNumber) {
      const search = this.table.search
      search.page.now = requestPageNumber
      campaignApi.getCampaignList({
        date: {
          start: search.date.start.getTime(),
          end: search.date.end.getTime() + 1000 * 60 * 60 * 24 - 1
        },
        advertiserList: search.advertiserList === 'ALL' ? this.advertiserListAll : search.advertiserList,
        isSpecificAdvertiser: this.isSpecificAdvertiser,
        campaignName: search.campaignName === '전체' ? 'ALL' : search.campaignName,
        campaignStatus: search.campaignStatus,
        sort: search.sort,
        page: search.page
      },
      body => {
        this.table.campaignList = body.campaignList.campaignList
        this.table.totalCountOfCampaign = body.campaignList.totalCountOfCampaign
      })
    },
    getCampaignNameList () {
      campaignApi.getCampaignNameList({
        startDate: this.table.search.date.start.getTime(),
        endDate: this.table.search.date.end.getTime(),
        advertiserCodeList: this.table.search.advertiserList === 'ALL'
          ? this.advertiserListAll.map(advertiser => advertiser.advertiserCode)
          : this.table.search.advertiserList.map(advertiser => advertiser.advertiserCode)
      }, body => {
        this.queryForm.campaignNameList = body.campaignNameList
      })
    },
    sortReport (newSortingColumn) {
      const originSortingColumn = this.table.search.sort.column
      if (originSortingColumn === newSortingColumn) {
        this.table.search.sort.direction === 'ASC'
          ? this.table.search.sort.direction = 'DESC'
          : this.table.search.sort.direction = 'ASC'
      }
      this.table.search.sort.column = newSortingColumn
      this.searchCampaignList(this.table.search.page.now)
    },
    campaignCheckBoxAll (e) {
      e.target.checked
        ? this.table.campaignCheckBox = this.campaignCodeAll
        : this.table.campaignCheckBox = []
    },
    updateCampaignStatus (toStatus) {
      const checkedCampaignIds = this.table.campaignCheckBox
      const checkedCampaigns = this.table.campaignList.filter(c => checkedCampaignIds.includes(c.campaignCode))
      const hasAuthority = this.isAvailableCampaignUpdate()
      const sucess = body => {
        this.showConfirm(body.result.message)
        this.searchCampaignList(this.table.search.page.now)
        this.table.campaignCheckBox = []
      }
      const api = toStatus === 'ACTIVE' ? campaignManager.activateCampaigns : campaignManager.deactivateCampaigns
      const result = api(checkedCampaigns, hasAuthority, sucess)
      if (!result.isValid) {
        this.showConfirm(result.message, '확인', () => { this.showMessage = false }, '', () => {})
      }
    },
    copyCampaign () {
      const checkedCampaignIds = this.table.campaignCheckBox
      const checkedCampaigns = this.table.campaignList.filter(c => checkedCampaignIds.includes(c.campaignCode))
      const hasAuthority = this.isAvailableCampaignUpdate()
      const confirm = (yesCallback, text) => {
        this.confirm = {
          subTitle: '해당 캠페인을 복사하시겠습니까?',
          text: text,
          yes: '확인',
          no: '취소',
          yesCallback: () => {
            this.showMessage = false
            yesCallback()
          },
          noCallback: () => { this.showMessage = false }
        }
        this.showMessage = true
      }
      const success = body => {
        this.searchCampaignList(1)
        this.table.campaignCheckBox = []
        if (body.result.code === 0) {
          this.showConfirm(
            '캠페인이 복사되었습니다.\n복사 생성한 캠페인으로\n이동하시겠습니까?',
            '바로가기',
            () => { this.$router.push(`/campaign/create/default?campaignId=${body.result.copiedCampaignId}`) },
            '취소'
          )
        } else {
          store.commit('setError', { id: Date.now(), message: body.result.message, hadshown: false })
        }
      }
      const result = campaignManager.copyCampaigns(checkedCampaigns, hasAuthority, confirm, success)
      if (!result.isValid) {
        this.showConfirm(result.message, '확인', () => { this.showMessage = false }, '', () => {})
      }
    },
    changeAdvertiser (advertiserCode) {
      this.setAdvertiserMixin(this.onlyAdvertiserList, advertiserCode)
    },
    goCampaign ({
      campaignCode,
      campaignStatus,
      advertiserCode,
      campaignGroupType,
      campaignType
    }) {
      if (!this.isAvailableCampaignUpdate(campaignGroupType, campaignType)) {
        this.showConfirm('해당 캠페인에 수정 권한이 없어서\n확인할 수 없습니다.')
        return
      }
      const subPath = campaignGroupType === 'MALL_REWARD' ? 'shopping-reward' : 'default'
      const route = campaignStatus === '임시저장'
        ? {
          path: '/campaign/create/default',
          query: {
            campaignId: campaignCode
          }
        }
        : {
          path: `/campaign/update/${subPath}/${campaignCode}`
        }
      this.changeAdvertiser(advertiserCode)
      this.$router.push(route)
    },
    createCampaign () {
      if (this.$refs.campaignGuide && !this.$refs.campaignGuide.checkReadCampaignGuide()) {
        return
      }
      this.$router.push('/campaign/create/type')
    },
    downloadExcel () {
      const search = this.table.search
      campaignApi.downloadCampaignListExcel({
        date: {
          start: search.date.start.getTime(),
          end: search.date.end.getTime() + 1000 * 60 * 60 * 24 - 1
        },
        advertiserList: search.advertiserList === 'ALL' ? this.advertiserListAll : search.advertiserList,
        isSpecificAdvertiser: this.isSpecificAdvertiser,
        campaignName: search.campaignName === '전체' ? 'ALL' : search.campaignName,
        campaignStatus: search.campaignStatus,
        sort: search.sort,
        page: {
          now: 0,
          size: 0
        }
      },
      blob => {
        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, 'campaign-list.xlsx')
        } else {
          const a = document.createElement('a')
          a.href = URL.createObjectURL(blob)
          a.setAttribute('download', 'campaign-list.xlsx')
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    showConfirm (message, yes = '확인', yesCallback = () => {
      this.showMessage = false
    }, no = '', noCallback = () => {
      this.showMessage = false
    }) {
      this.confirm = {
        subTitle: '',
        text: message,
        yes: yes,
        no: no,
        yesCallback: yesCallback,
        noCallback: noCallback
      }
      this.showMessage = true
    },
    showCopyConfirm (yesCallback = () => {
      this.showMessage = false
    }) {
      this.confirm = {
        subTitle: '해당 캠페인을 복사하시겠습니까?',
        text: '쿠폰 및 타겟, 공유하기 설정은 복사되지 않습니다.',
        yes: '확인',
        no: '취소',
        yesCallback: yesCallback,
        noCallback: () => {
          this.showMessage = false
        }
      }
      this.showMessage = true
    },
    setRejectMessage (message) {
      this.showRejectMessage = message
    },
    closeRejectMessage () {
      this.showRejectMessage = ''
    },
    formatDate (date) {
      if (date < new Date('1980-01-01')) {
        return '-'
      } else {
        return $.formatDate(date)
      }
    },
    getStatusBoxClass (status, endDateTime) {
      const endDate = new Date(endDateTime)
      return {
        work: status === '작동' && endDate > Date.now(),
        checking: status === '심사중',
        save_storage: status === '임시저장',
        end: status === '종료' || (status === '작동' && endDate <= Date.now())
      }
    },
    checkCampaignDeactiveNow (campaignDeactivePeriods) {
      return !campaignDeactivePeriods.every(function (item, idx) {
        return item.deactiveStartDateTime > Date.now() || Date.now() > item.deactiveEndDateTime
      })
    },
    getStatusBoxDisplay ({
      campaignStatus,
      campaignStartDate,
      campaignEndDate,
      targetFileUploaded,
      campaignDeactiveYN,
      campaignDeactivePeriods,
      campaignGroupType
    }) {
      if (campaignStatus === '작동' && campaignStartDate > Date.now()) {
        return '작동(대기)'
      } else if (campaignStatus === '작동' && campaignEndDate <= Date.now()) {
        return '종료'
      } else if (campaignStatus === '작동' && campaignDeactiveYN && this.checkCampaignDeactiveNow(campaignDeactivePeriods)) {
        return '작동(중지)'
      } else if (campaignStatus === '작동' && !targetFileUploaded && campaignGroupType !== CAMPAIGN_GROUP_TYPE.DISPLAYAD) {
        return '작동(업로드)'
      } else {
        return campaignStatus
      }
    },
    activateScroll () {
      jQuery('.js-scrollbar-external').scrollbar({
        autoScrollSize: true,
        scrollx: jQuery('.external-scroll_x'),
        scrolly: jQuery('.external-scroll_y'),
        onInit: () => {
          document.querySelector('.js-scrollbar-external').firstElementChild.addEventListener('scroll', () => {
            document.querySelector('.tbl_head').scrollLeft = document.querySelector('.js-scrollbar-external').firstElementChild.scrollLeft
            document.querySelector('.tbl_head_left').scrollTop = document.querySelector('.js-scrollbar-external').firstElementChild.scrollTop
          })
        }
      })
    },
    openFullScreen () {
      let widths = this.isAllAdvertiser ? [124, 88] : []
      let dataColumn = this.isAllAdvertiser ? ['advertiserName', 'advertiserCode'] : []
      let columns = this.isAllAdvertiser
        ? [{ name: '광고주명', sort: false, rowspan: 1, colspan: 1 }, { name: '광고주ID', sort: false, rowspan: 1, colspan: 1 }]
        : []
      widths = widths.concat([130, 88, 60, 50, 60, 55, 60, 76, 76, 110, 100, 76, 80, 65])
      dataColumn = dataColumn.concat(['campaignName', 'campaignCode', 'feeType', 'feeValue', 'campaignTargetStatus', 'campaignApprovalStatus', 'campaignStatus', 'campaignStartDate', 'campaignEndDate', 'budget', 'bannerImpression', 'bannerClick', 'totalClick', 'ctr'])
      columns = columns.concat([
        {
          name: '캠페인명',
          sort: true,
          orderBy: 'CAMPAIGN_NAME',
          sortType: 'DESC',
          rowspan: 1,
          colspan: 1
        },
        {
          name: '캠페인ID',
          sort: false,
          rowspan: 1,
          colspan: 1
        },
        {
          name: '과금 방식',
          sort: true,
          orderBy: 'FEE_TYPE',
          rowspan: 1,
          colspan: 1
        },
        {
          name: '단가(원, %)',
          sort: false,
          rowspan: 1,
          colspan: 1
        },
        {
          name: '타겟',
          sort: false,
          rowspan: 1,
          colspan: 1
        },
        {
          name: '심사',
          sort: true,
          orderBy: 'CAMPAIGN_APPROVAL_STATUS',
          sortType: 'DESC',
          rowspan: 1,
          colspan: 1
        },
        {
          name: '상태',
          sort: true,
          orderBy: 'CAMPAIGN_STATUS',
          sortType: 'DESC',
          rowspan: 1,
          colspan: 1
        },
        {
          name: '시작일',
          sort: false,
          rowspan: 1,
          colspan: 1
        },
        {
          name: '종료일',
          sort: false,
          rowspan: 1,
          colspan: 1
        },
        {
          name: '총예산',
          sort: true,
          orderBy: 'BUDGET',
          sortType: 'DESC',
          rowspan: 1,
          colspan: 1
        },
        {
          name: '노출수',
          sort: true,
          orderBy: 'BANNER_IMPRESSION',
          sortType: 'DESC',
          rowspan: 1,
          colspan: 1
        },
        {
          name: '배너클릭',
          sort: true,
          orderBy: 'BANNER_CLICK',
          sortType: 'DESC',
          rowspan: 1,
          colspan: 1
        },
        {
          name: '총클릭',
          sort: true,
          orderBy: 'TOTAL_CLICK',
          sortType: 'DESC',
          rowspan: 1,
          colspan: 1
        },
        {
          name: 'CTR(%)',
          sort: true,
          orderBy: 'CTR',
          sortType: 'DESC',
          rowspan: 1,
          colspan: 1
        }
      ])
      this.$router.push({
        name: 'FullScreenGrid',
        params: {
          type: 'campaignManagement',
          isAdmin: false,
          headType: 'default',
          head: '캠페인 관리',
          isSpecificAdvertiser: !this.isAllAdvertiser,
          designatedCampaignStatus: {
            params: {
              advertiserList: this.table.search.advertiserList === 'ALL' ? this.advertiserListAll : this.table.search.advertiserList,
              campaignName: this.table.search.campaignName === '전체' ? 'ALL' : this.table.search.campaignName,
              campaignStatus: this.table.search.campaignStatus,
              date: {
                start: this.table.search.date.start.getTime(),
                end: this.table.search.date.end.getTime() + 1000 * 60 * 60 * 24 - 1
              },
              page: {
                now: this.table.search.page.now,
                size: this.table.search.page.size
              },
              sort: {
                column: this.table.search.sort.column,
                direction: this.table.search.sort.direction
              }
            },
            frame: {
              title: `총 ${this.table.totalCountOfCampaign}건`,
              tableWidth: 1024,
              marginLeft: -20,
              widths: widths,
              widthPostfix: 'px',
              isSummary: false,
              dataColumn: dataColumn,
              columns: columns
            },
            totalCount: this.table.totalCountOfCampaign,
            data: this.table.campaignList,
            summary: {}
          }
        }
      })
    },
    isAvailableStatusUpdate () {
      const invalid = [
        {
          condition: this.advertiser.isRewardShoppingMall,
          message: '쇼핑적립 캠페인의 경우<br>운영자가 작동/종료 처리<br>할 수 없습니다.'
        },
        {
          condition: this.table.campaignCheckBox.length <= 0,
          message: '캠페인을 선택해주세요.'
        }
      ].find(({ condition }) => condition)
      invalid && this.showConfirm(invalid.message)
      return invalid === undefined
    },
    showPinkToast (message) {
      store.commit('setError', {
        id: Date.now(),
        message: message,
        hadshown: false
      })
    },
    deleteCampaigns () {
      const checkedCampaignIds = this.table.campaignCheckBox
      const checkedCampaigns = this.table.campaignList.filter(c => checkedCampaignIds.includes(c.campaignCode))
      const confirm = (yesCallback) => {
        this.showConfirm(
          '해당 캠페인을 삭제하시겠습니까?',
          '확인',
          () => {
            this.showMessage = false
            yesCallback()
          },
          '취소'
        )
      }
      const success = ({ result: { message } }) => {
        this.showPinkToast(message)
        this.searchCampaignList(this.table.search.page.now)
        this.table.campaignCheckBox = []
      }
      const result = campaignManager.deleteCampaigns(checkedCampaigns, confirm, success)
      if (!result.isValid) {
        this.showConfirm(result.message, '확인', () => { this.showMessage = false }, '', () => {})
      }
    },
    isAvailableCampaignUpdate (campaignGroupType, campaignType) {
      return this.isAdmin || (this.hasAroundMeRole && campaignGroupType === CAMPAIGN_GROUP_TYPE.AROUND_ME && campaignType === CAMPAIGN_TYPE.OUTER)
    }
  },
  computed: {
    ...mapGetters(['advertiser', 'onlyAdvertiserList', 'isAdmin', 'hasAroundMeRole']),
    ymd () {
      const today = new Date()
      return {
        today: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        aWeekAgo: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6),
        aYearAgo: new Date(today.getFullYear() - 1, today.getMonth(), today.getDate()),
        limitedStartDate: new Date(Math.max(
          // 오늘로부터 3년, 단 2021년 1월 1일부터
          new Date(2021, 0, 1).getTime(),
          new Date(today.getFullYear() - 3, today.getMonth(), 0).getTime())
        )
      }
    },
    campaignCodeAll () {
      return this.table.campaignList.map(value => value.campaignCode)
    },
    isAllAdvertiser () {
      return this.advertiser ? this.advertiser.advId === 'ALL' : false
    },
    isSpecificAdvertiser () {
      return !this.isAllAdvertiser
    },
    advertiserListAll () {
      const advertiserListAll = this.onlyAdvertiserList.slice(1)
      return advertiserListAll.map(function (advertiser) {
        return {
          advertiserCode: advertiser.advId,
          advertiserName: advertiser.advName
        }
      })
    },
    maxRange () {
      return this.isSpecificAdvertiser
        ? {
          years: 1,
          months: 0,
          days: 0
        }
        : {
          years: 0,
          months: 3,
          days: 0
        }
    }
  },
  watch: {
    advertiser () {
      this.table.search.advertiserList = this.advertiser.advId === 'ALL'
        ? 'ALL'
        : [{
          advertiserCode: this.advertiser.advId,
          advertiserName: this.advertiser.advName
        }]
      this.isAllAdvertiser
        ? this.queryForm.campaignNameList = []
        : this.getCampaignNameList()
      this.initDate()
      this.onlyAdvertiserList.length && this.searchCampaignList(1)
    },
    advertiserList (newVal, oldVal) {
      if (oldVal.length === 0 && this.advertiser !== null) {
        this.searchCampaignList(1)
      }
    }
  },
  directives: {
    'click-outside': $.clickOutside()
  }
}
</script>
