<template lang="pug">
  .ly_pop.ly_pop_wrap.new_store_reg.adv_center
    .ly_pop_layer
      .ly_pop_cont
        h1.pop_tit 매장 신규 등록
        .form
          table
            caption
              span 매장 신규 등록
            colgroup
              col(style='width:30px')
              col
            tbody
              tr
                td
                  span.inp_radio
                    input.inp_rdo(type='radio',
                      name='rdo1',
                      id='ns2_rdo1',
                      checked='true',
                      :value='false',
                      v-model='isBulk')
                    label.rdo_control_label.lbl(for='ns2_rdo1')
                      span.ico_rdo
                td
                  label.rdo_control_label.lbl(for='ns2_rdo1')
                    span.ico_rdo 직접입력
                      span
                  table
                    colgroup
                      col(style='width:66px')
                      col(style='width:356px')
                      col
                    tbody
                      tr
                        td
                          label.ns2_inp1 광고주명
                        td {{ advertiserName }}
                        td
                      tr
                        td
                          label.ns2_inp3 매장명
                            em.required
                              span.blind 필수
                        td
                          .inp_area(:class="{has_error: !isBulk && !validation.shopName.isValid}")
                            .inp_bx(style="width: 100%;")
                              span.txt_lbl(v-if="shop.shopName === ''") 예: 대관령 곱창
                              input(type='text',
                                :value='shop.shopName',
                                @input="onInputTarget('shopName', $event.target.value)")
                            span.txt_explain(v-if='!isBulk') {{ validation.shopName.message }}
                        td
                          span.txt_count {{ validation.shopName.bytes }}/100
                      tr
                        td
                          label(for=ns2_inp3) 주소
                            em.required
                              span.blind 필수
                        td
                          .inp_area(:class="{has_error: !isBulk && !validation.address.isValid}")
                            .inp_bx(style="width: 100%;")
                              span.txt_lbl(v-if="shop.address === ''") 예: 경기도 성남시 삼평동 629 플뮤
                              input(type='text',
                                :value='shop.address',
                                @input="onInputTarget('address', $event.target.value)")
                            span.txt_explain(v-if='!isBulk') {{ validation.address.message }}
                        td
                          span.txt_count {{ validation.address.bytes }}/200
                      tr(v-if='isAdmin', style='display: table-row;')
                        td
                          label 위경도
                            em.required
                              span.blind 필수
                        td
                          .latlong_inp_wrap
                            .inp_area(:class="{has_error: !isBulk && !validation.latitude.isValid}")
                              label(for='ns2_inp4') 북위
                              .inp_bx(style="width: 132px;")
                                span.txt_lbl(v-if="shop.latitude === ''") 예: 37.401234
                                input(type='text',
                                  :value='shop.latitude',
                                  @input="onInputTarget('latitude', $event.target.value)")
                            .inp_area(:class="{has_error: !isBulk && !validation.longitude.isValid}")
                              label(for='ns2_inp5') 동경
                              .inp_bx(style="width: 132px;")
                                span.txt_lbl(v-if="shop.longitude === ''") 예: 127.104273
                                input(type='text',
                                  :value='shop.longitude',
                                  @input="onInputTarget('longitude', $event.target.value)")
                          span.txt_explain(v-if='!isBulk && !(validation.latitude.isValid && validation.longitude.isValid)',
                            style='color: #fa2828;') 유효한 위경도 값을 입력해주세요.
                        td
              tr
                td
                  span.inp_radio
                    input.inp_rdo(type='radio',
                      id='ns2_rdo2',
                      :value='true',
                      v-model='isBulk')
                    label.rdo_control_label.lbl(for='ns2_rdo2')
                      span.ico_rdo
                td
                  label.rdo_control_label.lbl(for='ns2_rdo2')
                    span.ico_rdo 벌크입력
                  .inp_area
                    .addfile_wrap
                      span.inp_file
                        span.txt_file(
                          @click='selectFile',
                          :style="{cursor: csvFile.file==null?'pointer':'not-allowed'}"
                        ) 파일업로드
                      download-file-form(:file-path='csvFilePath' :file-name='csvFileName')
                      .bx_add_file(v-if='csvFile.file!==null')
                        ul.lst_einfo
                          ul
                            p.file_name
                              a.txt_link(style='text-overflow: ellipsis; overflow: hidden; max-width: 300px') {{csvFile.fileName}}
                            button.btn_del_file(@click='removeFile')
                              span.blind 첨부파일 삭제
                  .notice_area
                    strong.notice_title 파일업로드 유의사항
                    ul
                      li 파일 형식이 csv, utf-8 형식인지 확인해주세요.
                      li 첫 행은 유지하고 두번째 행부터 내용을 작성해주세요.
                      li 매장명은 100 byte, 매장주소는 200 byte 까지 입력 가능합니다.
                      li 매장명에 ,(콤마)가 있다면 콤마를 지우고 파일을 등록해주세요.
                      li(v-if='isAdmin')
                        | 위,경도는 십진수 (도)(DD)방식으로 표기하고 소수점 자리까지 입력해주세요.
                        br
                        | (예: 37.401234, 127.104273)
        .btn_act_area
          button.btn.btn_bl.btn_pop_lg(type='button',
            :class="{disable: isDisableOnRegister}",
            @click="preRegister") 등록
      confirm(v-if="popUp.showConfirm", :confirm="confirm" @yes="confirm.yesCallback", @no="confirm.noCallback")
      button.btn_close(type='button', @click="closeModal")
        span.blind 닫기
</template>

<script>
import { mapGetters } from 'vuex'
import Confirm from './Confirm'
import ShopApi from '@/api/shop'
import FileManager from '../../common/file-manager'
import DownloadFileForm from '@/components/common/DownloadFileForm'
import { ShopForm } from '@/components/shop/shop-form'

export default {
  props: ['advertiserCode', 'advertiserName'],
  components: {
    DownloadFileForm,
    Confirm
  },
  data: function () {
    return {
      isBulk: false,
      csvFile: {
        file: null,
        fileName: '',
        progress: 0
      },
      shop: {
        shopName: '',
        address: '',
        latitude: '',
        longitude: ''
      },
      popUp: {
        showConfirm: false
      },
      validation: null,
      validationFunc: null
    }
  },
  created () {
    const shopForm = new ShopForm()
    this.validation = shopForm.validation
    this.validationFunc = shopForm
    if (!this.isAdmin) {
      this.validation.latitude.isValid = true
      this.validation.longitude.isValid = true
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
    isDisableOnRegister () {
      return this.isBulk ? (this.csvFile.file == null) : !this.validationFunc.validAll()
    },
    csvFileName () {
      return this.isAdmin ? 'shop_bulk_sample_admin.csv' : 'shop_bulk_sample.csv'
    },
    csvFilePath () {
      return this.isAdmin ? '/static/csv/shop_bulk_sample_admin.csv' : '/static/csv/shop_bulk_sample.csv'
    }
  },
  methods: {
    closeModal: function () {
      this.$emit('showModal', false)
    },
    closeModalAndRefresh: function () {
      var callback = () => {
        this.$emit('onRegistered')
        this.$emit('showModal', false)
      }
      if (this.isAdmin) {
        callback()
      } else {
        this.popUp.showConfirm = false
        this.confirm = {
          text: '매장 신규 등록을 요청하였습니다.',
          yes: '확인',
          yesCallback: () => {
            callback()
          },
          noCallback: () => {
          }
        }
        this.popUp.showConfirm = true
      }
    },
    onInputTarget (target, value) {
      this.shop[target] = value
      var suffix = target.substring(0, 1).toUpperCase() + target.substring(1, target.length)
      this.validationFunc['valid' + suffix](value)
    },
    selectFile: function () {
      if (this.csvFile.file !== null) return
      var callback = file => {
        this.csvFile.file = file.data
        this.csvFile.fileName = file.name
        this.isBulk = true
      }
      FileManager.openFile('.csv').then(callback)
    },
    removeFile: function () {
      this.csvFile.file = null
      this.csvFile.fileName = ''
      this.csvFile.fileUrl = ''
    },
    preRegister: function () {
      if (!this.isDisableOnRegister) {
        this.confirm = {
          yes: '확인',
          no: '취소',
          yesCallback: () => {
            this.register()
          },
          noCallback: () => {
            this.popUp.showConfirm = false
          }
        }
        if (this.isAdmin) {
          this.confirm.popupType = 'v2'
          this.confirm.text = '매장으로 등록하시겠습니까?'
        } else {
          this.confirm.subTitle = '매장으로 등록하시겠습니까?'
          this.confirm.text = '운영자 승인 후 매장 등록이 완료됩니다.'
        }
        this.popUp.showConfirm = true
      }
    },
    register: function () {
      this.popUp.showConfirm = false
      if (this.isBulk) {
        this.csvFile.isInProgress = true
        ShopApi.registerShopBulk(
          {
            advertiserCode: this.advertiserCode,
            advertiserName: this.advertiserName,
            isAdmin: this.isAdmin,
            file: this.csvFile.file
          },
          e => {
            this.csvFile.progress = e.loaded / e.total * 100
          },
          () => {
            this.popUp.showConfirm = true
            this.confirm = {
              subTitle: '',
              text: '매장이 등록중입니다. \n매장 개수가 많을 경우, \n모든 매장이 등록될 때까지 다소 시간이 소요될 수 있습니다.\n',
              yes: '확인',
              yesCallback: () => {
                this.closeModalAndRefresh()
              },
              noCallback: () => {
              }
            }
          },
          result => {
            if (!result.isSuccessful) {
              this.popUp.showConfirm = true
              this.confirm = {
                subTitle: '',
                text: '파일업로드에 실패하였습니다.\n유의사항을 확인하고\n파일을 수정하여 다시 업로드해주세요.\n지속적으로 업로드에 실패할 경우\n관리자에게 문의해주세요.',
                yes: '확인',
                yesCallback: () => {
                  this.popUp.showConfirm = false
                },
                noCallback: () => {
                }
              }
            }
          }
        )
      } else {
        ShopApi.registerShop({
          advertiserCode: this.advertiserCode,
          advertiserName: this.advertiserName,
          isAdmin: this.isAdmin,
          shopModel: this.shop
        }, this.closeModalAndRefresh, result => {
          if (!result.isSuccessful) alert(result.resultMessage || '매장 등록에 실패하였습니다. 관리자에게 문의해주세요.')
        })
      }
    }
  }
}
</script>
