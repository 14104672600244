<template lang="pug">
.bx_item
  dl
    dt.sel_tit 캠페인 상태
    dd.sel_cont
      // [D] 목록 레이어 노출시 .sel에 .on 클래스 추가
      .sel(style='width:170px',
        :class="{'on' : queryForm.isSelectCampaignStatus}",
        @click="queryForm.isSelectCampaignStatus = !queryForm.isSelectCampaignStatus")
        // [D] select_lst 항목 선택 시 해당 내용 a 태그에 병기하여 추가, "selected" 클래스 추가
        a.selected
          | {{displaySelectedCampaignStatus}}
          span.ic_arrow_lg
        .dropdown_layer
          //
            [D]
            스크롤바 라이브러리 사용
            div.dropdown_wrap scrollbar-inner 클래스 추가 시 라이브러리 동작 함.
          .dropdown_wrap.scrollbar-inner
            ul.select_lst
              li
                span.inp_checkbox2
                  // [D] 선택 시 .is_checked 추가
                  input#check-all.inp_chk(
                    type='checkbox', :checked='queryForm.campaignStatus.length === 4', @click="checkCampaignStatusAll($event)")
                  label.check_control_label(for='check-all')
                    span.ico_chk
                    | 전체
              li
                span.inp_checkbox2
                  // [D] 선택 시 .is_checked 추가
                  input#check-save.inp_chk(type='checkbox', :checked="checked.creating", value="CREATING", @click="setCampaignStatusQuery($event)")
                  label.check_control_label(for='check-save')
                    span.ico_chk
                    | 임시저장
              li
                span.inp_checkbox2
                  // [D] 선택 시 .is_checked 추가
                  input#check-wait.inp_chk(type='checkbox', :checked="checked.waitApproval", value="WAIT_APPROVAL", @click="setCampaignStatusQuery($event)")
                  label.check_control_label(for='check-wait')
                    span.ico_chk
                    | 심사중
              li
                span.inp_checkbox2
                  // [D] 선택 시 .is_checked 추가
                  input#check-work.inp_chk(type='checkbox', :checked="checked.active", value="ACTIVE", @click="setCampaignStatusQuery($event)")
                  label.check_control_label(for='check-work')
                    span.ico_chk
                    | 작동
              li
                span.inp_checkbox2
                  // [D] 선택 시 .is_checked 추가
                  input#check-end.inp_chk(type='checkbox', :checked="checked.inactive", value="INACTIVE", @click="setCampaignStatusQuery($event)")
                  label.check_control_label(for='check-end')
                    span.ico_chk
                    | 종료
</template>

<script>
import _ from '@/partial'
import { mapGetters } from 'vuex'

export default {
  name: 'CampaignStatusBoxItem',
  data () {
    return {
      queryForm: {
        isSelectCampaignStatus: false,
        campaignStatus: ['CREATING', 'WAIT_APPROVAL', 'ACTIVE', 'INACTIVE']
      }
    }
  },
  methods: {
    close () {
      this.queryForm.isSelectCampaignStatus = false
    },
    setCampaignStatusQuery (e) {
      e.target.checked
        ? this.queryForm.campaignStatus.push(e.target.value)
        : this.queryForm.campaignStatus = this.queryForm.campaignStatus.filter(value => value !== e.target.value)
      this.$emit('select', this.queryForm.campaignStatus)
    },
    checkCampaignStatusAll (e) {
      e.target.checked
        ? this.queryForm.campaignStatus = ['CREATING', 'WAIT_APPROVAL', 'ACTIVE', 'INACTIVE']
        : this.queryForm.campaignStatus = []
      this.$emit('select', this.queryForm.campaignStatus)
    }
  },
  computed: {
    ...mapGetters(['advertiser']),
    displaySelectedCampaignStatus () {
      const status = this.queryForm.campaignStatus
      const length = status.length
      if (length === 4) {
        return '전체'
      } else if (length === 0) {
        return '미지정'
      }

      const displaySelectedCampaignStatus = []
      _.each(
        this.queryForm.campaignStatus,
        (value) => {
          switch (value) {
            case 'ACTIVE': displaySelectedCampaignStatus.push('작동')
              break
            case 'INACTIVE': displaySelectedCampaignStatus.push('종료')
              break
            case 'WAIT_APPROVAL': displaySelectedCampaignStatus.push('심사중')
              break
            case 'CREATING': displaySelectedCampaignStatus.push('임시저장')
              break
          }
        }
      )
      return displaySelectedCampaignStatus.join(', ')
    },
    checked () {
      return {
        active: this.queryForm.campaignStatus.includes('ACTIVE'),
        inactive: this.queryForm.campaignStatus.includes('INACTIVE'),
        waitApproval: this.queryForm.campaignStatus.includes('WAIT_APPROVAL'),
        creating: this.queryForm.campaignStatus.includes('CREATING')
      }
    }
  }
}
</script>

<style scoped>

</style>
